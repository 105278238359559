import { ReactElement, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Select,
  useDisclosure,
} from '@rhythm/components'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'

import { useScheduledTransmissionTypeControllerUpdate } from '../../../../../../features/scheduledtransmissions/api/updateScheduledTransmissionType'
import { DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest } from '../../../../../../lib/api'
import { TRANSMISSION_TYPES } from '../constants'

interface ChangeDatePopoverIconButtonProps {
  onDateChange: (date: Date, index: number) => void
  previousTransmissionDate?: string | null
  currentScheduledDate: string
  isActive: boolean
  index: number
  transmissionId: string
}

const ChangeDatePopoverIconButton = ({
  onDateChange,
  previousTransmissionDate,
  currentScheduledDate,
  isActive,
  index,
  transmissionId,
}: ChangeDatePopoverIconButtonProps): ReactElement => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [newTransmissionType, setNewTransmissionType] = useState<any>()
  const [error, setError] = useState('')
  const [isSavingDisabled, setIsSavingDisabled] = useState(false)
  const defaultDate = moment(currentScheduledDate).startOf('day').toDate()
  const [date, setDate] = useState<Date>(defaultDate)
  const updateScheduledTransmissionMutation =
    useScheduledTransmissionTypeControllerUpdate()
  const minDate = useMemo(() => {
    if (previousTransmissionDate) {
      return moment(previousTransmissionDate)
        .startOf('day')
        .add(1, 'days')
        .toDate()
    }

    return moment().startOf('day').toDate()
  }, [previousTransmissionDate])
  const handleSelectChange = (newValue: any) => {
    setNewTransmissionType(newValue)
    setError('')
    setIsSavingDisabled(false)
  }

  const handleUpdateScheduleTransmissionType = async () => {
    if (!newTransmissionType) {
      setError('Please select a transmission type')
      setIsSavingDisabled(true)
      return
    }
    try {
      const requestPayload: DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest =
        {
          id: transmissionId,
          updateScheduledTransmissionTypeDto: {
            transmissionType: newTransmissionType.value,
          },
        }
      await updateScheduledTransmissionMutation.mutateAsync(requestPayload)
    } catch (error) {
      console.log(error)
    }
  }

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const menuButton = (
    <MenuButton
      as={IconButton}
      aria-label="Options"
      icon="menu"
      variant="plain"
      background="none"
      boxSize="md"
      px="4"
      _hover={{
        color: 'primary.400',
      }}
      sx={{
        svg: {
          height: '16px',
          width: '16px',
        },
        cursor: 'pointer !important',
        '&:disabled': {
          background: 'none',
        },
      }}
      disabled={!isActive}
    />
  )
  const changeDatePopover = (
    <Popover
      placement="bottom-end"
      isLazy
      isOpen={isOpen}
      onClose={() => {
        setDate(defaultDate)
        onClose()
      }}
    >
      <PopoverTrigger>{menuButton}</PopoverTrigger>
      <PopoverContent w="275px">
        <PopoverBody>
          <Box
            __css={{
              '.react-datepicker': {
                borderColor: 'neutral.400',
                borderRadius: 'md',
                borderWidth: '0.5px',
                width: '100%',
                // boxShadow: 'dropdown',
                fontFamily: 'body',
              },
              '.react-datepicker__month-container': {
                width: 'inherit',
              },

              '.react-datepicker__header': {
                backgroundColor: 'neutral.white',
                border: 'none',
                padding: 'none',
                margin: 'xl',
              },
              '.react-datepicker__day-name': {
                color: 'neutral.800',
                fontSize: 'sm',
                lineHeight: '22px',
                letterSpacing: 'base',
                width: 'sm',
                margin: 'none',
                _notLast: {
                  mr: 'xl',
                },
              },
              '.react-datepicker__day': {
                // color: 'neutral.black',
                // lineHeight: '22px',
                letterSpacing: 'base',
                fontSize: 'sm',
                fontWeight: 'bold',
                width: 'sm',
                margin: 'none',
                mb: 'md',
                _notLast: {
                  mr: 'xl',
                },
                _hover: {
                  backgroundColor: 'neutral.400',
                  borderRadius: '2px',
                },
              },
              '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
                {
                  color: 'neutral.white',
                  backgroundColor: 'primary.400',
                  borderRadius: '2px',
                  _hover: {
                    backgroundColor: 'primary.400',
                  },
                },
              '.react-datepicker__day--selected': {
                color: 'white !important',
              },
              // '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected': {
              //   color: 'neutral.white',
              //   backgroundColor: 'primary.400',
              //   borderRadius: '2px',
              //   _hover: {
              //     backgroundColor: 'primary.400',
              //   },
              // },
              // '.react-datepicker__day--selected': {
              //   color: 'white !important',
              // },
            }}
          >
            <ReactDatePicker
              minDate={minDate}
              selected={date}
              inline
              onChange={date => {
                if (date) {
                  setDate(date)
                }
              }}
            />
          </Box>
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <Button
            variant="secondaryDark"
            size="sm"
            onClick={() => {
              setDate(defaultDate)
              onClose()
            }}
            mr={2}
          >
            Cancel
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              onDateChange(date, index)
              onClose()
            }}
          >
            Confirm
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )

  if (!isActive) {
    return (
      <Menu>
        {menuButton}
        <MenuList>
          <MenuItem onClick={onToggle}>Change Date</MenuItem>
          <MenuItem onClick={onModalOpen}>Change Transmission Type</MenuItem>
        </MenuList>
      </Menu>
    )
  }
  return (
    <Menu>
      <MenuList>
        <MenuItem onClick={onToggle}>Change Date</MenuItem>
        <MenuItem onClick={onModalOpen}>Change Transmission Type</MenuItem>
      </MenuList>
      {changeDatePopover}

      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        isCentered
        header={<Heading variant={'h5'}>Change Transmission Type To</Heading>}
      >
        <Select
          placeholder="Please select the transmission type"
          value={newTransmissionType}
          onChange={handleSelectChange}
          options={TRANSMISSION_TYPES}
          isInvalid={!!error}
        />
        {error && (
          <div style={{ color: 'red', marginTop: '0.5rem' }}>{error}</div>
        )}
        <HStack mt={8}>
          <Button variant="secondaryLight" size={'md'} onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size={'md'}
            onClick={handleUpdateScheduleTransmissionType}
            disabled={isSavingDisabled}
          >
            Save
          </Button>
        </HStack>
      </Modal>
    </Menu>
  )
}

export default ChangeDatePopoverIconButton
