import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { UpdateSettingsDTO } from '../../../lib/api'

export const useUpdateManyFeatureFlags = () => {
  const Api = useApiContext()
  const queryClient = useQueryClient()

  return useMutation(
    (UpdateSettingsDTO: UpdateSettingsDTO[]) =>
      Api.featureFlagControllerUpdateMany(UpdateSettingsDTO),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('featureFlags')
        queryClient.invalidateQueries('featureFlagsAdmin')
      },
      onError: (error: AxiosError) => {
        console.error('Error updating feature flag:', error.message)
        // Additional error handling logic (e.g., show error notification)
      },
    },
  )
}
