import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'

export const useGetICDDiagnosisCodes = (
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const Api = useApiContext()
  return useQuery(
    ['icdDiagnosisCodes'],
    async () => {
      const res =
        await Api.transmissionReportsControllerFetchICDDiagnosisCodes()
      const { icdDiagnosisCodes } = res.data
      const transformedCodes = [
        { label: '-', value: '-' },
        ...icdDiagnosisCodes.map(({ code, title }) => ({
          label: `${code} - ${title}`,
          value: `${code}`,
        })),
      ]
      return transformedCodes
    },
    {
      ...options,
      staleTime: twentyFourHoursInMs,
    },
  )
}
