import { IconButton } from '@chakra-ui/react'
import {
  Flex,
  Icon,
  MenuItem,
  Spinner,
  Text,
  Tooltip,
} from '@rhythm/components'
import moment from 'moment'

import { ClinicalNote } from '../../../../../lib/api'

export interface ColumnCellProps {
  row: { original: ClinicalNote; index: number; id: string }
  value: any
  isOnTransmissionReportPage?: boolean
}

export const MAX_NOTES_PER_PAGE = 20
export const MAX_PINNED_NOTES = 3

export const DateCell = ({
  value,
  isOnTransmissionReportPage,
}: ColumnCellProps) => {
  return (
    <div>
      <Text
        variant="secondary"
        fontSize={isOnTransmissionReportPage ? 'sm' : 'md'}
        fontWeight={'bold'}
      >
        {moment(value).format('MM-DD-YYYY')}
      </Text>
      <Text
        variant="secondary"
        fontSize={isOnTransmissionReportPage ? 'sm' : 'md'}
      >
        {moment(value).format('hh:mm A')}
      </Text>
    </div>
  )
}

export const OwnerCell = ({
  value,
  isOnTransmissionReportPage,
}: ColumnCellProps) => {
  return (
    <Text
      variant="secondary"
      fontSize={isOnTransmissionReportPage ? 'sm' : 'md'}
      fontWeight={'bold'}
      textTransform={'capitalize'}
    >
      {value.given} {value.family}
    </Text>
  )
}

export const NoteCell = ({
  value,
  isOnTransmissionReportPage,
}: ColumnCellProps) => {
  return (
    <Text
      variant="secondary"
      fontSize={isOnTransmissionReportPage ? 'sm' : 'md'}
    >
      {value}
    </Text>
  )
}

export const RowActionIconButton = ({
  icon,
  label,
  onClick,
  isLoading = false,
  disabled = false,
  color = 'gray.500',
  ...props
}: any) => (
  <Tooltip label={label}>
    <IconButton
      variant="ghost"
      size="xs"
      width="18px"
      aria-label={label}
      icon={
        isLoading ? (
          <Spinner size="xs" color="primary.400" />
        ) : (
          <Icon icon={icon} color={color} style={{ width: '18px' }} />
        )
      }
      onClick={onClick}
      disabled={disabled}
      {...props}
    />
  </Tooltip>
)

export const ActionIconButton = ({
  isLoading,
  icon,
  onClick,
  ...props
}: any) => (
  <IconButton
    variant="ghost"
    size="xs"
    disabled={isLoading}
    width="18px"
    aria-label={icon}
    icon={
      isLoading ? (
        <Spinner size="xs" color="primary.400" />
      ) : (
        <Icon icon={icon} color="gray.500" style={{ width: '18px' }} />
      )
    }
    onClick={onClick}
    {...props}
  />
)

export const ActionMenuItem = ({
  onClick,
  icon,
  label,
  color = 'gray.500',
  ...props
}: any) => (
  <MenuItem onClick={onClick} {...props}>
    <Flex alignItems="center" width="100%">
      <Icon icon={icon} color={color} style={{ width: '16px' }} mr={2} />
      {label}
    </Flex>
  </MenuItem>
)
