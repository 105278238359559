import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Box,
  Button,
  Error,
  Heading,
  HStack,
  Spinner,
  VStack,
} from '@rhythm/components'
import { Controller, useForm } from 'react-hook-form'

import { EmptyState } from '../../../../../components/Notes'
import Note from '../../../../../components/Notes/Note'
import { useCreateClinicalNote } from '../../../../../features/clinicalNotes/createClinicalNote'
import { useUpdateClinicalNote } from '../../../../../features/clinicalNotes/updateClinicalNote'
import { useToastHook } from '../../../../../hooks/useToastHook'
import { ClinicalNote } from '../../../../../lib/api'

const CLINICAL_NOTE_CHAR_LIMIT = 255

export const AddClinicalNote = ({
  patientId: patientIdStr,
  onClose,
  selectedNote,
  setSelectedNote,
}: {
  patientId?: string
  onClose?: () => void
  selectedNote: ClinicalNote | null
  setSelectedNote: (note: ClinicalNote | null) => void
}): React.ReactElement => {
  const { id: patientIdFromUrl } = useParams<{ id: string }>()
  const patientId = patientIdStr ?? patientIdFromUrl

  const { showToast } = useToastHook()
  const [noteClicked, setNoteClicked] = useState(!!selectedNote)
  const { mutateAsync: createClinicalNote, isLoading: isCreateLoading } =
    useCreateClinicalNote()
  const { mutateAsync: updateClinicalNote, isLoading: isUpdateLoading } =
    useUpdateClinicalNote(patientId)
  const { control, reset, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      clinicalNote: '',
    },
  })
  useEffect(() => {
    if (selectedNote) {
      setValue('clinicalNote', selectedNote.content)
      setNoteClicked(true)
    }
  }, [selectedNote, setValue])

  const cleanUp = () => {
    reset()
    setNoteClicked(false)
    selectedNote && setSelectedNote(null)
    onClose && onClose()
  }

  const handleSaveClick = async (value: string) => {
    try {
      if (selectedNote) {
        await updateClinicalNote({
          updateNoteRequest: { content: value },
          noteId: selectedNote.id,
        })
      } else {
        await createClinicalNote({
          patientId,
          content: value,
        })
      }
      showToast(
        selectedNote
          ? 'Clinical note updated successfully.'
          : 'Clinical note saved successfully.',
      )
    } catch (error) {
      showToast('Failed to save note. Please try again.', 'error')
    }
    cleanUp()
  }

  const NoteOrEmptyState = ({
    onChange,
    onBlur,
    value,
    error,
  }: {
    onChange: (newVal: string) => void
    onBlur: () => void
    value: string
    error: any
  }) => {
    return (
      <>
        {noteClicked ? (
          <>
            <Note
              value={value ?? ''}
              onChange={newVal => onChange(newVal)}
              onBlur={() => {
                onBlur()
                if (!value) {
                  setNoteClicked(false)
                  setSelectedNote(null)
                }
              }}
              shouldFocus={noteClicked}
              charLimit={CLINICAL_NOTE_CHAR_LIMIT}
            />
            {error?.message && <Error message={error.message} />}
          </>
        ) : (
          <>
            <EmptyState
              onClick={() => setNoteClicked(true)}
              style={{
                height: '122px',
              }}
            />
          </>
        )}
        <HStack spacing="md" mt="xl" justifyContent={'flex-end'}>
          <Button
            variant="secondaryLight"
            disabled={!value || isCreateLoading || isUpdateLoading}
            onClick={() => cleanUp()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleSaveClick(value)}
            disabled={
              !!error ||
              !value ||
              isCreateLoading ||
              isUpdateLoading ||
              value.trim() === selectedNote?.content
            }
          >
            {(isCreateLoading || isUpdateLoading) && (
              <Spinner size="sm" mr={3} />
            )}
            {selectedNote ? 'Update' : 'Save'}
          </Button>
        </HStack>
      </>
    )
  }

  return (
    <HStack spacing="xl" mb="lg" width={'100%'}>
      <VStack spacing="xl" width={'100%'}>
        <div
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            display: 'flex',
            width: '100%',
          }}
        >
          <Heading variant="h5">
            {selectedNote ? 'Edit Clinical Note' : 'Add New Note'}
          </Heading>
        </div>
        <Box mb="xl" width={'100%'}>
          <Controller
            name={'clinicalNote'}
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <NoteOrEmptyState
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={error}
              />
            )}
            rules={{
              maxLength: {
                value: CLINICAL_NOTE_CHAR_LIMIT,
                message: `Note cannot be longer than ${CLINICAL_NOTE_CHAR_LIMIT} characters.`,
              },
            }}
          />
        </Box>
      </VStack>
    </HStack>
  )
}
