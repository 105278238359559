import { useMutation } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  DefaultApiAnalyticsControllerDownloadReportRequest,
  DownloadReportRequest,
} from '../../lib/api'

export const useDownloadAnalyticsReport = () => {
  const Api = useApiContext()
  return useMutation(async (params: DownloadReportRequest) => {
    const res = await Api.analyticsControllerDownloadReport({
      downloadReportRequest: params,
    } as DefaultApiAnalyticsControllerDownloadReportRequest)
    return res.data
  })
}
