import {
  Column,
  DataTable,
  TableCell,
  TableHeader,
  TableRow,
} from '@rhythm/components'

import { RegisteredNpis } from '../../../lib/api'

interface NpiListProps {
  data: RegisteredNpis[]
  columns?: Column[]
  isLoading?: boolean
  getData?: (data: any) => void
  onRowClick?: (data: any) => void
  total?: number
  totalPageCount?: any
  initialPageIndex: number
  initialPageSize: number
}

const NpiList = ({
  data,
  columns,
  isLoading,
  total = 50,
  totalPageCount,
  onRowClick,
  getData,
  initialPageIndex,
  initialPageSize,
}: NpiListProps) => {
  return (
    <DataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      fetchData={getData}
      isSortable
      totalRowCount={total}
      totalPageCount={totalPageCount}
      initialPageSize={initialPageSize}
      onRowClick={onRowClick}
      isClickable={onRowClick !== undefined}
      initialPageIndex={initialPageIndex}
      goToPageIndex={initialPageIndex}
      // hasPagination
      tableBodyCell={<TableCell px="2xl" py="xl" valign="top" />}
      variant="striped"
      colorScheme="#F6F8FC"
      tableBodyRow={() => (
        <TableRow
          borderBottomColor="neutral.200"
          borderBottomStyle="solid"
          borderBottomWidth="2px"
          bg="neutral.white"
          borderRadius="4px"
          position="relative"
          sx={{
            'svg:first-of-type': {
              display: 'none',
            },
            'td:first-of-type': {
              borderRadius: '4px 0 0 4px',
            },
            'td:last-of-type': {
              borderRadius: '0 4px 4px 0',
            },
            _hover: {
              'svg:first-of-type': {
                display: 'block',
              },
            },
          }}
        />
      )}
      tableHeadCell={
        <TableHeader
          color="neutral.800"
          bg="neutral.white"
          borderBottomColor="neutral.200"
          borderBottomStyle="solid"
          borderBottomWidth="2px"
          px="2xl"
          py="xl"
        />
      }
    />
  )
}

export default NpiList
