import { Box, Flex, Icon, Text } from '@rhythm/components'
import { IconName, IconNameFilled } from '@rhythm/components/dist/Icon/IconsMap'

import { useAnalyticsContext } from '../../../../context/AnalyticsContext'
import { useFeatureFlagsContext } from '../../../../context/FeatureFlagsContext'
import { routes } from '../../../../routes'

import { fetchAnalyticsProps } from './columns'

interface MenuItem {
  icon: IconName | IconNameFilled
  iconActive?: IconName | IconNameFilled
  label: string
  route: string
}

const analyticsMenu: MenuItem[] = [
  {
    icon: 'hospital',
    iconActive: 'hospital-active',
    label: 'Account Overview',
    route: routes.physician.analytics.accountOverview,
  },
  {
    icon: 'status-warning',
    label: 'Alerts',
    route: routes.physician.analytics.alerts,
  },
  {
    icon: 'dollar-circle',
    iconActive: 'dollar-circle-active',
    label: 'Billing',
    route: routes.physician.analytics.billing,
  },
  {
    icon: 'calendar',
    label: 'Scheduling',
    route: routes.physician.analytics.scheduling,
  },
  {
    icon: 'signature',
    label: 'Signature Status',
    route: routes.physician.analytics.signatureStatus,
  },
]

export const AnalyticsNavBar = () => {
  const { hasFeatureFlag } = useFeatureFlagsContext()
  const isSchedulingAnalyticsEnabled = hasFeatureFlag('schedulingAnalytics')
  const menuItems = analyticsMenu.filter(
    menuItem => isSchedulingAnalyticsEnabled || menuItem.label !== 'Scheduling',
  )
  return (
    <Box
      as="nav"
      aria-label="Main navigation"
      bg="white"
      boxShadow="md"
      p={4}
      width={'250px'}
      height={'100vh'}
    >
      <Box p={4}>
        <Text fontSize={'3xl'}>Analytics</Text>
      </Box>
      <Box mt={5} />
      <Box p={2}>
        {menuItems.map((menuItem, index) => (
          <NavItem
            key={index}
            menuItem={menuItem}
            active={menuItem.route === window.location.pathname}
          />
        ))}
      </Box>
    </Box>
  )
}

const NavItem = ({
  menuItem,
  active,
}: {
  menuItem: MenuItem
  active?: boolean
}) => {
  const { updateActiveAnalyticsQueryParams, setResetQueryParamsToTable } =
    useAnalyticsContext()

  return (
    <Flex
      alignItems="center"
      width={'100%'}
      p={3}
      cursor={'pointer'}
      _hover={{
        bg: active ? 'blue.100' : 'blue.50',
      }}
      bg={active ? 'blue.50' : 'transparent'}
      borderRadius="sm"
      onClick={() => {
        if (active) return
        const { defaultQuery } = fetchAnalyticsProps(menuItem.route)
        updateActiveAnalyticsQueryParams(defaultQuery)
        sessionStorage.setItem('selectedAnalyticsTab', menuItem.route)
        setResetQueryParamsToTable(menuItem.route)
      }}
    >
      {active && menuItem.iconActive ? (
        <Icon icon={menuItem.iconActive} />
      ) : (
        <Icon icon={menuItem.icon} color={active ? 'blue.600' : ''} />
      )}
      <Text
        ml={2}
        fontSize={'xl'}
        fontWeight={active ? 'bold' : ''}
        color={active ? 'blue.600' : ''}
      >
        {menuItem.label}
      </Text>
    </Flex>
  )
}
