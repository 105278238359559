import React from 'react'

import 'pure-react-carousel/dist/react-carousel.es.css'

import { Box } from '@rhythm/components'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'

import CarouselControls from './CarouselControls'

export interface RecentlyViewedCarouselProps {
  children: React.ReactElement[]
  slideHeight?: number
  slideWidth?: number
  visibleSlides?: number
}

const RecentlyViewedCarousel = ({
  children,
  slideHeight = 250,
  slideWidth = 380,
  visibleSlides = 3,
}: RecentlyViewedCarouselProps): React.ReactElement => {
  return (
    <Box
      position="relative"
      sx={{
        '.carousel__slide-focus-ring': {
          display: 'none',
        },
        '.carousel__inner-slide': {
          width: 'calc(100% - 16px)',
        },
      }}
    >
      <CarouselProvider
        naturalSlideHeight={slideHeight}
        naturalSlideWidth={slideWidth}
        totalSlides={children.length}
        visibleSlides={visibleSlides}
        dragEnabled={false}
      >
        <Slider>
          {children.map((child, index) => (
            <Slide key={index} index={index}>
              {child}
            </Slide>
          ))}
        </Slider>
        <CarouselControls />
      </CarouselProvider>
    </Box>
  )
}

export default RecentlyViewedCarousel
