import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  DefaultApiAnalyticsControllerGetPractitionersRequest,
  GetPractitionersQuery,
} from '../../lib/api'

export const useGetPractitioners = (
  getPractitionersQuery: GetPractitionersQuery,
) => {
  const Api = useApiContext()
  return useQuery(
    'analyticsPractitioners',
    async () => {
      const res = await Api.analyticsControllerGetPractitioners({
        getPractitionersQuery,
      } as DefaultApiAnalyticsControllerGetPractitionersRequest)
      return res.data
    },
    {
      enabled:
        !!getPractitionersQuery &&
        !!getPractitionersQuery.clinicIds &&
        getPractitionersQuery.clinicIds.length > 0,
    },
  )
}
