import { CSSProperties, FocusEvent, useEffect } from 'react'

import { Box, Error, Heading, Skeleton } from '@rhythm/components'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { TransmissionReportNote } from '../../lib/api/generated-client'

import Note from './Note'

export type NoteFieldValue = {
  id: string
  note: string // Allow undefined
  flag: boolean
}

export interface NotesProps {
  notes?: TransmissionReportNote[]
  groupName: string
  onSubmit: (note: NoteFieldValue, alertId?: string, flag?: boolean) => void
  onDelete: (noteId: string, note?: any) => void
  max?: number
  canAdd?: boolean
  isLoading?: boolean
  alertId?: string
}
interface EmptyStateProps {
  onClick?: () => void
  placeholder?: string
  style?: CSSProperties
}

export const EmptyState = ({
  onClick,
  placeholder,
  style,
}: EmptyStateProps) => (
  <Box
    width="100%"
    bg="neutral.200"
    borderWidth="0.5px"
    borderRadius="md"
    p="xl"
    _hover={{
      cursor: onClick ? 'pointer' : 'default',
    }}
    onClick={onClick}
    style={style ? style : {}}
  >
    <Heading variant="h6" fontWeight="regular">
      {placeholder || 'Click here to add a note...'}
    </Heading>
  </Box>
)
export const Notes = ({
  notes,
  groupName,
  onSubmit,
  onDelete,
  max,
  canAdd = true,
  isLoading,
}: NotesProps) => {
  const maxCount = max ?? 5

  const initialFieldValues: NoteFieldValue[] | undefined = notes?.map(
    ({ id, note, flag }) => ({
      id,
      note: note ?? '', // Initialize empty strings for undefined notes
      flag,
    }),
  )

  const { control, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      [groupName]: initialFieldValues,
    },
  })

  const { fields, append } = useFieldArray({
    control,
    name: groupName,
    keyName: 'fieldId',
  })

  useEffect(() => {
    reset({
      [groupName]: initialFieldValues,
    })
  }, [notes])

  const handleBlur = (
    e: FocusEvent<HTMLTextAreaElement>,
    onBlur: () => void,
    index: number,
  ) => {
    const field = fields[index]
    const { id, note, flag } = field
    onBlur()
    const updatedNote = e.target.value.trim()
    if (updatedNote !== note) {
      if (updatedNote) {
        onSubmit({ id, note: updatedNote, flag })
      } else {
        onDelete(id, field)
      }
    }
  }

  const handleAddNewNote = () => {
    if (canAdd && fields.length < maxCount) {
      append({ id: '', note: '', flag: false } as NoteFieldValue)
    }
  }

  return (
    <Box>
      <Skeleton isLoaded={!isLoading} noOfLines={maxCount}>
        {fields.length > 0 ? (
          fields.map((field, index) => (
            <Box key={field.fieldId} mb="xl">
              <Controller
                name={`${groupName}.${index}.note`}
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <Note
                      value={value ?? ''}
                      onBlur={e => {
                        if (!error) handleBlur(e, onBlur, index)
                      }}
                      onChange={newVal => onChange(newVal)}
                      shouldFocus={!field.id}
                    />
                    {error?.message && <Error message={error.message} />}
                  </>
                )}
                rules={{
                  maxLength: {
                    value: 2000,
                    message: 'Notes cannot be longer than 2000 characters.',
                  },
                }}
              />
            </Box>
          ))
        ) : (
          <EmptyState onClick={handleAddNewNote} />
        )}
      </Skeleton>
    </Box>
  )
}

Notes.displayName = 'Notes'
export default Notes
