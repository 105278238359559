import React, { useMemo } from 'react'

import {
  Avatar,
  Box,
  Card,
  CardProps,
  Flex,
  Heading,
  HStack,
  Icon,
  Skeleton,
  SkeletonCircle,
  SkeletonProps,
  Text,
  VStack,
} from '@rhythm/components'
import moment from 'moment'

import { BatteryIcon } from '../../../../../assets'
import { TransmissionReportDto } from '../../../../../lib/api'
import { AppDeterminedBillableReasons } from '../../../../../utils/constants/transmissionReports'
import formatUtcDate from '../../../../../utils/formatUtcDate'
import { DollarSignIconBlack } from '../BillingCard/CustomIcons'
import UpdateTransmissionColorInput from '../UpdateTransmissionColorInput'

export interface TransmissionSummaryCardProps extends CardProps {
  transmissionReport?: TransmissionReportDto
}

const TransmissionSummaryCard = ({
  transmissionReport,
}: TransmissionSummaryCardProps): React.ReactElement => {
  const { givenName, familyName } = transmissionReport?.patient || {}

  const flagHeartFailure: any = transmissionReport?.flagHeartFailure ?? false

  const deviceType = transmissionReport?.device?.deviceType
  let showType = ''
  if (deviceType && deviceType !== 'no_device_type') {
    showType = deviceType === 'PM' ? 'Pacemaker' : deviceType
  }
  if (
    transmissionReport?.transmissionType &&
    ['in-clinic', 'manual'].includes(transmissionReport.transmissionType)
  ) {
    showType =
      transmissionReport.transmissionType === 'in-clinic'
        ? 'In-clinic'
        : 'Manual'
  }

  const { Skel, SkelCircle } = useMemo(
    () => ({
      Skel: (args: SkeletonProps) => (
        <Skeleton
          isLoaded={
            transmissionReport !== undefined && deviceType !== undefined
          }
          {...args}
        />
      ),
      SkelCircle: (args: SkeletonProps) => (
        <SkeletonCircle
          isLoaded={
            transmissionReport !== undefined && deviceType !== undefined
          }
          {...args}
        />
      ),
    }),
    [transmissionReport, deviceType],
  )
  return (
    <Flex position="relative" pt="xl">
      <Box
        background="neutral.400"
        borderRadius="md"
        boxShadow="card"
        height="xl"
        position="absolute"
        top={0}
        left={0}
        right={0}
        mx="xl"
      />
      <Card display="flex" position="relative" width="100%">
        <VStack width="100%" alignItems="flex-start" spacing="xl" mb="lg">
          <HStack spacing="lg" mb="md" width="100%">
            <SkelCircle>
              <Avatar name={`${givenName} ${familyName}`} size="sm" />
            </SkelCircle>
            <Skel>
              <Heading variant="h6">{`${givenName} ${familyName}`}</Heading>
            </Skel>
            {transmissionReport?.isEriTracked && (
              <Box
                backgroundColor="#DFDAFB"
                padding="1"
                marginLeft="1"
                marginRight="1"
                borderRadius="sm"
              >
                <HStack spacing="1">
                  <BatteryIcon />
                  <Text fontSize="11px" color="#2612A5" fontWeight="bold">
                    ERI TRACKED
                  </Text>
                </HStack>
              </Box>
            )}
          </HStack>
          {(transmissionReport?.color === 'GREEN' ||
            transmissionReport?.color === 'GRAY') &&
          transmissionReport.smartNarrativeStatus === 'True' ? (
            <HStack
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding="4px 6px"
              gap="4px"
              width="123px"
              height="20px"
            >
              <Box
                background="#EDF7FE"
                borderRadius="4px"
                padding="4px 6px 4px 6px"
                width="123px"
                height="20px"
              >
                <Text
                  width="111px"
                  height="12px"
                  fontFamily="Lato"
                  fontStyle="normal"
                  fontWeight={700}
                  fontSize="11px"
                  lineHeight="12px"
                  textAlign="center"
                  letterSpacing="0.06em"
                  textTransform="uppercase"
                  color="#0E6DAD"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="clip"
                >
                  smart narrative
                </Text>
              </Box>
            </HStack>
          ) : (
            ''
          )}
          <HStack spacing="lg" mb="md" style={{ width: '100%' }}>
            <HStack alignItems="center" spacing="lg" style={{ width: '100%' }}>
              <UpdateTransmissionColorInput
                transmissionReport={transmissionReport}
              />
              <VStack
                alignItems="flex-start"
                spacing="0"
                flex={1}
                style={{ width: '50%' }}
              >
                <Skel>
                  <Text color="neutral.800" fontWeight="bold">
                    {transmissionReport?.transmissionType === 'remote'
                      ? formatUtcDate(
                          transmissionReport?.transmissionDate,
                          'MM/DD/YYYY h:mmA UTC',
                        )
                      : ''}
                  </Text>
                </Skel>
                <Skel h="lg">
                  <Heading variant="h2" mt={0} size={'md'}>
                    <span> </span>
                    {showType}
                    {flagHeartFailure ? ' Heart Failure' : ''} Report
                  </Heading>
                </Skel>
              </VStack>
              {transmissionReport?.transmissionType === 'remote' && (
                <VStack
                  alignItems="flex-start"
                  spacing="2"
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    width: '50%',
                  }}
                >
                  <HStack spacing={2}>
                    <Skel h="lg">
                      <div id="infoSign">
                        <Icon
                          icon="calendar"
                          boxSize={6}
                          fontSize={12}
                          id="infoIconStyle"
                          color={'black'}
                        />
                      </div>
                      <div>
                        Next Scheduled Transmission:{'  '}
                        {transmissionReport?.nextScheduledTransmissionDate
                          ? moment
                              .utc(
                                transmissionReport?.nextScheduledTransmissionDate,
                              )
                              .format('MM/DD/YYYY')
                          : '-'}
                      </div>
                    </Skel>
                  </HStack>
                  <HStack spacing={2}>
                    <Skel h="lg">
                      <div id="infoSign">
                        <Icon
                          icon="calendar"
                          boxSize={6}
                          fontSize={12}
                          id="infoIconStyle"
                          color={'black'}
                        />
                      </div>
                      <div>
                        Last Billing Date:{'  '}
                        {transmissionReport?.lastBillableDate
                          ? moment(transmissionReport?.lastBillableDate).format(
                              'MM/DD/YYYY',
                            )
                          : '-'}
                      </div>
                    </Skel>
                  </HStack>
                  {transmissionReport?.billing?.isAppDeterminedBillable && (
                    <HStack spacing={2}>
                      <Skel h="lg">
                        <div id="infoSign">
                          <DollarSignIconBlack />
                        </div>
                        <span>
                          {'App has determined this report to be billable'}
                        </span>
                      </Skel>
                    </HStack>
                  )}
                  {transmissionReport?.billing?.appDeterminedReason ===
                    AppDeterminedBillableReasons.SIXTY_TWENTY_RULE && (
                    <HStack style={{ alignItems: 'flex-start' }}>
                      <Skel h="lg">
                        <div id="infoSign">
                          <Icon
                            icon="information"
                            boxSize={6}
                            fontSize={12}
                            id="infoIconStyle"
                            color={'black'}
                          />
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            top: '7px',
                          }}
                        >
                          Previously dismissed report from{' '}
                          {moment
                            .utc(transmissionReport.transmissionDate)
                            .format('MM/DD')}{' '}
                          transmission date. Please review for processing.
                        </div>
                      </Skel>
                    </HStack>
                  )}
                </VStack>
              )}
            </HStack>
          </HStack>
          <VStack alignItems="flex-start" spacing="sm"></VStack>
        </VStack>
      </Card>
    </Flex>
  )
}

export default TransmissionSummaryCard
