import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerUpdateRequest,
  TransmissionReportCountResponseDto,
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportListResponseDto,
} from '../../../lib/api'
import { queryClient } from '../../../lib/api/react-query'
import { useTransmissionReportsListContext } from '../context'

export const useUpdateTransmissionReport = () => {
  const { setIsLoading: setIsLoadingTransmissionReportsList } =
    useTransmissionReportsListContext()

  const Api = useApiContext()
  return useMutation(
    (
      updateTransmissionReportParams: DefaultApiTransmissionReportsControllerUpdateRequest,
    ) =>
      Api.transmissionReportsControllerUpdate(updateTransmissionReportParams),
    {
      onSuccess: data => {
        setIsLoadingTransmissionReportsList(true)

        const { data: transmissionReport } = data
        const newColor =
          transmissionReport.color as unknown as TransmissionReportDtoColorEnum
        let oldColor =
          transmissionReport.color as unknown as TransmissionReportDtoColorEnum
        queryClient.setQueryData<Partial<TransmissionReportDto | undefined>>(
          ['transmissionReport', transmissionReport.id],
          cachedData => {
            if (!cachedData) return
            oldColor = cachedData.color!
            return {
              ...cachedData,
              ...transmissionReport,
            } as unknown as TransmissionReportDto
          },
        )

        queryClient.setQueryData<
          Partial<TransmissionReportListResponseDto | undefined>
        >(['transmissionReportsList'], previous => {
          if (!previous) return
          const transmissionReportIndex =
            previous?.transmissionReports?.findIndex(
              (cachedTransmissionReport: TransmissionReportDto) =>
                cachedTransmissionReport.id === transmissionReport.id,
            )

          if (
            previous?.transmissionReports &&
            transmissionReportIndex !== undefined
          ) {
            previous.transmissionReports[transmissionReportIndex] = {
              ...previous?.transmissionReports[transmissionReportIndex],
              ...(transmissionReport as unknown as Partial<TransmissionReportDto>),
            }
          }

          return previous
        })
        if (oldColor !== newColor) {
          queryClient.setQueryData<
            Partial<TransmissionReportCountResponseDto | undefined>
          >(['TransmissionReportsCountByColor'], previous => {
            if (!previous || !previous.counts) return

            const previousCounts: any = { ...previous.counts }
            previousCounts[oldColor] -= 1
            previousCounts[newColor] += 1

            return { ...previous, counts: previousCounts }
          })
        } else {
          queryClient.refetchQueries(['TransmissionReportsCountByColor'])
        }
        queryClient.invalidateQueries('transmissionReport')
        queryClient.invalidateQueries('patient')
        setIsLoadingTransmissionReportsList(false)
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
      },
    },
  )
}
