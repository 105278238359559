import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@rhythm/components'

import { useAnalyticsContext } from '../../../context/AnalyticsContext'
import { useGetAnalyticsAccountOverviewData } from '../../../features/reportGeneration/useGetAnalyticsAccountOverviewData'
import { AccountOverviewQuery } from '../../../lib/api'

import { AnalyticsDataTable } from './components/AnalyticsDataTable'
import { AnalyticsFiltersBar } from './components/AnalyticsFiltersBar'
import { AnalyticsLayout } from './components/AnalyticsLayout'
import {
  accountOverviewColumns,
  fetchAnalyticsProps,
} from './components/columns'

export const AccountOverview = () => {
  const { pathname } = useLocation()
  const { title } = fetchAnalyticsProps(pathname)
  const { activeAnalyticsQueryParams } = useAnalyticsContext()
  const { data, isLoading, refetch, isRefetching } =
    useGetAnalyticsAccountOverviewData(
      activeAnalyticsQueryParams as AccountOverviewQuery,
    )
  useEffect(() => {
    if (
      !!activeAnalyticsQueryParams &&
      !!activeAnalyticsQueryParams.clinicIds &&
      activeAnalyticsQueryParams.clinicIds.length > 0 &&
      activeAnalyticsQueryParams.dateRange &&
      activeAnalyticsQueryParams.dateRange.length > 0 &&
      activeAnalyticsQueryParams.columns &&
      activeAnalyticsQueryParams.columns.length > 0
    ) {
      refetch()
    }
  }, [activeAnalyticsQueryParams, refetch])

  const isTableDataLoading =
    (!!activeAnalyticsQueryParams &&
      activeAnalyticsQueryParams.clinicIds.length < 1) ||
    isLoading
  return (
    <AnalyticsLayout title={title}>
      <AnalyticsFiltersBar />
      <Box>
        <AnalyticsDataTable
          columns={accountOverviewColumns
            .map(column => {
              if (
                activeAnalyticsQueryParams?.columns?.includes(
                  column.accessor as never,
                )
              ) {
                return column
              }
            })
            .filter(Boolean)}
          data={data}
          isLoading={isTableDataLoading}
          isRefetching={isRefetching}
        />
      </Box>
    </AnalyticsLayout>
  )
}
