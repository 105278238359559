import React from 'react'

import { Box, Error, Form, Skeleton, TextareaField } from '@rhythm/components'
import { Controller, useForm } from 'react-hook-form'

import { useUpdateTransmissionReport } from '../../features/transmissionReports'
import { TransmissionReportDto } from '../../lib/api'

const contentMaxLength = 2000

export type CommentFieldValue = {
  id: TransmissionReportDto['id']
  comment: TransmissionReportDto['comment']
}

export interface CommentCardProps {
  transmissionReport?: TransmissionReportDto
}

export const CommentCard = ({
  transmissionReport,
}: CommentCardProps): React.ReactElement => {
  const { mutate, isLoading } = useUpdateTransmissionReport()
  const { comment: defaultComment, id: transmissionReportId } =
    transmissionReport ?? { comment: '', id: 0 }

  const handleUpdateComment = ({
    comment,
    shouldUpdate,
  }: {
    comment: string
    shouldUpdate: boolean
  }) => {
    if (shouldUpdate) {
      mutate({
        transmissionReportId: transmissionReportId ?? 0,
        updateTransmissionReportParams: { comment: comment ?? '' },
      })
    }
  }

  const initialFieldValues: CommentFieldValue = {
    id: transmissionReportId,
    comment: defaultComment,
  }

  const { control } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: initialFieldValues,
  })

  return (
    <Skeleton isLoaded={!isLoading} minHeight={'100px'}>
      <Box width={'full'}>
        <Form onSubmit={() => {}}>
          <Controller
            name={'comment'}
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
              formState: { isValid },
            }) => (
              <>
                <TextareaField
                  label=""
                  height={!value || value.length < 500 ? '100px' : '200px'}
                  w="full"
                  defaultValue={value ?? ''}
                  name="comment"
                  variant="default"
                  placeholder="Notes ..."
                  onChange={onChange}
                  onBlur={e => {
                    onBlur()
                    let newValue = value ?? ''
                    if (
                      e?.target?.value &&
                      e?.target?.value !== (defaultComment as unknown as string)
                    ) {
                      newValue = e.target.value.replace(/\n/g, ' ')
                    }
                    handleUpdateComment({
                      comment: newValue,
                      shouldUpdate: newValue !== defaultComment && isValid,
                    })
                  }}
                />
                {error?.message && <Error message={error.message} />}
              </>
            )}
            rules={{
              maxLength: {
                value: contentMaxLength,
                message: `Notes cannot be longer than ${contentMaxLength} characters.`,
              },
            }}
          />
        </Form>
      </Box>
    </Skeleton>
  )
}
