import { useMemo } from 'react'

import { Flex, HStack, Skeleton, SkeletonProps } from '@rhythm/components'

import { CommentCard } from '../../../../components/Comment/Comment'
import { TransmissionReportDto } from '../../../../lib/api'
import NarrativeCard from '../../../../pages/physician/TransmissionReportPage/components/NarrativeCard'

export interface TransmissionReportAlertsProps {
  transmissionReport?: TransmissionReportDto
}

const ClinicianInterpretationCards = ({
  transmissionReport,
}: TransmissionReportAlertsProps) => {
  const { Skel } = useMemo(
    () => ({
      Skel: (args: SkeletonProps) => (
        <Skeleton isLoaded={transmissionReport !== undefined} {...args} />
      ),
    }),
    [transmissionReport],
  )

  return (
    <NarrativeCard icon="file" title="Provider/Clinician Interpretation">
      <Flex direction="row" width="100%">
        <HStack spacing="xl" width="100%" mb="xl">
          <div style={{ width: '100%', height: '100%' }}>
            <Skel height={'100px'}>
              <CommentCard transmissionReport={transmissionReport} />
            </Skel>
          </div>
        </HStack>
      </Flex>
    </NarrativeCard>
  )
}

export default ClinicianInterpretationCards
