import { Patient } from '../../../lib/api'
import formatDate from '../../../utils/formatDate'

export const getDefaultTransmissionValuesForPatient = (
  patient?: Patient | null,
) => {
  if (!patient) return {}

  const {
    mrn2,
    birthDate,
    monitorReason,
    diagnosisOption,
    oac,
    referringPhysician,
    practitioner,
    referringProvider,
  } = patient || {}

  return {
    mrn2: mrn2 ?? '',
    birthDate: formatDate(birthDate) ?? '',
    referringPhysician: referringPhysician ?? '',
    referringProvider: referringProvider ? referringProvider : null,
    monitorReason: monitorReason
      ? { label: monitorReason, value: monitorReason }
      : undefined,
    diagnosisOption: diagnosisOption
      ? { label: diagnosisOption, value: diagnosisOption }
      : undefined,
    oac: oac ? { label: oac, value: oac } : undefined,
    practitionerId: practitioner
      ? {
          label:
            (practitioner.prefix?.length ? `${practitioner.prefix} ` : '') +
            `${practitioner.given} ${practitioner.family}`,
          value: practitioner.id,
        }
      : undefined,
  }
}
