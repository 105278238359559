import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Box,
  Card,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  TextButton,
  VStack,
} from '@rhythm/components'
import moment from 'moment'

import { useGetPinnedNotesByPatientId } from '../../../../../features/clinicalNotes/getPinnedNotesByPatientId'
import { useUpdateClinicalNote } from '../../../../../features/clinicalNotes/updateClinicalNote'
import { useToastHook } from '../../../../../hooks/useToastHook'
import { ClinicalNote } from '../../../../../lib/api'

export const PinnedNotesCard = ({ onClick }: { onClick: () => void }) => {
  const { id: patientId } = useParams<{ id: string }>()
  const { showToast } = useToastHook()
  const { data, isLoading } = useGetPinnedNotesByPatientId({
    patientId,
  })
  const [pinLoadingId, setPinLoadingId] = useState<string>('')

  const { mutateAsync: pinNote, isLoading: pinLoading } =
    useUpdateClinicalNote(patientId)
  const { pinnedNotes, count } = data || {}
  const PinnedNoteCard = ({ note }: { note: ClinicalNote }) => {
    const handlePinClick = async (noteId: string, isPinned: boolean) => {
      setPinLoadingId(noteId)
      await pinNote({
        noteId,
        updateNoteRequest: { isPinned: false },
      })
      showToast(`Note ${isPinned ? 'un' : ''}pinned.`)
      setPinLoadingId('')
    }
    return (
      <Card py={4} px={5}>
        <HStack alignItems={'flex-start'}>
          {pinLoading && note.id === pinLoadingId ? (
            <Spinner
              color="orange.400"
              size={'sm'}
              style={{ marginRight: '6px', position: 'relative', top: '2px' }}
            />
          ) : (
            <Icon
              icon={'pin-filled'}
              color="orange.300"
              style={{ width: '14px', marginRight: '5px', cursor: 'pointer' }}
              onClick={() => {
                handlePinClick(note.id, note.isPinned)
              }}
            />
          )}
          <VStack width={'100%'} alignItems={'flex-start'}>
            <Text textOverflow={'ellipsis'}>{note.content}</Text>
            <Box
              sx={{
                color: 'gray.500',
                fontSize: '12px',
                justifyContent: 'flex-start',
              }}
            >
              by{' '}
              <span style={{ textTransform: 'capitalize' }}>
                {note.updatedBy.given} {note.updatedBy.family}
              </span>{' '}
              <br />
              on {moment(note.updatedAt).format('MM-DD-YYYY')}{' '}
              {moment(note.updatedAt).format('hh:mm A')}
            </Box>
            <Text></Text>
          </VStack>
        </HStack>
      </Card>
    )
  }
  if (!isLoading && !pinnedNotes?.length) return null
  return (
    <>
      {pinnedNotes && pinnedNotes.length > 0 ? (
        <Stack>
          <Text fontSize="lg" fontWeight="bold" mb="2">
            Pinned Notes
          </Text>
          <Stack>
            {pinnedNotes?.map(note => {
              return <PinnedNoteCard key={note.id} note={note} />
            })}
          </Stack>
          <TextButton
            onClick={onClick}
            py={0}
            pl={0}
            justifyContent={'flex-start'}
          >
            View all notes ({count})
          </TextButton>
        </Stack>
      ) : null}
    </>
  )
}
