// import { useState, useEffect, useMemo } from 'react'
import React, { useMemo } from 'react'

import { Select } from '@rhythm/components'

import './CustomPaginationStyle.css'

import { StylesConfig } from 'react-select'

export interface CustomPaginationProps {
  rowsPerPage: number
  setRowsPerPage?: (data: number) => void
  currentPage: number
  totalPageCount: number
  startIndex: number
  total: number
  setPageIndex: (data: number) => void
  customPageSizeOptions?: number[]
  style?: React.CSSProperties
}
const CustomPagination = ({
  rowsPerPage,
  setRowsPerPage,
  currentPage,
  totalPageCount,
  startIndex,
  setPageIndex,
  total,
  customPageSizeOptions,
  style,
}: CustomPaginationProps): React.ReactElement => {
  let pageSizeOptions: {
    label: string
    value: number
  }[]
  if (customPageSizeOptions && customPageSizeOptions.length > 0) {
    pageSizeOptions = customPageSizeOptions.map(option => ({
      label: String(option),
      value: option,
    }))
  } else {
    pageSizeOptions = [10, 20, 50, 100, total].map(
      (option, index, options) => ({
        label: options.length - 1 === index ? 'All' : String(option),
        value: option,
      }),
    )
  }

  const getPageNumberRange = () => {
    const totalPageButtons = Math.min(5, Math.ceil(total / rowsPerPage))
    const pageRange = []

    const leftBoundary = Math.max(
      currentPage - Math.floor(totalPageButtons / 2),
      1,
    )
    const rightBoundary = Math.min(
      leftBoundary + totalPageButtons - 1,
      Math.ceil(total / rowsPerPage),
    )

    for (let i = leftBoundary; i <= rightBoundary; i++) {
      pageRange.push(i)
    }

    return pageRange
  }
  const fetchDataForPage = (page: number) => {
    setPageIndex(page)
  }

  const customStyles: StylesConfig<
    {
      label: string
      value: number
    },
    false
  > = {
    control: base => ({
      ...base,
      borderColor: 'transparent',
      ':hover': {
        borderColor: 'transparent',
      },
      boxShadow: 'none',
      display: 'flex',
      cursor: 'pointer',
    }),
    container: base => ({
      ...base,
      width: '70px',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'neutral.200' : 'transparent',
      color: 'neutral.black',
      borderRadius: 'radii.sm',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'neutral.200',
      },
    }),
    menuList: base => ({
      ...base,
      padding: '4px 8px',
      borderRadius: 'radii.sm',
      borderColor: 'transparent',
      textAlign: 'start',
    }),
    menu: base => ({
      ...base,
      width: 'auto',
    }),
  }
  const defaultPageValue =
    pageSizeOptions.find(page => page.value === rowsPerPage) ??
    pageSizeOptions[0]

  const value = useMemo(() => {
    return (
      pageSizeOptions.find(option => option.value === rowsPerPage) ?? {
        label: 'All',
        value: total,
      }
    )
  }, [rowsPerPage, total, pageSizeOptions])

  return (
    <div style={style ?? { position: 'sticky', bottom: 0 }}>
      <div
        className=""
        style={{
          backgroundColor: '#fff',
          borderRadius: '7px',
          padding: '12px 24px',
          fontSize: '14px',
          textAlign: 'right',
          marginTop: '2px',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>
            <span
              style={{
                color: 'rgb(108, 119, 137)',
                display: 'inline-block',
                marginRight: '15px',
              }}
            >
              {' '}
              Rows per page:
            </span>
          </label>
          <span className="pagination-ddl">
            <Select
              options={pageSizeOptions}
              value={value}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={customStyles as any}
              placeholder={null}
              defaultValue={defaultPageValue}
              onChange={(option: any) =>
                setRowsPerPage && setRowsPerPage(option.value)
              }
              menuPlacement="auto"
              isSearchable={false}
            />
          </span>
        </div>
        <div className="pagination">
          {' '}
          <span className="page-info" style={{ padding: '0 36px' }}>
            {' '}
            {startIndex + 1}-{Math.min(startIndex + rowsPerPage, total)} of{' '}
            {total}{' '}
          </span>
          <button
            style={{
              marginRight: '30px',
              position: 'relative',
              top: '-4px',
            }}
            disabled={currentPage === 1}
            onClick={e => {
              e.preventDefault()
              fetchDataForPage(1)
            }}
          >
            {' '}
            <div style={{ display: 'flex' }}>
              <div
                className="arrow left"
                style={{ position: 'relative', right: '-4px' }}
              />
              <div className="arrow left" />
            </div>
          </button>{' '}
          {/* Previous arrow */}{' '}
          <button
            style={{
              marginRight: '30px',
              position: 'relative',
              top: '-4px',
            }}
            disabled={currentPage === 1}
            onClick={e => {
              e.preventDefault()
              fetchDataForPage(currentPage - 1)
            }}
          >
            <div className="arrow left" />
          </button>{' '}
          {/* Page numbers */}{' '}
          {getPageNumberRange().map(pageNumber => (
            <button
              style={{ margin: '0 10px' }}
              key={pageNumber}
              onClick={e => {
                e.preventDefault()
                fetchDataForPage(pageNumber)
              }}
              className={`pagination-button ${
                currentPage === pageNumber ? 'active' : ''
              }`}
            >
              {' '}
              {pageNumber}{' '}
            </button>
          ))}{' '}
          {/* Next arrow */}{' '}
          <button
            style={{ marginLeft: '30px' }}
            disabled={currentPage === totalPageCount}
            onClick={e => {
              e.preventDefault()
              fetchDataForPage(currentPage + 1)
            }}
          >
            <div className="arrow right" />
          </button>
          <button
            style={{ marginLeft: '30px' }}
            disabled={currentPage === totalPageCount}
            onClick={e => {
              e.preventDefault()
              fetchDataForPage(totalPageCount)
            }}
          >
            {' '}
            <div style={{ display: 'flex' }}>
              <div className="arrow right" />
              <div
                className="arrow right"
                style={{ position: 'relative', left: '-4px' }}
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomPagination
