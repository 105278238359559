import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Heading,
  HStack,
  IconBadge,
  Modal,
  ModalProps,
  Skeleton,
  Stack,
} from '@rhythm/components'

import { getFullPDF } from '../../../../../utils/transmissionReportUtil'

export interface ViewReportListModalProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  reportsList: object[] | undefined
}

const ViewVendorReportCombinedPDFModal = ({
  isOpen,
  isLoading,
  onClose,
  reportsList,
}: ViewReportListModalProps) => {
  const [isPdfGenerated, setIsPdfGenerated] = useState(false)
  const [fullPdf, setFullPdf] = useState<any>(null)

  const generatePdf = async (reports: object[]) => {
    const pdf = await getFullPDF(reports)
    setFullPdf(pdf)
    setIsPdfGenerated(true)
  }

  useEffect(() => {
    if (isOpen) {
      if (reportsList && reportsList.length > 0 && !fullPdf) {
        generatePdf(reportsList)
      }
    } else {
      setFullPdf(null)
      setIsPdfGenerated(false)
    }
  }, [isOpen, reportsList])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width="90%"
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      }
    >
      <Stack spacing="none" mb="lg">
        <HStack spacing="xl">
          <IconBadge icon="file" size="lg" />
          <Stack alignItems="flex-start" spacing="sm">
            <Heading variant="h4">Vendor Report</Heading>
          </Stack>
        </HStack>
      </Stack>
      <Box>
        <Skeleton
          isLoaded={!isLoading && (isPdfGenerated || reportsList?.length === 0)}
          noOfLines={5}
          h="lg"
          w="full"
        >
          <Stack>
            {reportsList && reportsList.length > 0 && isPdfGenerated ? (
              <iframe
                title="VendorReport"
                src={fullPdf}
                style={{ height: '800px', margin: 'auto', width: '100%' }}
              />
            ) : (
              <HStack
                justifyContent="center"
                color="gray.600"
                alignItems="center"
                my="3xl"
              >
                <Heading variant="h5">No Report Found</Heading>
              </HStack>
            )}
          </Stack>
        </Skeleton>
      </Box>
    </Modal>
  )
}

export default ViewVendorReportCombinedPDFModal
