import { useMemo } from 'react'

import { useQueries } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import { UploadFileResponse } from '../../../../lib/api/generated-client'

type GetDocumentsParams = {
  fileIds: string[]
}

export const useGetDocuments = ({ fileIds }: GetDocumentsParams) => {
  const Api = useApiContext()
  const queries = useMemo(
    () =>
      fileIds.map(fileId => {
        return {
          queryKey: ['documents', fileId],
          queryFn: () => Api.filesControllerGet({ fileId }),
          enabled: !!fileId,
        }
      }),
    [Api, fileIds],
  )
  const documents = useQueries(queries)

  const error = useMemo(() => documents.find(res => !!res.error), [documents])
  const isError = useMemo(() => documents.some(res => res.isError), [documents])
  const isLoading = useMemo(
    () => documents.some(res => res.isLoading),
    [documents],
  )
  const isSuccess = useMemo(
    () => documents.some(res => res.isSuccess),
    [documents],
  )
  const data = useMemo(
    () =>
      documents
        .filter(doc => doc?.isSuccess)
        .reduce((acc, doc: any) => {
          if (doc?.data?.data) {
            acc.push(doc.data.data)
          }
          return acc
        }, [] as UploadFileResponse[]),
    [documents],
  )

  return {
    error,
    isError,
    isSuccess,
    isLoading,
    data,
  }
}
