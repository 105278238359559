import React from 'react'

import { TransmissionReportColorEnum } from '../../../../../lib/api'

import { ReportFilterOption, ReportFilterPanel } from './ReportFilterPanel'

function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

const filterOptions = [
  { label: 'All', value: 'all' },
  {
    label: capitalizeFirstLetter(TransmissionReportColorEnum.Red),
    value: TransmissionReportColorEnum.Red,
  },
  {
    label: capitalizeFirstLetter(TransmissionReportColorEnum.Yellow),
    value: TransmissionReportColorEnum.Yellow,
  },
  {
    label: 'Routine',
    value: TransmissionReportColorEnum.Green,
  },
  {
    label: 'Dismissed',
    value: TransmissionReportColorEnum.Gray,
  },
]

export const FilterByReportColor = ({
  onMenuItemClick,
}: {
  onMenuItemClick: (filter: ReportFilterOption) => void
}): React.ReactElement => {
  return (
    <ReportFilterPanel
      onMenuItemClick={onMenuItemClick}
      filterOptions={filterOptions}
      title="Alert Type:"
    />
  )
}
