import { useInfiniteQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import { MAX_NOTES_PER_PAGE } from '../../pages/physician/PatientProfilePage/components/ClinicalNotes/constants'

export const useGetClinicalNotesByPatientId = ({
  patientId,
}: {
  patientId: string
}) => {
  const Api = useApiContext()
  return useInfiniteQuery(
    ['getNotesByPatientId', { id: patientId }],
    async ({ pageParam = 1 }) => {
      const res = await Api.clinicalNotesControllerGetClinicalNotes({
        patientId,
        page: pageParam as number,
        limit: MAX_NOTES_PER_PAGE,
      })
      const { notes } = res.data
      return notes
    },
    {
      enabled: !!patientId,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length < MAX_NOTES_PER_PAGE) return undefined
        return pages.length + 1
      },
    },
  )
}
