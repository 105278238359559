import { DEFAULT_COUNTRY_NAME } from '../../../constants'
import { FindOneOrganizationResponse } from '../../../lib/api'

export const getOrganizationResponseMapper = (
  responseData: FindOneOrganizationResponse,
): FindOneOrganizationResponse => ({
  ...responseData,
  organization: {
    ...responseData.organization,
    address: {
      ...responseData.organization.address,
      country: DEFAULT_COUNTRY_NAME,
    },
  },
})
