import { useQuery } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'

export const useGetReportTemplatesByTxId = ({
  transmissionReportId,
  deviceType,
  vendor,
}: {
  transmissionReportId: number | undefined
  deviceType: string
  vendor: string
}) => {
  const Api = useApiContext()
  return useQuery(
    ['getReportTemplatesByTxId', { id: transmissionReportId }],
    async () => {
      const res = await Api.reportTemplateControllerGetReportTemplates({
        transmissionReportId: transmissionReportId as number,
        deviceType: deviceType,
        vendor: vendor,
      })
      const { templates } = res.data
      templates.sort((a, b) => a.name.localeCompare(b.name))

      return templates
    },
    { enabled: !!transmissionReportId },
  )
}
