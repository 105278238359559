import { useCallback } from 'react'

import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@rhythm/components'

import { LabelAndValue } from '../../constants'

interface CustomFilterButtonProps {
  placeholder: string
  options: LabelAndValue[]
  defaultValue?: LabelAndValue
  currentOption: LabelAndValue
  setValue: (value: LabelAndValue) => void
}

/**
 * CustomFilterButton is a reusable component for creating filter dropdown menus.
 *
 * @param {Object} props - The properties that define the component.
 * @param {string} props.placeholder - The placeholder text for the button and this will be persistent.
 * @param {Object} props.defaultValue - The default value for the dropdown menu.
 * @param {Array} props.options - The options for the dropdown menu.
 * @param {string} props.currentOption - The currently selected option.
 * @param {Function} props.setValue - The function to update the selected option.
 */
const CustomFilterButton = ({
  placeholder,
  options,
  defaultValue,
  currentOption,
  setValue,
}: CustomFilterButtonProps) => {
  const handleOptionClick = useCallback(
    (option: LabelAndValue) => {
      setValue(option)
    },
    [setValue],
  )

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            py={2}
            px={3}
            bg={isOpen ? 'primary.100' : 'transparent'}
            color={isOpen ? 'primary.400' : 'neutral.800'}
            _focus={{
              background: 'neutral.200',
            }}
            _hover={{
              background: isOpen ? 'primary.100' : 'neutral.200',
            }}
          >
            <Flex alignItems={'center'} gap={2}>
              <span>
                {placeholder}: {currentOption.label}
              </span>
              <Icon
                icon={'chevron-down'}
                boxSize={'14px'}
                style={{
                  transform: isOpen ? 'rotate(180deg)' : 'none',
                  transitionDuration: '0.3s',
                }}
              />
            </Flex>
          </MenuButton>
          <MenuList defaultValue={defaultValue?.value ?? undefined}>
            {options.map(option => {
              return (
                <MenuItem
                  color={'neutral.800'}
                  key={option.value}
                  value={option.value}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                  {option.value === currentOption.value && (
                    <Icon
                      icon={'check'}
                      color={'primary.400'}
                      boxSize={'14px'}
                      ml={'auto'}
                    />
                  )}
                </MenuItem>
              )
            })}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default CustomFilterButton
