import { Roles, User } from '../lib/api'

import { USER_TYPE_INTERNAL } from './constants'

export const checkIfExternal = (role: Roles | string): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, userType] = role.split(':')
  return userType !== USER_TYPE_INTERNAL
}

export const hasRole = (user: Partial<User>, roles: Roles[] = []): boolean => {
  return roles.includes(user.role as Roles)
}
