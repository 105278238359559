import React, { useState } from 'react'

import {
  Box,
  Button,
  Heading,
  HStack,
  IconBadge,
  //Text,
  Modal,
  ModalProps,
  Skeleton,
  Stack,
} from '@rhythm/components'

import {
  useGetTransmissionReportById,
  useGetVendorTransmissionReports,
} from '../../../../../features/transmissionReports/api'
import { Patient } from '../../../../../lib/api'

import ViewVendorReports from './ViewVendorReports'

export interface ViewReportListModalProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  patient?: Patient
  transmissionId: number
}

const ViewReportListModal = ({
  isOpen,
  onClose,
  //patient,
  transmissionId,
}: ViewReportListModalProps) => {
  const [pdfGenerated, setPdfGenerated] = useState(false)
  const { data: transmissionReport } = useGetTransmissionReportById(
    {
      transmissionReportId: Number(transmissionId),
    },
    {
      enabled: !!Number(transmissionId),
    },
  )
  const { data: vendorTransmissionReportsListData, isLoading } =
    useGetVendorTransmissionReports(
      {
        transmissionReportId: transmissionId,
      },
      { enabled: isOpen },
    )

  const vendorTransmissionReportsList =
    vendorTransmissionReportsListData?.filter(
      (e: any) =>
        !e.name.includes(transmissionReport?.patient?.rhythmId) && e.pdfUrl,
    )
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width="90%"
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      }
    >
      <Stack spacing={'none'} mb={'lg'}>
        <HStack spacing="xl">
          <IconBadge icon="file" size="lg" />
          <Stack alignItems="flex-start" spacing="sm">
            <Heading variant="h4">Vendor Report</Heading>
          </Stack>
        </HStack>
      </Stack>
      <Box>
        <Skeleton
          isLoaded={!isLoading && pdfGenerated}
          noOfLines={5}
          h="lg"
          w="full"
        >
          <Stack>
            {vendorTransmissionReportsList && (
              <ViewVendorReports
                reportList={vendorTransmissionReportsList}
                setPdfGenerated={setPdfGenerated}
              />
            )}
            {vendorTransmissionReportsList &&
              vendorTransmissionReportsList.length === 0 && (
                <HStack
                  justifyContent="center"
                  color="gray.600"
                  alignItems="center"
                  my="3xl"
                >
                  <Heading variant="h5">No Report Found</Heading>
                </HStack>
              )}
          </Stack>
        </Skeleton>
      </Box>
    </Modal>
  )
}

export default ViewReportListModal
