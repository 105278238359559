import { useMutation } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  ClinicalNote,
  DefaultApiClinicalNotesControllerDeleteNoteRequest,
  queryClient,
} from '../../lib/api'

export const useDeleteClinicalNote = (patientId: string) => {
  const Api = useApiContext()

  return useMutation(
    (params: DefaultApiClinicalNotesControllerDeleteNoteRequest) =>
      Api.clinicalNotesControllerDeleteNote(params),
    {
      onSuccess: async ({ data }, { noteId }) => {
        try {
          queryClient.setQueryData(
            ['getNotesByPatientId', { id: patientId }],
            (oldData: any) => {
              if (!oldData?.pages.length) {
                throw new Error('No notes found')
              }

              const newPages = oldData.pages.map((page: any) =>
                page.filter((note: ClinicalNote) => note.id !== noteId),
              )

              return { ...oldData, pages: newPages }
            },
          )
          queryClient.invalidateQueries([
            'getPinnedNotesByPatientId',
            { id: patientId },
          ])
        } catch (error) {
          queryClient.invalidateQueries('getNotesByPatientId')
          queryClient.invalidateQueries('getPinnedNotesByPatientId')
        }
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
