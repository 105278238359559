import { Box, Card, Icon, Text, VStack } from '@rhythm/components'

export const NoResultsFound = () => {
  return (
    <Box
      width={'calc(97vw - 340px)'}
      height={'calc(100vh - 350px)'}
      cursor={'default'}
      userSelect={'none'}
    >
      <Card p={4} width="100%" height={'100%'}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <VStack spacing={3}>
            <Box display={'flex'}>
              <Icon name="search" icon="status-alert" mr={3} />
              <Text
                variant="h1"
                fontSize={20}
                position={'relative'}
                top={'-1px'}
              >
                No Results Found
              </Text>
            </Box>
            <Text variant="label" fontSize={16} textColor={'neutral.800'}>
              Try adjusting the date range or selected accounts/clinics
            </Text>
          </VStack>
        </Box>
      </Card>
    </Box>
  )
}
