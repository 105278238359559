import React, { useState } from 'react'

import { Box, InputProps } from '@rhythm/components'
import ReactDatePicker, { DatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { datePickerStyling } from './datePickerStylings'
import { DatePickerHeader, DatePickerInput } from './DatePickerSubcomponents'

export interface DatePickerV2Props {
  inputProps?: Partial<InputProps>
  onlyOpenCalenderOnIconClick?: boolean
  datePickerProps?: DatePickerProps
}

const DatePickerv2 = ({
  inputProps = {},
  onlyOpenCalenderOnIconClick = false,
  datePickerProps,
}: DatePickerV2Props): React.ReactElement => {
  const { onSelect, ...restDatePickerProps } = datePickerProps ?? {}
  const [calenderOpen, setCalenderOpen] = useState<boolean>(false)

  return (
    <Box __css={datePickerStyling}>
      <ReactDatePicker
        open={calenderOpen}
        customInput={
          <DatePickerInput
            {...inputProps}
            setCalenderOpen={setCalenderOpen}
            bgColor={'white'}
          />
        }
        onInputClick={() => {
          if (!onlyOpenCalenderOnIconClick) {
            setCalenderOpen(true)
          }
        }}
        onClickOutside={() => setCalenderOpen(false)}
        onSelect={(date, e) => {
          setCalenderOpen(false)
          if (onSelect !== undefined) {
            onSelect(date, e)
          }
        }}
        renderCustomHeader={DatePickerHeader}
        showPopperArrow={false}
        {...restDatePickerProps}
      />
    </Box>
  )
}

export default DatePickerv2
