import { Box, Flex, IconButton } from '@rhythm/components'

import { AccountsDropdown } from './AccountsDropdown'
import { AnalyticsDateRangePicker } from './AnalyticsDateRangePicker'
import { DownloadReportButton } from './DownloadReportButton'
import { FilterButton } from './FilterButton'

export const AnalyticsFiltersBar = () => {
  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Flex width={'60%'} alignItems={'center'}>
        <Box width={'90%'}>
          <AccountsDropdown />
        </Box>
        <Box ml={2}>
          <FilterButton />
        </Box>
      </Flex>
      <Flex width={'35%'} alignItems={'center'}>
        <AnalyticsDateRangePicker />
        <DownloadReportButton />
      </Flex>
    </Flex>
  )
}
