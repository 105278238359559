import React, { useState } from 'react'

import {
  Box,
  Card,
  CardProps,
  Editable,
  Heading,
  HStack,
  IconBadge,
  IconBadgeProps,
  IconButton,
  Text,
} from '@rhythm/components'

export interface AlertCardProps extends CardProps {
  title?: string
  icon?: IconBadgeProps['icon']
  onDelete?: () => void
  canDelete?: boolean
  name?: string
  existingTitles: string[]
  onTitleSubmit: (title: string) => void
  isLastCard?: boolean
}

export const AlertCard = ({
  title,
  icon = 'file',
  onDelete,
  canDelete,
  isLastCard,
  children,
  name = 'alert-card-title',
  onTitleSubmit,
  existingTitles,
  ...rest
}: AlertCardProps): React.ReactElement => {
  const [tempTitle, setTempTitle] = useState<string | undefined>(undefined)
  return (
    <Card {...rest}>
      <Box mb="2xl" width="full" display={'flex'} flexDirection={'column'}>
        <HStack width={'full'}>
          <IconBadge icon={icon} size="lg" />
          {!canDelete && isLastCard ? (
            <Heading variant="h4">{title}</Heading>
          ) : (
            <Box width={'full'}>
              <Editable
                name={name}
                placeholder="Type alert card name..."
                defaultValue={title}
                fontSize="2xl"
                variant="title"
                w="full"
                onSubmit={(submitTitle: string) => {
                  if (title === submitTitle) return
                  setTempTitle(submitTitle)
                  onTitleSubmit(submitTitle)
                }}
                // isInvalid={tempTitle === '' || tempTitle === 'Presenting'}
              />
            </Box>
          )}
          {canDelete && !isLastCard && (
            <IconButton
              aria-label="delete alert card"
              icon="delete"
              variant="plain"
              onClick={onDelete}
            />
          )}
        </HStack>
        <HStack width={'full'}>
          {tempTitle && existingTitles?.includes(tempTitle.toLowerCase()) && (
            <Text fontSize="sm" color="red.500" m={2} ml={20}>
              An alert card with a similar title already exists in the report.
              Please rename the alert card.
            </Text>
          )}
        </HStack>
      </Box>
      {children}
    </Card>
  )
}
