import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerDismissAllRequest,
  queryClient,
  TransmissionReportDto,
  TransmissionReportListResponseDto,
} from '../../../lib/api'

import { useTransmissionReportsListContext } from '..'

interface UseBatchDismissTransmissionReportParams {
  tranmissionReportIds: string[]
}

export const useBatchDismissTransmissionReport = () => {
  const { setIsLoading: setIsLoadingTransmissionReportsList } =
    useTransmissionReportsListContext()

  const Api = useApiContext()

  return useMutation(
    (params: UseBatchDismissTransmissionReportParams) => {
      return Api.transmissionReportsControllerDismissAll(
        params as unknown as DefaultApiTransmissionReportsControllerDismissAllRequest,
      )
    },
    {
      onSuccess: data => {
        setIsLoadingTransmissionReportsList(true)
        window.location.reload()

        const { data: signatureResp } = data
        queryClient.setQueryData<
          Partial<TransmissionReportListResponseDto | undefined>
        >(['transmissionReportsList'], cachedData => {
          if (!cachedData || !signatureResp) return

          const signatures: string[] = (
            signatureResp as unknown as string
          ).split(',')
          for (const dismissId of signatures) {
            const updatedUnsignedTransmissionReports =
              cachedData.transmissionReports?.filter(
                (transmissionReport: TransmissionReportDto) =>
                  transmissionReport.id !== Number(dismissId),
              ) || []
            cachedData.transmissionReports = updatedUnsignedTransmissionReports

            const updatedTotal = (cachedData?.total || 0) - 1
            cachedData.total = updatedTotal
          }
          return cachedData
        })

        setIsLoadingTransmissionReportsList(false)
      },
    },
  )
}
