import { useEffect, useMemo } from 'react'

import { Box, Flex, HStack, Skeleton, Text } from '@rhythm/components'
import { useForm } from 'react-hook-form'
import { OptionProps } from 'react-select'

import MultiSelectWithFormControl, {
  CustomCheckBoxOption,
  CustomValueContainer,
} from '../../../../components/GlobalFiltersModal/MultiSelectWithFormControl'
import { useAnalyticsContext } from '../../../../context/AnalyticsContext'
import { useGlobalFilter } from '../../../../hooks/useGlobalFilter'

import { Account, fetchAccountClinicMap } from './constants'

type OptionType = {
  label: string
  value: string
  children?: OptionType[]
}

type FormData = {
  accounts: OptionType[]
  clinics: OptionType[]
  dateRange: string[]
}

export const AccountsDropdown = () => {
  const { isLoading, data: filterData, isSuccess } = useGlobalFilter()
  const {
    selectedAccounts,
    setSelectedAccounts,
    hasSetSelectedAccountsOnce,
    setHasSetSelectedAccountsOnce,
  } = useAnalyticsContext()
  const { control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      accounts: [],
      clinics: [],
      dateRange: [],
    },
  })

  const convertAccountsToOptions = (accounts: Account[] | undefined) => {
    return (
      (accounts ?? []).map(account => ({
        label: account.name,
        value: account.id,
        children:
          account.clinics?.map(clinic => ({
            label: clinic.name,
            value: clinic.id,
          })) ?? [],
      })) ?? []
    )
  }

  useEffect(() => {
    const selectedAccs: OptionType[] =
      convertAccountsToOptions(selectedAccounts)
    setValue('accounts', selectedAccs)
  }, [selectedAccounts, setValue])

  // fetch clinics data from api and map it to accounts
  const accountsMap = useMemo(() => {
    const map = fetchAccountClinicMap(filterData?.clinics ?? [])
    return map
  }, [filterData])

  // map accounts to options array for the dropdown
  const accountsOptionsArray = useMemo(() => {
    return Array.from(accountsMap, ([key, value]) => ({
      label: value.name,
      value: key,
      children: value.clinics?.map(clinic => ({
        label: clinic.name,
        value: clinic.id,
      })),
    })).sort((a: any, b: any) => a.label.localeCompare(b.label))
  }, [accountsMap])

  useEffect(() => {
    if (hasSetSelectedAccountsOnce) return
    const allAccountsArr = Array.from(accountsMap.values())
    const allAccountsOptions = convertAccountsToOptions(allAccountsArr)
    setValue('accounts', allAccountsOptions)
    setSelectedAccounts(allAccountsArr)
    if (isSuccess && allAccountsArr && allAccountsArr.length > 0)
      setHasSetSelectedAccountsOnce(true)
  }, [
    accountsMap,
    hasSetSelectedAccountsOnce,
    isSuccess,
    setHasSetSelectedAccountsOnce,
    setSelectedAccounts,
    setValue,
  ])

  // map selected accounts to options array for the clinics dropdown
  const clinicsOptionsArray = useMemo(() => {
    return (selectedAccounts ?? []).flatMap(account => {
      const acc = accountsMap.get(account.id)
      return (
        acc?.clinics?.map(clinic => ({
          label: clinic.name,
          value: clinic.id,
          children: [
            {
              label: account.name,
              value: account.id,
            },
          ],
        })) ?? []
      )
    })
  }, [accountsMap, selectedAccounts])

  // Update selected clinics when selected accounts change
  const selectedClinics = useMemo(() => {
    return (selectedAccounts ?? []).flatMap(
      account =>
        account.clinics?.map(clinic => ({
          label: clinic.name,
          value: clinic.id,
          children: [
            {
              label: account.name,
              value: account.id,
            },
          ],
        })) ?? [],
    )
  }, [selectedAccounts])

  // set selected clinics to dropdown
  useEffect(() => {
    setValue('clinics', selectedClinics)
  }, [selectedClinics, setValue])

  const updateAccountsOnClinicsChange = (
    clinicsRemainingIntact: OptionType[],
  ) => {
    if (clinicsRemainingIntact.length === 0 && accountsMap.size < 2) {
      setValue('clinics', clinicsOptionsArray)
      return
    }

    // reconstruct selected accounts based on selected clinics
    const map = new Map<string, Account>()

    clinicsRemainingIntact.forEach(clinic => {
      const accountId = clinic.children?.[0].value
      if (!accountId) return

      const account = map.get(accountId) || {
        name: clinic.children?.[0].label ?? '',
        id: accountId,
        clinics: [],
      }

      account.clinics?.push({
        name: clinic.label,
        id: clinic.value,
      })

      map.set(accountId, account)
    })
    setSelectedAccounts(Array.from(map.values()))
  }

  return (
    <Skeleton width={'full'} height={'lg'} noOfLines={1} isLoaded={!isLoading}>
      <HStack mb={5} spacing={3}>
        <Box width={'50%'}>
          <MultiSelectWithFormControl
            name={'accounts'}
            options={accountsOptionsArray}
            placeholder={'Select Accounts'}
            isDisabled={accountsMap.size < 2}
            onBlur={() => {
              handleSubmit(data => {
                const accountData = data.accounts
                  .map(account => {
                    return accountsMap.get(account.value)
                  })
                  .filter(Boolean) as Account[]
                setSelectedAccounts(accountData)
              })()
            }}
            components={{
              ValueContainer: props => (
                <CustomValueContainer {...props} max={1} label={'accounts'}>
                  {props?.children}
                </CustomValueContainer>
              ),
              Option: props => <OptionComponent props={props} />,
            }}
            maxOptionsBeforeClearAll={1}
            withSelectAll
            control={control}
          />
        </Box>
        {selectedAccounts && selectedAccounts.length > 0 ? (
          <Box width={'50%'}>
            <MultiSelectWithFormControl
              name={'clinics'}
              options={clinicsOptionsArray}
              placeholder={'Select Clinics'}
              isDisabled={clinicsOptionsArray.length === 1}
              onBlur={() => {
                handleSubmit(data => {
                  updateAccountsOnClinicsChange(data.clinics)
                })()
              }}
              components={{
                ValueContainer: props => (
                  <CustomValueContainer {...props} max={1} label={'clinics'}>
                    {props?.children}
                  </CustomValueContainer>
                ),
                Option: props => (
                  <OptionComponent props={props} showSecondLine />
                ),
              }}
              maxOptionsBeforeClearAll={1}
              withSelectAll
              control={control}
            />
          </Box>
        ) : (
          <Box width={'50%'} />
        )}
      </HStack>
    </Skeleton>
  )
}

export const OptionComponent = ({
  props,
  showSecondLine = false,
}: {
  props: OptionProps<OptionType, true>
  showSecondLine?: boolean
}) => (
  <CustomCheckBoxOption {...props}>
    <Flex flexDirection={'column'} py={2}>
      <Text fontSize={'md'} fontWeight={'semibold'} color={'inherit'}>
        {props?.data?.label}
      </Text>
      {showSecondLine && (
        <Text color={'inherit'} mt={1}>
          {props?.data?.children?.[0]?.label}
        </Text>
      )}
    </Flex>
  </CustomCheckBoxOption>
)
