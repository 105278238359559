import { TransmissionReportDto, VtVendor } from '../../../lib/api'

export const getDefaultTransmissionValuesForDeviceInfo = (
  transmissionReport?: TransmissionReportDto | null,
  vendorList?: VtVendor[],
) => {
  if (!transmissionReport) return {}

  return {
    manufacturer: {
      label: transmissionReport.deviceVendor?.vendorName,
      value: transmissionReport.deviceVendor?.vendorId,
    },
    deviceSerialNumber: transmissionReport.device?.deviceSerialNumber,
  }
}
