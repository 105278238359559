import React, { useState } from 'react'

import {
  Flex,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@rhythm/components'

export type ReportFilterOption = {
  label: string
  value: string
}

export const ReportFilterPanel = ({
  onMenuItemClick,
  filterOptions,
  title,
}: {
  onMenuItemClick: (filter: ReportFilterOption) => void
  filterOptions: ReportFilterOption[]
  title: string
}): React.ReactElement => {
  const [currentFilter, setCurrentFilter] = useState<ReportFilterOption>({
    label: 'All',
    value: 'all',
  })

  return (
    <Flex
      borderRight={2}
      h={'full'}
      key={'filter'}
      px="2"
      minW={160}
      _last={{ borderBottom: 'none' }}
      _hover={{ cursor: 'pointer' }}
      boxShadow={'none'}
      justifyContent={'space-between'}
    >
      <Text
        color={'#516880'}
        fontSize={'14px'}
        fontWeight={600}
        fontFamily={'Lato'}
        textAlign={'left'}
        mr={'12px'}
        alignContent={'center'}
      >
        {title}
      </Text>
      <HStack>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton as={Link}>
                <HStack>
                  <Text minW={12}>{currentFilter.label}</Text>
                  <Icon
                    icon={!isOpen ? 'drop-down' : 'drop-up'}
                    boxSize={'sm'}
                    color="#0E6DAD"
                    alignContent={'flex-end'}
                    float={'right'}
                    paddingLeft={'2px'}
                  />
                </HStack>
              </MenuButton>

              <MenuList marginLeft={'-80px'}>
                {filterOptions.map(option => (
                  <MenuItem
                    minW={2}
                    key={`${option.value}-filter-options`}
                    onClick={() => {
                      setCurrentFilter(option)
                      onMenuItemClick(option)
                    }}
                  >
                    <HStack
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'full'}
                    >
                      <Text>{option.label}</Text>
                      {currentFilter.value === option.value && (
                        <Icon
                          icon="check"
                          boxSize={'sm'}
                          color="#0E6DAD"
                          float={'right'}
                        />
                      )}
                    </HStack>
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </HStack>
    </Flex>
  )
}
