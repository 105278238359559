export const datePickerStyling = {
  '.react-datepicker': {
    borderColor: 'neutral.400',
    borderRadius: 'md',
    borderWidth: '0.5px',
    boxShadow: 'dropdown',
    fontFamily: 'body',
  },
  '.react-datepicker-wrapper': {
    width: '100%',
  },
  '.react-datepicker__header': {
    backgroundColor: 'neutral.white',
    border: 'none',
    padding: 'none',
    margin: 'xl',
  },
  '.react-datepicker__day-name': {
    color: 'neutral.800',
    fontSize: 'sm',
    lineHeight: '22px',
    letterSpacing: 'base',
    width: 'md',
    margin: 'none',
    _notLast: {
      mr: 'xl',
    },
  },
  '.react-datepicker__day': {
    // color: 'neutral.black',
    lineHeight: '22px',
    letterSpacing: 'base',
    fontSize: 'md',
    fontWeight: 'bold',
    width: 'md',
    margin: 'none',
    mb: 'md',
    _notLast: {
      mr: 'xl',
    },
    _hover: {
      backgroundColor: 'neutral.400',
      borderRadius: '2px',
    },
  },
  '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
    {
      color: 'neutral.white',
      backgroundColor: 'primary.400',
      borderRadius: '2px',
      _hover: {
        backgroundColor: 'primary.400',
      },
    },
  '.react-datepicker__day--selected': {
    color: 'white !important',
  },
  '.react-datepicker-popper': {
    zIndex: '2',
  },
  '.react-datepicker__day--keyboard-selected': {
    color: 'black',
    backgroundColor: 'neutral.white',
  },
  // '.react-datepicker__day--weekend, .react-datepicker__day--outside-month, react-datepicker__day--disabled': {
  //   // color: 'text.secondary',
  //   fontWeight: 'normal',
  // },
}

export const dateRangePickerStyling = {
  '.react-datepicker': {
    borderColor: 'neutral.400',
    borderRadius: 'md',
    borderWidth: '0.5px',
    boxShadow: 'dropdown',
    fontFamily: 'body',
  },
  '.react-datepicker-wrapper': {
    width: '100%',
  },
  '.react-datepicker__header': {
    backgroundColor: 'neutral.white',
    border: 'none',
    padding: 'none',
    margin: 'xl',
  },
  '.react-datepicker__day-name': {
    color: 'neutral.800',
    fontSize: 'sm',
    lineHeight: '22px',
    letterSpacing: 'base',
    width: 'md',
    margin: 'none',
    _notLast: {
      mr: 'xl',
    },
  },

  '.react-datepicker__day': {
    // color: 'neutral.black',
    lineHeight: '40px',
    letterSpacing: 'base',
    fontSize: 'md',
    fontWeight: 'bold',
    margin: 'none',
    mb: 'md',
    _hover: {
      backgroundColor: 'neutral.400',
      borderRadius: '2px',
    },
    marginRight: '0',
    textAlign: 'center',
    width: '40px',
  },
  '.react-datepicker__day--selected': {
    color: 'neutral.white',
    backgroundColor: 'primary.400',
    borderRadius: '2px',
    _hover: {
      backgroundColor: 'primary.400',
    },
  },
  '.react-datepicker__day--in-range': {
    backgroundColor: 'neutral.400',
    color: 'inherit',
    borderRadius: '0',
    _hover: {
      backgroundColor: 'neutral.600',
    },
  },
  '.react-datepicker__day--in-selecting-range': {
    backgroundColor: 'neutral.200',
    color: 'inherit',
    borderRadius: '0',
  },
  '.react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start':
    {
      color: 'neutral.white',
      backgroundColor: 'primary.400',
      borderLeftRadius: '5px',
      _hover: {
        backgroundColor: 'primary.400',
        borderLeftRadius: '5px',
      },
    },
  '.react-datepicker__day--range-end, .react-datepicker__day--selecting-range-end':
    {
      color: 'neutral.white',
      backgroundColor: 'primary.400',
      borderRightRadius: '5px',
      _hover: {
        backgroundColor: 'primary.400',
      },
    },
  '.react-datepicker__day--outside-month': {
    color: 'neutral.600',
  },
  '.react-datepicker-popper': {
    zIndex: '2',
  },
  // '.react-datepicker__day--weekend, .react-datepicker__day--outside-month, react-datepicker__day--disabled': {
  //   // color: 'text.secondary',
  //   fontWeight: 'normal',
  // },
}
