import { HTMLAttributes, ReactElement, useState } from 'react'

import { Flex, HStack } from '@chakra-ui/react'
import {
  Box,
  Button,
  Heading,
  Modal,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@rhythm/components'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { OptionProps, ValueContainerProps } from 'react-select'

import { VENDOR_FILTER_OPTIONS } from '../../constants'
import { useApiContext } from '../../context/ApiContext'
import { useGlobalFilter } from '../../hooks/useGlobalFilter'
import {
  CreateGlobalFilterParams,
  UpdateGlobalFilterParams,
} from '../../lib/api'
import { sortArray } from '../../utils/basic'

import MultiSelectWithFormControl, {
  CustomCheckBoxOption,
  CustomValueContainer,
} from './MultiSelectWithFormControl'

type OptionType = {
  label: string
  value: string
  parentName?: string
}

type FormData = {
  clinics: OptionType[]
  practitioners: OptionType[]
  vendors: OptionType[]
  deviceTypes: OptionType[]
}

interface GlobalFilterModalProps extends HTMLAttributes<HTMLDivElement> {
  isModalOpen: boolean
  setShow: (value: boolean) => void
}

const GlobalFiltersModal = ({
  isModalOpen,
  setShow,
}: GlobalFilterModalProps): ReactElement => {
  const queryClient = useQueryClient()

  const [defaultFilterData, setDefaultFilterData] = useState<FormData>({
    clinics: [],
    practitioners: [],
    vendors: [],
    deviceTypes: [],
  })

  const { isLoading, data: filterData } = useGlobalFilter({
    onSuccess: data => {
      const processedData = {
        clinics:
          data?.filteredClinics?.map(clinic => ({
            value: clinic.id,
            label: clinic?.name ?? '',
          })) ?? [],
        practitioners:
          data?.filteredPractitioners?.map(practitioner => ({
            value: practitioner.id,
            label: practitioner?.given ?? '',
          })) ?? [],
        vendors:
          data?.vendors?.map(vendor => ({
            value: vendor,
            label: VENDOR_FILTER_OPTIONS[vendor]?.label,
          })) ?? [],
        deviceTypes:
          data?.deviceTypes?.map(deviceType => ({
            value: deviceType,
            label: deviceType,
          })) ?? [],
      }
      setDefaultFilterData(processedData)
      reset(processedData)
    },
  })

  const Api = useApiContext()

  const { control, handleSubmit, reset, setValue } = useForm<FormData>({
    defaultValues: defaultFilterData,
  })

  // Once everywhere in the app is using the filter object from the context, we can remove this state.
  const [isFilterUpdating, setIsFilterUpdating] = useState<boolean>(false)

  const updateFilterMutation = useMutation({
    mutationFn: (
      newFilterData: CreateGlobalFilterParams | UpdateGlobalFilterParams,
    ) => {
      if (!filterData?.filterId) {
        return Api.globalFilterControllerCreate({
          createGlobalFilterParams: newFilterData,
        })
      }
      return Api.globalFilterControllerUpdate({
        updateGlobalFilterParams: newFilterData,
      })
    },
    onSuccess: () => {
      // We need to stop invalidating queries ourselves and instead update the filter object present in the context of the app,
      // which will automatically invalidate the queries wherever the filter object is used. In this case, all the queries will
      // be updated rather than just the ones required by the app with a filter.
      queryClient.invalidateQueries().then(() => {
        setIsFilterUpdating(false)
        setShow(false)
      })
    },
  })

  const onSubmit = (data: FormData) => {
    setIsFilterUpdating(true)
    const newFilterData: CreateGlobalFilterParams | UpdateGlobalFilterParams = {
      organizationIds: data.clinics.map(clinic => clinic.value),
      practitionerIds: data.practitioners.map(
        practitioner => practitioner.value,
      ),
      vendors: data.vendors.map(vendor => vendor.value),
      deviceTypes: data.deviceTypes.map(deviceType => deviceType.value),
      userId: filterData?.user?.id ?? '',
    }
    updateFilterMutation.mutate(newFilterData)
    setDefaultFilterData(data)
  }

  const closeTheModal = () => {
    setShow(false)
    reset(defaultFilterData)
  }

  const resetValue = () => {
    setValue('clinics', [])
    setValue('practitioners', [])
    setValue('vendors', [])
    setValue('deviceTypes', [])
  }

  return (
    <Modal
      isOpen={isModalOpen}
      size={'2xl'}
      width={'34rem'}
      closeOnOverlayClick={false}
      onClose={closeTheModal}
      isCentered
      footer={
        <HStack justifyContent="flex-end">
          <Button variant="secondaryLight" size={'md'} onClick={closeTheModal}>
            Close
          </Button>
          <Button
            variant="primary"
            size={'md'}
            isLoading={isFilterUpdating}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </HStack>
      }
    >
      <HStack spacing="xl" mb="3xl">
        <Box style={{ padding: '10px 15px 30px 0' }} borderRadius="lg">
          <VStack alignItems="flex-start">
            <Heading variant="h4">Global Filter</Heading>
            <Box onClick={resetValue} className="reset-button">
              Clear All Filters
            </Box>
          </VStack>
        </Box>
      </HStack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <Skeleton
            width={'full'}
            height={'lg'}
            noOfLines={5}
            isLoaded={!isLoading}
          >
            <MultiSelectWithFormControl
              name={'clinics'}
              options={sortArray<{
                label: string
                value: string
                parentName?: string
              }>(
                filterData?.clinics?.map(clinic => ({
                  value: clinic.id,
                  label: clinic?.name ?? '',
                  parentName: clinic?.parent?.name ?? '',
                })) ?? [],
                'label',
              )}
              components={{
                ValueContainer: ClinicsValueContainer,
                Option: OptionComponent,
              }}
              maxOptionsBeforeClearAll={2}
              withSelectAll
              control={control}
            />
            <MultiSelectWithFormControl
              name={'practitioners'}
              options={sortArray<OptionType>(
                filterData?.practitioners?.map(practitioner => ({
                  value: practitioner.id,
                  label: `${practitioner?.given} ${practitioner?.family}`,
                })) ?? [],
                'label',
              )}
              components={{
                ValueContainer: PractitionersValueContainer,
              }}
              withSelectAll
              maxOptionsBeforeClearAll={2}
              control={control}
            />
            <MultiSelectWithFormControl
              name={'vendors'}
              options={sortArray<OptionType>(
                Object.values(VENDOR_FILTER_OPTIONS)?.map(vendor => ({
                  value: vendor.value,
                  label: vendor.label,
                })) ?? [],
                'label',
              )}
              withSelectAll
              control={control}
            />
            <MultiSelectWithFormControl
              name={'deviceTypes'}
              label={'Device Types'}
              options={sortArray<OptionType>(
                ['PM', 'ICD', 'CRT-D', 'CRT-P', 'ILR'].map(deviceType => ({
                  value: deviceType,
                  label: deviceType,
                })) ?? [],
                'label',
              )}
              withSelectAll
              control={control}
            />
          </Skeleton>
        </Stack>
      </form>
    </Modal>
  )
}
const PractitionersValueContainer = (
  props: ValueContainerProps<OptionType, true>,
) => {
  return (
    <CustomValueContainer {...props} max={2} label={'practitioners'}>
      {props?.children}
    </CustomValueContainer>
  )
}

const ClinicsValueContainer = (
  props: ValueContainerProps<OptionType, true>,
) => {
  return (
    <CustomValueContainer {...props} max={2} label={'clinics'}>
      {props?.children}
    </CustomValueContainer>
  )
}

const OptionComponent = (props: OptionProps<OptionType, true>) => (
  <CustomCheckBoxOption {...props}>
    <Flex flexDirection={'column'}>
      <Text fontSize={'md'} fontWeight={'semibold'} color={'inherit'}>
        {props?.data?.label}
      </Text>
      <Text color={'inherit'} mt={1}>
        {props?.data?.parentName}
      </Text>
    </Flex>
  </CustomCheckBoxOption>
)
export default GlobalFiltersModal
