import { Modal, VStack } from '@rhythm/components'

import { useTransmissionReportContext } from '../../../../../features/transmissionReports'
import { ClinicalNote } from '../../../../../lib/api'

import { AddClinicalNote } from './AddClinicalNote'

export interface AddClinicalNoteModalProps {
  isOpen: boolean
  onClose: () => void
  selectedNote: ClinicalNote | null
  setSelectedNote: (note: ClinicalNote | null) => void
}

const AddClinicalNoteModal = ({
  isOpen,
  onClose,
  selectedNote,
  setSelectedNote,
}: AddClinicalNoteModalProps) => {
  const { currentTransmission } = useTransmissionReportContext()
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <VStack>
        <AddClinicalNote
          patientId={currentTransmission?.patient?.id}
          onClose={onClose}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
        />
      </VStack>
    </Modal>
  )
}

export default AddClinicalNoteModal
