import { Card, Spinner } from '@rhythm/components'

const FormLoading = () => (
  <Card
    justifyContent="center"
    height="100vh"
    alignItems="center"
    display="flex"
  >
    <Spinner size="xl" color="primary.400" />
  </Card>
)

export default FormLoading
