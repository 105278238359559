import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'

export const useGetPinnedNotesByPatientId = ({
  patientId,
}: {
  patientId: string
}) => {
  const Api = useApiContext()
  return useQuery(
    ['getPinnedNotesByPatientId', { id: patientId }],
    async () => {
      const res = await Api.clinicalNotesControllerGetPinnedNotesAndTotalCount({
        patientId,
      })
      return res.data
    },
    {
      enabled: !!patientId,
    },
  )
}
