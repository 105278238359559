import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import moment from 'moment'

import { useGetPractitioners } from '../features/reportGeneration/useGetPractitioners'
import { useGlobalFilter } from '../hooks/useGlobalFilter'
import {
  AccountOverviewQuery,
  AlertsQuery,
  BillableQuery,
  SchedulingQuery,
  SignatureStatusQuery,
} from '../lib/api'
import { fetchAnalyticsProps } from '../pages/physician/AnalyticsPages/components/columns'
import {
  Account,
  fetchAccountClinicMap,
} from '../pages/physician/AnalyticsPages/components/constants'

export type QueryParams =
  | AccountOverviewQuery
  | BillableQuery
  | AlertsQuery
  | SchedulingQuery
  | SignatureStatusQuery

interface AnalyticsContextInterface {
  selectedAccounts: Account[]
  setSelectedAccounts: React.Dispatch<React.SetStateAction<Account[]>>
  hasSetSelectedAccountsOnce: boolean
  setHasSetSelectedAccountsOnce: React.Dispatch<React.SetStateAction<boolean>>
  isFilterSubmenuOpen: boolean
  setIsFilterSubmenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  activeAnalyticsQueryParams: QueryParams | null
  updateActiveAnalyticsQueryParams: (
    update: QueryParams | ((prev: QueryParams) => QueryParams),
    isOffsetUpdated?: boolean,
  ) => void
  resetQueryParamsToTable: string | null
  setResetQueryParamsToTable: React.Dispatch<
    React.SetStateAction<string | null>
  >
}

const initialState: AnalyticsContextInterface = {
  selectedAccounts: [],
  setSelectedAccounts: () => {},
  hasSetSelectedAccountsOnce: false,
  setHasSetSelectedAccountsOnce: () => {},
  isFilterSubmenuOpen: false,
  setIsFilterSubmenuOpen: () => {},
  activeAnalyticsQueryParams: null,
  updateActiveAnalyticsQueryParams: () => {},
  resetQueryParamsToTable: '',
  setResetQueryParamsToTable: () => {},
}

const AnalyticsContext = createContext<AnalyticsContextInterface>(initialState)

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const location = useLocation()
  const history = useHistory()
  const { data: filterData } = useGlobalFilter()
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | null>(
    null,
  )
  const { defaultQuery, columns } = fetchAnalyticsProps(location.pathname)
  const [activeAnalyticsQueryParams, setActiveAnalyticsQueryParams] =
    useState<QueryParams>(defaultQuery)
  const clinicIds = useMemo(() => {
    return selectedAccounts?.flatMap(account => {
      return account.clinics?.map(clinic => clinic.id) ?? []
    })
  }, [selectedAccounts])
  const { refetch: refetchPractitioners } = useGetPractitioners({
    clinicIds: clinicIds ?? [],
  })
  const [resetQueryParamsToTable, setResetQueryParamsToTable] = useState<
    string | null
  >(null)

  useEffect(() => {
    if (clinicIds && clinicIds.length > 0) {
      refetchPractitioners()
    }
  }, [clinicIds, refetchPractitioners])

  useEffect(() => {
    const { defaultQuery, key } = fetchAnalyticsProps(location.pathname)
    const savedDateRange = sessionStorage.getItem(`${key}_dateRange`)
    const savedColumnsStr = sessionStorage.getItem(`${key}_columns`)
    const savedColumns = savedColumnsStr ? savedColumnsStr?.split(',') : null
    const savedFiltersStr = sessionStorage.getItem(`${key}_filters`)
    const savedFilters = savedFiltersStr
      ? (JSON.parse(savedFiltersStr) as any[])
      : null
    const savedDateRangeArr = (savedDateRange ?? '').split(',')
    if (
      savedDateRangeArr.length > 1 &&
      savedDateRangeArr.every(date => moment(date).isValid())
    ) {
      defaultQuery.dateRange = savedDateRangeArr
    }
    if (savedColumns && savedColumns.length > 0) {
      defaultQuery.columns = savedColumns as any[]
    }
    if (savedFilters && savedFilters.length > 0) {
      defaultQuery.filters = savedFilters
    }
    setActiveAnalyticsQueryParams(defaultQuery)
  }, [location.pathname])

  const updateActiveAnalyticsQueryParams = useCallback(
    (
      update:
        | typeof activeAnalyticsQueryParams
        | ((
            prev: typeof activeAnalyticsQueryParams,
          ) => typeof activeAnalyticsQueryParams),
      isOffsetUpdated?: boolean,
    ) => {
      setActiveAnalyticsQueryParams((prev: any) => {
        const updatedParams =
          typeof update === 'function' ? update(prev) : { ...prev, ...update }
        return {
          ...updatedParams,
          limit: prev.limit && prev.limit > 100 ? 50 : updatedParams.limit,
          offset: isOffsetUpdated ? updatedParams.offset : 0,
        }
      })
    },
    [],
  )
  const [hasSetSelectedAccountsOnce, setHasSetSelectedAccountsOnce] =
    useState(false)
  const [isFilterSubmenuOpen, setIsFilterSubmenuOpen] = useState(false)

  useEffect(() => {
    if (resetQueryParamsToTable) {
      history.push(resetQueryParamsToTable)
      setResetQueryParamsToTable(null)
    }
  }, [history, resetQueryParamsToTable])

  const fetchClinicIds = (accounts: Account[]): string[] => {
    return accounts.flatMap(account => {
      return account.clinics?.map(clinic => clinic.id) ?? []
    })
  }

  useEffect(() => {
    const { key } = fetchAnalyticsProps(location.pathname)
    const savedColumnsStr = sessionStorage.getItem(`${key}_columns`)
    const savedColumns = savedColumnsStr ? savedColumnsStr?.split(',') : []
    const cols =
      savedColumns.length > 0
        ? columns
            .filter(column => savedColumns.includes(column.accessor as any))
            .map(column => column.accessor as any)
        : columns.map(column => column.accessor as any)

    if (!selectedAccounts) return
    const clinicIds = selectedAccounts?.length
      ? fetchClinicIds(selectedAccounts)
      : filterData?.clinics?.map(clinic => clinic.id) || []
    updateActiveAnalyticsQueryParams(prev => ({
      ...prev,
      clinicIds,
      columns: cols,
    }))
  }, [
    columns,
    filterData?.clinics,
    location.pathname,
    selectedAccounts,
    updateActiveAnalyticsQueryParams,
  ])

  useEffect(() => {
    if (selectedAccounts && selectedAccounts.length === 0) {
      updateActiveAnalyticsQueryParams(prev => ({
        ...prev,
        clinicIds: filterData?.clinics?.map(clinic => clinic.id) ?? [],
        columns: columns.map(column => column.accessor as any),
      }))
      const map = fetchAccountClinicMap(filterData?.clinics ?? [])
      const arr = Array.from(map.values())
      setSelectedAccounts(arr ?? [])
    }
    // run this only when the pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const contextValue = useMemo(
    () => ({
      selectedAccounts,
      setSelectedAccounts,
      hasSetSelectedAccountsOnce,
      setHasSetSelectedAccountsOnce,
      isFilterSubmenuOpen,
      setIsFilterSubmenuOpen,
      activeAnalyticsQueryParams,
      updateActiveAnalyticsQueryParams,
      resetQueryParamsToTable,
      setResetQueryParamsToTable,
    }),
    [
      selectedAccounts,
      hasSetSelectedAccountsOnce,
      isFilterSubmenuOpen,
      activeAnalyticsQueryParams,
      updateActiveAnalyticsQueryParams,
      resetQueryParamsToTable,
    ],
  )
  return (
    <AnalyticsContext.Provider
      value={contextValue as AnalyticsContextInterface}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalyticsContext = (): AnalyticsContextInterface =>
  useContext(AnalyticsContext)
