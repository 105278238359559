import { Text } from '@rhythm/components'

import { ReportHistoryView } from '../../../../lib/api'

export type ColumnCellProps = {
  row: {
    original: ReportHistoryView
  }
}

export const UserCell = ({ row: { original } }: ColumnCellProps) => {
  const user = original.USER
  return <Text fontWeight="bold">{user}</Text>
}
export const DateCell = ({ row: { original } }: ColumnCellProps) => {
  const date = original.DATE
  return <Text fontWeight="bold">{date}</Text>
}

export const SectionCell = ({ row: { original } }: ColumnCellProps) => {
  const section = original.SECTION
  return <Text fontWeight="bold">{section}</Text>
}

export const cells = {
  DateCell,
  SectionCell,
  UserCell,
}

export const columns = [
  {
    Header: 'DATE',
    accessor: 'DATE',
    Cell: cells.DateCell,
    width: 225,
  },
  {
    Header: 'SECTION',
    accessor: 'SECTION',
    Cell: cells.SectionCell,
  },
  {
    Header: 'USER',
    accessor: 'USER',
    Cell: cells.UserCell,
    width: 200,
  },
]
