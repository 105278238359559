import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiReportTemplateControllerReplaceTemplateRequest,
  queryClient,
} from '../../../lib/api'

export const useReplaceReportTemplate = (toast: any) => {
  const Api = useApiContext()
  return useMutation(
    async (
      reportTemplateReplace: DefaultApiReportTemplateControllerReplaceTemplateRequest,
    ) => {
      const { data } = await Api.reportTemplateControllerReplaceTemplate(
        reportTemplateReplace,
      )
      return {
        data,
        transmissionReportId:
          reportTemplateReplace.applyOrReplaceTemplateRequest.reportId,
      }
    },
    {
      onSuccess: ({ data, transmissionReportId }) => {
        queryClient.invalidateQueries('transmissionReport').then(() => {
          toast(data.removed, data.applied)
        })
        queryClient.invalidateQueries(['billing', transmissionReportId])
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
