import React from 'react'

import {
  Box,
  Icon,
  Tag,
  TagLabel,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  Wrap,
} from '@rhythm/components'

const PRIORITY_OPTIONS = ['VT Priority', 'AF Priority', 'New Onset Afib']

interface PriorityTagProps extends UseRadioProps {
  children: string
}

const PriorityTag = ({
  children,
  ...rest
}: PriorityTagProps): React.ReactElement => {
  const { getInputProps, getCheckboxProps } = useRadio(rest)

  const inputProps = getInputProps()
  const checkboxProps = getCheckboxProps()

  return (
    <Box as="label">
      <input {...inputProps} />
      <Tag
        {...checkboxProps}
        bg="transparent"
        borderColor="feedback.error"
        borderWidth="0.5px"
        cursor="pointer"
        _checked={{
          bg: 'feedback.errorLight',
        }}
      >
        {rest.isChecked && <Icon icon="check" boxSize="sm" mr="md" />}
        <TagLabel>{children}</TagLabel>
      </Tag>
    </Box>
  )
}

interface PriorityTagSelectorProps {
  defaultValue: string
  onChange: (value: string) => void
}

const PriorityTagSelector = ({
  defaultValue,
  onChange,
}: PriorityTagSelectorProps): React.ReactElement => {
  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: 'priority',
    defaultValue,
    onChange,
  })

  const groupProps = getRootProps()

  return (
    <Wrap spacing="md" {...groupProps}>
      {PRIORITY_OPTIONS.map(option => {
        const radioProps = getRadioProps({ value: option })
        return (
          <PriorityTag key={option} {...radioProps}>
            {option}
          </PriorityTag>
        )
      })}
      <Tag
        bg="transparent"
        borderColor="neutral.800"
        borderWidth="0.5px"
        color="text.secondary"
        cursor="pointer"
        onClick={() => setValue('')}
      >
        Remove Priority
      </Tag>
    </Wrap>
  )
}

export default PriorityTagSelector
