import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiReportTemplateControllerApplyTemplateRequest,
  queryClient,
} from '../../../lib/api'

export const useApplyReportTemplate = (toast: any) => {
  const Api = useApiContext()

  return useMutation(
    async (
      reportTemplateApply: DefaultApiReportTemplateControllerApplyTemplateRequest,
    ) => {
      const { data } =
        await Api.reportTemplateControllerApplyTemplate(reportTemplateApply)
      return {
        data,
        transmissionReportId:
          reportTemplateApply.applyOrReplaceTemplateRequest.reportId,
      }
    },
    {
      onSuccess: ({ data, transmissionReportId }) => {
        queryClient.invalidateQueries('transmissionReport').then(() => {
          toast(data.template)
        })
        queryClient.invalidateQueries(['billing', transmissionReportId])
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
