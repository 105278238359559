import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiPatientsControllerFindAllAuthorizedPractitionersRequest } from '../../../lib/api'

export const useGetAuthorizedPractitioners = (
  params: DefaultApiPatientsControllerFindAllAuthorizedPractitionersRequest,
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const Api = useApiContext()
  return useQuery(
    ['authorized-practitioners', params.patientId],
    async () => {
      if (params.patientId === '') return undefined
      const res =
        await Api.patientsControllerFindAllAuthorizedPractitioners(params)
      return res.data.practitioners
    },
    { ...options },
  )
}
