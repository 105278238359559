import { useState } from 'react'

import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
} from '@rhythm/components'

import { PrimaryCtaText } from '../../../PatientProfilePage/components/DeviceDetailsWithTransmissionSchedule/constants'
import SignReportModal from '../SignReportModal'

export interface PrimaryCtaButtonProps {
  flagAndMessage: {
    flag: boolean
    msg: string
  }
  onPrimaryCtaClick: () => void
  isDisabled: boolean
  isLoading?: boolean
  primaryCtaText: PrimaryCtaText
  setPrimaryCtaText: (text: PrimaryCtaText) => void
  allowSignAndNext?: boolean
}

const PrimaryCtaButton = ({
  flagAndMessage,
  onPrimaryCtaClick,
  isDisabled,
  isLoading,
  primaryCtaText,
  setPrimaryCtaText,
  allowSignAndNext = true,
}: PrimaryCtaButtonProps) => {
  const [confirmSignatureModalOpen, setConfirmSignatureModalOpen] =
    useState(false)
  const isNext = primaryCtaText === PrimaryCtaText.SignAndNext

  const handleClick = (val: PrimaryCtaText) => {
    if (val === PrimaryCtaText.SignAndNext) {
      setConfirmSignatureModalOpen(true)
    } else {
      updatePrimaryCtaText(val)
    }
    setPrimaryCtaText(val)
    sessionStorage.setItem('primaryCtaText', val)
  }
  const updatePrimaryCtaText = (val: PrimaryCtaText) => {
    setPrimaryCtaText(val)
    sessionStorage.setItem('primaryCtaText', val)
  }
  return (
    <>
      {/* adding disbaled in the this button to instantly trigger the change
        from billing date adding it in the above component is delaying the process
        and allowing the user to click on sign reports without billing date */}
      <Tooltip
        hasArrow
        label={flagAndMessage.msg}
        placement="top"
        arrowSize={25}
      >
        <div style={{ display: 'flex' }}>
          <Button
            onClick={onPrimaryCtaClick}
            disabled={isDisabled || isLoading}
            minWidth="141px"
            height={'42px'}
          >
            {isLoading && (
              <Spinner
                size="sm"
                color="gray"
                thickness="2px"
                marginRight={'5px'}
              />
            )}
            {primaryCtaText}
          </Button>
          {allowSignAndNext && (
            <Menu variant="primary" offset={[-137, 5]}>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    variant="primary"
                    leftIcon={isOpen ? 'drop-down' : 'drop-up'}
                    iconSpacing={'-0.5'}
                    style={{
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      position: 'relative',
                      left: '-5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                    width={'15px'}
                    height={'42px'}
                    disabled={isDisabled}
                  ></MenuButton>
                  <MenuList width={'xl'} minWidth={'170px'}>
                    <MenuItem
                      onClick={() => handleClick(PrimaryCtaText.SignReport)}
                      display={'inline-block'}
                    >
                      Sign Report
                      {!isNext && (
                        <Icon
                          icon="check"
                          boxSize={'sm'}
                          color="#0E6DAD"
                          alignContent={'flex-end'}
                          float={'right'}
                        ></Icon>
                      )}
                    </MenuItem>

                    <MenuItem
                      onClick={() => handleClick(PrimaryCtaText.SignAndNext)}
                      display={'inline-block'}
                    >
                      Sign and Next
                      {isNext && (
                        <Icon
                          icon="check"
                          boxSize={'sm'}
                          color="#0E6DAD"
                          alignContent={'flex-end'}
                          float={'right'}
                        ></Icon>
                      )}
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          )}
        </div>
      </Tooltip>
      {
        // open modal to confirm signature
      }
      {confirmSignatureModalOpen && (
        <SignReportModal
          isOpen={confirmSignatureModalOpen}
          onClose={() => setConfirmSignatureModalOpen(false)}
          onConfirm={() => setConfirmSignatureModalOpen(false)}
          reportType={'Transmission Report'}
          signReportText={'Confirm Signature'}
          isLoading={false}
        />
      )}
    </>
  )
}

export default PrimaryCtaButton
