import React from 'react'

import { Center, Logo } from '@rhythm/components'

const LoadingPage = () => {
  return (
    <Center height="100%">
      <Logo transform="scale(4)" />
    </Center>
  )
}

export default LoadingPage
