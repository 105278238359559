import { useState } from 'react'

import { Card, Divider, VStack } from '@rhythm/components'

import { useUserContext } from '../../../../../context/UserContext'
import { ClinicalNote, Roles } from '../../../../../lib/api'

import { AddClinicalNote } from './AddClinicalNote'
import { ViewClinicalNotes } from './ViewClinicalNotes'

const ClinicalNotes = (): React.ReactElement => {
  const { currentUser } = useUserContext()
  const isUserInternalSuperAdmin = currentUser.role === Roles.InternalsuperAdmin

  const [selectedNote, setSelectedNote] = useState<ClinicalNote | null>(null)

  return (
    <Card>
      <VStack spacing="xl" width={'100%'}>
        {(currentUser?.isExternal || isUserInternalSuperAdmin) && (
          <AddClinicalNote
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
          />
        )}
        {(currentUser?.isExternal || isUserInternalSuperAdmin) && <Divider />}
        <ViewClinicalNotes
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
        />
      </VStack>
    </Card>
  )
}

export default ClinicalNotes
