import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Accordion } from '@rhythm/components'

import {
  useGetTransmissionReportsCountByColor,
  useTransmissionReportsListContext,
} from '../../../../../features/transmissionReports'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import { TransmissionReportDtoColorEnum } from '../../../../../lib/api'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../../../utils/constants/transmissionReports'
import { getExpandedIndex } from '../../../../../utils/transmissionReportUtil'
import { TransmissionReportPageLocationState } from '../../TransmissionReportPage'

import TransmissionReportSidebarAccordionWithPage from './TransmissionReportSidebarAccordionWithPage'

export interface TransmissionReportsAccordionProps {
  expandedIndexOverride?: number
  selectedTransmissionReportId: number
  onSelectTransmissionReport: (id: number) => void
}

const TransmissionReportsAccordion = ({
  expandedIndexOverride,
  selectedTransmissionReportId,
  onSelectTransmissionReport,
}: TransmissionReportsAccordionProps): React.ReactElement => {
  const location = useLocation<TransmissionReportPageLocationState>()

  const [expandedIndex, setExpandedIndex] = useState(
    getExpandedIndex(
      location.state?.defaultExpanded ?? TransmissionReportDtoColorEnum.Red,
    ),
  )

  useEffect(() => {
    if (
      typeof expandedIndexOverride === 'number' &&
      expandedIndexOverride >= 0
    ) {
      setExpandedIndex(expandedIndexOverride)
      // Clear the expandedIndexOverride in location.state
      location.state.expandedIndexOverride = undefined
    }
  }, [expandedIndexOverride, location.state])

  const [otherAccordionLoading, setOtherAccordionLoading] = useState(false)
  useEffect(() => {
    const windowObj = window as any
    windowObj['closeSideBarExpand'] = () => setExpandedIndex(-1)
  }, [])

  const { data: countData, isLoading: countLoading }: any =
    useGetTransmissionReportsCountByColor()

  const [dismissItems, setDismissItems] = useState<number[]>([])

  const { currentUser } = useCurrentUser()
  let existingRole: boolean = false
  if (currentUser?.role) {
    existingRole = PRACTITIONER_SIGNATURE_ROLES.includes(currentUser.role)
  }

  const { setLoadedTransmissionReports } = useTransmissionReportsListContext()

  const onSelect = (id: number): void => {
    onSelectTransmissionReport(id)
  }

  const onCheckChange = (id: number, isChecked: boolean): void => {
    let dismissIds = dismissItems
    if (isChecked) {
      if (!dismissIds.includes(id)) {
        dismissIds = [...dismissIds, id]
      }
    } else {
      const itemIndex = dismissIds.findIndex(x => x === id)
      if (itemIndex > -1) {
        dismissIds = [
          ...dismissIds.slice(0, itemIndex),
          ...dismissIds.slice(itemIndex + 1, dismissIds.length),
        ]
      }
    }
    setDismissItems(dismissIds)
  }

  const [grayIds, setGrayIds] = useState<number[]>([])

  const onCheckChangeAll = (isChecked: boolean) => {
    let greyReportIds: number[] = []
    if (isChecked) {
      if (grayIds.length > 0) {
        greyReportIds = grayIds
      }
    }
    setDismissItems(greyReportIds)
  }

  return (
    <Accordion
      allowToggle
      // allowMultiple
      index={expandedIndex}
      onChange={expanded =>
        !otherAccordionLoading && setExpandedIndex(expanded as number)
      }
      height="100%"
      overflowY="scroll"
      sx={{
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      maxH="100%"
      display="flex"
      flexDirection="column"
    >
      {!countLoading && (
        <>
          <TransmissionReportSidebarAccordionWithPage
            selectedTransmissionReportId={selectedTransmissionReportId}
            isExpanded={expandedIndex === (0 as any)}
            colorType={TransmissionReportDtoColorEnum.Red}
            defaultCount={
              !countLoading
                ? countData?.counts[TransmissionReportDtoColorEnum.Red]
                : 0
            }
            setLoadedTransmissionReports={setLoadedTransmissionReports}
            onSelect={onSelect}
            otherAccordionLoading={otherAccordionLoading}
            setOtherAccordionLoading={setOtherAccordionLoading}
          />
          <TransmissionReportSidebarAccordionWithPage
            selectedTransmissionReportId={selectedTransmissionReportId}
            isExpanded={expandedIndex === (1 as any)}
            colorType={TransmissionReportDtoColorEnum.Yellow}
            defaultCount={
              !countLoading
                ? countData?.counts[TransmissionReportDtoColorEnum.Yellow]
                : 0
            }
            setLoadedTransmissionReports={setLoadedTransmissionReports}
            onSelect={onSelect}
            otherAccordionLoading={otherAccordionLoading}
            setOtherAccordionLoading={setOtherAccordionLoading}
          />
          <TransmissionReportSidebarAccordionWithPage
            selectedTransmissionReportId={selectedTransmissionReportId}
            isExpanded={expandedIndex === (2 as any)}
            colorType={TransmissionReportDtoColorEnum.Green}
            defaultCount={
              !countLoading
                ? countData?.counts[TransmissionReportDtoColorEnum.Green]
                : 0
            }
            setLoadedTransmissionReports={setLoadedTransmissionReports}
            onSelect={onSelect}
            otherAccordionLoading={otherAccordionLoading}
            setOtherAccordionLoading={setOtherAccordionLoading}
          />
          <TransmissionReportSidebarAccordionWithPage
            selectedTransmissionReportId={selectedTransmissionReportId}
            isExpanded={expandedIndex === (3 as any)}
            colorType={TransmissionReportDtoColorEnum.Gray}
            defaultCount={
              !countLoading
                ? countData?.counts[TransmissionReportDtoColorEnum.Gray]
                : 0
            }
            setLoadedTransmissionReports={setLoadedTransmissionReports}
            existingRole={existingRole}
            onSelect={onSelect}
            otherAccordionLoading={otherAccordionLoading}
            setOtherAccordionLoading={setOtherAccordionLoading}
            onCheckChange={onCheckChange}
            selectedTransmissionIds={dismissItems}
            onCheckChangeAll={onCheckChangeAll}
            setGrayIds={setGrayIds}
          />
        </>
      )}
    </Accordion>
  )
}

export default TransmissionReportsAccordion
