import {
  useCreateTransmissionReportAlertNote,
  useDeleteTransmissionReportAlertNote,
  useUpdateTransmissionReportAlertNote,
} from '../api'

export function useTransmissionReportNotes(onCardUpdate?: any) {
  const { isLoading: isCreatingNote, mutateAsync: createNote } =
    useCreateTransmissionReportAlertNote(onCardUpdate)
  const { isLoading: isDeletingNote, mutateAsync: deleteNote } =
    useDeleteTransmissionReportAlertNote(onCardUpdate)
  const { isLoading: isUpdatingNote, mutateAsync: updateNote } =
    useUpdateTransmissionReportAlertNote(onCardUpdate)

  return {
    isLoading: (isCreatingNote || isUpdatingNote || isDeletingNote) ?? false,
    createNote,
    updateNote,
    deleteNote,
  }
}
