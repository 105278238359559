import React from 'react'

import {
  Box,
  Card,
  HStack,
  IconBadge,
  Image,
  Stack,
  Text,
} from '@rhythm/components'

import formatDate from '../../../../../utils/formatDate'

export interface ReportChartCardProps {
  title: React.ReactNode
  lastUpdatedDate: string
  onClickReport: () => void
  isBookmarked?: boolean

  // TECH_SUITE_ONLY: replace image with actual rendered chart
  chartImageSrc: string
}

const ReportChartCard = ({
  title,
  lastUpdatedDate,
  onClickReport,
  isBookmarked,
  chartImageSrc,
}: ReportChartCardProps): React.ReactElement => {
  return (
    <Card
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      px="3xl"
      py="2xl"
    >
      <HStack
        alignItems="flex-start"
        justifyContent="space-between"
        spacing="xl"
        mb="2xl"
      >
        <Stack spacing="sm">
          <Box cursor="pointer" onClick={onClickReport}>
            {typeof title === 'string' ? (
              <Text fontWeight="bold">{title}</Text>
            ) : (
              title
            )}
          </Box>
          <Text variant="secondary" fontSize="sm">
            {`Last Updated ${formatDate(lastUpdatedDate)}`}
          </Text>
        </Stack>
        {isBookmarked && (
          <IconBadge icon="bookmark-filled" color="neutral.800" size="md" />
        )}
      </HStack>
      <Box overflow="hidden">
        <Image src={chartImageSrc} maxHeight="100%" fit="contain" mx="auto" />
      </Box>
    </Card>
  )
}

export default ReportChartCard
