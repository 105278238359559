import React, { useEffect, useRef } from 'react'

interface InvisibleComponentProps {
  onVisible: () => void
}

const InvisibleComponent: React.FC<InvisibleComponentProps> = ({
  onVisible,
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            onVisible()
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1, // Adjust the threshold as needed
      },
    )

    const currentElement = elementRef.current

    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement)
      }
    }
  }, [onVisible])

  return (
    <div
      ref={elementRef}
      style={{
        width: '1px',
        height: '1px',
        opacity: 0,
      }}
    >
      {/* Invisible component */}
    </div>
  )
}

export default InvisibleComponent
