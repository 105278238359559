import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Badge,
  Box,
  Button,
  DataTable,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Switch,
  TableHeader,
  TableRow,
  Text,
  useDisclosure,
  VStack,
} from '@rhythm/components'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import * as yup from 'yup'

import { useApiContext } from '../../../../../../context/ApiContext'
import {
  useCreateScheduledTransmission,
  useScheduledTransmissionControllerUpdate,
} from '../../../../../../features/scheduledtransmissions/api'
import { useGetScheduleTermDays } from '../../../../../../features/transmissionReports/api/getScheduleTermDays'
import {
  GetScheduledTransmissionsResponse,
  ScheduledTransmission,
  ScheduleTerm,
} from '../../../../../../lib/api'
import {
  ColumnCellProps,
  createUpdatedScheduledTransmissions,
  DeviceScheduleTableProps,
  getBadgeVariantBasedOnStatus,
  processFetchedDataForDataTable,
} from '../constants'

import AddAdHocModal from './AddAdHocModal'
import ChangeDatePopoverIconButton from './ChangeDatePopoverIconButton'
import ResetModal from './ResetModal'
import TransmissionValuesCard, {
  DeviceScheduleFormValues,
  TxCardData,
} from './TransmissionValuesCard'

interface DeviceScheduleProps {
  deviceId: string
  deviceType: string
  isScheduleEnabled: boolean
  transmissionScheduleTermsAndFrequency: {
    heart_failure: {
      termDays: ScheduleTerm | null
      startDate: string | null
    }
    combo_billing: {
      termDays: ScheduleTerm | null
      startDate: string | null
    }
    routine: {
      termDays: ScheduleTerm | null
      startDate: string | null
    }
  }
  termDays?: number | null
  orgId?: string | null
}

interface ChangeDateState {
  newDate: string
  id: string
  index: number
}

export const Cells = {
  StatusCell: ({ value }: ColumnCellProps) => (
    <Badge variant={getBadgeVariantBasedOnStatus(value)}>{value}</Badge>
  ),
  IndexCell: ({ row: { index, original } }: ColumnCellProps) => {
    return (
      <Flex gap={2} alignItems={'center'}>
        <Text variant="secondary">{index + 1 + ' - ' + original.type}</Text>
        {original.isAdHocTransmission && <Badge size={'xs'}>Ad Hoc</Badge>}
      </Flex>
    )
  },
  DateCell: ({ value }: ColumnCellProps) => {
    return (
      <Text variant="secondary">{moment.utc(value).format('MM-DD-YYYY')}</Text>
    )
  },
}

export type TransmissionType = 'routine' | 'heart_failure' | 'combo_billing'

const DeviceSchedule = ({
  deviceId,
  isScheduleEnabled,
  deviceType,
  termDays,
  orgId,
  transmissionScheduleTermsAndFrequency,
}: DeviceScheduleProps) => {
  const API = useApiContext()
  const queryClient = useQueryClient()
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { data: scheduleTermDays } = useGetScheduleTermDays()

  const [isReadOnly, setIsReadOnly] = useState(true)
  const [isDirty, setIsDirty] = useState(!isScheduleEnabled) // Is Dirty is true when the form has been changed by the user and is being edited and has not been saved yet
  const [isValidFormData, setIsValidFormData] = useState(false) // This is to check if the child component has all valid form data.
  const [txCardData, setTxCardData] = useState<TxCardData>({})

  const [isFormResetted, setIsFormResetted] = useState({
    routine: false,
    heart_failure: false,
    combo_billing: false,
  })

  const [changeDateState, setChangeDateState] =
    useState<ChangeDateState | null>(null)

  const [scheduledTransmissionsState, setScheduledTransmissionsState] =
    useState<DeviceScheduleTableProps[]>([])

  const [isAdHocModalOpen, setIsAdHocModalOpen] = useState(false)

  const methods = useForm<DeviceScheduleFormValues>({
    resolver: yupResolver(
      yup.object().shape({
        isEriTracked: yup.boolean(),
      }),
    ),
    mode: 'onChange',
    defaultValues: {
      isEriTracked: false,
    },
  })

  const { control, watch, trigger } = methods

  const watchedIsEriTracked = watch('isEriTracked')
  const {
    data: fetchedScheduledTransmissions,
    isLoading,
    isError,
  } = useQuery<GetScheduledTransmissionsResponse>(
    ['scheduledTransmission', { id: deviceId }],
    async () => {
      const response = await API.scheduledTransmissionControllerGetAll({
        deviceId,
      })
      return response.data
    },
    {
      enabled: !!deviceId && !!orgId,
      retry: 0,
      onSuccess: resp => {
        // If there are no transmissions or the last transmission is before today
        // then the form should be editable
        if (resp?.transmissionScheduleReadOnly) {
          setIsReadOnly(true)
        }
        if (
          resp.total === 0 ||
          moment
            .utc(resp.data[resp.total - 1].scheduledDate)
            .isBefore(moment(), 'days')
        ) {
          setIsDirty(true)
        } else {
          // The else case is when the schedule is enabled and there are transmissions and the last transmission is after today
          // then the form should be uneditable
          methods.setValue(
            'startDate',
            moment(resp.data[0].scheduledDate).format('MM/DD/YYYY'),
          )

          setIsDirty(false)
        }
        if (resp.data[resp.total - 1]?.transmissionType) {
          methods.setValue(
            'transmissionType',
            deviceType === 'ILR'
              ? 'routine'
              : resp.data[resp.total - 1]?.transmissionType,
          )
        } else if (deviceType === 'ILR') {
          methods.setValue('transmissionType', 'routine')
        }

        const hasEriTrackedForScheduled = resp.data.some(
          (item: ScheduledTransmission) =>
            item.transmissionStatus === 'scheduled' && item.isEriTracked,
        )

        if (hasEriTrackedForScheduled) {
          methods.setValue('isEriTracked', true)
        }

        const processedData = processFetchedDataForDataTable(
          resp.data,
          isReadOnly,
        )
        const updatedScheduledTransmissions =
          removeERIFromHeartFailure(processedData)
        setScheduledTransmissionsState(updatedScheduledTransmissions)

        // Trigger the validation of the form once, for the isValid check.
        trigger()
      },
      onError: error => {
        console.error('Error fetching scheduled transmissions:', error)
      },
    },
  )

  const disableAdhocTransmission = useMemo(() => {
    if (
      !fetchedScheduledTransmissions?.data[
        fetchedScheduledTransmissions.total - 1
      ]?.scheduledDate
    )
      return false

    return moment(
      fetchedScheduledTransmissions.data[
        fetchedScheduledTransmissions.total - 1
      ]?.scheduledDate,
    ).isBefore(moment(), 'days')
  }, [fetchedScheduledTransmissions])

  const createScheduledTransmissionMutation = useCreateScheduledTransmission()
  const updateScheduledTransmissionMutation =
    useScheduledTransmissionControllerUpdate()

  const processedData = useMemo(() => {
    return processFetchedDataForDataTable(
      fetchedScheduledTransmissions?.data ?? [],
      isReadOnly,
    )
  }, [fetchedScheduledTransmissions?.data, isReadOnly])

  useEffect(() => {
    // If either the form is not editable or the start date or transmission frequency is null,
    // then stop the function.
    if (!isDirty || changeDateState) {
      return
    }

    // Generate the temp data based on the given start date and term days for each tx type.
    const tempData: DeviceScheduleTableProps[] =
      createUpdatedScheduledTransmissions({
        isEriTracked: watchedIsEriTracked,
        scheduleTypeWithDateAndTerm: txCardData,
      })

    // Combine the processed data and temporary data, then remove ERI from Heart Failure if necessary.
    const updatedScheduledTransmissions = removeERIFromHeartFailure([
      ...processedData,
      ...tempData,
    ])

    // Set the scheduled transmissions state to the processed data and temp data.
    setScheduledTransmissionsState(updatedScheduledTransmissions)
  }, [isDirty, processedData, txCardData, watchedIsEriTracked])

  const removeERIFromHeartFailure = (objects: DeviceScheduleTableProps[]) => {
    const dateMap = new Map()

    // Group objects by date
    objects.forEach(object => {
      const date = object.date
      if (!dateMap.has(date)) {
        dateMap.set(date, [])
      }
      dateMap.get(date).push(object)
    })

    // Iterate through each date group
    dateMap.forEach(objectsWithDate => {
      let hasHeartFailure = false
      let hasRoutine = false

      // Check if both types exist on the same date
      objectsWithDate.forEach((object: { type: string }) => {
        if (object.type === 'Heart Failure/ERI') {
          hasHeartFailure = true
        } else if (object.type === 'Routine/ERI') {
          hasRoutine = true
        }
      })

      // If both types exist, remove "/ERI" from Heart Failure type
      if (hasHeartFailure && hasRoutine) {
        objectsWithDate.forEach((object: { type: string }) => {
          if (object.type === 'Heart Failure/ERI') {
            object.type = 'Heart Failure'
          }
        })
      }
    })

    return objects
  }

  const resetFormToDefaultValues = () => {
    setIsFormResetted({
      routine: true,
      heart_failure: true,
      combo_billing: true,
    })

    methods.reset({
      startDate: null,
      transmissionType: null,
      transmissionFrequency: scheduleTermDays?.[termDays ?? 0] ?? null,
      isEriTracked: false,
    })
    setTxCardData({})
    setIsValidFormData(false)
    setIsDirty(false)
  }

  const handleCancel = () => {
    // Set the scheduled transmissions state to the fetched data.
    setScheduledTransmissionsState(
      processFetchedDataForDataTable(
        fetchedScheduledTransmissions?.data ?? [],
        isReadOnly,
      ),
    )

    // If the form is editable, then reset all the form values to the default values.
    if (isDirty) {
      resetFormToDefaultValues()
    }
    // If the changeDateState is not null, then reset the changeDateState to null.
    if (changeDateState !== null) {
      setChangeDateState(null)
    }
    setIsDirty(false)
  }

  const handleChangeDate = (newDate: Date, newIndex: number, id: string) => {
    setIsDirty(true)
    // Check if the index is out of bounds or if the transmission frequency is not set
    if (newIndex < 0 || newIndex >= scheduledTransmissionsState.length) {
      console.error('Index out of bounds')
      return
    }

    // Clone the array to avoid mutating the state directly.
    const updatedTransmissions = [...scheduledTransmissionsState]

    // Pre-calculate the initial date.
    const newDateMoment = moment(newDate)

    // Calculate the day difference for the selected transmission
    const selectedTransmission = updatedTransmissions[newIndex]

    // If the scheduled transmission is an ad hoc transmission, then set the status to unscheduled and the date to the new date.
    if (selectedTransmission.isAdHocTransmission) {
      updatedTransmissions[newIndex].status = 'unscheduled'
      updatedTransmissions[newIndex].date = newDateMoment.format('YYYY-MM-DD')
      setScheduledTransmissionsState(
        updatedTransmissions.sort((a, b) => a.date.localeCompare(b.date)),
      )
      setChangeDateState({
        newDate: moment(newDate).format('YYYY-MM-DD'),
        index: newIndex,
        id,
      })
      return
    }

    const dayDiff = newDateMoment.diff(
      moment(selectedTransmission.date, 'YYYY-MM-DD'),
      'days',
    )

    // Update the specified object and subsequent objects
    for (let i = newIndex; i < updatedTransmissions.length; i++) {
      if (updatedTransmissions[i].isAdHocTransmission) continue

      if (updatedTransmissions[i].type === selectedTransmission.type) {
        const updatedDate = moment(updatedTransmissions[i].date, 'YYYY-MM-DD')

        // If the transmission type is 'heart_failure' and the type of the current transmission is the same as the selected one,
        // or if the transmission type is not 'heart_failure', add the day difference to the current date
        updatedDate.add(dayDiff, 'days')

        // Update the date and status of the current transmission
        updatedTransmissions[i].date = updatedDate.format('YYYY-MM-DD')
        updatedTransmissions[i].status = 'unscheduled'
      }
    }

    const sortedArr = updatedTransmissions.sort((a, b) =>
      a.date.localeCompare(b.date),
    )
    // Update the state with the updated transmissions
    setScheduledTransmissionsState([...sortedArr])
    setIsDirty(false)
    setChangeDateState({
      newDate: moment(newDate).format('YYYY-MM-DD'),
      index: newIndex,
      id,
    })
  }

  const handleConfirmAndSchedule = async () =>
    // data: DeviceScheduleFormValues
    {
      // If the transmission type or start date is null, then stop the function.
      // if (data.transmissionFrequency === null || data.startDate === null) return

      // If the changeDateState is not null, then update the change date or else
      // create the scheduled transmissions for the device.
      if (changeDateState !== null) {
        await handleUpdateDateScheduledTransmission()
      } else {
        await handleCreateScheduledTransmission()
      }
      setIsDirty(false)
    }

  const handleCreateScheduledTransmission = async () => {
    setIsDirty(false)

    try {
      const createTransmissionDto = (type: TransmissionType) => {
        const data = txCardData[type]
        if (!data) return undefined
        const x = moment(data.startDate).format('YYYY-MM-DD')
        return data?.startDate && data?.termDays
          ? {
              startDate: x,
              transmissionFrequency: data.termDays,
            }
          : undefined
      }

      const comboBilling = createTransmissionDto('combo_billing')
      const heartFailure = createTransmissionDto('heart_failure')
      const routine = createTransmissionDto('routine')

      await createScheduledTransmissionMutation.mutateAsync(
        {
          createScheduledTransmissionDto: {
            deviceId,
            isEriTracked: watchedIsEriTracked,
            comboBilling: comboBilling,
            heartFailure: heartFailure,
            routine: routine,
          },
        },
        {
          onError: err => {
            console.log('Error creating scheduled transmissions: ', err)
            setIsDirty(true)
          },
          onSuccess: () => {
            queryClient.invalidateQueries(['patient'])
            queryClient.invalidateQueries([
              'scheduledTransmission',
              { id: deviceId },
            ])
            queryClient.invalidateQueries('scheduledTransmission')
            queryClient.invalidateQueries(
              'find-upcoming-Scheduled-transmission',
            )
            setIsDirty(false)
          },
        },
      )
    } catch (err) {
      console.log(err)
    }
  }

  const handleUpdateDateScheduledTransmission = async () => {
    if (changeDateState === null) return
    try {
      await updateScheduledTransmissionMutation.mutateAsync(
        {
          scheduledTransmissionId: changeDateState.id,
          updateDateScheduledTransmissionDto: {
            newDate: changeDateState.newDate,
          },
        },
        {
          onError: err => {
            console.log('Error updating the change date: ', err)
          },
          onSuccess: () => {
            queryClient.invalidateQueries('patient')
            queryClient.invalidateQueries('scheduledTransmission')
            queryClient.invalidateQueries([
              'scheduledTransmission',
              { id: deviceId },
            ])
            queryClient.invalidateQueries(
              'find-upcoming-Scheduled-transmission',
            )
            setChangeDateState(null)
          },
        },
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputChangeForTxCard = useCallback(
    (
      txType: TransmissionType,
      value: {
        isChecked: boolean
        txDate: string | null
        txFreq: {
          label: string
          value: string
          freq: number
          transmissions: number
        } | null
        validFormData?: boolean | undefined
      },
    ) => {
      setTxCardData({
        ...txCardData,
        [txType]: {
          startDate: value.txDate,
          termDays: value?.txFreq?.freq,
          isChecked: value.isChecked,
        },
      })
      setIsValidFormData(value.validFormData ?? false)
      setIsDirty(true)
    },
    [txCardData],
  )

  const getDisableDateForChangeDate = (index: number) => {
    const currentTransmission = scheduledTransmissionsState[index]
    const previousTransmissionWithSameType = scheduledTransmissionsState
      .slice(0, index)
      .reverse()
      .find(
        ({ type, isAdHocTransmission }) =>
          type === currentTransmission.type && !isAdHocTransmission,
      )

    if (
      !previousTransmissionWithSameType ||
      currentTransmission.isAdHocTransmission ||
      moment(previousTransmissionWithSameType.date).isBefore(moment(), 'days')
    )
      return moment().format('MM-DD-YYYY')
    return moment(previousTransmissionWithSameType.date).format('MM-DD-YYYY')
  }

  if (isLoading) return <Text>Loading...</Text>
  if (isError) return <Text>Error</Text>

  return (
    <Box>
      <HStack
        display={'flex'}
        justifyContent={'space-between'}
        alignSelf={'center'}
        mb={2}
      >
        <Heading variant="h5">Device Schedule</Heading>
        <Flex gap={1}>
          <Button
            size={'sm'}
            onClick={() => {
              setIsAdHocModalOpen(true)
            }}
            isDisabled={
              isReadOnly || disableAdhocTransmission || changeDateState !== null
            }
            hidden={
              fetchedScheduledTransmissions &&
              fetchedScheduledTransmissions?.data?.length <= 0
            }
          >
            Add Ad Hoc Transmission
          </Button>
          <Button
            variant="secondaryLight"
            size={'sm'}
            onClick={onToggle}
            disabled={
              isReadOnly ||
              isDirty ||
              changeDateState !== null ||
              createScheduledTransmissionMutation.isLoading ||
              updateScheduledTransmissionMutation.isLoading
            }
          >
            Reset Transmission Schedule
          </Button>
        </Flex>
      </HStack>
      <form>
        <Controller
          name="isEriTracked"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <HStack my={4}>
                <Switch
                  id="isEriTracked"
                  onChange={onChange}
                  isChecked={value ?? false}
                  isDisabled={isReadOnly || !isDirty}
                />
                <FormLabel htmlFor="isEriTracked" mb="0">
                  ERI Tracking
                </FormLabel>
              </HStack>
            )
          }}
        />
        <VStack spacing={8} alignItems="start">
          <HStack
            spacing={4}
            display={'flex'}
            flexDirection={'row'}
            width={'full'}
          >
            {['routine', 'heart_failure', 'combo_billing'].map(txType => (
              <TransmissionValuesCard
                key={`${txType}-select-card`}
                transmissionType={
                  txType as 'routine' | 'heart_failure' | 'combo_billing'
                }
                onInputChange={handleInputChangeForTxCard}
                isDirty={isDirty}
                txCardData={txCardData}
                scheduleTermsAndFrequency={
                  transmissionScheduleTermsAndFrequency[
                    txType as TransmissionType
                  ]
                }
                isReadOnly={isReadOnly}
                isFormResetted={isFormResetted[txType as TransmissionType]}
                setIsFormResetted={setIsFormResetted}
              />
            ))}
          </HStack>

          {scheduledTransmissionsState.length > 0 && (
            <DataTable
              columns={[
                {
                  Header: 'Transmission Type',
                  Cell: Cells.IndexCell,
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Cell: Cells.StatusCell,
                },
                {
                  Header: 'Date',
                  accessor: 'date',
                  Cell: Cells.DateCell,
                },
                {
                  Header: '',
                  accessor: 'action',
                  width: '10%',
                  Cell: ({
                    row: { original, index },
                    value,
                  }: ColumnCellProps) => (
                    <ChangeDatePopoverIconButton
                      onDateChange={(newDate, newIndex) =>
                        handleChangeDate(newDate, newIndex, original.id)
                      }
                      previousTransmissionDate={getDisableDateForChangeDate(
                        index,
                      )}
                      currentScheduledDate={original.date}
                      // Only allow the change date button to be active if the transmission is scheduled
                      // or unscheduled and the form is uneditable and the changeDateState is null or the
                      // index matches the changeDateState index
                      isActive={
                        !isReadOnly &&
                        (original.status === 'scheduled' ||
                          original.status === 'unscheduled') &&
                        (changeDateState === null ||
                          changeDateState.index === index)
                      }
                      index={index}
                      transmissionId={scheduledTransmissionsState[index].id}
                    />
                  ),
                },
              ]}
              data={scheduledTransmissionsState}
              size="sm"
              isSortable
              isSearchable
              isClickable
              selectedRowBackgroundColor="primary.100"
              selectedRowBorderColor="primary.400"
              borderCollapse="separate"
              borderColor="neutral.200"
              borderStyle="solid"
              borderWidth="1px"
              borderSpacing="0 2px"
              tableHeadCell={
                <TableHeader
                  color="neutral.800"
                  bg="neutral.white"
                  borderColor="neutral.200"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px"
                  py="xl"
                />
              }
              tableBodyRow={() => (
                <TableRow
                  alignItems="center"
                  borderBottomColor="neutral.200"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px"
                  sx={{
                    svg: {
                      display: 'none',
                    },
                    '&:hover svg': {
                      display: 'block',
                    },
                  }}
                  bg="neutral.white"
                />
              )}
            />
          )}
          <HStack w="100%" justifyContent="flex-end">
            <Button
              variant="secondaryLight"
              disabled={
                isReadOnly ||
                !(isDirty && isValidFormData && changeDateState) ||
                createScheduledTransmissionMutation.isLoading ||
                updateScheduledTransmissionMutation.isLoading
              }
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              disabled={
                isReadOnly || !(isDirty && isValidFormData && changeDateState)
              }
              isLoading={
                createScheduledTransmissionMutation.isLoading ||
                updateScheduledTransmissionMutation.isLoading
              }
              onClick={async () => {
                await handleConfirmAndSchedule()
              }}
            >
              Confirm and Schedule
            </Button>
          </HStack>
        </VStack>
      </form>
      {!isReadOnly && (
        <>
          <ResetModal
            isOpen={isOpen}
            onClose={onClose}
            deviceId={deviceId}
            onReset={resetFormToDefaultValues}
          />
          <AddAdHocModal
            deviceId={deviceId}
            isOpen={isAdHocModalOpen}
            deviceType={deviceType}
            onClose={() => setIsAdHocModalOpen(false)}
          />
        </>
      )}
    </Box>
  )
}

export default DeviceSchedule
