import { useEffect } from 'react'
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'

import { AccessToken } from '@okta/okta-auth-js'
import { SecureRoute, useOktaAuth } from '@okta/okta-react'

import { AnalyticsProvider } from '../context/AnalyticsContext'
import { useApiContext } from '../context/ApiContext'
import { useUserContext } from '../context/UserContext'
import AppLayout from '../layouts/AppLayout'
import { Roles } from '../lib/api'
import LoadingPage from '../pages/LoadingPage'
import NotFoundPage from '../pages/NotFoundPage'
import routes from '../routes/config'
import { checkIfExternal } from '../utils/authorization'

import PhysicianRoutes from './PhysicianRoutes'
import UserRoutes from './UserRoutes'

class AuthError extends Error {}

const extractRoleFromAccessToken = (accessToken?: AccessToken): Roles => {
  let role: Roles | undefined
  const claims = accessToken?.claims
  if (claims != null) {
    const { roles } = claims
    if (Array.isArray(roles) && roles.length > 0) {
      role = roles[0] as Roles
    } else if (typeof roles === 'string') {
      role = roles as Roles
    }
  }
  if (role == null) throw new AuthError('user does not have an assigned role')
  return role as Roles
}

const AuthRoutes = () => {
  const location = useLocation()
  const { currentUser, setCurrentUser } = useUserContext()
  const { authState, oktaAuth } = useOktaAuth()

  const Api = useApiContext()

  useEffect(() => {
    const oktaSetUser = async () => {
      try {
        const { given_name, family_name, email, sub } = await oktaAuth.getUser()
        const role = extractRoleFromAccessToken(authState?.accessToken)

        if (given_name && family_name && email && sub) {
          const { data: userData } =
            await Api.usersControllerFindCurrentUserInfo()

          setCurrentUser({
            lastName: userData?.user?.family ?? family_name,
            firstName: userData?.user?.given ?? given_name,
            id: userData?.user?.id ?? '',
            email,
            externalId: sub,
            role,
            credentials: userData.user?.credentials?.join(', '),
            isExternal: checkIfExternal(role),
            organizations: userData?.user?.organizations,
            featureFlagRole: userData.user?.featureFlagRole,
          })
          return
        }
      } catch (e) {
        console.error('Okta could not get user', e)
      }
    }

    if (authState && authState.isAuthenticated) {
      oktaSetUser()
    }
  }, [Api, authState, oktaAuth, setCurrentUser])

  // If authenticated but currentUser externalId hasn't been set, render the loading page
  if (
    authState?.isAuthenticated &&
    (currentUser.externalId == null || currentUser.externalId.length === 0)
  ) {
    return <LoadingPage />
  }

  const ROUTES_WITH_HEADER_BACKGROUND = [routes.physician.transmissionReport]
  const ROUTES_WITHOUT_SIDENAV = [routes.physician.transmissionReview]
  const hasHeaderBackground = ROUTES_WITH_HEADER_BACKGROUND.some(path =>
    matchPath(location.pathname, { path, exact: true }),
  )
  const hideSideNav = ROUTES_WITHOUT_SIDENAV.some(path =>
    matchPath(location.pathname, { path, exact: true }),
  )

  return (
    <>
      {!authState?.isAuthenticated && <LoadingPage />}
      <SecureRoute path={routes.root}>
        <AppLayout
          hasHeaderBackground={hasHeaderBackground}
          hideSideNav={hideSideNav}
        >
          <Switch>
            <Route path={routes.physician.root}>
              <AnalyticsProvider>
                <PhysicianRoutes />
              </AnalyticsProvider>
            </Route>
            <Route path={routes.user.root}>
              <UserRoutes />
            </Route>
            <Route exact path={routes.root}>
              <Redirect to={routes.physician.root} />
            </Route>
            <Route exact path={routes.operations.root}>
              <Redirect to={routes.operations.root} />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </AppLayout>
      </SecureRoute>
    </>
  )
}

export default AuthRoutes
