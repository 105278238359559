import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  FindAllPatientsParams,
  FindAllPatientsResponse,
} from '../../../lib/api'

export interface GetAllPatientsProps {
  offset?: number
  limit?: number
  isNew?: boolean
  search?: string
  sortBy?: string
  sortDirection?: string
  isRecentSigned?: boolean
  isDownloaded?: boolean
  deviceSchedule?: string
  status?: string
}

export interface NewGetAllPatientsProps {
  skip?: number
  pageLimit?: number
  isNew?: boolean
  search?: string
  sortBy?: string
  sortDirection?: string
  isRecentSigned?: boolean
  isDownloaded?: boolean
  deviceSchedule?: string
  status?: string
}

export function useGetAllPatients(
  findAllPatientsParams: NewGetAllPatientsProps,
  options:
    | Omit<
        UseQueryOptions<
          FindAllPatientsResponse,
          unknown,
          FindAllPatientsResponse,
          (string | NewGetAllPatientsProps)[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined = {},
) {
  const Api = useApiContext()
  return useQuery(
    ['patients', 'all', findAllPatientsParams],
    async () => {
      if (!findAllPatientsParams.pageLimit && !findAllPatientsParams.skip) {
        findAllPatientsParams.pageLimit = 10
        findAllPatientsParams.skip = 0
      }
      const res = await Api.patientsControllerGetAllPatientsWithAccessTo({
        findAllPatientsParams: findAllPatientsParams as FindAllPatientsParams,
      })
      return res.data
    },
    options,
  )
}
