import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import { DefaultApiTransmissionReportsControllerAddAlertImageRequest } from '../../../../lib/api'
import { queryClient } from '../../../../lib/api/react-query'

export const useCreateTransmissionReportAlertImage = () => {
  const Api = useApiContext()
  return useMutation(
    (
      transmissionReportAddAlertImageParams: DefaultApiTransmissionReportsControllerAddAlertImageRequest,
    ) =>
      Api.transmissionReportsControllerAddAlertImage(
        transmissionReportAddAlertImageParams,
      ),
    {
      onSuccess: (_, variables) => {
        const { transmissionReportId } = variables
        queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReportId,
        ])
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
      },
    },
  )
}
