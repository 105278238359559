import React, { useContext, useEffect, useState } from 'react'

import { Box } from '@rhythm/components'
import { ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel'

import Fab from './Fab'

const CarouselControls = () => {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide,
  )

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  const showBackButton = currentSlide !== 0
  const showNextButton =
    carouselContext.state.totalSlides > carouselContext.state.visibleSlides &&
    currentSlide !==
      carouselContext.state.totalSlides - carouselContext.state.visibleSlides

  return (
    <>
      {showBackButton && (
        <Box position="absolute" top="calc(50% - 32px)" left="-32px">
          <ButtonBack>
            <Fab icon="arrow-left" />
          </ButtonBack>
        </Box>
      )}
      {showNextButton && (
        <Box position="absolute" top="calc(50% - 32px)" right="-32px">
          <ButtonNext>
            <Fab icon="arrow-right" />
          </ButtonNext>
        </Box>
      )}
    </>
  )
}

export default CarouselControls
