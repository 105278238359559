import { cells, headerCells, PatientCell } from './Cells'

const columns = [
  {
    Header: headerCells.CheckBoxHeader,
    id: 'checkbox',
    width: 0,
    Cell: cells.CheckBoxCell,
  },
  {
    Header: 'name/dob/mrn',
    accessor: 'familyName',
    Cell: PatientCell,
    width: 160,
  },
  {
    Header: 'Status',
    Cell: cells.PatientStatusCell,
    width: 160,
  },
  {
    Header: 'Clinic Name',
    accessor: 'name',
    Cell: cells.ClinicCell,
    width: 225,
  },
  {
    Header: 'Device Type',
    accessor: 'type',
    Cell: cells.TypeCell,
    width: 120,
  },
  {
    Header: 'Vendor',
    accessor: 'vendor',
    Cell: cells.DeviceTypeCell,
    width: 200,
  },
  {
    Header: 'Most Recent Report',
    Cell: cells.MostRecentReportCell,
    width: 320,
  },
  {
    Header: 'Reports',
    Cell: cells.ReportsCell,
    //minWidth: 150,
  },
]

export default columns
