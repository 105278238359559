import React, { useEffect, useMemo, useState } from 'react'

import {
  Box,
  Card,
  Heading,
  HStack,
  Logo,
  Stack,
  StackProps,
  Text,
} from '@rhythm/components'

import { useOrganization } from '../../../../../features/organizations/api/getOrganization'
import { useTransmissionReportsListContext } from '../../../../../features/transmissionReports'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import { TransmissionReportDtoColorEnum } from '../../../../../lib/api'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../../../utils/constants/transmissionReports'

import TransmissionReportsStatusCard from './TransmissionReportStatusCard'

export interface TransmissionReportsStatusCardRowProps extends StackProps {
  onSignAllRoutine: () => void
  countData: any
  isLoading: boolean
}

const TransmissionReportsStatusCardRow = ({
  onSignAllRoutine,
  countData,
  isLoading,
  ...restOfStackProps
}: TransmissionReportsStatusCardRowProps): React.ReactElement => {
  const { currentUser } = useCurrentUser()

  const cloneTransmissionReportDtoColorEnum = JSON.parse(
    JSON.stringify(TransmissionReportDtoColorEnum),
  )

  let userParentOrganizationId: string = ''
  if (
    currentUser &&
    currentUser.organizations &&
    (currentUser.organizations?.length ?? 0) > 0
  ) {
    userParentOrganizationId = currentUser?.organizations[0]?.parentId ?? ''
  }

  const { data: userOrgData } = useOrganization({
    organizationId: userParentOrganizationId,
  })
  const enableSignAll: boolean = userOrgData?.organization.isSignAll ?? false

  let existingRole: boolean
  if (currentUser && currentUser.role) {
    existingRole = PRACTITIONER_SIGNATURE_ROLES.includes(currentUser.role)
    if (existingRole) {
      delete cloneTransmissionReportDtoColorEnum.Gray
    }
  }

  const [zeroState, setZeroState] = useState<boolean>(false)

  useEffect(() => {
    if (countData?.counts) {
      let zeroStateIterator = true
      const colorCounts: any = countData.counts
      for (const colorKey in colorCounts) {
        if (colorCounts[colorKey] && colorCounts[colorKey] > 0) {
          zeroStateIterator = false
        }
      }
      setZeroState(zeroStateIterator)
    }
  }, [countData])

  const { data: transmissionReportsData } = useTransmissionReportsListContext()
  // console.log('transmissionReportsData ',transmissionReportsData)
  const transmissionReports = useMemo(
    () =>
      isLoading || !transmissionReportsData
        ? []
        : transmissionReportsData.transmissionReports,
    [transmissionReportsData, isLoading],
  )

  return (
    <HStack spacing={'lg'} alignItems={'stretch'} {...restOfStackProps}>
      {zeroState ? (
        <Card px="none" py="none" w={'full'}>
          <Stack alignItems={'center'} justifyContent={'center'} my={'5xl'}>
            <Box mb={'2xl'}>
              <Logo transform="scale(2)" />
            </Box>
            <Heading variant="h5" mb={'sm'}>
              Nice Work, You&apos;re All Caught Up
            </Heading>
            <Text color={'gray.600'}>
              There are no transmission reports for review at this time
            </Text>
          </Stack>
        </Card>
      ) : (
        !zeroState &&
        (
          Object.keys(cloneTransmissionReportDtoColorEnum) as Array<
            keyof typeof TransmissionReportDtoColorEnum
          >
        ).map((key, i) => {
          return (
            <TransmissionReportsStatusCard
              isLoading={isLoading}
              key={`${key}-${i}`}
              width={'100%'}
              colorEnum={cloneTransmissionReportDtoColorEnum[key]}
              counts={countData?.counts}
              onSignAllRoutine={onSignAllRoutine}
              transmissionReports={transmissionReports}
              enableSignAll={enableSignAll}
            />
          )
        })
      )}
    </HStack>
  )
}

export default TransmissionReportsStatusCardRow
