import { TramssionReportColor, TransmissionStatus } from '../types'

const getReportName = (status?: TramssionReportColor | TransmissionStatus) => {
  switch (status) {
    case TransmissionStatus.RED:
      return 'Red Alert Report'
    case TransmissionStatus.YELLOW:
      return 'Yellow Alert Report'
    case TransmissionStatus.ROUTINE:
      return 'Routine Report'
    default:
      return 'Report'
  }
}

export default getReportName
