import { useQuery } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiHistoryControllerFindOneRequest } from '../../../lib/api'

export const useGetTransmissionReportHistoryById = ({
  transmissionReportId,
  patientId,
  signedDate,
}: DefaultApiHistoryControllerFindOneRequest) => {
  const Api = useApiContext()
  return useQuery(
    ['transmissionReportHistory', transmissionReportId, patientId, signedDate],
    async () => {
      const {
        data: { transmissionReportHistory },
      } = await Api.historyControllerFindOne({
        transmissionReportId,
        patientId,
        signedDate,
      })
      return transmissionReportHistory
    },
  )
}
