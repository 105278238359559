import React from 'react'

import { Box, Flex, HStack } from '@rhythm/components'

import NarrativeCard from '../NarrativeCard'

import DeviceBatteryNotes from './DeviceBatteryNotes'
import DeviceBatteryTable from './DeviceBatteryTable'

const DeviceBatteryCard = (): React.ReactElement => {
  return (
    <NarrativeCard title="Battery Status" icon="battery">
      <HStack alignItems="top" spacing="4xl">
        <Flex width="50%" direction="column" alignItems="stretch">
          <DeviceBatteryNotes />
        </Flex>
        <Flex width="50%" direction="column" alignItems="stretch">
          <Box overflowY="auto">
            <DeviceBatteryTable />
          </Box>
        </Flex>
      </HStack>
    </NarrativeCard>
  )
}

export default DeviceBatteryCard
