import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerDeleteAlertRequest,
  TransmissionReport,
} from '../../../../lib/api'
import { queryClient } from '../../../../lib/api/react-query'

export const useDeleteTransmissionReportAlert = () => {
  const Api = useApiContext()
  return useMutation(
    (
      transmissionReportAlertCreateParams: DefaultApiTransmissionReportsControllerDeleteAlertRequest,
    ) =>
      Api.transmissionReportsControllerDeleteAlert(
        transmissionReportAlertCreateParams,
      ),
    {
      onSuccess: (data, variables) => {
        const { transmissionReportId, alertId } = variables

        const {
          data: { deleted },
        } = data

        if (deleted) {
          queryClient.setQueryData<Partial<TransmissionReport | undefined>>(
            ['transmissionReport', transmissionReportId],
            transmissionReport => {
              if (transmissionReport?.alerts && alertId) {
                transmissionReport.alerts = transmissionReport?.alerts.filter(
                  alert => alert.id !== alertId,
                )
              }
              return transmissionReport
            },
          )
        }
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
      },
    },
  )
}
