import { Toast, useToast } from '@rhythm/components'
import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiScheduledTransmissionControllerUpdateRequest } from '../../../lib/api'

export const useScheduledTransmissionControllerUpdate = () => {
  const Api = useApiContext()
  const toast = useToast()
  return useMutation(
    (req: DefaultApiScheduledTransmissionControllerUpdateRequest) =>
      Api.scheduledTransmissionControllerUpdate(req),
    {
      onSuccess: (data, variables) => {},
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
        toast({
          position: 'bottom-right',
          render: ({ onClose }) => (
            <Toast variant="error" onClose={onClose}>
              Error Updating Transmissions
            </Toast>
          ),
        })
      },
    },
  )
}
