import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiTransmissionReportsControllerVendorTransmissionsReportsRequest } from '../../../lib/api'

export const useGetVendorTransmissionReports = (
  params: DefaultApiTransmissionReportsControllerVendorTransmissionsReportsRequest,
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const Api = useApiContext()
  return useQuery(
    ['vendorTransmissionReport', params.transmissionReportId],
    async () => {
      if (!params.transmissionReportId) return
      const { data } =
        await Api.transmissionReportsControllerVendorTransmissionsReports(
          params,
        )
      return data.vendorTransmissionReports
    },
    {
      ...options,
    },
  )
}
