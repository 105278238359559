import {
  Box,
  Card,
  CardProps,
  Heading,
  HStack,
  SkeletonSquare,
  Stack,
  Text,
} from '@rhythm/components'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { GraphLineIcon } from '../../../../assets'
import { useGetPatientsMonitoredMonthlyData } from '../../api'

export interface TotalPatientsMonitoredGraphProps extends CardProps {}

const TotalPatientsMonitoredGraph = ({
  ...restOfCardProps
}: TotalPatientsMonitoredGraphProps) => {
  const { data: patientsData, isLoading } = useGetPatientsMonitoredMonthlyData(
    {},
  )
  if (isLoading) return <SkeletonSquare h={210} m={'lg'} w={'unset'} />
  if (!patientsData || patientsData.length < 1)
    return (
      <Card px="none" py="none" {...restOfCardProps}>
        <Stack alignItems={'center'} spacing={'lg'} my={'4xl'}>
          <Box>
            <GraphLineIcon height={'100px'} />
          </Box>
          <Box>
            <Heading variant="h5">No Data Available</Heading>
          </Box>
        </Stack>
      </Card>
    )

  return (
    <Card px="none" py="none" {...restOfCardProps}>
      <HStack
        justifyContent="space-between"
        spacing="xl"
        mb="2xl"
        pt="4xl"
        px="4xl"
      >
        <Heading variant="h5">{'Total Patients Monitored'}</Heading>
      </HStack>
      <Box w={'100%'} height={200}>
        <ResponsiveContainer>
          <LineChart
            data={patientsData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="0"
              vertical={false}
              stroke={'#B8C0CE'}
            />
            <XAxis
              strokeOpacity={0.2}
              padding={{ left: 20 }}
              dataKey={'xAxisLabel'}
            />
            <YAxis
              tickCount={4}
              axisLine={false}
              tickLine={false}
              type={'number'}
              allowDecimals={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line type="linear" dataKey="count" stroke="#1083cb" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Card>
  )
}

export default TotalPatientsMonitoredGraph

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean
  payload?: any
}) => {
  if (active && payload && payload.length) {
    const { count, monthString, year } = payload[0].payload
    return (
      <Stack justifyItems={'center'} bg={'#213147'} color={'white'} p={'md'}>
        <Text align={'center'} color={'white'}>
          {count} patients monitored
        </Text>
        <Text align={'center'} color={'white'}>
          {monthString} {year}
        </Text>
      </Stack>
    )
  }

  return null
}
