import React from 'react'

import FilterIcon from './FilterIcon'

import './customBadge.css'

export interface CustomBadgeProps {
  count: number
  isOpen?: boolean
}

const CustomBadge = ({
  count,
  isOpen,
}: CustomBadgeProps): React.ReactElement => {
  return (
    <span className="notification">
      <FilterIcon></FilterIcon>
      {count > 0 && !isOpen && (
        <span
          className="badge"
          style={
            count > 99
              ? {
                  width: '24px',
                  borderRadius: '40%',
                }
              : {}
          }
        >
          {count < 10 ? 0 : ''}
          {count}
        </span>
      )}
    </span>
  )
}

export default CustomBadge
