import '@fontsource/bad-script'

import { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Heading,
  IconBadge,
  Modal,
  Skeleton,
  Text,
  VStack,
} from '@rhythm/components'
import saveAs from 'file-saver'
import _ from 'lodash'

import { useApiContext } from '../../../context/ApiContext'
import {
  BatchJobStatus,
  queryClient,
  UpdateBatchDownloadDto,
} from '../../../lib/api'
import {
  getCurrentDate,
  getDateTimeWithTimezone,
} from '../../../utils/transmissionReportUtil'

import { IconComponent, IconType } from './icons/CustomIcons'

export interface ExportReportsModalProps {
  jobId: number
  isOpen: boolean
  defaultBatchStatus: string
  onClose: () => void
  onCloseUncheckAll: () => void
  tranmissionReportIds: Array<number>
  batchStatusError: string
}

const ExportReportsModal = ({
  jobId,
  isOpen,
  defaultBatchStatus,
  tranmissionReportIds,
  onClose,
  onCloseUncheckAll,
  batchStatusError,
}: ExportReportsModalProps) => {
  const [downloadStage, setDownloadStage] = useState<string>(defaultBatchStatus)
  const [showLoader, setShowLoader] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const Api = useApiContext()

  const getBatchJobInfo = useCallback(
    async (property: any) => {
      if (jobId > 0) {
        return new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            const result: any = await Api.batchDownloadControllerFindOne({
              id: jobId,
            })

            if (result && _.has(result.data, property)) {
              const batchJobInfo: UpdateBatchDownloadDto =
                result?.data?.batchDownload
              if (
                batchJobInfo.status === BatchJobStatus.Success ||
                batchJobInfo.status === BatchJobStatus.Failed
              ) {
                clearInterval(interval) // Clear the interval
                resolve(result) // Resolve with the data
              }
            }
          }, 2500)
        })
      }
    },
    [Api, jobId],
  )

  const checkPrepareStatus = useCallback(async () => {
    let result: any

    try {
      if (jobId && jobId > 0) {
        result = await getBatchJobInfo('batchDownload').catch(() =>
          setDownloadStage(BatchJobStatus.Failed),
        )
        const batchJobInfo: UpdateBatchDownloadDto = result?.data?.batchDownload
        if (
          batchJobInfo.status === BatchJobStatus.Success &&
          batchJobInfo.s3SignedUrl !== null
        ) {
          if ((batchJobInfo?.s3SignedUrl?.length ?? 0) > 0) {
            setDownloadUrl(batchJobInfo.s3SignedUrl)
            setDownloadStage(BatchJobStatus.Success)
          }
        } else {
          setDownloadStage(BatchJobStatus.Failed)
        }
      }
    } catch (err) {
      setDownloadStage(BatchJobStatus.Failed)
    }
  }, [jobId, getBatchJobInfo])

  useEffect(() => {
    checkPrepareStatus().catch(() => setDownloadStage(BatchJobStatus.Failed))
  }, [jobId, checkPrepareStatus])

  useEffect(() => {
    setDownloadStage(defaultBatchStatus)
  }, [defaultBatchStatus])

  async function downloadZip() {
    const blob = await fetch(downloadUrl).then(r => r.blob())

    const downloadDate = getDateTimeWithTimezone()

    if (tranmissionReportIds.length > 0) {
      await Api.batchDownloadControllerUpdateBatchPDFDownloadInfo({
        batchPDFDownloadInfoRequest: {
          tranmissionReportIds: tranmissionReportIds,
          downloadDate: downloadDate,
        },
      })
      setShowLoader(false)
    }

    return saveAs(
      blob,
      `RhythmSynergy_Reports_${getCurrentDate('MM.DD.YYYY')}.zip`,
    )
  }

  const handleDownload = async () => {
    try {
      if ((downloadUrl?.length ?? 0) === 0) {
        return
      }
      setShowLoader(true)
      await downloadZip()
      onCloseModalWithUncheck()
      queryClient.invalidateQueries('patients')
    } catch (err) {
      setDownloadStage(BatchJobStatus.Failed)
      return null
    }
  }

  const onCloseModalWithUncheck = () => {
    setDownloadStage(BatchJobStatus.Initialized)
    setShowLoader(false)
    setDownloadUrl('')
    onCloseUncheckAll()
  }

  const onCloseModal = () => {
    setDownloadStage(BatchJobStatus.Initialized)
    setShowLoader(false)
    setDownloadUrl('')
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnOverlayClick={false}
      width={368}
      isCentered={true}
    >
      <Box w="full" textAlign={'center'}>
        <VStack w="full" spacing="xl">
          {downloadStage === BatchJobStatus.Initialized && (
            <>
              <Heading variant="h4">Preparing files for download</Heading>
              <Text
                fontSize={14}
                color={'#6C7789'}
                fontWeight={600}
                marginTop={12}
              >
                It may take a few minutes
              </Text>
              <Skeleton
                h="xs"
                mb="2xl"
                width="92%"
                isLoaded={false}
                endColor="#1A9EE0"
              ></Skeleton>
            </>
          )}
          {downloadStage === BatchJobStatus.Success && (
            <>
              <Box>
                <IconComponent iconType={IconType.Succes} />
              </Box>
              <Heading variant="h5">The files are ready for download!</Heading>

              <Box w="full">
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={showLoader}
                  borderRadius={8}
                  size="md"
                  onClick={() => handleDownload()}
                >
                  <IconBadge
                    icon={'download'}
                    size="sm"
                    background={'transparent'}
                    color={'white'}
                  />
                  {'Download Reports'}
                </Button>
              </Box>
            </>
          )}
          {downloadStage === BatchJobStatus.Failed && (
            <>
              <Box>
                <IconComponent iconType={IconType.Error} />
              </Box>
              <Heading variant="h4">
                {batchStatusError ? batchStatusError : 'Something went wrong'}
              </Heading>
              <Text fontSize={14} color={'#6C7789'} fontWeight={600}>
                Please try again
              </Text>
            </>
          )}
        </VStack>
      </Box>
    </Modal>
  )
}

export default ExportReportsModal
