interface IDisplayPlural {
  count: number
  word: string
  suffix?: string
  displayCount?: boolean
}

const displayPlural = ({
  count,
  word,
  suffix = 's',
  displayCount = false,
}: IDisplayPlural): string => {
  const plural = `${displayCount ? count : ''} ${word}`.trim()
  return plural
}

export default displayPlural
