import { useMutation } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  ClinicalNote,
  DefaultApiClinicalNotesControllerUpdateNoteRequest,
  queryClient,
} from '../../lib/api'

export const useUpdateClinicalNote = (patientId: string) => {
  const Api = useApiContext()
  return useMutation(
    (params: DefaultApiClinicalNotesControllerUpdateNoteRequest) =>
      Api.clinicalNotesControllerUpdateNote(params),
    {
      onSuccess: async ({ data }, { noteId, updateNoteRequest }) => {
        try {
          queryClient.setQueryData(
            ['getNotesByPatientId', { id: patientId }],
            (oldData: any) => {
              if (!oldData?.pages.length) {
                throw new Error('No notes found')
              }
              const newPages = oldData.pages.map((page: any) => {
                if (updateNoteRequest?.isPinned !== undefined) {
                  return page.map((note: ClinicalNote) =>
                    note.id === noteId ? data.updatedNote : note,
                  )
                } else {
                  return page.filter((note: ClinicalNote) => note.id !== noteId)
                }
              })
              if (updateNoteRequest?.isPinned === undefined) {
                newPages[0] = [data.updatedNote, ...newPages[0]]
              }

              return { ...oldData, pages: newPages }
            },
          )
          queryClient.invalidateQueries([
            'getPinnedNotesByPatientId',
            { id: patientId },
          ])
        } catch (error) {
          queryClient.invalidateQueries('getNotesByPatientId')
          queryClient.invalidateQueries('getPinnedNotesByPatientId')
        }
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
