import React, { useCallback, useEffect, useState } from 'react'

import {
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/react'
import {
  AccordionIcon,
  AccordionPanel,
  Button,
  Checkbox,
  Flex,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@rhythm/components'

import { useBatchDismissTransmissionReport } from '../../../../../features/transmissionReports'

interface AccordionItemPanelProps {
  reportCount: number
  selectedTransmissionIds: number[]
  onCheckChangeAll: (isChecked: boolean) => void
}

export const AccordionSelectAllPanel = ({
  reportCount,
  selectedTransmissionIds,
  onCheckChangeAll,
}: AccordionItemPanelProps): React.ReactElement => {
  const isAllSelected = useCallback(() => {
    return (
      (selectedTransmissionIds?.length ?? 0) === reportCount &&
      reportCount !== 0
    )
  }, [selectedTransmissionIds, reportCount])

  const [isOpenDismiss, setIsOpenDismiss] = useState(false)
  const open = () => {
    if ((selectedTransmissionIds?.length ?? 0) > 0) {
      setIsOpenDismiss(!isOpenDismiss)
    }
  }

  const close = () => {
    setIsOpenDismiss(false)
  }

  const selectedLength = selectedTransmissionIds.length === 0
  useEffect(() => {
    setIsOpenDismiss(false)
  }, [selectedLength])

  const handleCheckAllChange = (isChecked: boolean) => {
    onCheckChangeAll(isChecked)
  }

  const { mutate: batchDismissReport } = useBatchDismissTransmissionReport()

  const handleDismiss = useCallback(() => {
    const selectedTransmissionStringIds: string[] = selectedTransmissionIds.map(
      num => num.toString(),
    )
    batchDismissReport({ tranmissionReportIds: selectedTransmissionStringIds })
    setIsOpenDismiss(false)
  }, [batchDismissReport, selectedTransmissionIds])

  return (
    <AccordionPanel p="none" h={'100%'} overflow={'hidden'}>
      <Flex
        key={'selectAll'}
        px="xl"
        py="2xl"
        borderBottomWidth="0.5px"
        _last={{ borderBottom: 'none' }}
        _hover={{ cursor: 'pointer' }}
        boxShadow={'none'}
      >
        <Flex width="md" mr="xl" minWidth={'100px'}>
          <Checkbox
            isChecked={isAllSelected()}
            width={'100%'}
            onChange={e => handleCheckAllChange(e.target.checked)}
          >
            <Text
              fontFamily={'Lato'}
              color={'#6C7789'}
              fontWeight={700}
              fontSize={'12px'}
            >
              SELECT ALL
            </Text>
          </Checkbox>
        </Flex>
        <Flex
          flexDirection="column"
          maxWidth={100}
          flexGrow={1}
          position="relative"
        ></Flex>
        <Flex>
          <Popover
            returnFocusOnClose={false}
            isOpen={isOpenDismiss}
            onClose={close}
            placement="top"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <HStack>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton as={Link}>
                        <Text
                          color={'#516880'}
                          fontSize={'14px'}
                          fontWeight={600}
                          fontFamily={'Lato'}
                          textAlign={'right'}
                        >
                          Actions
                          {isOpen ? (
                            <AccordionIcon
                              style={{
                                marginLeft: '6px',
                                fontSize: '1.5rem',
                              }}
                            />
                          ) : (
                            <AccordionIcon
                              style={{
                                marginLeft: '6px',
                                fontSize: '1.5rem',
                              }}
                              transform={'auto'}
                            />
                          )}
                        </Text>
                      </MenuButton>
                      <MenuList minWidth={'97px'} marginLeft={'-32px'}>
                        <MenuItem onClick={open}>Dismiss</MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </HStack>
            </PopoverTrigger>
            <PopoverContent
              color="white"
              bg="#213147e0"
              borderColor="#213147"
              padding="16px"
            >
              <PopoverArrow bg="#213147e0" />
              <PopoverBody>
                <HStack marginBottom="16px">
                  <Text>{`Are you sure you want to dismiss ${selectedTransmissionIds.length} selected reports?`}</Text>
                </HStack>
                <Text>This action cannot be undone.</Text>
              </PopoverBody>
              <PopoverFooter display="flex" justifyContent="flex-end">
                <ButtonGroup size="sm" marginTop="15px">
                  <Button variant="plain" onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    colorScheme="red"
                    onClick={() => handleDismiss()}
                  >
                    Dismiss
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Flex>
      </Flex>
    </AccordionPanel>
  )
}
