import React from 'react'

import {
  Button,
  Center,
  Heading,
  HStack,
  IconBadge,
  Modal,
  Stack,
  Text,
} from '@rhythm/components'

import { Device } from '../../types'
import displayValue from '../../utils/displayValue'
import formatDate from '../../utils/formatDate'

const formatDeviceDates = (implantDate: string, removalDate?: string) => {
  const startDate = formatDate(implantDate)
  const endDate = removalDate ? formatDate(removalDate) : 'Present'
  return `${startDate} - ${endDate}`
}

export interface DeviceHistoryModalProps {
  isOpen: boolean
  onClose: () => void
  patientName: string
  devices: Device[]
}

const DeviceHistoryModal = ({
  isOpen,
  onClose,
  patientName,
  devices,
}: DeviceHistoryModalProps): React.ReactElement => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={<Button onClick={onClose}>Close</Button>}
    >
      <HStack spacing="xl" mb="4xl">
        <IconBadge icon="device" size="lg" />
        <Stack spacing="sm">
          <Text fontWeight="bold">{patientName}</Text>
          <Heading variant="h4">Device History</Heading>
        </Stack>
      </HStack>
      {devices.length === 0 && (
        <Center>
          <Text>No device history</Text>
        </Center>
      )}
      <Stack spacing="xl" pl="4xl">
        {devices.map(device => (
          <Stack
            key={device.id}
            spacing="md"
            position="relative"
            pb="xl"
            _notLast={{
              borderBottomWidth: '0.5px',
              _before: {
                content: '""',
                position: 'absolute',
                top: '11px',
                left: '-29px',
                height: '110%',
                width: '0.5px',
                bg: 'neutral.600',
              },
            }}
            _after={{
              content: '""',
              position: 'absolute',
              top: '4px',
              left: '-32px',
              height: '7.5px',
              width: '7.5px',
              bg: 'primary.500',
              borderRadius: 'full',
            }}
          >
            <Text fontWeight="bold">
              {formatDeviceDates(
                device.implantDate as string,
                device.removalDate,
              )}
            </Text>
            <Stack spacing="xs">
              <Heading variant="h5">{device.model}</Heading>
              <Text>{device.serialNumber}</Text>
            </Stack>
            <Stack spacing="none">
              <Text variant="secondary">{`Implanting Physician: ${displayValue(
                device.implantingPhysician,
              )}`}</Text>
              <Text variant="secondary">{`Diagnosis Code: ${displayValue(
                device.diagnosisCode,
              )}`}</Text>
              <Text variant="secondary">{device.notes}</Text>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Modal>
  )
}

export default DeviceHistoryModal
