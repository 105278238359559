import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  Text,
  useToast,
  VStack,
} from '@rhythm/components'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useMutation } from 'react-query'
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID'

import { useApiContext } from '../../../../../../context/ApiContext'
import {
  queryClient,
  TransmissionReportAttachment,
} from '../../../../../../lib/api'

interface CustomFileType extends File {
  preview: string
  id: string
  status: boolean | null
  errors?: FileRejection['errors']
}

const INVALID_CHARS_REGEX = /[/\\:*?"<>|]/
const MAX_FILENAME_LENGTH = 100
const MAX_FILES = 10

// Component to render individual file attachment entries
const AttachmentListItem = ({
  id,
  originalName,
  onDelete,
  errors,
}: {
  id: string
  originalName: string
  onDelete: (id: string) => void
  errors?: FileRejection['errors']
}) => (
  <Flex justifyContent="space-between" alignItems="center">
    <Flex gap={2} alignItems="center">
      <Icon icon="file" boxSize={4} color="text.primary" />
      <Flex flexDirection="column" gap={0.5}>
        <Text variant="h5" fontWeight={600}>
          {originalName}
        </Text>
        {errors && (
          <Text variant="smallCaps" color="red.300">
            {errors.map(error => error.message).join(', ')}
          </Text>
        )}
      </Flex>
    </Flex>
    <IconButton
      variant="plain"
      color="red.300"
      _hover={{ color: 'red.400', background: 'red.50' }}
      mr={1}
      onClick={() => onDelete(id)}
      size="sm"
      icon="delete"
      aria-label="Delete file"
    />
  </Flex>
)

// Main component for attaching PDFs to a report
const AttachPDFModal = ({
  isOpen,
  attachments,
  onClose,
  reportId,
  patientName,
}: {
  attachments: TransmissionReportAttachment[]
  isOpen: boolean
  onClose: () => void
  reportId: number
  patientName: string
}) => {
  const [attachedFiles, setAttachedFiles] = useState(attachments)
  const [deletedFiles, setDeletedFiles] = useState<string[]>([])
  const [newFiles, setNewFiles] = useState<CustomFileType[]>([])

  const Api = useApiContext()

  const toast = useToast()

  useEffect(() => {
    setAttachedFiles(attachments)
  }, [attachments])

  // Mutation for saving the new files. The deleted files array will remove the existing files.
  const handleSave = useMutation({
    mutationFn: async () => {
      await Api.transmissionReportsControllerAddAttachments({
        files: newFiles,
        deletedFiles,
        transmissionReportId: reportId,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['transmissionReportAttachments', reportId])
      queryClient.invalidateQueries(['vendorTransmissionReport', reportId])
      if (newFiles.length > 0) {
        toast({
          title: 'Files Attached',
          description: 'Files have been successfully attached to the report',
          variant: 'subtle',
          duration: 3000,
          position: 'bottom-right',
          status: 'success',
        })
      }
      if (newFiles.length === 0 && deletedFiles.length > 0) {
        toast({
          title: 'Files Removed',
          description: 'Files have been successfully removed from the report',
          variant: 'subtle',
          duration: 3000,
          position: 'bottom-right',
          status: 'success',
        })
      }
      setDeletedFiles([])
      setNewFiles([])
      onClose()
    },
  })

  // Handler for when files are dropped.
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (
        newFiles.length +
          acceptedFiles.length +
          rejectedFiles.length +
          attachedFiles.length >
        MAX_FILES
      ) {
        toast({
          title: 'Too Many Files',
          description: "You can't attach more than 10 files",
          variant: 'subtle',
          position: 'bottom-right',
          status: 'error',
        })
        return
      }

      const updatedFiles = [
        ...newFiles,
        ...acceptedFiles.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueID(),
          }) as CustomFileType
        }),
        ...rejectedFiles.map(rejectedFile => {
          return Object.assign(rejectedFile.file, {
            status: false,
            id: generateUniqueID(),
            errors: rejectedFile.errors,
          }) as CustomFileType
        }),
      ]

      setNewFiles(updatedFiles)
    },
    [attachedFiles.length, newFiles, toast],
  )

  const currentMaxFiles = MAX_FILES - attachedFiles.length - newFiles.length
  const isMaxFilesReached = currentMaxFiles <= 0

  const { open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop,
    multiple: true,
    accept: '.pdf',
    disabled: currentMaxFiles <= 0,
    maxFiles: currentMaxFiles,
    validator: file => {
      if (file.size > 10 * 1024 * 1024) {
        return {
          isValid: false,
          code: 'File Too Large',
          message: 'File Too Large, maximum size is 10 MB',
        }
      }
      if (file.type !== 'application/pdf') {
        return {
          isValid: false,
          code: 'Invalid File Type',
          message: 'Only PDF files are allowed',
        }
      }
      if (INVALID_CHARS_REGEX.test(file.name)) {
        return {
          isValid: false,
          code: 'Invalid File Name',
          message: 'Invalid File Name',
        }
      }
      if (file.name.length > MAX_FILENAME_LENGTH) {
        return {
          isValid: false,
          code: 'File Name Too Long',
          message: 'File Name Too Long',
        }
      }
      return null
    },
  })

  // Closes modal and resets changes of the files.
  const closeModal = useCallback(() => {
    onClose()
    setAttachedFiles(attachments)
    setDeletedFiles([])
    setNewFiles([])
  }, [attachments, onClose])

  // Checks for file errors in newly added files.
  const hasFileProcessingError = useMemo(
    () => newFiles.some(file => file.errors && file.errors.length > 0),
    [newFiles],
  )

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={closeModal}
      isCentered
      header={
        <Flex flexDirection="column" gap={1}>
          <Heading variant="h5" mt={2}>
            {patientName}
          </Heading>
          <Text variant="h6" color="neutral.800">
            Attach additional PDFs to the transmission report
          </Text>
        </Flex>
      }
      headerProps={{ px: 8 }}
      bodyProps={{ px: 8, py: 6 }}
      footer={
        <Flex justifyContent="flex-end">
          <Button style={{ marginRight: '10px' }} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={
              hasFileProcessingError ||
              (newFiles.length === 0 && deletedFiles.length === 0) ||
              handleSave.isLoading
            }
            isLoading={handleSave.isLoading}
            onClick={() => handleSave.mutate()}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <Flex
        {...getRootProps()}
        height="180px"
        marginBottom="20px"
        borderColor="neutral.600"
        borderRadius="2px"
        borderStyle="dashed"
        borderWidth="1px"
        alignItems="center"
        justifyContent="center"
        transitionDuration={'0.3s'}
        {...(isMaxFilesReached
          ? { opacity: 0.5 }
          : {
              _hover: { bg: 'gray.50', cursor: 'pointer' },
            })}
        onClick={open}
        w="full"
      >
        <input {...getInputProps()} />
        <VStack spacing={4}>
          <Flex
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {isMaxFilesReached ? (
              <>
                <Flex
                  width={12}
                  height={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={'50%'}
                  border={'2px dotted'}
                  borderColor={'neutral.600'}
                >
                  <Icon icon="close" boxSize={6} p={0.5} />
                </Flex>
                <Text fontSize={'1.1rem'} mt={2}>
                  Max files reached
                </Text>
                <Text variant="secondary" align={'center'}>
                  Users can only attach 10 files
                </Text>
              </>
            ) : (
              <>
                <Flex
                  width={12}
                  height={12}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={'50%'}
                  border={'2px dotted'}
                  borderColor={'neutral.600'}
                >
                  <Icon icon="upload" boxSize={6} p={0.5} />
                </Flex>
                <Text fontSize={'1.1rem'} mt={2}>
                  Drag and drop PDF files here or click here to select files.
                </Text>
                <Text variant="secondary" align={'center'}>
                  Users can upload 10 pdf files (up to 10 MB each)
                </Text>
              </>
            )}
          </Flex>
        </VStack>
      </Flex>
      {attachedFiles.length + newFiles.length > 0 && (
        <Box width="full">
          <Heading variant="h6" mb={1}>
            Added Attachments:
          </Heading>
          <Divider />
          <Flex
            flexDirection="column"
            gap={3}
            mt={1}
            maxHeight="200px"
            overflow="auto"
          >
            {attachedFiles.map(attachment => (
              <AttachmentListItem
                key={attachment.id}
                id={attachment.id}
                originalName={attachment.originalName}
                onDelete={(id: string) => {
                  setAttachedFiles(attachedFiles.filter(file => file.id !== id))
                  setDeletedFiles([...deletedFiles, id])
                }}
              />
            ))}
            {newFiles.map(file => (
              <AttachmentListItem
                key={file.id}
                id={file.id}
                originalName={file.name}
                errors={file.errors}
                onDelete={(id: string) => {
                  setNewFiles(newFiles.filter(file => file.id !== id))
                }}
              />
            ))}
          </Flex>
        </Box>
      )}
    </Modal>
  )
}

export default AttachPDFModal
