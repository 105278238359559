import { Editable } from '@rhythm/components'

import { baseStyles } from '../../../../../features/transmissionReports'
import { VtDeviceLead } from '../../../../../lib/api/generated-client'
import { DeviceLeadLocation } from '../../../../../types'
import displayValue from '../../../../../utils/displayValue'

import { DeviceLeadEmptyState } from './DeviceLeadEmptyState'

export interface DeviceLeadModelProps {
  deviceLeads?: VtDeviceLead[]
  onSubmit: (value: any) => void
}

export const DeviceLeadModel = ({
  deviceLeads,
  onSubmit,
}: DeviceLeadModelProps) => {
  return (
    <>
      {Object.keys(DeviceLeadLocation).map((location, _i) => {
        const deviceLead = deviceLeads?.find(
          lead => lead.leadLocation === location,
        )
        if (deviceLead) {
          return (
            <Editable
              {...baseStyles}
              py="lg"
              key={`deviceLeads.leadModelNumber-${deviceLead.leadId}`}
              name="deviceLeads.leadModelNumber"
              defaultValue={displayValue(deviceLead?.leadModelNumber)}
              onSubmit={value =>
                onSubmit({
                  name: 'deviceLeads.leadModelNumber',
                  value,
                  leadId: deviceLead?.leadId,
                })
              }
            />
          )
        }
        return <DeviceLeadEmptyState key={`deviceLeads.model-empty-${_i}`} />
      })}
    </>
  )
}
