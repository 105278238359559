import { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Heading, HStack, Icon, Link, Stack } from '@rhythm/components'

import {
  GetAllPatientsProps,
  useGetAllPatients,
} from '../../../features/patients/api'
import { TotalPatientsMonitoredGraph } from '../../../features/patients/components'
import { useGetTransmissionReportsCountByColor } from '../../../features/transmissionReports'
import useCurrentUser from '../../../hooks/useCurrentUser'
import { TransmissionReportDtoColorEnum } from '../../../lib/api'
import routes from '../../../routes/config'
import getTimeOfDay from '../../../utils/getTimeOfDay'

import SignAllRoutineModal from './components/SignAllRoutineModal'
import TransmissionReportsStatusCardRow from './components/TransmissionReportsStatusCard/TransmissionReportsStatusCardRow'

const DashboardPage = () => {
  const { currentUser } = useCurrentUser()
  const timeOfDay = getTimeOfDay()
  const { data: countData, isLoading } = useGetTransmissionReportsCountByColor()
  const [color, setColor] = useState(TransmissionReportDtoColorEnum.Gray)
  const [displayReviewAllTransmissions, setDisplayReviewAllTransmissions] =
    useState(true)

  useEffect(() => {
    console.log('CD', countData)
    if (!countData?.counts) return setDisplayReviewAllTransmissions(false)

    if ((countData.counts as any)?.RED > 0) {
      setColor(TransmissionReportDtoColorEnum.Red)
    } else if ((countData.counts as any)?.YELLOW > 0) {
      setColor(TransmissionReportDtoColorEnum.Yellow)
    } else if ((countData.counts as any)?.GREEN > 0) {
      setColor(TransmissionReportDtoColorEnum.Green)
    } else if ((countData.counts as any)?.GRAY === 0) {
      return setDisplayReviewAllTransmissions(false)
    }
    setDisplayReviewAllTransmissions(true)
  }, [countData])

  const onSignAllRoutineReports = () => {
    setSignAllRoutineModalIsOpen(false)
  }
  const [signAllRoutineModalIsOpen, setSignAllRoutineModalIsOpen] =
    useState(false)

  const queryData: GetAllPatientsProps = {} as GetAllPatientsProps
  const [allPatientsQueryData, setAllPatientsQueryData] =
    useState<GetAllPatientsProps>({ ...queryData })

  const { isLoading: isLoadingAllPatients } = useGetAllPatients(
    allPatientsQueryData,
    {
      enabled: signAllRoutineModalIsOpen,
    },
  )
  const greeting = `Good ${timeOfDay}`
  const capitalizeFirstLetter = (name: string) =>
    name.charAt(0).toUpperCase() + name.slice(1)
  const capitalizedFirstName = capitalizeFirstLetter(
    currentUser.firstName ?? '',
  )
  const capitalizedLastName = currentUser.lastName
    ? capitalizeFirstLetter(currentUser.lastName)
    : ''

  const fullName = `${capitalizedFirstName} ${capitalizedLastName}`

  const fullNameWithPrefix = currentUser.prefix
    ? `, ${currentUser.prefix}. ${fullName}`
    : `, ${fullName}`

  const headingContent = `${greeting}${fullNameWithPrefix}`

  return (
    <Box mt="2xl" mb="5xl">
      <Heading variant="h1" fontWeight="light" mb="5xl">
        {headingContent}
      </Heading>

      <Stack alignItems="stretch" spacing="md">
        <HStack justifyContent={'space-between'}>
          <Heading variant="h4">Transmission Reports</Heading>
          {displayReviewAllTransmissions && (
            <RouterLink
              to={{
                pathname: routes.physician.transmissionReview,
                state: { defaultExpanded: color },
              }}
            >
              <Link as={'div'} fontSize={'lg'}>
                <HStack>
                  <Box>Review All Transmissions</Box>
                  <Icon boxSize={'md'} icon={'arrow-right'} />
                </HStack>
              </Link>
            </RouterLink>
          )}
        </HStack>
        <TransmissionReportsStatusCardRow
          countData={countData}
          isLoading={isLoading}
          onSignAllRoutine={() => setSignAllRoutineModalIsOpen(true)}
        />

        {/* <PopUpClass
          isOpen={popUpIsOpen}
          onClose={() => setpopUpIsOpen(false)}
        /> */}

        <SignAllRoutineModal
          isOpen={signAllRoutineModalIsOpen}
          onClose={() => setSignAllRoutineModalIsOpen(false)}
          onConfirm={onSignAllRoutineReports}
          isLoading={isLoadingAllPatients}
          queryData={allPatientsQueryData}
          // searchTerm={searchTerm}
          setQueryData={setAllPatientsQueryData}
        />
      </Stack>

      <HStack alignItems="stretch" spacing="xl" my="lg">
        <TotalPatientsMonitoredGraph width={'100%'} />
      </HStack>
    </Box>
  )
}

export default DashboardPage
