import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  Select,
  Text,
} from '@rhythm/components'
import moment from 'moment/moment'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import * as yup from 'yup'

import DatePickerV2 from '../../../../../../components/DatePickerv2'
import { useApiContext } from '../../../../../../context/ApiContext'
import {
  CreateAdHocTransmissionDtoTransmissionTypeEnum,
  queryClient,
} from '../../../../../../lib/api'

interface AdHocTransmissionType {
  transmissionType: { label: string; value: string } | null
  date: string
}

const AddAdHocModal = ({
  isOpen,
  onClose,
  deviceId,
  deviceType,
}: {
  isOpen: boolean
  onClose: () => void
  deviceId: string
  deviceType: string
}) => {
  const methods = useForm<AdHocTransmissionType>({
    mode: 'onChange',
    defaultValues: {
      transmissionType: null,
      date: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        transmissionType: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
          })
          .typeError('Transmission Type is required')
          .test(
            'combo-billing-validation',
            'Combo Billing is not available for ILR devices.',
            transmissionType => {
              if (
                deviceType === 'ILR' &&
                transmissionType?.value === 'combo_billing'
              ) {
                return false
              }
              return true
            },
          ),

        date: yup
          .string()
          .typeError('Date is a required field')
          .test(
            'is-date-valid',
            'Date must be a valid date and in MM/DD/YYYY format.',
            value => moment(value, 'MM/DD/YYYY', true).isValid(),
          )
          .test(
            'is-date-future',
            'Date cannot be in the past.',
            value => !moment(value, 'MM/DD/YYYY').isBefore(moment(), 'day'),
          ),
      }),
    ),
  })
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods

  const closeModal = () => {
    reset()
    onClose()
  }

  const API = useApiContext()

  const { mutate, isLoading } = useMutation(
    async (data: AdHocTransmissionType) => {
      const response = await API.scheduledTransmissionControllerCreateAdHoc({
        createAdHocTransmissionDto: {
          deviceId,
          transmissionType: data.transmissionType
            ?.value as CreateAdHocTransmissionDtoTransmissionTypeEnum,
          date: moment(data.date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        },
      })
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'scheduledTransmission',
          { id: deviceId },
        ])
        closeModal()
      },
      onError: err => {
        console.log('Error: Unable to create the ad hoc transmission.', err)
      },
    },
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      isCentered
      header={
        <Flex flexDirection={'column'} gap={1}>
          <Heading variant={'h5'}>Add Ad Hoc Transmission</Heading>
          <Text variant="h6">
            Create a Ad Hoc transmission in the scheduled transmissions.
          </Text>
        </Flex>
      }
      headerProps={{
        px: 8,
      }}
      bodyProps={{
        px: 8,
        py: 6,
      }}
    >
      <form
        onSubmit={handleSubmit((data: AdHocTransmissionType) => mutate(data))}
      >
        <Flex gap={4} w={'100%'} alignItems={'stretch'}>
          <FormControl>
            <FormLabel htmlFor={'transmissionType'}>
              Transmission Type
            </FormLabel>
            <Controller
              name={'transmissionType'}
              control={control}
              render={({ field: { value, onChange, ref } }) => {
                return (
                  <Select
                    ref={ref}
                    value={value}
                    placeholder={'Select Transmission Type'}
                    options={[
                      { label: 'Routine', value: 'routine' },
                      { label: 'Heart Failure', value: 'heart_failure' },
                      { label: 'Combo Billing', value: 'combo_billing' },
                    ]}
                    onChange={onChange}
                    required={true}
                  />
                )
              }}
            />
            {errors?.transmissionType && (
              <Text color="red" mt={2}>
                {errors?.transmissionType.message}
              </Text>
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="date">Date</FormLabel>
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePickerV2
                  onlyOpenCalenderOnIconClick={true}
                  datePickerProps={{
                    minDate: new Date(),
                    id: 'date',
                    selected:
                      value && moment(value, 'MM/DD/YYYY', true).isValid()
                        ? moment(value).toDate()
                        : null,
                    popperPlacement: 'top',
                    placeholderText: 'MM/DD/YYYY',
                    onlyOpenCalenderOnIconClick: true,
                    strictParsing: true,
                    adjustDateOnChange: true,
                    onChangeRaw: (e: any) => {
                      if (e?.target?.value) onChange(e.target.value)
                    },
                    onChange: (date: any) => {
                      if (date instanceof Date)
                        onChange(moment(date).format('MM/DD/YYYY'))
                    },
                  }}
                />
              )}
            />
            {errors?.date && (
              <Text color="red" mt={2}>
                {errors?.date.message}
              </Text>
            )}
          </FormControl>
        </Flex>
        <HStack mt={8}>
          <Button variant="secondaryLight" size={'md'} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type={'submit'}
            isLoading={isLoading}
            variant="primary"
            size={'md'}
          >
            Create
          </Button>
        </HStack>
      </form>
    </Modal>
  )
}

export default AddAdHocModal
