import { useEffect } from 'react'

import {
  Button,
  DataTable,
  Heading,
  HStack,
  Modal,
  ModalProps,
  Stack,
  TableHeader,
  TableRow,
} from '@rhythm/components'

import { useGetTransmissionReportHistoryById } from '../../../../../features/transmissionReports/api/getTransmissionReportHistoryById '
import { queryClient } from '../../../../../lib/api'
import { HistorySignIcon } from '../BillingCard/CustomIcons'
import { columns } from '../reportHistoryColumns'

export interface ViewReportHisotryModalProps
  extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  transmissionReport: any
  transmissionId: string
  patientId: string
}

interface TransmissionReportAudit {
  id: string
  lastUpdatedDate: string
  lastUpdatedUser: string
  section: string
}

interface Image {
  id: string
}

const ViewReportHisotryModal = ({
  isOpen,
  onClose,
  transmissionReport,
  transmissionId,
  patientId,
}: ViewReportHisotryModalProps) => {
  let isLoading = true
  const { givenName, familyName } = transmissionReport?.patient || {}
  const tableData:
    | Record<string, any>[]
    | { DATE: string; SECTION: string; USER: any }[] = []

  useEffect(() => {
    queryClient.invalidateQueries(['transmissionReportHistory'])
  }, [isOpen])
  const signatures =
    transmissionReport?.signatures[
      transmissionReport?.signatures?.length - 1
    ] ?? undefined
  const createdAt = signatures?.createdAt ?? ''

  const {
    data: transmissionReportHistory,
    //isLoading,
  } = useGetTransmissionReportHistoryById({
    transmissionReportId: transmissionId ? transmissionId.toString() : '',
    patientId,
    signedDate: createdAt,
  })
  const TimestampDisplay = (timestamp: string) => {
    const date = new Date(timestamp)
    const seconds = date.getUTCSeconds()
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const amOrPm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12
    const formattedTime = `${formattedHours}:${
      minutes < 10 ? '0' : ''
    }${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${amOrPm} UTC, ${
      date.getUTCMonth() + 1
    }/${date.getUTCDate()}/${date.getUTCFullYear()}`
    return formattedTime
  }

  function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
      return false
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }

    return true
  }
  if (
    transmissionReport &&
    transmissionReport.signatureStatus === 'SIGNED' &&
    transmissionReport.signatures &&
    transmissionReport.signatures.length > 0
  ) {
    const signatures =
      transmissionReport.signatures[transmissionReport.signatures.length - 1] ??
      undefined
    // const createdAt = signatures.createdAt
    console.log(transmissionReport)
    console.log(transmissionReportHistory)

    if (transmissionReportHistory) {
      const countersAudit = transmissionReportHistory.countersAudit
      const counterObj = transmissionReport?.counters
      if (counterObj && countersAudit.length !== 0) {
        const keys = Object.keys(counterObj)
        keys.splice(0, 1)
        keys.splice(14, 3)
        keys.forEach((k: string) => {
          for (let i = 0; i < countersAudit.length - 1; i++) {
            if (
              (countersAudit[i] as any)[k] !== (countersAudit[i + 1] as any)[k]
            ) {
              tableData.push({
                DATE: TimestampDisplay(countersAudit[i].lastUpdatedDate ?? ''),
                SECTION: `${k} ${(countersAudit[i] as any)[k]} changed to ${
                  (countersAudit[i + 1] as any)[k]
                } `,
                USER: countersAudit[i].lastUpdatedUser,
              })
            }
          }
          const lastCounterAudit: any = countersAudit[countersAudit.length - 1]
          if ((lastCounterAudit as any)[k] !== counterObj[k]) {
            tableData.push({
              DATE: TimestampDisplay(
                countersAudit[countersAudit.length - 1].lastUpdatedDate ?? '',
              ),
              SECTION: `${k} ${(lastCounterAudit as any)[k]} changed to ${
                counterObj[k]
              } `,
              USER: lastCounterAudit.lastUpdatedUser,
            })
          }
        })
      }
      const reportHistory = transmissionReportHistory.transmissionReportAudit
      if (reportHistory.length !== 0) {
        for (let i = 0; i < reportHistory.length - 1; i++) {
          if (reportHistory[i].color !== reportHistory[i + 1].color) {
            tableData.push({
              DATE: TimestampDisplay(reportHistory[i].lastUpdatedDate ?? ''),
              SECTION: `color ${reportHistory[i].color} changed to ${
                reportHistory[i + 1].color
              } `,
              USER: reportHistory[i].lastUpdatedUser,
            })
          }

          if (reportHistory[i].comment !== reportHistory[i + 1].comment) {
            tableData.push({
              DATE: TimestampDisplay(reportHistory[i].lastUpdatedDate ?? ''),
              SECTION: `comment ${reportHistory[i].comment} changed to ${
                reportHistory[i + 1].comment
              } `,
              USER: reportHistory[reportHistory.length - 1].lastUpdatedUser,
            })
          }
        }
        if (
          transmissionReport.color !==
          reportHistory[reportHistory.length - 1].color
        ) {
          tableData.push({
            DATE: TimestampDisplay(
              reportHistory[reportHistory.length - 1].lastUpdatedDate ?? '',
            ),
            SECTION: `color ${
              reportHistory[reportHistory.length - 1].color
            } changed to ${transmissionReport.color} `,
            USER: reportHistory[reportHistory.length - 1].lastUpdatedUser,
          })
        }
        if (
          transmissionReport.comment !==
          reportHistory[reportHistory.length - 1].comment
        ) {
          tableData.push({
            DATE: TimestampDisplay(
              reportHistory[reportHistory.length - 1].lastUpdatedDate ?? '',
            ),
            SECTION: `comment ${
              reportHistory[reportHistory.length - 1].comment
            } changed to ${transmissionReport.comment} `,
            USER: reportHistory[reportHistory.length - 1].lastUpdatedUser,
          })
        }
      }
      if (transmissionReportHistory.billingAudit.length !== 0) {
        const billingHistory = transmissionReportHistory.billingAudit
        let billingObjectHistory1
        let billingObjectHistory2

        for (let i = 0; i < billingHistory.length - 1; i++) {
          billingObjectHistory1 = billingHistory[i].billingobject
          billingObjectHistory2 = billingHistory[i + 1].billingobject
          if (billingObjectHistory1 && billingObjectHistory2) {
            if (
              billingObjectHistory1.billable !== billingObjectHistory2.billable
            ) {
              tableData.push({
                DATE: TimestampDisplay(billingHistory[i].lastUpdatedDate ?? ''),
                SECTION: `billable ${billingObjectHistory1.billable} changed to ${billingObjectHistory2.billable}`,
                USER: billingHistory[i].lastUpdatedUser,
              })
            }
            if (
              billingObjectHistory1.billing_date !==
              billingObjectHistory2.billing_date
            ) {
              tableData.push({
                DATE: TimestampDisplay(billingHistory[i].lastUpdatedDate ?? ''),
                SECTION: `billing_date ${billingObjectHistory1.billing_date} changed to ${billingObjectHistory2.billing_date}`,
                USER: billingHistory[i].lastUpdatedUser,
              })
            }

            const currentTechnical_code: any =
              billingObjectHistory2.technical_code === undefined ||
              billingObjectHistory2.technical_code === null ||
              billingObjectHistory2.technical_code === ''
                ? ''
                : (billingObjectHistory2.technical_code as any).value
            const historyTechnical_code: any =
              billingObjectHistory1.technical_code === undefined ||
              billingObjectHistory1.technical_code === null ||
              billingObjectHistory1.technical_code === ''
                ? ''
                : (billingObjectHistory1.technical_code as any).value
            if (currentTechnical_code !== historyTechnical_code) {
              tableData.push({
                DATE: TimestampDisplay(billingHistory[i].lastUpdatedDate ?? ''),
                SECTION: `technical_code [${historyTechnical_code}] changed to  [${currentTechnical_code}]`,
                USER: billingHistory[i].lastUpdatedUser,
              })
            }

            const currentDiagnosis_code: string[] | undefined = Array.isArray(
              billingObjectHistory2?.diagnosis_code,
            )
              ? billingObjectHistory2?.diagnosis_code.map(
                  (d: { value: any }) => d.value,
                )
              : []
            const historyDiagnosis_code: string[] | undefined = Array.isArray(
              billingObjectHistory1?.diagnosis_code,
            )
              ? billingObjectHistory1?.diagnosis_code.map(
                  (d: { value: any }) => d.value,
                )
              : []
            if (!arraysAreEqual(currentDiagnosis_code, historyDiagnosis_code)) {
              tableData.push({
                DATE: TimestampDisplay(billingHistory[i].lastUpdatedDate ?? ''),
                SECTION: `diagnosis_code [${historyDiagnosis_code}] changed to  [${currentDiagnosis_code}]`,
                USER: billingHistory[i].lastUpdatedUser,
              })
            }

            const historyProfessional_code: string[] | undefined =
              Array.isArray(billingObjectHistory2?.professional_code)
                ? billingObjectHistory2?.professional_code.map(
                    (d: { value: any }) => d.value,
                  )
                : []
            const currentProfessional_code: string[] | undefined =
              Array.isArray(billingObjectHistory1?.professional_code)
                ? billingObjectHistory1?.professional_code.map(
                    (d: { value: any }) => d.value,
                  )
                : []

            if (
              !arraysAreEqual(
                currentProfessional_code,
                historyProfessional_code,
              )
            ) {
              tableData.push({
                DATE: TimestampDisplay(billingHistory[i].lastUpdatedDate ?? ''),
                SECTION: `professional_code [${historyProfessional_code}] changed to  [${currentProfessional_code}]`,
                USER: billingHistory[i].lastUpdatedUser,
              })
            }
          }
        }
        const lastHistoryItem: any =
          billingHistory[billingHistory.length - 1].billingobject
        const billingobject = transmissionReport.billing.billingobject
        if (lastHistoryItem && billingobject) {
          if (billingobject.billable !== lastHistoryItem.billable) {
            tableData.push({
              DATE: TimestampDisplay(
                billingHistory[billingHistory.length - 1].lastUpdatedDate ?? '',
              ),
              SECTION: `billable ${lastHistoryItem.billable} changed to ${billingobject.billable}`,
              USER: billingHistory[billingHistory.length - 1].lastUpdatedUser,
            })
          }
          if (billingobject.billing_date !== lastHistoryItem.billing_date) {
            tableData.push({
              DATE: TimestampDisplay(
                billingHistory[billingHistory.length - 1].lastUpdatedDate ?? '',
              ),
              SECTION: `billing_date ${lastHistoryItem.billing_date} changed to ${billingobject.billing_date}`,
              USER: billingHistory[billingHistory.length - 1].lastUpdatedUser,
            })
          }
          const currentTechnical_code =
            billingobject.technical_code === ''
              ? ''
              : billingobject.technical_code.value
          const historyTechnical_code =
            lastHistoryItem.technical_code === ''
              ? ''
              : lastHistoryItem.technical_code.value
          if (currentTechnical_code !== historyTechnical_code) {
            tableData.push({
              DATE: TimestampDisplay(
                billingHistory[billingHistory.length - 1].lastUpdatedDate ?? '',
              ),
              SECTION: `technical_code [${historyTechnical_code}] changed to  [${currentTechnical_code}]`,
              USER: billingHistory[billingHistory.length - 1].lastUpdatedUser,
            })
          }
          const currentDiagnosis_code = billingobject?.diagnosis_code?.map(
            (d: { value: any }) => d.value,
          )
          const historyDiagnosis_code = lastHistoryItem?.diagnosis_code?.map(
            (d: { value: object }) => d.value,
          )
          if (!arraysAreEqual(currentDiagnosis_code, historyDiagnosis_code)) {
            tableData.push({
              DATE: TimestampDisplay(
                billingHistory[billingHistory.length - 1].lastUpdatedDate ?? '',
              ),
              SECTION: `diagnosis_code [${historyDiagnosis_code}] changed to  [${currentDiagnosis_code}]`,
              USER: billingHistory[billingHistory.length - 1].lastUpdatedUser,
            })
          }

          const currentProfessional_code =
            billingobject?.professional_code === ''
              ? []
              : billingobject?.professional_code?.map(
                  (d: { value: any }) => d.value,
                )
          const historyProfessional_code =
            lastHistoryItem.professional_code === ''
              ? []
              : lastHistoryItem?.professional_code?.map(
                  (d: { value: object }) => d.value,
                )
          if (
            !arraysAreEqual(currentProfessional_code, historyProfessional_code)
          ) {
            tableData.push({
              DATE: TimestampDisplay(
                billingHistory[billingHistory.length - 1].lastUpdatedDate ?? '',
              ),
              SECTION: `professional_code [${historyProfessional_code}] changed to  [${currentProfessional_code}]`,
              USER: billingHistory[billingHistory.length - 1].lastUpdatedUser,
            })
          }
        }
      }

      if (
        transmissionReport.notes.length !== 0 &&
        transmissionReportHistory.transmissionReportNoteAudit.length !== 0
      ) {
        const currentIds = transmissionReport.notes.map(
          (note: { id: any }) => note.id,
        )
        const historyIds =
          transmissionReportHistory.transmissionReportNoteAudit.map(
            audit => audit.id,
          )
        // Find IDs removed
        const removedIds = new Set(
          historyIds.filter(id => !currentIds.includes(id)),
        )

        transmissionReport.notes.forEach((n1: { id: any; note: any }) => {
          const historyNote =
            transmissionReportHistory.transmissionReportNoteAudit.find(
              (n2: { id: any }) => n2.id === n1.id,
            )
          if (historyNote !== undefined && historyNote.note !== n1.note) {
            const { location, lastUpdatedUser, lastUpdatedDate } = historyNote
            tableData.push({
              DATE: TimestampDisplay(lastUpdatedDate ?? ''),
              SECTION: `${location} Report note was modified`,
              USER: lastUpdatedUser,
            })
          }
        })

        removedIds.forEach((removedId: string) => {
          const notes =
            transmissionReportHistory.transmissionReportNoteAudit.filter(
              (note: { id: any }) => note.id === removedId,
            )
          if (notes.length !== 0) {
            for (let i = 0; i < notes.length - 1; i++) {
              const { location, lastUpdatedUser, lastUpdatedDate } = notes[i]
              tableData.push({
                DATE: TimestampDisplay(lastUpdatedDate ?? ''),
                SECTION: `${location} Report note was modified`,
                USER: lastUpdatedUser,
              })
            }

            const { location, lastUpdatedUser, lastUpdatedDate } =
              notes[notes.length - 1]
            tableData.push({
              DATE: TimestampDisplay(lastUpdatedDate ?? ''),
              SECTION: `${location} Report note was deleted`,
              USER: lastUpdatedUser,
            })
          }
        })
      }

      if (transmissionReportHistory.newNoteAudit.length !== 0) {
        transmissionReportHistory.newNoteAudit.forEach(n => {
          tableData.push({
            DATE: TimestampDisplay(n?.lastUpdatedDate ?? ''),
            SECTION: n.section,
            USER: n.lastUpdatedUser,
          })
        })
      }

      if (
        transmissionReport.alerts.length !== 0 &&
        transmissionReportHistory.transmissionReportAlertAudit.length !== 0
      ) {
        const currentIds = transmissionReport.alerts.map(
          (alert: { id: any }) => alert.id,
        )
        const historyIds =
          transmissionReportHistory.transmissionReportAlertAudit.map(
            audit => audit.id,
          )
        const removedIds = new Set(
          historyIds.filter(id => !currentIds.includes(id)),
        )
        transmissionReport.alerts.forEach((a1: { id: any; title: any }) => {
          const historyAlert =
            transmissionReportHistory.transmissionReportAlertAudit.find(
              (a2: { id: any }) => a2.id === a1.id,
            )
          if (historyAlert !== undefined && historyAlert.title !== a1.title) {
            const { lastUpdatedUser, lastUpdatedDate } = historyAlert
            tableData.push({
              DATE: TimestampDisplay(lastUpdatedDate ?? ''),
              SECTION: `Alert card was modified`,
              USER: lastUpdatedUser,
            })
          }
        })

        removedIds.forEach((removedId: string) => {
          const alerts =
            transmissionReportHistory.transmissionReportAlertAudit.filter(
              (a: { id: any }) => a.id === removedId,
            )

          console.log('alerts ', alerts)
          console.log('alerts.length ', alerts.length)

          if (alerts.length !== 0) {
            console.log('if--- ')

            for (let i = 0; i < alerts.length - 1; i++) {
              console.log('Alert card was modified')
              const { lastUpdatedUser, lastUpdatedDate } = alerts[i]
              console.log(' alerts[i] ', alerts[i])

              tableData.push({
                DATE: TimestampDisplay(lastUpdatedDate ?? ''),
                SECTION: `Alert card was modified`,
                USER: lastUpdatedUser,
              })
            }
            const { lastUpdatedUser, lastUpdatedDate } =
              alerts[alerts.length - 1]
            tableData.push({
              DATE: TimestampDisplay(lastUpdatedDate ?? ''),
              SECTION: `Alert card was deleted`,
              USER: lastUpdatedUser,
            })
          }
        })
      }
      if (transmissionReportHistory.transmissionReportImageAudit.length !== 0) {
        const removedIds =
          transmissionReportHistory.transmissionReportImageAudit.map(
            image => image.id,
          )
        removedIds.forEach((removedId: string) => {
          const image =
            transmissionReportHistory.transmissionReportImageAudit.find(
              (i: { id: any }) => i.id === removedId,
            )
          if (image) {
            const { location, lastUpdatedUser, lastUpdatedDate } = image
            tableData.push({
              DATE: TimestampDisplay(lastUpdatedDate ?? ''),
              SECTION: `${location} Image was deleted`,
              USER: lastUpdatedUser,
            })
          }
        })
      }
    }
    tableData.push({
      DATE: TimestampDisplay(signatures.createdAt),
      SECTION: `Report has been signed`,
      USER: `${signatures.user.given} ${signatures.user.family}`,
    })
  }
  if (transmissionReport) {
    tableData.push({
      DATE: TimestampDisplay(transmissionReport.createdAt),
      SECTION: `Report has been created`,
      USER: 'CRM System',
    })
  }
  tableData.sort(
    (a, b) => new Date(b.DATE).getTime() - new Date(a.DATE).getTime(),
  )
  isLoading = false

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width="90%"
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      }
    >
      <Stack spacing={'none'} mb={'lg'}>
        <HStack spacing="xl">
          <div id="dollarSign">
            <HistorySignIcon />
          </div>{' '}
          <Stack alignItems="flex-start" spacing="sm">
            <Heading variant="h6">{`${givenName} ${familyName}`}</Heading>

            <Heading variant="h4">History Log</Heading>
          </Stack>
        </HStack>
      </Stack>

      <DataTable
        hasPagination
        isSortable
        isSearchable
        isClickable
        columns={columns}
        //fetchData={(data: any) => getData(data)}
        data={tableData as Record<string, any>[]}
        // totalRowCount={totalRowCount}
        // totalPageCount={totalPageCount}
        isLoading={isLoading}
        //  searchTerm={searchTerm}
        //initialPageSize={DEFAULT_PAGE_SIZE}
        ////initialPageIndex={currPageIndex}
        // goToPageIndex={currPageIndex}
        selectedRowBackgroundColor="primary.100"
        selectedRowBorderColor="primary.400"
        borderCollapse="separate"
        borderSpacing="0 2px"
        tableHeadCell={
          <TableHeader
            color="neutral.800"
            bg="neutral.white"
            borderBottomColor="neutral.200"
            borderBottomStyle="solid"
            height={35}
          />
        }
        tableBodyRow={() => (
          <TableRow
            borderBottomColor="neutral.200"
            borderBottomStyle="solid"
            borderBottomWidth="2px"
            bg="neutral.white"
            sx={{
              _hover: {
                '#patients-list__action-menu': {
                  display: 'block',
                },
                cursor: 'default',
                background: 'var(--chakra-colors-primary-100)',
                boxShadow:
                  'inset 0px 0px 1px #1a9ee0, inset 0px 0px 1px #1a9ee0, inset 0px 0px 1px #1a9ee0, inset 0px 0px 1px #1a9ee0',
              },
              '&:first-of-type': {
                borderTopWidth: 'none',
                '> td': {
                  '&:first-of-type': {
                    borderBottomLeftRadius: 'md',
                  },
                  '&:last-of-type': {
                    borderBottomRightRadius: 'md',
                  },
                },
              },
              '&:not(:first-of-type) > td': {
                '&:first-of-type': {
                  borderTopLeftRadius: 'md',
                  borderBottomLeftRadius: 'md',
                },
                '&:last-of-type': {
                  borderTopRightRadius: 'md',
                  borderBottomRightRadius: 'md',
                },
              },
              '&:last-of-type': {
                borderBottomWidth: 'none',
              },
            }}
          />
        )}
      />
    </Modal>
  )
}

export default ViewReportHisotryModal
