import '@fontsource/bad-script'

import { useEffect, useState } from 'react'

import { pdf, PDFViewer, StyleSheet } from '@react-pdf/renderer'
import {
  Button,
  Error,
  Modal,
  ModalProps,
  Spinner,
  Text,
} from '@rhythm/components'
import saveAs from 'file-saver'
import moment from 'moment'
import PDFMerger from 'pdf-merger-js/browser'

import {
  useGetTransmissionReportById,
  useGetVendorTransmissionReports,
} from '../../../../../features/transmissionReports'
import { useGetICDDiagnosisCodes } from '../../../../../features/transmissionReports/api/getICDDiagnosisCodes'
import { useSendDownloadData } from '../../../../../features/transmissionReports/api/sendDownloadData'
import {
  Billing,
  queryClient,
  TransmissionReportNote,
} from '../../../../../lib/api'
import { ReportType } from '../../../../../types'
import {
  checkIfEditedAfterSigned,
  getDateTimeWithTimezone,
  getReportDownloadName,
} from '../../../../../utils/transmissionReportUtil'

import CreatingILRPDF from './CreatingILRPDF'
import CreatingILRPDFGrey from './CreatingILRPDFGrey'
import CreatingInClinicILRPDF from './CreatingInClinicILRPDF'
import CreatingInClinicILRPDFGrey from './CreatingInClinicILRPDFGrey'
import CreatingInClinicPDF from './CreatingInClinicPDF'
import CreatingInClinicPDFGrey from './CreatingInClinicPDF Grey'
import CreatingManualILRPDF from './CreatingManualILRPDF'
import CreatingManualILRPDFGrey from './CreatingManualILRPDFGrey'
import CreatingManualPDF from './CreatingManualPDF'
import CreatingManualPDFGrey from './CreatingManualPDF Grey'
import CreatingPDF from './CreatingPDF'
import CreatingPDFGrey from './CreatingPDFGrey'

export interface ViewPDFProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  transmissionId: number
  rhythmid: string
  transmissionReportNote?: TransmissionReportNote[]
  alertImages?: string[][]
  urlegm: any
  practitionGet: any
  isReportList?: boolean
  clinicAddress: any
  devicetype: any
  SignReportButton?: any
  onOpen?: () => void
  isZeroSign?: boolean
  saveCombinedPDF?: boolean
}

const ViewPDF = ({
  isOpen,
  onClose,
  transmissionId,
  rhythmid,
  alertImages,
  urlegm,
  practitionGet,
  isReportList,
  clinicAddress,
  devicetype,
  SignReportButton,
  onOpen,
  isZeroSign,
  saveCombinedPDF,
}: ViewPDFProps) => {
  let mergedPdf: string | Blob
  const [pdfUrlArray, setPdfUrlArray] = useState([])
  const [hasOpened, setHasOpened] = useState(false)
  const [transmissionSignedReport, setTransmissionSignedReport] =
    useState<any>(null)
  const [vendorReportChecked, setVendorReportChecked] = useState(false)
  const { mutate: sendDownloadedData } = useSendDownloadData()
  const { data: transmissionReport } = useGetTransmissionReportById(
    {
      transmissionReportId: transmissionId,
    },
    {
      enabled: !!transmissionId,
    },
  )
  const { isLoading, data: vendorTransmissionReportsList } =
    useGetVendorTransmissionReports(
      {
        transmissionReportId: transmissionId,
      },
      {
        enabled: !!transmissionId,
      },
    )

  const billing = queryClient.getQueryData<Billing>(['billing', transmissionId])

  const { data: icdDianosisCodes } = useGetICDDiagnosisCodes()

  let requiredpdfInfo: any = []
  useEffect(() => {
    if (isOpen && !hasOpened) {
      onOpen && onOpen()
      setHasOpened(true)
    }
    return () => {
      hasOpened && setHasOpened(false)
    }
  }, [isOpen, hasOpened, onOpen])

  // code to get vendor pdf base64 starts
  useEffect(() => {
    setPdfUrlArray([])
    if (
      vendorTransmissionReportsList &&
      vendorTransmissionReportsList.length !== 0
    ) {
      vendorTransmissionReportsList.sort((a: any, b: any) =>
        moment(b.lastModified).valueOf() > moment(a.lastModified).valueOf()
          ? 1
          : -1,
      )

      let hasLatestTransmissionReportItem = false
      /*eslint-disable */
      requiredpdfInfo = vendorTransmissionReportsList?.filter((report: any) => {
        let prefixName = JSON.parse(JSON.stringify(report))['name'].split('.')
        if (prefixName[prefixName.length - 1] === 'pdf') {
          if (report.name.includes(rhythmid)) {
            if (!hasLatestTransmissionReportItem && isReportList) {
              hasLatestTransmissionReportItem = true
              return report
            }
          } else {
            return report
          }
        }
      })

      let hasLatestTransmissionReport = false
      if (isReportList) {
        const signedReport = requiredpdfInfo?.find((report: any) => {
          if (report && isZeroSign) return report
          if (report.name.includes(rhythmid) && !hasLatestTransmissionReport) {
            hasLatestTransmissionReport = true
            return report
          } else if (
            JSON.parse(JSON.stringify(report))['name'].indexOf(
              'Signed_Rhythm_Report',
            ) > -1
          ) {
            return report
          }
        })
        if (signedReport) {
          setTransmissionSignedReport(signedReport)
        } else {
          setTransmissionSignedReport({})
        }
      }
      let caprureArray: any = []
      for (const element of requiredpdfInfo) {
        if (element?.pdfUrl && element.name) {
          caprureArray.push(element.pdfUrl)
        }
      }

      setPdfUrlArray(caprureArray)
    }
  }, [vendorTransmissionReportsList])
  //code to get vendor pdf ends here

  if (!transmissionReport && isLoading)
    return <Spinner size="sm" color="primary.400" />

  if (!transmissionReport)
    return <Error message={'Trouble finding this Report. Please try again'} />

  const showEditedByInfoOnPdf = checkIfEditedAfterSigned(transmissionReport)

  const reportType =
    transmissionReport.transmissionType as unknown as ReportType

  const downloadDate = getDateTimeWithTimezone()

  const isILRDevice = transmissionReport?.device?.deviceType === 'ILR'

  const getPDFComponentWithProps = (Component: any, specificProps = {}) => {
    const isExistDiagnosisOption =
      icdDianosisCodes &&
      icdDianosisCodes.find(
        e =>
          transmissionReport?.patient &&
          e.value === transmissionReport?.patient.diagnosisOption,
      )

    if (isExistDiagnosisOption && transmissionReport?.patient) {
      transmissionReport.patient.diagnosisOption = isExistDiagnosisOption.label
    }
    const commonProps = {
      key: transmissionReport?.patient?.id,
      transmissionReport,
      billing,
      urlImg1: alertImages?.[0][0],
      urlImg2: alertImages?.[0][1],
      urlImg3: alertImages?.[0][2],
      urlImg4: alertImages?.[1][0],
      urlImg5: alertImages?.[1][1],
      urlImg6: alertImages?.[1][2],
      urlImgEpisode1: alertImages?.[2][0],
      urlImgEpisode2: alertImages?.[2][1],
      urlImgEpisode3: alertImages?.[2][2],
      urlegm,
      practitionGet,
      deviceType: devicetype,
      clinicAddress,
      showEditedByInfoOnPdf,
      editedUser: transmissionReport.editedUser,
      editedDate: transmissionReport.editedDate,
    }
    const componentProps = {
      ...commonProps,
      ...specificProps,
    }
    return <Component {...componentProps} />
  }
  const getCorrectPDFComponent = () => {
    let PDFComponent
    switch (reportType) {
      case ReportType.InClinic:
        PDFComponent = isILRDevice
          ? CreatingInClinicILRPDFGrey
          : CreatingInClinicPDFGrey
        break
      case ReportType.Manual:
        PDFComponent = isILRDevice
          ? CreatingManualILRPDFGrey
          : CreatingManualPDFGrey
        break
      default:
        PDFComponent = isILRDevice ? CreatingILRPDFGrey : CreatingPDFGrey
    }

    return getPDFComponentWithProps(PDFComponent)
  }

  const PDFComponent = getCorrectPDFComponent()

  const getDownloadName = () => {
    return getReportDownloadName(
      reportType ?? '',
      transmissionReport?.patient?.givenName ?? '',
      transmissionReport?.patient?.familyName ?? '',
      transmissionReport?.transmissionDate ?? '',
      transmissionReport?.flagHeartFailure ?? false,
    )
  }

  // Merge and download PDF code Starts here
  async function downloadMerge() {
    if (
      !vendorReportChecked &&
      isReportList &&
      !transmissionSignedReport.pdfUrl
    ) {
      return false
    }
    if (isReportList && !vendorReportChecked) {
      let blob = await fetch(transmissionSignedReport.pdfUrl).then(r =>
        r.blob(),
      )
      sendDownloadedData({
        transmissionReportId: transmissionReport?.id!,
        downloadDate: downloadDate,
      })
      return saveAs(blob, getDownloadName())
    }
    if (vendorReportChecked && pdfUrlArray.length !== 0) {
      let merger = new PDFMerger()
      // merging container pdf  data
      if (!isReportList) {
        const blob = pdf(PDFComponent)
        blob.updateContainer(PDFComponent)
        const result = await blob.toBlob()
        await merger.add(result, null)
      }

      // add vendor reports
      for await (const pdfUrl of pdfUrlArray) {
        await merger.add(pdfUrl, null)
      }

      mergedPdf = await merger.saveAsBlob()

      saveAs(mergedPdf, getDownloadName())
      if (isReportList) {
        sendDownloadedData({
          transmissionReportId: transmissionReport?.id!,
          downloadDate: downloadDate,
        })
      }
    } else {
      try {
        const merger = new PDFMerger()
        const blob = pdf(PDFComponent)
        if (blob) {
          blob.updateContainer(PDFComponent)
          // Convert main PDF to Blob
          const mainBlob = await blob.toBlob()
          // Add main PDF to merger
          await merger.add(mainBlob)
        }
        // Save merged PDF as Blob
        const mergedBlob = await merger.saveAsBlob()
        return saveAs(mergedBlob, getDownloadName())
      } catch (error) {
        console.error('Error merging PDFs', error)
      }
    }
  }

  // Merge and download PDF code ends here

  const styles = StyleSheet.create({
    contentTitle: {
      top: 10,
      fontSize: 18,
    },

    buttonItem: {
      top: 10,
    },
    buttonItemTwo: {
      top: 10,
      left: 10,
    },
  })

  const fetchPDFViewerComponent = () => {
    if (!isILRDevice) {
      if (reportType === ReportType.Remote) {
        return CreatingPDF
      } else if (reportType === ReportType.InClinic) {
        return CreatingInClinicPDF
      } else if (reportType === ReportType.Manual) {
        return CreatingManualPDF
      }
    }
    if (reportType === ReportType.Remote) {
      return CreatingILRPDF
    } else if (reportType === ReportType.InClinic) {
      return CreatingInClinicILRPDF
    } else if (reportType === ReportType.Manual) {
      return CreatingManualILRPDF
    }

    return CreatingPDF
  }

  const renderDownloadButton = () => {
    if (isReportList) {
      if (transmissionSignedReport?.pdfUrl) {
        return (
          <Button style={styles.buttonItem} onClick={downloadMerge}>
            Download
          </Button>
        )
      }
      return (
        <Button
          style={styles.buttonItem}
          disabled={!vendorReportChecked}
          onClick={downloadMerge}
        >
          Download
        </Button>
      )
    }
    return (
      <Button style={styles.buttonItem} onClick={downloadMerge}>
        Download
      </Button>
    )
  }

  return (
    <Modal isOpen={isOpen} width="90%" onClose={onClose}>
      {!isReportList && (
        <PDFViewer
          showToolbar={false}
          style={{ height: '800px', margin: 'auto', width: '100%' }}
        >
          {getPDFComponentWithProps(fetchPDFViewerComponent())}
        </PDFViewer>
      )}

      {isReportList && (
        <>
          {transmissionSignedReport?.pdfUrl ? (
            <iframe
              title={transmissionSignedReport.name}
              src={transmissionSignedReport.pdfUrl}
              style={{ height: '800px', margin: 'auto', width: '100%' }}
            />
          ) : (
            <>
              {transmissionSignedReport !== null ? (
                <div
                  style={{
                    height: '100px',
                    margin: 'auto',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '50px',
                  }}
                >
                  No Signed Report Found
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
      <div>
        <div>
          {/* {!isReportList && ( */}
          <div hidden={pdfUrlArray.length === 0} style={styles.contentTitle}>
            <input
              type="checkbox"
              checked={vendorReportChecked}
              onChange={() => setVendorReportChecked(!vendorReportChecked)}
              disabled={saveCombinedPDF}
            />
            <Text> Vendor Report</Text>
          </div>
          {/* )} */}

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {renderDownloadButton()}
              <Button style={styles.buttonItemTwo} onClick={onClose}>
                Close
              </Button>
            </div>
            {SignReportButton ? (
              <div style={{ marginTop: '10px' }}>
                <SignReportButton />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewPDF
