import React from 'react'

import { Editable, EditableProps, Flex } from '@rhythm/components'

import {
  baseStyles,
  ColumnHeader,
  RowTitle,
  TransmissionReportValuesGrid,
  usePatchTransmissionReportDevice,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import {
  VendorDataOverrides,
  VtDeviceLead,
  VtDeviceMeasurement,
} from '../../../../../lib/api'
import { DeviceLeadLocation } from '../../../../../types'
import { VENDOR_DATA_OVERRIDE_KEYS } from '../../../../../utils/constants/transmissionReports'
import displayMonitoredValue from '../../../../../utils/displayMonitoredValue'

interface CellProps extends EditableProps {
  name: string
}
const Cell = (props: CellProps) => (
  <Flex {...baseStyles}>
    <Editable w="full" fontSize="sm" {...props} />
  </Flex>
)

const EmptyCell = () => (
  <Flex {...baseStyles} paddingLeft="md">
    {displayMonitoredValue('')}
  </Flex>
)

const DeviceMonitoredValuesTable = (): React.ReactElement => {
  const { transmissionReport, isLoading: isLoadingTransmissionReport } =
    useTransmissionReportContext()
  const { mutate: patchTransmissionReportDevice } =
    usePatchTransmissionReportDevice()

  const handleSubmit = (data: {
    name: keyof VendorDataOverrides
    value: string
    leadId?: VendorDataOverrides['deviceLeadId']
  }) => {
    console.log('data ', data)
    const { name, value, leadId } = data
    if (transmissionReport?.id && VENDOR_DATA_OVERRIDE_KEYS.includes(name)) {
      const vendorDataOverrides = {
        [name]: value,
        ...(!!leadId && { deviceLeadId: leadId }),
      }
      patchTransmissionReportDevice({
        transmissionReportId: transmissionReport.id,
        vendorDataOverrides,
      })
    }
  }

  const { deviceLeads, deviceMeasurement } = transmissionReport ?? {}
  const raLead = deviceLeads?.find(
    lead => lead.leadLocation === DeviceLeadLocation.RA,
  )
  const rvLead = deviceLeads?.find(
    lead => lead.leadLocation === DeviceLeadLocation.RV,
  )
  const lvLead = deviceLeads?.find(
    lead => lead.leadLocation === DeviceLeadLocation.LV,
  )

  return (
    <TransmissionReportValuesGrid
      key={`device-leads-${deviceMeasurement?.id}${!deviceLeads && '-loading'}`}
      gridTemplateColumns="1fr repeat(3, 20%)"
      gridTemplateRows="repeat(7, minmax(40px, auto))"
      loading={{
        isLoading: isLoadingTransmissionReport,
        numOfColumns: 4,
        numOfRows: 6,
      }}
    >
      <ColumnHeader>Device</ColumnHeader>
      <ColumnHeader>&nbsp;&nbsp;&nbsp;RA</ColumnHeader>
      <ColumnHeader>&nbsp;&nbsp;&nbsp;RV</ColumnHeader>
      <ColumnHeader>&nbsp;&nbsp;&nbsp;LV</ColumnHeader>

      <RowTitle>Sensing (mV)</RowTitle>
      {[raLead, rvLead, lvLead].map(
        (deviceLead: VtDeviceLead | undefined, _i) => (
          <Cell
            key={`sensing-${deviceMeasurement?.id}-${_i}`}
            name="sensing"
            defaultValue={displayMonitoredValue(deviceLead?.sensing)}
            onSubmit={value =>
              handleSubmit({
                name: 'deviceLeads.sensing',
                value,
                leadId: deviceLead?.leadId,
              })
            }
          />
        ),
      )}

      <RowTitle>Lead Impedance (&#8486;)</RowTitle>
      {[raLead, rvLead, lvLead].map(
        (deviceLead: VtDeviceLead | undefined, _i) => (
          <Cell
            key={`impedance-${deviceMeasurement?.id}-${_i}`}
            name="impedance"
            defaultValue={displayMonitoredValue(deviceLead?.impedance)}
            onSubmit={value =>
              handleSubmit({
                name: 'deviceLeads.impedance',
                value,
                leadId: deviceLead?.leadId,
              })
            }
          />
        ),
      )}

      <RowTitle>Pacing Threshold (V@ms)</RowTitle>
      {[raLead, rvLead, lvLead].map(
        (deviceLead: VtDeviceLead | undefined, _i) => (
          <Flex
            key={`threshold-pulsewidth-${deviceMeasurement?.id}-${_i}`}
            {...baseStyles}
            display="grid"
            gridTemplateColumns="50px 20px 50px"
          >
            <Editable
              name="threshold"
              fontSize="sm"
              textAlign="center"
              defaultValue={displayMonitoredValue(deviceLead?.threshold)}
              onSubmit={value =>
                handleSubmit({
                  name: 'deviceLeads.threshold',
                  value,
                  leadId: deviceLead?.leadId,
                })
              }
            />
            <span style={{ margin: '0 1px' }}>@</span>
            <br />
            <Editable
              name="pulsewidth"
              fontSize="sm"
              textAlign="center"
              defaultValue={displayMonitoredValue(deviceLead?.pulsewidth)}
              onSubmit={value =>
                handleSubmit({
                  name: 'deviceLeads.pulsewidth',
                  value,
                  leadId: deviceLead?.leadId,
                })
              }
            />
          </Flex>
        ),
      )}

      <RowTitle>High Voltage Impedance (&#8486;)</RowTitle>
      {[undefined, deviceMeasurement?.hvImpedance, undefined].map(
        (data: VtDeviceMeasurement['hvImpedance'] | undefined, _i) =>
          typeof data !== 'undefined' ? (
            <Cell
              key={`hvImpedance-${deviceMeasurement?.id}-${_i}`}
              name="hvImpedance"
              defaultValue={displayMonitoredValue(data)}
              onSubmit={value =>
                handleSubmit({
                  name: 'deviceMeasurement.hvImpedance',
                  value,
                })
              }
            />
          ) : (
            <EmptyCell key={`hvImpedance-${deviceMeasurement?.id}-${_i}`} />
          ),
      )}

      <RowTitle>AF Burden (%)</RowTitle>
      {[deviceMeasurement?.afBurden, undefined, undefined].map(
        (data: VtDeviceMeasurement['afBurden'] | undefined, _i) =>
          typeof data !== 'undefined' ? (
            <Cell
              key={`afBurden-${deviceMeasurement?.id}-${_i}`}
              name="afBurden"
              defaultValue={displayMonitoredValue(data)}
              onSubmit={value =>
                handleSubmit({
                  name: 'deviceMeasurement.afBurden',
                  value,
                })
              }
            />
          ) : (
            <EmptyCell key={`afBurden-${deviceMeasurement?.id}-${_i}`} />
          ),
      )}

      <RowTitle>Percent Paced (%)</RowTitle>
      {[
        { apAPacePercent: deviceMeasurement?.apAPacePercent },
        { rvpRvPacePercent: deviceMeasurement?.rvpRvPacePercent },
        { lvpPercent: deviceMeasurement?.lvpPercent },
      ].map((data: Record<string, string | undefined | null>, _i) => {
        const [[overrideKey, defaultValue]] = Object.entries(data)

        return (
          <Cell
            key={`percent-paced-${deviceMeasurement?.id}-${_i}`}
            name="percent-paced"
            defaultValue={displayMonitoredValue(defaultValue)}
            onSubmit={value =>
              handleSubmit({
                name: `deviceMeasurement.${overrideKey}` as keyof VendorDataOverrides,
                value,
              })
            }
          />
        )
      })}
    </TransmissionReportValuesGrid>
  )
}

export default DeviceMonitoredValuesTable
