import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApiVtVendorControllerListRequest,
  queryClient,
} from '../../../../lib/api'

export const useVtVendorList = () => {
  const Api = useApiContext()
  return useMutation(
    (params: DefaultApiVtVendorControllerListRequest) => {
      return Api.vtVendorControllerList(params)
    },
    {
      onSuccess: res => {
        queryClient.setQueryData(['vtVendorList'], res.data)
      },
    },
  )
}
