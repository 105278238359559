import { useGetPatientsMonitoredMonthlyDataProps } from './getPatientsMonitoredMonthlyData'

export const addMissingMonthObjectInArr = (
  arr: useGetPatientsMonitoredMonthlyDataProps[],
  startFromIso: string,
) => {
  if (!arr.length) {
    return arr
  }
  const startFrom = new Date(startFromIso)
  const startFromMonth = startFrom.getUTCMonth() + 1
  const startFromYear = startFrom.getUTCFullYear()
  // sort by year and month
  arr.sort((a, b) => (a.year !== b.year ? a.year - b.year : a.month - b.month))
  if (
    (arr[0].year === startFromYear && arr[0].month < startFromMonth) ||
    arr[0].year < startFromYear
  ) {
    return []
  }
  const getNextMonth = (current: useGetPatientsMonitoredMonthlyDataProps) => ({
    year: current.month === 12 ? current.year + 1 : current.year,
    month: current.month === 12 ? 1 : current.month + 1,
    count: 0,
  })

  const getPrevMonth = (current: useGetPatientsMonitoredMonthlyDataProps) => ({
    year: current.month === 1 ? current.year - 1 : current.year,
    month: current.month === 1 ? 12 : current.month - 1,
    count: 0,
  })

  while (
    (arr[0].month !== startFromMonth || arr[0].year !== startFromYear) &&
    arr.length < 12
  ) {
    arr.unshift(getPrevMonth(arr[0]))
  }

  for (let i = 0; i < 12; i++) {
    const current = arr[i]
    const nextOne = arr.find(d => d.month === (current.month % 12) + 1)

    if (!nextOne) {
      arr.splice(i + 1, 0, getNextMonth(current))
    }
  }
  return arr
}

export const addMonthAndYearLabelInArr = (
  data: useGetPatientsMonitoredMonthlyDataProps[],
): useGetPatientsMonitoredMonthlyDataProps[] => {
  const formattedData = []
  let currentYear
  for (const currentMonthData of data) {
    const monthString = new Date(
      `${currentMonthData.year}-${currentMonthData.month}-15`,
    ).toLocaleString('en-us', { month: 'short' })
    const newData: useGetPatientsMonitoredMonthlyDataProps = {
      ...currentMonthData,
      monthString: monthString,
    }

    if (!currentYear || currentMonthData.year > currentYear) {
      newData.xAxisLabel = `${monthString} ${currentMonthData.year}`
      currentYear = currentMonthData.year
    } else if (currentMonthData.year === currentYear) {
      newData.xAxisLabel = monthString
    }

    formattedData.push(newData)
  }
  return formattedData
}
