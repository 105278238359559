import React from 'react'

import {
  Flex,
  Heading,
  HStack,
  Search,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TextButton,
} from '@rhythm/components'

import { AnalyticReportType } from '../../../types'

import reportsData from './__mocks__/reportsData'
import RecentlyViewedCarousel from './components/RecentlyViewedCarousel'
import ReportChartCard from './components/ReportChartCard'
import ReportsGrid from './components/ReportsGrid'

const ReportsPage = () => {
  const recentlyViewedReports = [
    reportsData[6],
    reportsData[2],
    reportsData[1],
    reportsData[4],
    reportsData[5],
  ]
  const bookmarkedReports = reportsData.filter(report => report.isBookmarked)
  const productivityReports = reportsData.filter(
    report => report.type === AnalyticReportType.PRODUCTIVITY,
  )

  // TECH_SUITE_ONLY: reports currently all route to same page
  const routeToOperationsReportPage = () => {
    window.location.href = `//${process.env.REACT_APP_OPERATIONS_DOMAIN}/operations/report`
  }

  return (
    <Stack spacing="4xl" mb="5xl">
      <Heading
        variant="h3"
        fontWeight="regular"
      >{`${reportsData.length} Reports`}</Heading>

      <Stack spacing="xl">
        <Heading variant="h6">{`Recently Viewed ${recentlyViewedReports.length}`}</Heading>
        <RecentlyViewedCarousel>
          {recentlyViewedReports.map(report => (
            <ReportChartCard
              key={report.title}
              title={<Heading variant="h5">{report.title}</Heading>}
              lastUpdatedDate={report.lastUpdatedDate}
              onClickReport={routeToOperationsReportPage}
              isBookmarked={report.isBookmarked}
              chartImageSrc={report.chartImageSrc}
            />
          ))}
        </RecentlyViewedCarousel>
      </Stack>

      <Tabs>
        <HStack justifyContent="space-between" spacing="2xl">
          <HStack spacing="2xl">
            <TabList>
              <Tab>{`All Reports ${reportsData.length}`}</Tab>
              <Tab>{`My Bookmarked Reports ${bookmarkedReports.length}`}</Tab>
              <Tab>{`Productivity ${productivityReports.length}`}</Tab>
            </TabList>
            <TextButton
              leftIcon="add"
              padding={{ base: 'none', md: 'none' }}
              onClick={() => {}}
            >
              Create Custom Filter
            </TextButton>
          </HStack>
          <Flex flex={1} maxWidth="250px">
            <Search
              size="md"
              leftIcon={{
                icon: 'search',
                color: 'neutral.600',
                size: 'sm',
              }}
              rightIcon={{
                icon: 'close-circle',
                color: 'neutral.black',
                size: 'sm',
              }}
              placeholder="Search All Reports"
            />
          </Flex>
        </HStack>
        <TabPanels>
          <TabPanel>
            <ReportsGrid reports={reportsData} />
          </TabPanel>
          <TabPanel>
            <ReportsGrid reports={bookmarkedReports} />
          </TabPanel>
          <TabPanel>
            <ReportsGrid reports={productivityReports} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default ReportsPage
