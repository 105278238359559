import React, { useRef, useState } from 'react'

import {
  Avatar,
  Card,
  Heading,
  // TextButton,
  VStack,
} from '@rhythm/components'

export interface UserCardProps {
  name: string
  profileImageSrc?: string
  onUpdateProfileImage: (imageSrc: string) => void
}

const UserCard = ({
  name,
  profileImageSrc,
  onUpdateProfileImage,
}: UserCardProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [currentImageSrc, setCurrentImageSrc] = useState(profileImageSrc)

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const file = event.target.files && event.target.files[0]
    const imageSrc = URL.createObjectURL(file as Blob | MediaSource)
    setCurrentImageSrc(imageSrc)
    onUpdateProfileImage(imageSrc)
  }

  return (
    <Card width="100%">
      <VStack spacing="lg">
        <Avatar
          borderWidth="0.5px"
          borderColor="neutral.600"
          boxSize="120px"
          size={'2xl'}
          src={currentImageSrc}
          name={name}
        />
        <Heading variant="h3" textAlign="center">
          {name}
        </Heading>
        {/*<TextButton*/}
        {/*  fontSize="sm"*/}
        {/*  p={{ base: 'none', md: 'none' }}*/}
        {/*  onClick={() => inputRef.current?.click()}*/}
        {/*>*/}
        {/*  Update Profile Photo*/}
        {/*</TextButton>*/}
      </VStack>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        hidden
        onChange={onInputChange}
      />
    </Card>
  )
}

export default UserCard
