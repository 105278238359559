import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Box,
  DataTable,
  Flex,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Tab,
  TableCell,
  TableHeader,
  TableRow,
  TabList,
  Tabs,
  TextButton,
  VStack,
} from '@rhythm/components'

import { useDeleteClinicalNote } from '../../../../../features/clinicalNotes/deleteClinicalNote'
import { useGetClinicalNotesByPatientId } from '../../../../../features/clinicalNotes/getClinicalNotesByPatientId'
import { useGetPinnedNotesByPatientId } from '../../../../../features/clinicalNotes/getPinnedNotesByPatientId'
import { useUpdateClinicalNote } from '../../../../../features/clinicalNotes/updateClinicalNote'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import { useToastHook } from '../../../../../hooks/useToastHook'
import { ClinicalNote, Roles } from '../../../../../lib/api'
import ConfirmationModal from '../../../TransmissionReportPage/components/ConfirmationModal'

import AddClinicalNoteModal from './AddClinicalNoteModal'
import {
  ActionIconButton,
  ActionMenuItem,
  ColumnCellProps,
  DateCell,
  MAX_PINNED_NOTES,
  NoteCell,
  OwnerCell,
  RowActionIconButton,
} from './constants'
import InvisibleComponent from './InvisibleComponent'

const selectedTabStyle = {
  bg: 'neutral.white',
  fontWeight: 'bold',
  boxShadow: '0px 0px 3px 0px #969696 !important',
  color: 'gray.600',
}

const tabStyle = {
  px: '15px',
  height: '35px',
  borderRadius: '6px',
  marginRight: '0px !important',
  color: 'gray.400',
}

export const ViewClinicalNotes = ({
  patientId: patientIdStr,
  selectedNote,
  setSelectedNote,
}: {
  patientId?: string
  selectedNote: ClinicalNote | null
  setSelectedNote: (note: ClinicalNote | null) => void
}): React.ReactElement => {
  const { id: patientIdFromUrl } = useParams<{ id: string }>()
  const patientId = patientIdStr ?? patientIdFromUrl
  const isOnTransmissionReportPage = !!patientIdStr
  const { showToast } = useToastHook()
  const { currentUser } = useCurrentUser()
  const isUserInternalSuperAdmin = currentUser.role === Roles.InternalsuperAdmin
  const isUserInternal = !currentUser?.isExternal
  const { mutateAsync: deleteNote, isLoading: deleteLoading } =
    useDeleteClinicalNote(patientId)
  const { mutateAsync: pinNote, isLoading: pinLoading } =
    useUpdateClinicalNote(patientId)
  const [pinLoadingId, setPinLoadingId] = useState<string>('')
  const [selectedTab, setSelectedTab] = useState<'all' | 'standard' | 'pinned'>(
    'all',
  )
  const [isAddClinicalNoteModalOpen, setIsAddClinicalNoteModalOpen] =
    useState(false)

  const {
    data: notes,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useGetClinicalNotesByPatientId({
    patientId,
  })
  const { data: pinnedNotesData } = useGetPinnedNotesByPatientId({
    patientId,
  })
  const { pinnedNotes, count } = pinnedNotesData ?? {}
  const allNotes = useMemo(() => {
    return notes?.pages.reduce((acc: ClinicalNote[], page: ClinicalNote[]) => {
      if (selectedTab === 'standard') {
        return [...acc, ...page.filter(note => !note.isPinned)]
      } else if (selectedTab === 'pinned') {
        return [...acc, ...page.filter(note => note.isPinned)]
      }
      return [...acc, ...page]
    }, [])
  }, [notes?.pages, selectedTab])

  const [noteToDelete, setNoteToDelete] = useState<string | null>(null)

  const handleDelete = async () => {
    if (!noteToDelete) return
    try {
      await deleteNote({
        noteId: noteToDelete,
      })
      showToast('Note deleted successfully', 'error')
    } catch (error) {
      showToast('Failed to delete note. Please try again.', 'error')
    }
    setNoteToDelete(null)
  }

  const handlePinClick = async (noteId: string, isPinned: boolean) => {
    if ((pinnedNotes ?? []).length >= MAX_PINNED_NOTES && !isPinned) {
      showToast(
        `You can only pin ${MAX_PINNED_NOTES} notes at a time.`,
        'warning',
      )
      return
    }
    setPinLoadingId(noteId)
    await pinNote({
      noteId,
      updateNoteRequest: { isPinned: !isPinned },
    })
    showToast(`Note ${isPinned ? 'un' : ''}pinned.`)
    setPinLoadingId('')
  }
  const isEditMode = (noteId: string) => {
    return selectedNote && selectedNote.id === noteId
  }

  const ClinicalNotesPageActionsCell = ({ row }: ColumnCellProps) => {
    if (isUserInternal && !isUserInternalSuperAdmin) return null

    const isCreatedByUser = row.original.createdBy.id === currentUser.id
    const showEditAndDelete = isCreatedByUser || isUserInternalSuperAdmin
    const showPin = !isUserInternalSuperAdmin || currentUser.isExternal
    if (!showEditAndDelete && !showPin) return null

    if (isEditMode(row.original.id)) return null

    return (
      <HStack spacing="xl">
        {showEditAndDelete ? (
          <RowActionIconButton
            icon="edit"
            label="Edit"
            onClick={() =>
              setSelectedNote(row.original as unknown as ClinicalNote)
            }
          />
        ) : (
          <div style={{ width: '18px' }} />
        )}
        {showPin ? (
          <RowActionIconButton
            icon={row.original.isPinned ? 'pin-filled' : 'pin'}
            label={row.original.isPinned ? 'Unpin note' : 'Pin note'}
            onClick={() =>
              handlePinClick(row.original.id, row.original.isPinned)
            }
            isLoading={pinLoading && row.original.id === pinLoadingId}
            disabled={pinLoading && row.original.id === pinLoadingId}
          />
        ) : (
          <div style={{ width: '18px' }} />
        )}
        {showEditAndDelete ? (
          <RowActionIconButton
            icon="delete"
            label="Delete"
            onClick={() => setNoteToDelete(row.original.id)}
            color="red.400"
          />
        ) : (
          <div style={{ width: '18px' }} />
        )}
      </HStack>
    )
  }

  const TransmissionReportsPageActionsCell = ({ row }: ColumnCellProps) => {
    if (isUserInternal && !isUserInternalSuperAdmin) return null

    const isCreatedByUser = row.original.createdBy.id === currentUser.id
    const showEditAndDelete = isCreatedByUser || isUserInternalSuperAdmin
    const showPin = !isUserInternalSuperAdmin || currentUser.isExternal
    if (!showEditAndDelete && !showPin) return null

    if (isEditMode(row.original.id)) return null

    return (
      <Menu autoSelect={false}>
        <MenuButton>
          <ActionIconButton
            isLoading={pinLoading && row.original.id === pinLoadingId}
            icon="more-vertical"
            onClick={() => {}}
          />
        </MenuButton>
        <MenuList style={{ minWidth: '100px' }}>
          {showEditAndDelete && (
            <ActionMenuItem
              onClick={() => {
                setSelectedNote(row.original as unknown as ClinicalNote)
                setIsAddClinicalNoteModalOpen(true)
              }}
              icon="edit"
              label="Edit"
            />
          )}
          {showPin && (
            <ActionMenuItem
              onClick={() =>
                handlePinClick(row.original.id, row.original.isPinned)
              }
              icon={row.original.isPinned ? 'pin-filled' : 'pin'}
              label={row.original.isPinned ? 'Unpin' : 'Pin'}
            />
          )}
          {showEditAndDelete && (
            <ActionMenuItem
              onClick={() => setNoteToDelete(row.original.id)}
              icon="delete"
              label="Delete"
              color="red.400"
            />
          )}
        </MenuList>
      </Menu>
    )
  }

  const fetchBg = (note: ClinicalNote) => {
    if (selectedNote && selectedNote.id === note.id) {
      return 'gray.200'
    }
    if (note.isPinned) {
      return 'orange.100'
    }
    return 'neutral.white'
  }

  const fetchHoverBg = (note: ClinicalNote): string => {
    if (isEditMode(note.id)) return ''
    return note?.isPinned ? 'orange.200' : 'gray.100'
  }

  const getEditModeProps = (id: string) => {
    if (isEditMode(id)) {
      return {
        opacity: 0.6,
        cursor: 'not-allowed',
        border: '1px solid',
        borderColor: 'gray.400',
      }
    }
    return {}
  }

  const getHoverProps = () => {
    if (!isOnTransmissionReportPage) {
      return {
        svg: {
          display: 'none',
        },
        '&:hover svg': {
          display: 'block',
        },
      }
    }
    return {}
  }

  const handleTabChange = (index: number) => {
    if (index === 0) {
      setSelectedTab('all')
    } else if (index === 1) {
      setSelectedTab('standard')
    } else {
      setSelectedTab('pinned')
    }
  }

  return (
    <HStack spacing="xl" mb="lg" width={'100%'}>
      <VStack spacing="xl" width={'100%'}>
        <HStack
          spacing="xl"
          width={'100%'}
          height={14}
          justifyContent={
            isOnTransmissionReportPage ? 'space-between' : 'flex-start'
          }
        >
          <Heading variant="h5">Clinical Notes ({count})</Heading>
          {isOnTransmissionReportPage && !isUserInternal && (
            <TextButton
              onClick={() => {
                console.log('clicked')
                setIsAddClinicalNoteModalOpen(true)
              }}
              py={0}
              pl={3}
              pr={0}
            >
              <Flex>
                <Icon icon={'add'} boxSize={4} /> &nbsp;&nbsp; Add Note
              </Flex>
            </TextButton>
          )}
        </HStack>
        <HStack alignItems="flex-start" spacing="5xl" mt="4xl" width={'100%'}>
          <Tabs
            variant={'unstyled'}
            sx={{
              background: 'gray.50',
              width: '100%',
              borderRadius: '5px',
              border: '1px solid',
              borderColor: 'gray.300',
              px: 'md',
              height: '45px',
            }}
            onChange={index => handleTabChange(index)}
          >
            <TabList
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                top: '4px',
              }}
            >
              <Tab _selected={selectedTabStyle} sx={tabStyle}>
                All
              </Tab>
              <Tab _selected={selectedTabStyle} sx={tabStyle}>
                Standard
              </Tab>
              <Tab _selected={selectedTabStyle} sx={tabStyle}>
                <Icon
                  icon={'pin-filled'} // TODO: Change this to UnpinIcon
                  color="gray.500"
                  style={{ width: '13px', marginRight: '5px' }}
                />
                Pinned Notes
              </Tab>
            </TabList>
          </Tabs>
        </HStack>
        {isLoading ? (
          <Spinner size="xl" color="primary.400" />
        ) : (
          <Box mb="xl" width={'100%'}>
            <DataTable
              columns={[
                {
                  Header: 'Date',
                  accessor: 'updatedAt',
                  Cell: (props: ColumnCellProps) => (
                    <DateCell
                      {...props}
                      isOnTransmissionReportPage={isOnTransmissionReportPage}
                    />
                  ),
                  width: isOnTransmissionReportPage ? '20' : '50',
                },
                {
                  Header: 'Owner',
                  accessor: 'updatedBy',
                  Cell: (props: ColumnCellProps) => (
                    <OwnerCell
                      {...props}
                      isOnTransmissionReportPage={isOnTransmissionReportPage}
                    />
                  ),
                  width: isOnTransmissionReportPage ? '20' : '50',
                },
                {
                  Header: 'Note',
                  accessor: 'content',
                  width: isOnTransmissionReportPage ? '50' : '200',
                  Cell: (props: ColumnCellProps) => (
                    <NoteCell
                      {...props}
                      isOnTransmissionReportPage={isOnTransmissionReportPage}
                    />
                  ),
                },
                {
                  Header: '',
                  accessor: 'id',
                  width: isOnTransmissionReportPage ? '5' : '40',
                  Cell: isOnTransmissionReportPage
                    ? TransmissionReportsPageActionsCell
                    : ClinicalNotesPageActionsCell,
                },
              ]}
              size="sm"
              isSortable
              tableBodyCell={<TableCell px={1} sx={{ fontSize: '12px' }} />}
              tableHeadCell={
                <TableHeader
                  color="neutral.800"
                  bg="neutral.white"
                  borderColor="neutral.200"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px"
                  py="xl"
                  px={1}
                />
              }
              tableBodyRow={row => (
                <TableRow
                  alignItems="center"
                  borderBottomColor="neutral.200"
                  borderBottomStyle="solid"
                  borderBottomWidth="1px"
                  sx={{
                    '&:hover': {
                      bg: fetchHoverBg(row.original as ClinicalNote),
                    },
                    ...getEditModeProps(row.original.id),
                    ...getHoverProps(),
                  }}
                  bg={fetchBg(row.original as ClinicalNote)}
                />
              )}
              data={allNotes ?? []}
            />
            {isFetchingNextPage && (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Spinner size="md" color="primary.400" />
              </div>
            )}
            <InvisibleComponent
              onVisible={() => {
                if (hasNextPage && !isFetchingNextPage) {
                  fetchNextPage()
                }
              }}
            />
            <div style={{ height: '120px' }} />
          </Box>
        )}
      </VStack>
      {noteToDelete && (
        <ConfirmationModal
          isOpen={!!noteToDelete}
          isLoading={deleteLoading}
          onClose={() => setNoteToDelete(null)}
          onConfirm={() => {
            handleDelete()
          }}
          title="Are you sure you want to delete this note?"
          description="This action cannot be undone."
        />
      )}

      <AddClinicalNoteModal
        isOpen={isAddClinicalNoteModalOpen}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
        onClose={() => setIsAddClinicalNoteModalOpen(false)}
      />
    </HStack>
  )
}
