import {
  Box,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@rhythm/components'

import { Patient } from '../../../../../../lib/api'
import { ReportType } from '../../../../../../types'

import { ReportMenuItem } from './ReportMenuItem'

interface ReportsMenuProps {
  patient: Patient
}

export const ReportsMenu = ({
  patient,
}: ReportsMenuProps): React.ReactElement => {
  const { reportData, clinic } = patient

  const reportDataArray: ReportType[] = reportData as unknown as ReportType[]
  const count = reportDataArray.filter(
    (e: any) => e.downloaded === false,
  ).length

  const getReportItemCount = (count: number) => {
    reportDataArray.forEach((e: any) => {
      if (
        clinic.ehrIntegration &&
        e.transmissiontype === ReportType.Remote &&
        !e.downloaded
      ) {
        count = count - 1
      }
    })
    if (count === 0) {
      return '-'
    }
    return count + ' Not Downloaded'
  }

  return (
    <Box>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              style={{
                background: 'transparent',
                fontSize: '14px',
                lineHeight: '18px',
              }}
            >
              <Text
                color={'#455468'}
                fontSize={'14px'}
                fontWeight={600}
                fontFamily={'Lato'}
                textAlign={'left'}
              >
                All Reports
                <Icon
                  icon={isOpen ? 'chevron-up' : 'chevron-down'}
                  style={{
                    color: '#213147',
                    paddingLeft: '5px',
                    paddingTop: '5px',
                  }}
                  boxSize={'sm'}
                />
                <br />
                <Text fontFamily={'Lato'} fontSize={12} color={'#6C7789'}>
                  <Box> {getReportItemCount(count)} </Box>
                </Text>
              </Text>
            </MenuButton>
            <MenuList
              key="allReportsMenu"
              minWidth={'381px'}
              marginLeft={'-32px'}
              maxH={190}
              overflowY={'auto'}
            >
              {reportData && reportData.length === 0 && (
                <MenuItem
                  fontSize={14}
                  fontWeight={700}
                  color={'#455468'}
                  closeOnSelect={false}
                  cursor={'default'}
                >
                  <HStack
                    w="full"
                    direction="row"
                    position="relative"
                    style={{ display: 'inline-block' }}
                  >
                    <Text style={{ float: 'left' }}>
                      <Text fontSize={12} fontFamily={'Lato'} color="#6C7789">
                        No Transmission Reports Found
                      </Text>
                    </Text>
                  </HStack>
                </MenuItem>
              )}
              {reportData &&
                reportData.map((e: any) => (
                  <ReportMenuItem reportItem={e} patient={patient} key={e.id} />
                ))}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}
