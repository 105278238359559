import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Button,
  Heading,
  IconBadge,
  Modal,
  Skeleton,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@rhythm/components'

import { useAnalyticsContext } from '../../../../context/AnalyticsContext'
import { useDownloadAnalyticsReport } from '../../../../features/reportGeneration/useDownloadAnalyticsReport'
import { IconComponent, IconType } from '../../PatientsPage/icons/CustomIcons'

import { fetchAnalyticsProps } from './columns'

const EXPIRY_TIME_IN_MINUTES = 5

export const DownloadReportButton = () => {
  const { pathname } = useLocation()
  const { key } = fetchAnalyticsProps(pathname)
  const { activeAnalyticsQueryParams } = useAnalyticsContext()
  const {
    mutateAsync: downloadReport,
    isLoading,
    isSuccess,
    isError,
  } = useDownloadAnalyticsReport()
  const toast = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [timeoutNum, setTimeoutNum] = useState<NodeJS.Timeout | null>(null)
  const [downloadUrl, setDownloadUrl] = useState('')

  const onDownloadReportClick = async () => {
    setIsOpen(true)
    try {
      const { downloadUrl }: any = await downloadReport({
        reportType: key,
        query: activeAnalyticsQueryParams as object,
      })
      if (!downloadUrl) {
        throw new Error('Download url not found')
      }
      setDownloadUrl(downloadUrl)
      const timer = setTimeout(
        () => {
          setIsExpired(true)
        },
        EXPIRY_TIME_IN_MINUTES * 60 * 1000,
      )
      setTimeoutNum(timer)
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'An error occurred while downloading the report. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
    }
  }

  const handleDownloadUrl = () => {
    window.open(downloadUrl, '_blank')
  }

  return (
    <>
      <Button
        variant="primary"
        size="md"
        ml={2}
        position={'relative'}
        top={'4px'}
        disabled={isLoading}
        onClick={onDownloadReportClick}
      >
        {isLoading && (
          <span>
            <Spinner size="sm" mr={1} />
          </span>
        )}
        {isLoading ? 'Downloading..' : 'Download Report'}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
          setIsExpired(false)
          if (timeoutNum) {
            clearTimeout(timeoutNum)
            setTimeoutNum(null)
          }
        }}
        hideCloseButton={isLoading}
        closeOnOverlayClick={false}
        width={368}
        isCentered={true}
      >
        <Box w="full" textAlign={'center'}>
          <VStack w="full" spacing="xl">
            {isLoading && (
              <>
                <Heading variant="h4">Preparing the data for download</Heading>
                <Text
                  fontSize={14}
                  color={'#6C7789'}
                  fontWeight={600}
                  marginTop={12}
                >
                  It may take a few minutes
                </Text>
                <Skeleton
                  h="xs"
                  mb="2xl"
                  width="92%"
                  isLoaded={false}
                  endColor="#1A9EE0"
                ></Skeleton>
              </>
            )}
            {isSuccess && !isLoading && !isExpired && (
              <>
                <Box>
                  <IconComponent iconType={IconType.Succes} />
                </Box>
                <Heading variant="h5">The file is ready for download!</Heading>

                <Box w="full">
                  <Button
                    variant="primary"
                    type="submit"
                    borderRadius={8}
                    disabled={isExpired}
                    size="md"
                    onClick={() => handleDownloadUrl()}
                  >
                    <IconBadge
                      icon={'download'}
                      size="sm"
                      background={'transparent'}
                      color={'white'}
                    />
                    {'Download Report'}
                  </Button>
                </Box>
              </>
            )}
            {isError && !isLoading && (
              <>
                <Box>
                  <IconComponent iconType={IconType.Error} />
                </Box>
                <Heading variant="h4">Something went wrong</Heading>
                <Text fontSize={14} color={'#6C7789'} fontWeight={600}>
                  Please try again
                </Text>
              </>
            )}
            {isExpired && !isLoading && (
              <>
                <Box>
                  <IconComponent iconType={IconType.Error} />
                </Box>
                <Heading variant="h5">
                  Sorry! The download link has expired
                </Heading>
                <Text fontSize={14} color={'#6C7789'} fontWeight={600}>
                  Please close this pop-up and try again.
                </Text>
              </>
            )}
          </VStack>
        </Box>
      </Modal>
    </>
  )
}
