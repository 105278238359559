import { TransmissionReportDtoColorEnum } from '../lib/api'

export const transmissionColorText = (
  color: TransmissionReportDtoColorEnum,
): string => {
  switch (color) {
    case TransmissionReportDtoColorEnum.Red:
      return 'urgent'
    case TransmissionReportDtoColorEnum.Yellow:
      return 'require review'
    case TransmissionReportDtoColorEnum.Green:
      return 'require signature'
    case TransmissionReportDtoColorEnum.Gray:
      return 'require triage'
  }
}
