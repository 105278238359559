import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, FormLabel, Text } from '@rhythm/components'
import moment from 'moment'

import DatePickerv2 from '../../../../components/DatePickerv2'
import DateRangePicker from '../../../../components/DatePickerv2/DateRangePicker'
import { useAnalyticsContext } from '../../../../context/AnalyticsContext'
import { ReportType as AnalyticsReportType } from '../../../../lib/api'

import { fetchAnalyticsProps } from './columns'
import { fetchApplicableDateRange, fetchFormattedDateRange } from './constants'

export const AnalyticsDateRangePicker = () => {
  const location = useLocation()
  const { key } = fetchAnalyticsProps(location.pathname)
  const { activeAnalyticsQueryParams, updateActiveAnalyticsQueryParams } =
    useAnalyticsContext()
  const dateRange = useMemo(() => {
    const defaultDateRange = [
      new Date(new Date().setDate(new Date().getDate() - 7)),
      new Date(),
    ]

    const activeDateRange = activeAnalyticsQueryParams?.dateRange

    if (activeDateRange && activeDateRange.length > 1) {
      const savedDateRange = sessionStorage.getItem(`${key}_dateRange`)
      const savedDateRangeArr = (savedDateRange ?? '').split(',')
      if (savedDateRangeArr.length > 1) {
        const newDateRange = fetchApplicableDateRange(
          savedDateRangeArr,
          activeDateRange,
        )
        if (newDateRange) {
          return newDateRange.map(date => moment(date).utc().toDate())
        }
      }
      return activeDateRange.map(date => moment(date).utc().toDate())
    }

    return defaultDateRange
  }, [activeAnalyticsQueryParams?.dateRange, key])

  const isAccountOverviewPage = key === AnalyticsReportType.AccountOverview

  return (
    <Box mb={5} width={'60%'}>
      <FormLabel htmlFor={'dateRange'}>
        <Text
          fontStyle={'md'}
          fontWeight={'bold'}
          color={'inherit'}
          textTransform={'uppercase'}
        >
          Select Date{!isAccountOverviewPage ? ' Range' : ''}*
        </Text>
      </FormLabel>
      {!isAccountOverviewPage ? (
        <DateRangePicker
          // maxDate={new Date(new Date().setDate(new Date().getDate() + 1))}
          dateRange={dateRange}
          onDateRangeSelected={(dateRange: Date[]) => {
            const formattedDR = fetchFormattedDateRange(dateRange)
            sessionStorage.setItem(`${key}_dateRange`, formattedDR.join())

            updateActiveAnalyticsQueryParams(prev => ({
              ...prev,
              dateRange: formattedDR,
            }))
          }}
        />
      ) : (
        <DatePickerv2
          datePickerProps={{
            id: 'date',
            selected: dateRange[0],
            onChange: (date: Date | null) => {
              if (!date) return
              const dateRange = [date, date]
              const formattedDR = fetchFormattedDateRange(dateRange)
              sessionStorage.setItem(`${key}_dateRange`, formattedDR.join())

              updateActiveAnalyticsQueryParams(prev => ({
                ...prev,
                dateRange: formattedDR,
              }))
            },
            placeholderText: 'mm/dd/yyyy',
          }}
        />
      )}
    </Box>
  )
}
