import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApi,
  DefaultApiTransmissionReportsControllerUpdateNoteRequest,
  queryClient,
  TransmissionReport,
  TransmissionReportNoteLocationEnum,
} from '../../../../lib/api'

const updateTransmissionReportAlertNote = async (
  Api: DefaultApi,
  noteInfo: DefaultApiTransmissionReportsControllerUpdateNoteRequest,
  onCardUpdate?: any,
) => {
  return new Promise(async (resolve: any, reject) => {
    const { data } = await Api.transmissionReportsControllerUpdateNote(noteInfo)
    const { note: updatedNote } = data
    const { transmissionReportId, noteId } = noteInfo
    if (onCardUpdate) {
      onCardUpdate(noteId, true)
      return
    }
    queryClient.setQueryData<Partial<TransmissionReport | undefined>>(
      ['transmissionReport', transmissionReportId],
      transmissionReport => {
        if (
          transmissionReport?.alerts &&
          updatedNote &&
          updatedNote.location === TransmissionReportNoteLocationEnum.Alert
        ) {
          const currentAlert = transmissionReport.alerts.find(alert =>
            alert.notes.find(note => note.id === noteId),
          )
          if (!currentAlert) return
          currentAlert.notes = currentAlert.notes.map(note =>
            note.id === noteId ? updatedNote : note,
          )
          transmissionReport.alerts = transmissionReport.alerts.map(alert =>
            alert.id === currentAlert.id ? currentAlert : alert,
          )
        } else if (
          transmissionReport?.notes &&
          updatedNote &&
          [
            TransmissionReportNoteLocationEnum.BatteryStatus,
            TransmissionReportNoteLocationEnum.MonitoredValues,
            TransmissionReportNoteLocationEnum.Transmission,
          ].includes(updatedNote.location)
        ) {
          transmissionReport.notes = transmissionReport.notes.map(note =>
            note.id === noteId ? updatedNote : note,
          )
        }
        return transmissionReport
      },
    )
    queryClient
      .invalidateQueries(['transmissionReport', transmissionReportId])
      .then(() => setTimeout(resolve, 100))
      .catch(() => setTimeout(resolve, 100))
  })
}
export const useUpdateTransmissionReportAlertNote = (onCardUpdate?: any) => {
  const Api = useApiContext()
  return useMutation(
    (noteInfo: DefaultApiTransmissionReportsControllerUpdateNoteRequest) =>
      updateTransmissionReportAlertNote(Api, noteInfo, onCardUpdate),
    {
      onSuccess: (data, variables) => {},
      onError: (error, variables, context) => {},
    },
  )
}
