// Importing necessary types
import { PatientActiveStatusEnum } from '../../../types'

export enum PatientMenuOptions {
  ALL_Patients = 'ALL_Patients',
  NEW = 'NEW',
  ALL_Reports = 'ALL_Reports',
  NOT_DOWNLOAD = 'NOT_DOWNLOAD',
  RECENT = 'RECENT',
}

// Defining the LabelAndValue type
export type LabelAndValue = {
  label: string
  value: string
}

// Defining the options for the status filter
export const StatusFilterOptions = [
  { value: PatientActiveStatusEnum.All, label: 'All' },
  { value: PatientActiveStatusEnum.Active, label: 'Active' },
  { value: PatientActiveStatusEnum.Inactive, label: 'Inactive' },
  // { value: PatientActiveStatusEnum.Pending, label: 'Pending' },
  { value: PatientActiveStatusEnum.Paused, label: 'Paused' },
  // { value: PatientActiveStatusEnum.New, label: 'New' },
  // { value: PatientActiveStatusEnum.InProgress, label: 'In Progress' },
  // { value: PatientActiveStatusEnum.Candidate, label: 'Candidate' },
  // { value: PatientActiveStatusEnum.Closed, label: 'Closed' },
]

// Defining the options for the patient filter
export const PatientFilterOptions = [
  { value: PatientMenuOptions.ALL_Patients, label: 'All' },
  { value: PatientMenuOptions.NEW, label: 'New' },
]

// Defining the options for the report filter
export const ReportFilterOptions = [
  { value: PatientMenuOptions.ALL_Reports, label: 'All' },
  {
    value: PatientMenuOptions.RECENT,
    label: 'Recently Signed (last 30 days)',
  },
  { value: PatientMenuOptions.NOT_DOWNLOAD, label: 'Not Downloaded' },
]

// Defining the options for the device schedule filter
export const DeviceScheduleFilterOptions = {
  ALL: {
    value: '',
    label: 'All',
  },
  UNSCHEDULED: {
    value: 'unscheduled',
    label: 'Unscheduled',
  },
  MISSED: {
    value: 'missed',
    label: 'Missed',
  },
  REQUIRES_CORRECTION: {
    value: 'requires_correction',
    label: 'Requires Correction',
  },
}
