import moment from 'moment'

import { DATE_FORMAT } from './constants'

const getZeroHourDbDate = (date?: string | null): Date | null => {
  if (!date) return null

  const dateTime = new Date(moment(date).format(DATE_FORMAT))
  const dateOnly = new Date(dateTime.getTime())
  dateOnly.setHours(0, 0, 0, 0)

  let dateParts: string[]
  let dayPart: number = 0
  if (date.includes('-')) {
    // Handles db date
    dateParts = date.split('-')
    dayPart = parseInt(dateParts[2])
  } else if (date.includes('/')) {
    // Handles picker date
    dateParts = date.split('/')
    dayPart = parseInt(dateParts[1])
  }
  if (dayPart > 0) {
    dateOnly.setDate(dayPart)
  }
  return dateOnly
}

export default getZeroHourDbDate
