import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiRegisteredNpisControllerGetNpisDataRequest } from '../../../lib/api'

export const useGetRegisteredNpi = (
  queryParams?: DefaultApiRegisteredNpisControllerGetNpisDataRequest,
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const Api = useApiContext()
  return useQuery(
    ['useGetRegisteredNpi', queryParams],
    async () => {
      const res = await Api.registeredNpisControllerGetNpisData({
        ...queryParams,
      })
      return res.data
    },
    {
      ...options,
      onError: (error: any) => {},
    },
  )
}
