import React from 'react'

import {
  Card,
  Heading,
  HStack,
  IconBadge,
  IconBadgeProps,
  VStack,
} from '@rhythm/components'

export interface NarrativeCardProps {
  children: React.ReactNode
  icon: IconBadgeProps['icon']
  iconVariant?: IconBadgeProps['variant']
  title: string
}

const NarrativeCard = ({
  children,
  icon,
  iconVariant,
  title,
}: NarrativeCardProps): React.ReactElement => {
  return (
    <Card width="100%">
      <HStack spacing="xl" mb="2xl">
        <IconBadge icon={icon} variant={iconVariant} size="lg" />
        <VStack alignItems="flex-start" spacing="sm">
          <Heading variant="h4">{title}</Heading>
        </VStack>
      </HStack>
      {children}
    </Card>
  )
}

export default NarrativeCard
