import { EMPTY_PLACEHOLDER } from './constants'

const displayValue = (value: any, placeholder: string = EMPTY_PLACEHOLDER) => {
  if (typeof value === 'string' && value.length) {
    return value
  }
  if (typeof value === 'number') {
    return value
  }
  return value ? value : placeholder
}

export default displayValue
