/**
 * This is ok for now since only one country is supported
 * when we support more, this should be change to a map or enum
 */
export const DEFAULT_COUNTRY_NAME = 'United States'
export const DEFAULT_COUNTRY_CODE = 'USA'
export const FILE_UPLOAD_LIMITS = {
  allowed_extensions: {
    '.pdf': '%PDF',
    '.docx': 'PK',
    '.doc': '',
    '.jpeg': '',
    '.jpg': '',
    '.png': '',
    '.xls': '',
    '.xlsx': '',
    '.gif': '',
  },
  max_size: 1024 * 1024 * 20, // 20mb
}
