import { DeviceVendors, PatientOacEnum, Roles } from '../../lib/api'

export const PRACTITIONER_SIGNATURE_ROLES: Roles[] = [
  Roles.ExternalpractitionerAdmin,
  Roles.Externalpractitioner,
  Roles.Externalprovider,
]

export const SIGNALL_ROLES: Roles[] = [
  Roles.ExternalpractitionerAdmin,
  Roles.Externalpractitioner,
  Roles.Externalprovider,
]

export const HIDE_SIGNALL_ROLES: Roles[] = [
  Roles.InternalapiAccess,
  Roles.InternalsuperAdmin,
  Roles.Internaladmin,
  Roles.Internalclinical,
  Roles.InternalnonClinical,
  Roles.Externaladmin,
  Roles.ExternalnonPractitionerAdmin,
  Roles.ExternalnonPractitioner,
  Roles.ExternalnonProvider,
  Roles.Externalclerical,
  Roles.ExternalheartFailure,
]
export const REASONS_FOR_MONITORING_OPTIONS = [
  { label: 'AF Management', value: 'AF Management' },
  { label: 'Bradycardia', value: 'Bradycardia' },
  { label: 'Cardiac Arrest', value: 'Cardiac Arrest' },
  { label: 'Complete Heart Block', value: 'Complete Heart Block' },
  { label: 'Cryptogenic Stroke/TIA', value: 'Cryptogenic Stroke/TIA' },
  { label: 'Heart Failure Unspecified', value: 'Heart Failure Unspecified' },
  { label: 'High Grade AV Block', value: 'High Grade AV Block' },
  { label: 'Palpitations', value: 'Palpitations' },
  { label: 'Primary Prevention of SCD', value: 'Primary Prevention of SCD' },
  {
    label: 'Secondary Prevention of SCD',
    value: 'Secondary Prevention of SCD',
  },
  { label: 'Sick Sinus Syndrome', value: 'Sick Sinus Syndrome' },
  { label: 'Suspected AF', value: 'Suspected AF' },
  { label: 'Syncope', value: 'Syncope' },
  { label: 'Tachy-Brady Syndrome', value: 'Tachy-Brady Syndrome' },
  { label: 'Ventricular Tachycardia', value: 'Ventricular Tachycardia' },
]

export const OAC_OPTIONS = [
  { label: PatientOacEnum.NA, value: PatientOacEnum.NA },
  { label: PatientOacEnum.No, value: PatientOacEnum.No },
  { label: PatientOacEnum.NotTolerated, value: PatientOacEnum.NotTolerated },
  { label: PatientOacEnum.Yes, value: PatientOacEnum.Yes },
  {
    label: PatientOacEnum.PatientDeclined,
    value: PatientOacEnum.PatientDeclined,
  },
  {
    label: PatientOacEnum.HOLAAClosureDevice,
    value: PatientOacEnum.HOLAAClosureDevice,
  },
  {
    label: PatientOacEnum.Contraindicated,
    value: PatientOacEnum.Contraindicated,
  },
]

export const DEVICES_VENDOR_OPTIONS = [
  {
    label: 'Abbott',
    url: 'https://www.merlin.net',
    value: DeviceVendors.Abbott,
  },
  {
    label: 'BIOTRONIK',
    url: 'https://www.biotronik-homemonitoring.com/hmsc_guiWeb/user/site/SignIn.jsf',
    value: DeviceVendors.Biotronik,
  },
  {
    label: 'Boston Scientific',
    url: 'https://www.latitude.bostonscientific.com/',
    value: DeviceVendors.BostonScientific,
  },
  {
    label: 'Medtronic',
    url: 'https://clc.medtroniccarelink.net/Clinician/Login/login.aspx?ReturnUrl=%2fClinician%2fhome.aspx',
    value: DeviceVendors.Medtronic,
  },
]

export const VENDOR_DATA_OVERRIDE_KEYS = [
  'device.batteryVoltage',
  'device.deviceImplantDate',
  'device.batteryStatus',
  'device.batteryMeasurementTime',
  'device.batteryRemainingLongevity',
  'device.batteryPercentRemaining',
  'device.capacitorChargeTime',
  'device.vendorId',
  'egmImage',

  'deviceMeasurement.afBurden',
  'deviceMeasurement.apAPacePercent',
  'deviceMeasurement.rvpRvPacePercent',
  'deviceMeasurement.bvpPercent',
  'deviceMeasurement.lvpPercent',
  'deviceMeasurement.hvImpedance',

  'deviceMeasurement.symptomCounters',
  'deviceMeasurement.symptomTime',
  'deviceMeasurement.tachyCounters',
  'deviceMeasurement.tachyTime',
  'deviceMeasurement.pauseCounters',
  'deviceMeasurement.pauseTime',
  'deviceMeasurement.bradyCounters',
  'deviceMeasurement.bradyTime',
  'deviceMeasurement.atCounters',
  'deviceMeasurement.atTime',
  'deviceMeasurement.afCounters',
  'deviceMeasurement.afTime',
  'deviceMeasurement.atafCounters',
  'deviceMeasurement.atafTime',

  'deviceMeasurement.mode',
  'deviceMeasurement.lowerRate',
  'deviceMeasurement.upperRate',
  'deviceMeasurement.atOrAf',
  'deviceMeasurement.vtZone1',
  'deviceMeasurement.vtZone2',
  'deviceMeasurement.vf',

  'deviceLeads.pulsewidth',
  'deviceLeads.sensing',
  'deviceLeads.impedance',
  'deviceLeads.threshold',
  'deviceLeads.leadModelNumber',
  'deviceLeads.vendorId',
  'vendor.vendorName',
]

export const PROFESSIONAL_OPTIONS_REMOTE = [
  {
    label: '93294',
    value: '93294',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis',
  },
  {
    label: '93295',
    value: '93295',
    description: 'Remote Professional ICD monitoring evaluation analysis',
  },
  {
    label: '93297',
    value: '93297',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis',
  },
  {
    label: '93298',
    value: '93298',
    description: 'Remote Professional ILR monitoring evaluation analysis',
  },
  {
    label: '93297-26',
    value: '93297-26',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis',
  },
  {
    label: '93298-26',
    value: '93298-26',
    description: 'Remote Professional ILR monitoring evaluation analysis',
  },
]

export const PROFESSIONAL_OPTIONS_IN_CLINIC = [
  {
    label: '93260',
    value: '93260',
    description:
      'In-office evaluation of programmable device with iterative adjustments.',
  },
  { label: '93261', value: '93261', description: '' },
  {
    label: '93279',
    value: '93279',
    description: 'In-office SR PM with programming changes',
  },
  {
    label: '93280',
    value: '93280',
    description: 'In-office DR PM with programming changes',
  },
  {
    label: '93281',
    value: '93281',
    description: 'In-office CRT-P with programming changes',
  },
  {
    label: '93282',
    value: '93282',
    description: 'In-office VR ICD with programming changes',
  },
  {
    label: '93283',
    value: '93283',
    description: 'In-office DR ICD with programming changes',
  },
  {
    label: '93284',
    value: '93284',
    description: 'In-office CRT-D with programming changes',
  },
  {
    label: '93285',
    value: '93285',
    description: 'In-office ILR with programming changes',
  },
  { label: '93286', value: '93286', description: '' },
  { label: '93287', value: '93287', description: '' },
  {
    label: '93288',
    value: '93288',
    description: 'In-office Any PM without programming changes',
  },
  {
    label: '93289',
    value: '93289',
    description: 'In-office Any ICD without programming changes',
  },
  { label: '93292', value: '93292', description: '' },
  { label: '93724', value: '93724', description: '' },
]

export const PROFESSIONAL_OPTIONS_MANUAL = [
  { label: '93260', value: '93260', description: '' },
  { label: '93261', value: '93261', description: '' },
  {
    label: '93279',
    value: '93279',
    description: 'In-office SR PM with programming changes',
  },
  {
    label: '93280',
    value: '93280',
    description: 'In-office DR PM with programming changes',
  },
  {
    label: '93281',
    value: '93281',
    description: 'In-office CRT-P with programming changes',
  },
  {
    label: '93282',
    value: '93282',
    description: 'In-office VR ICD with programming changes',
  },
  {
    label: '93283',
    value: '93283',
    description: 'In-office DR ICD with programming changes',
  },
  {
    label: '93284',
    value: '93284',
    description: 'In-office CRT-D with programming changes',
  },
  {
    label: '93285',
    value: '93285',
    description: 'In-office ILR with programming changes',
  },
  { label: '93286', value: '93286', description: '' },
  { label: '93287', value: '93287', description: '' },
  {
    label: '93288',
    value: '93288',
    description: 'In-office Any PM without programming changes',
  },
  {
    label: '93289',
    value: '93289',
    description: 'In-office Any ICD without programming changes',
  },
  { label: '93292', value: '93292', description: '' },
  {
    label: '93294',
    value: '93294',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis',
  },
  {
    label: '93295',
    value: '93295',
    description: 'Remote Professional ICD monitoring evaluation analysis',
  },
  {
    label: '93297',
    value: '93297',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis',
  },
  {
    label: '93298',
    value: '93298',
    description: 'Remote Professional ILR monitoring evaluation analysis',
  },
  {
    label: '93297-26',
    value: '93297-26',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis with professional modifier',
  },
  {
    label: '93298-26',
    value: '93298-26',
    description:
      'Remote Professional ILR monitoring evaluation analysis with professional modifier',
  },
]

export const TECHNICAL_OPTIONS = [
  { label: '-', value: '-', description: '' },
  {
    label: '93296',
    value: '93296',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis and tech support',
  },
  {
    label: '93297-TC',
    value: '93297-TC',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis with Technical modifier',
  },
  {
    label: '93298-TC',
    value: '93298-TC',
    description:
      'Remote Professional ILR monitoring evaluation analysis with Technical modifier',
  },
]

export const TECHNICAL_OPTIONS_ILR = [
  { label: '-', value: '-', description: '' },
  {
    label: '93298-TC',
    value: '93298-TC',
    description:
      'Remote Professional ILR monitoring evaluation analysis with Technical modifier',
  },
  {
    label: '93296',
    value: '93296',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis and tech support',
  },
]

export const TECHNICAL_OPTIONS_HEART_FAILURE = [
  { label: '-', value: '-', description: '' },
  {
    label: '93297-TC',
    value: '93297-TC',
    description:
      'Remote Professional heart failure (HF) monitoring evaluation analysis with Technical modifier',
  },
  {
    label: '93296',
    value: '93296',
    description:
      'Remote Professional pacemaker (PM) monitoring evaluation analysis and tech support',
  },
]

export const DIAGNOSIS_OPTIONS = [
  {
    label: 'Z95.810',
    value: 'Z95.810',
    description: 'Presence of automatic (implantable) cardiac defibrillator',
  },
  {
    label: 'I44.2',
    value: 'I44.2',
    description: 'Atrioventricular block, complete',
  },
  {
    label: 'I44.0',
    value: 'I44.0',
    description: 'Atrioventricular block, first degree',
  },
  {
    label: 'I44.1',
    value: 'I44.1',
    description: 'Atrioventricular block, second degree',
  },

  {
    label: 'I48.4',
    value: 'I48.4',
    description: 'Atypical atrial flutter',
  },
  {
    label: 'R00.1',
    value: 'R00.1',
    description: 'Bradycardia, unspecified',
  },
  {
    label: 'I46.8',
    value: 'I46.8',
    description: 'Cardiac arrest due to other underlying condition',
  },
  {
    label: 'I46.2',
    value: 'I46.2',
    description: 'Cardiac arrest due to underlying cardiac condition',
  },
  {
    label: 'I42.7',
    value: 'I42.7',
    description: 'Cardiomyopathy due to drug and external agent',
  },
  {
    label: 'I43',
    value: 'I43',
    description: 'Cardiomyopathy in diseases classified elsewhere',
  },
  {
    label: 'G90.01',
    value: 'G90.01',
    description: 'Carotid sinus syncope',
  },
  {
    label: 'R07.9',
    value: 'R07.9',
    description: 'Chest pain, unspecified',
  },
  {
    label: 'I48.20',
    value: 'I48.20',
    description: 'Chronic atrial fibrillation, unspecified',
  },
  {
    label: 'I42.0',
    value: 'I42.0',
    description: 'Dilated cardiomyopathy',
  },
  {
    label: 'R42',
    value: 'R42',
    description: 'Dizziness and giddiness',
  },
  {
    label: 'I25.5',
    value: 'I25.5',
    description: 'Ischemic cardiomyopathy',
  },
  {
    label: 'I45.81',
    value: 'I45.81',
    description: 'Long QT syndrome',
  },
  {
    label: 'I48.11',
    value: 'I48.11',
    description: 'Longstanding persistent atrial fibrillation',
  },
  {
    label: 'I42.1',
    value: 'I42.1',
    description: 'Obstructive hypertrophic cardiomyopathy',
  },
  {
    label: 'I44.39',
    value: 'I44.39',
    description: 'Other atrioventricular block',
  },
  {
    label: 'I42.8',
    value: 'I42.8',
    description: 'Other cardiomyopathies',
  },
  {
    label: 'R07.89',
    value: 'R07.89',
    description: 'Other chest pain',
  },
  {
    label: 'I24.8',
    value: 'I24.8',
    description: 'Other forms of acute ischemic heart disease',
  },
  {
    label: 'I42.2',
    value: 'I42.2',
    description: 'Other hypertrophic cardiomyopathy',
  },
  {
    label: 'I48.19',
    value: 'I48.19',
    description: 'Other persistent atrial fibrillation',
  },
  {
    label: 'I45.19',
    value: 'I45.19',
    description: 'Other right bundle-branch block',
  },
  {
    label: 'I49.8',
    value: 'I49.8',
    description: 'Other specified cardiac arrhythmias',
  },
  {
    label: 'I45.89',
    value: 'I45.89',
    description: 'Other specified conduction disorders',
  },
  {
    label: 'G45.8',
    value: 'G45.8',
    description:
      'Other transient cerebral ischemic attacks and related syndromes',
  },
  {
    label: 'I47.29',
    value: 'I47.29',
    description: 'Other ventricular tachycardia',
  },
  {
    label: 'R00.2',
    value: 'R00.2',
    description: 'Palpitations',
  },
  {
    label: 'I48.0',
    value: 'I48.0',
    description: 'Paroxysmal atrial fibrillation',
  },
  {
    label: 'I48.21',
    value: 'I48.21',
    description: 'Permanent atrial fibrillation',
  },
  {
    label: 'Z86.73',
    value: 'Z86.73',
    description:
      'Personal history of transient ischemic attack (TIA), and cerebral infarction without residual deficits',
  },
  {
    label: 'I49.5',
    value: 'I49.5',
    description: 'Sick sinus syndrome',
  },
  {
    label: 'I47.1',
    value: 'I47.1',
    description: 'Supraventricular tachycardia',
  },
  {
    label: 'R55',
    value: 'R55',
    description: 'Syncope and collapse',
  },
  {
    label: 'R00.0',
    value: 'R00.0',
    description: 'Tachycardia, unspecified',
  },
  {
    label: 'I47.21',
    value: 'I47.21',
    description: 'Torsades de pointes',
  },
  {
    label: 'I48.3',
    value: 'I48.3',
    description: 'Typical atrial flutter',
  },
  {
    label: 'I48.91',
    value: 'I48.91',
    description: 'Unspecified atrial fibrillation',
  },
  {
    label: 'I48.92',
    value: 'I48.92',
    description: 'Unspecified atrial flutter',
  },
  {
    label: 'I49.01',
    value: 'I49.01',
    description: 'Ventricular fibrillation',
  },
  {
    label: 'I49.02',
    value: 'I49.02',
    description: 'Ventricular flutter',
  },
  {
    label: 'I49.3',
    value: 'I49.3',
    description: 'Ventricular premature depolarization',
  },
  {
    label: 'I42.6',
    value: 'I42.6',
    description: 'Alcoholic cardiomyopathy',
  },
  {
    label: 'G45.3',
    value: 'G45.3',
    description: 'Amaurosis fugax',
  },
  {
    label: 'I25.3',
    value: 'I25.3',
    description: 'Aneurysm of heart',
  },
  {
    label: 'I20.1',
    value: 'I20.1',
    description: 'Angina pectoris with documented spasm',
  },
  {
    label: 'I25.721',
    value: 'I25.721',
    description:
      'Atherosclerosis of autologous artery coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.728',
    value: 'I25.728',
    description:
      'Atherosclerosis of autologous artery coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.722',
    value: 'I25.722',
    description:
      'Atherosclerosis of autologous artery coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.720',
    value: 'I25.720',
    description:
      'Atherosclerosis of autologous artery coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.711',
    value: 'I25.711',
    description:
      'Atherosclerosis of autologous vein coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.718',
    value: 'I25.718',
    description:
      'Atherosclerosis of autologous vein coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.712',
    value: 'I25.712',
    description:
      'Atherosclerosis of autologous vein coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.710',
    value: 'I25.710',
    description:
      'Atherosclerosis of autologous vein coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.761',
    value: 'I25.761',
    description:
      'Atherosclerosis of bypass graft of coronary artery of transplanted heart with angina pectoris with documented spasm',
  },
  {
    label: 'I25.768',
    value: 'I25.768',
    description:
      'Atherosclerosis of bypass graft of coronary artery of transplanted heart with other forms of angina pectoris',
  },
  {
    label: 'I25.762',
    value: 'I25.762',
    description:
      'Atherosclerosis of bypass graft of coronary artery of transplanted heart with refractory angina pectoris',
  },
  {
    label: 'I25.760',
    value: 'I25.760',
    description:
      'Atherosclerosis of bypass graft of coronary artery of transplanted heart with unstable angina',
  },
  {
    label: 'I25.812',
    value: 'I25.812',
    description:
      'Atherosclerosis of bypass graft of coronary artery of transplanted heart without angina pectoris',
  },
  {
    label: 'I25.810',
    value: 'I25.810',
    description:
      'Atherosclerosis of coronary artery bypass graft(s) without angina pectoris',
  },
  {
    label: 'I25.751',
    value: 'I25.751',
    description:
      'Atherosclerosis of native coronary artery of transplanted heart with angina pectoris with documented spasm',
  },
  {
    label: 'I25.750',
    value: 'I25.750',
    description:
      'Atherosclerosis of native coronary artery of transplanted heart with unstable angina',
  },
  {
    label: 'I25.811',
    value: 'I25.811',
    description:
      'Atherosclerosis of native coronary artery of transplanted heart without angina pectoris',
  },
  {
    label: 'I25.731',
    value: 'I25.731',
    description:
      'Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.738',
    value: 'I25.738',
    description:
      'Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.732',
    value: 'I25.732',
    description:
      'Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.730',
    value: 'I25.730',
    description:
      'Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.791',
    value: 'I25.791',
    description:
      'Atherosclerosis of other coronary artery bypass graft(s) with angina pectoris with documented spasm',
  },
  {
    label: 'I25.798',
    value: 'I25.798',
    description:
      'Atherosclerosis of other coronary artery bypass graft(s) with other forms of angina pectoris',
  },
  {
    label: 'I25.792',
    value: 'I25.792',
    description:
      'Atherosclerosis of other coronary artery bypass graft(s) with refractory angina pectoris',
  },
  {
    label: 'I25.790',
    value: 'I25.790',
    description:
      'Atherosclerosis of other coronary artery bypass graft(s) with unstable angina pectoris',
  },
  {
    label: 'I25.111',
    value: 'I25.111',
    description:
      'Atherosclerotic heart disease of native coronary artery with angina pectoris with documented spasm',
  },
  {
    label: 'I25.118',
    value: 'I25.118',
    description:
      'Atherosclerotic heart disease of native coronary artery with other forms of angina pectoris',
  },
  {
    label: 'I25.110',
    value: 'I25.110',
    description:
      'Atherosclerotic heart disease of native coronary artery with unstable angina pectoris',
  },
  {
    label: 'I25.10',
    value: 'I25.10',
    description:
      'Atherosclerotic heart disease of native coronary artery without angina pectoris',
  },
  {
    label: 'I49.1',
    value: 'I49.1',
    description: 'Atrial premature depolarization',
  },
  {
    label: 'I45.2',
    value: 'I45.2',
    description: 'Bifascicular block',
  },
  {
    label: 'I42.9',
    value: 'I42.9',
    description: 'Cardiomyopathy, unspecified',
  },
  {
    label: 'I63.9',
    value: 'I63.9',
    description: 'Cerebral infarction, unspecified',
  },
  {
    label: 'I67.9',
    value: 'I67.9',
    description: 'Cerebrovascular disease, unspecified',
  },
  {
    label: 'R07.1',
    value: 'R07.1',
    description: 'Chest pain on breathing',
  },
  {
    label: 'I50.42',
    value: 'I50.42',
    description: 'Chronic combined systolic/diastolic HF (congestive)',
  },
  {
    label: 'I50.22',
    value: 'I50.22',
    description: 'Chronic systolic (Congestive) HF',
  },
  {
    label: 'I25.82',
    value: 'I25.82',
    description: 'Chronic total occlusion of coronary artery',
  },
  {
    label: 'I25.41',
    value: 'I25.41',
    description: 'Coronary artery aneurysm',
  },
  {
    label: 'I25.84',
    value: 'I25.84',
    description: 'Coronary atherosclerosis due to calcified coronary lesion',
  },
  {
    label: 'I25.83',
    value: 'I25.83',
    description: 'Coronary atherosclerosis due to lipid-rich plaque',
  },
  {
    label: 'R06.00',
    value: 'R06.00',
    description: 'Dyspnea, unspecified',
  },
  {
    label: 'I42.4',
    value: 'I42.4',
    description: 'Endocardial fibroelastosis',
  },
  {
    label: 'I42.3',
    value: 'I42.3',
    description: 'Endomyocardial (eosinophilic) disease',
  },
  {
    label: 'R10.13',
    value: 'R10.13',
    description: 'Epigastric pain',
  },
  {
    label: 'I49.2',
    value: 'I49.2',
    description: 'Junctional premature depolarization',
  },
  {
    label: 'I44.4',
    value: 'I44.4',
    description: 'Left anterior fascicular block',
  },
  {
    label: 'I44.5',
    value: 'I44.5',
    description: 'Left posterior fascicular block',
  },
  {
    label: 'I21.A1',
    value: 'I21.A1',
    description: 'Myocardial infarction type 2',
  },
  {
    label: 'I5A',
    value: 'I5A',
    description: 'Non-ischemic myocardial injury (non-traumatic)',
  },
  {
    label: 'I21.4',
    value: 'I21.4',
    description: 'Non-ST elevation (NSTEMI) myocardial infarction',
  },
  {
    label: 'I25.2',
    value: 'I25.2',
    description: 'Old myocardial infarction',
  },
  {
    label: 'R06.01',
    value: 'R06.01',
    description: 'Orthopnea',
  },
  {
    label: 'R00.8',
    value: 'R00.8',
    description: 'Other abnormalities of heart beat',
  },
  {
    label: 'I44.69',
    value: 'I44.69',
    description: 'Other fascicular block',
  },
  {
    label: 'I20.8',
    value: 'I20.8',
    description: 'Other forms of angina pectoris',
  },
  {
    label: 'I25.89',
    value: 'I25.89',
    description: 'Other forms of chronic ischemic heart disease',
  },
  {
    label: 'R06.09',
    value: 'R06.09',
    description: 'Other forms of dyspnea',
  },
  {
    label: 'I21.A9',
    value: 'I21.A9',
    description: 'Other myocardial infarction type',
  },
  {
    label: 'I97.190',
    value: 'I97.190',
    description:
      'Other postprocedural cardiac functional disturbances following cardiac surgery',
  },
  {
    label: 'I97.191',
    value: 'I97.191',
    description:
      'Other postprocedural cardiac functional disturbances following other surgery',
  },
  {
    label: 'I49.49',
    value: 'I49.49',
    description: 'Other premature depolarization',
  },
  {
    label: 'I42.5',
    value: 'I42.5',
    description: 'Other restrictive cardiomyopathy',
  },
  {
    label: 'I45.5',
    value: 'I45.5',
    description: 'Other specified heart block',
  },
  {
    label: 'Z86.74',
    value: 'Z86.74',
    description: 'Personal history of sudden cardiac arrest',
  },
  {
    label: 'I23.7',
    value: 'I23.7',
    description: 'Postinfarction angina',
  },
  {
    label: 'I97.120',
    value: 'I97.120',
    description: 'Postprocedural cardiac arrest following cardiac surgery',
  },
  {
    label: 'I97.121',
    value: 'I97.121',
    description: 'Postprocedural cardiac arrest following other surgery',
  },
  {
    label: 'I45.6',
    value: 'I45.6',
    description: 'Pre-excitation syndrome',
  },
  {
    label: 'R07.2',
    value: 'R07.2',
    description: 'Precordial pain',
  },
  {
    label: 'I47.0',
    value: 'I47.0',
    description: 'Re-entry ventricular arrhythmia',
  },
  {
    label: 'I45.0',
    value: 'I45.0',
    description: 'Right fascicular block',
  },
  {
    label: 'R06.02',
    value: 'R06.02',
    description: 'Shortness of breath',
  },
  {
    label: 'I25.6',
    value: 'I25.6',
    description: 'Silent myocardial ischemia',
  },
  {
    label: 'I21.02',
    value: 'I21.02',
    description:
      'ST elevation (STEMI) myocardial infarction involving left anterior descending coronary artery',
  },
  {
    label: 'I21.21',
    value: 'I21.21',
    description:
      'ST elevation (STEMI) myocardial infarction involving left circumflex coronary artery',
  },
  {
    label: 'I21.01',
    value: 'I21.01',
    description:
      'ST elevation (STEMI) myocardial infarction involving left main coronary artery',
  },
  {
    label: 'I21.09',
    value: 'I21.09',
    description:
      'ST elevation (STEMI) myocardial infarction involving other coronary artery of anterior wall',
  },
  {
    label: 'I21.19',
    value: 'I21.19',
    description:
      'ST elevation (STEMI) myocardial infarction involving other coronary artery of inferior wall',
  },
  {
    label: 'I21.29',
    value: 'I21.29',
    description:
      'ST elevation (STEMI) myocardial infarction involving other sites',
  },
  {
    label: 'I21.11',
    value: 'I21.11',
    description:
      'ST elevation (STEMI) myocardial infarction involving right coronary artery',
  },
  {
    label: 'I22.2',
    value: 'I22.2',
    description: 'Subsequent non-ST elevation (NSTEMI) myocardial infarction',
  },
  {
    label: 'I22.0',
    value: 'I22.0',
    description:
      'Subsequent ST elevation (STEMI) myocardial infarction of anterior wall',
  },
  {
    label: 'I22.1',
    value: 'I22.1',
    description:
      'Subsequent ST elevation (STEMI) myocardial infarction of inferior wall',
  },
  {
    label: 'I22.8',
    value: 'I22.8',
    description:
      'Subsequent ST elevation (STEMI) myocardial infarction of other sites',
  },
  {
    label: 'R40.4',
    value: 'R40.4',
    description: 'Transient alteration of awareness',
  },
  {
    label: 'G45.9',
    value: 'G45.9',
    description: 'Transient cerebral ischemic attack, unspecified',
  },
  {
    label: 'I45.3',
    value: 'I45.3',
    description: 'Trifascicular block',
  },
  {
    label: 'I20.0',
    value: 'I20.0',
    description: 'Unstable angina',
  },
  {
    label: 'I44.60',
    value: 'I44.60',
    description: 'Unspecified fascicular block',
  },
  {
    label: 'I44.7',
    value: 'I44.7',
    description: 'Left bundle-branch block, unspecified',
  },
  {
    label: 'I45.10',
    value: 'I45.10',
    description: 'Unspecified right bundle-branch block',
  },
  {
    label: 'I45.9',
    value: 'I45.9',
    description: 'Conduction disorder, unspecified',
  },
  {
    label: 'I46.9',
    value: 'I46.9',
    description: 'Cardiac arrest, cause unspecified',
  },
  {
    label: 'I47.11',
    value: 'I47.11',
    description: 'Inappropriate sinus tachycardia, so stated',
  },
  {
    label: 'I47.19',
    value: 'I47.19',
    description: 'Other supraventricular tachycardia',
  },
  {
    label: 'I47.9',
    value: 'I47.9',
    description: 'Paroxysmal tachycardia, unspecified',
  },
  {
    label: 'I50.1',
    value: 'I50.1',
    description: 'Left ventricular failure, unspecified',
  },
  {
    label: 'I50.20',
    value: 'I50.20',
    description: 'Unspecified systolic (congestive) heart failure',
  },
  {
    label: 'I50.21',
    value: 'I50.21',
    description: 'Acute systolic (congestive) heart failure',
  },
  {
    label: 'I50.23',
    value: 'I50.23',
    description: 'Acute on chronic systolic (congestive) heart failure',
  },
  {
    label: 'I50.41',
    value: 'I50.41',
    description:
      'Acute combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    label: 'I50.43',
    value: 'I50.43',
    description:
      'Acute on chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    label: 'I50.810',
    value: 'I50.810',
    description: 'Right heart failure, unspecified',
  },
  {
    label: 'I50.811',
    value: 'I50.811',
    description: 'Acute right heart failure',
  },
  {
    label: 'I50.812',
    value: 'I50.812',
    description: 'Chronic right heart failure',
  },
  {
    label: 'I50.813',
    value: 'I50.813',
    description: 'Acute on chronic right heart failure',
  },
  {
    label: 'I50.814',
    value: 'I50.814',
    description: 'Right heart failure due to left heart failure',
  },
  {
    label: 'I50.82',
    value: 'I50.82',
    description: 'Biventricular heart failure',
  },
  {
    label: 'I50.83',
    value: 'I50.83',
    description: 'High output heart failure',
  },
  {
    label: 'I50.84',
    value: 'I50.84',
    description: 'End stage heart failure',
  },
  {
    label: 'I50.89',
    value: 'I50.89',
    description: 'Other heart failure',
  },
  {
    label: 'I50.9',
    value: 'I50.9',
    description: 'Heart failure, unspecified',
  },
  {
    label: 'Z45.010',
    value: 'Z45.010',
    description:
      'Encounter for checking and testing of cardiac pacemaker pulse generator [battery]',
  },
  {
    label: 'Z45.018',
    value: 'Z45.018',
    description:
      'Encounter for adjustment and management of other part of cardiac pacemaker',
  },
  {
    label: 'Z45.09',
    value: 'Z45.09',
    description:
      'Encounter for adjustment and management of other cardiac device',
  },
  {
    label: 'Z95.0',
    value: 'Z95.0',
    description: 'Presence of cardiac pacemaker',
  },
  {
    label: 'Z95.818',
    value: 'Z95.818',
    description: 'Presence of other cardiac implants and grafts',
  },
  {
    label: 'Z95.9',
    value: 'Z95.9',
    description:
      'Presence of cardiac and vascular implant and graft, unspecified',
  },
  {
    label: 'I21.3',
    value: 'I21.3',
    description:
      'ST elevation (STEMI) myocardial infarction of unspecified site',
  },
  {
    label: 'I21.9',
    value: 'I21.9',
    description: 'Acute myocardial infarction, unspecified',
  },
  {
    label: 'I22.9',
    value: 'I22.9',
    description:
      'Subsequent ST elevation (STEMI) myocardial infarction of unspecified site',
  },
  {
    label: 'I25.9',
    value: 'I25.9',
    description: 'Chronic ischemic heart disease, unspecified',
  },
  {
    label: 'I49.9',
    value: 'I49.9',
    description: 'Cardiac arrhythmia, unspecified',
  },
  {
    label: 'R93.89',
    value: 'R93.89',
    description:
      'Abnormal findings on diagnostic imaging of other specified body structures',
  },
  {
    label: 'R93.1',
    value: 'R93.1',
    description:
      'Abnormal findings on diagnostic imaging of heart and coronary circulation',
  },
  { label: 'G47.30', value: 'G47.30', description: 'Sleep apnea, unspecified' },
  {
    label: 'Z45.02',
    value: 'Z45.02',
    description:
      'Encounter for adjustment and management of automatic implantable cardiac defibrillator',
  },
  {
    label: '-',
    value: '-',
  },
]

export const diagnosisDefaultValueByDeviceType = (deviceType: any): string => {
  if (deviceType === '' || deviceType === undefined) {
    return ''
  }
  if (deviceType === 'CRT-P' || deviceType === 'PM') {
    return 'Z45.018'
  } else if (deviceType === 'ILR') {
    return 'Z95.818'
  } else if (deviceType === 'ICD' || deviceType === 'CRT-D') {
    return 'Z95.810'
  } else return ''
}

export enum AppDeterminedBillableReasons {
  CALCULATED = 'calculated',
  SIXTY_TWENTY_RULE = '60/20',
}

export const PROFESSIONAL_OPTIONS_IN_CLINIC_2 = (deviceType: string) => {
  const pacemakerCodes = [
    {
      description:
        'Programming device evaluation (single lead or leadless pacemaker)',
      label: '93279',
      value: '93279',
    },
    {
      description: 'Programming device evaluation (dual lead pacemaker)',
      value: '93280',
      label: '93280',
    },
    {
      description: 'Programming device evaluation (multiple lead pacemaker)',
      value: '93281',
      label: '93281',
    },
    {
      description: 'Peri-procedural evaluation (pacemaker)',
      value: '93286',
      label: '93286',
    },
    {
      description: 'Interrogation evaluation (pacemaker)',
      value: '93288',
      label: '93288',
    },
  ]

  const icdCodes = [
    {
      description: 'Programming evaluation (subcutaneous lead defibrillator)',
      value: '93260',
      label: '93260',
    },
    {
      description: 'Interrogation evaluation (subcutaneous lead defibrillator)',
      value: '93261',
      label: '93261',
    },
    {
      description: 'Programming evaluation (single lead ICD)',
      value: '93282',
      label: '93282',
    },
    {
      description: 'Programming evaluation (dual lead ICD)',
      value: '93283',
      label: '93283',
    },
    {
      description: 'Programming evaluation (multiple lead ICD)',
      value: '93284',
      label: '93284',
    },
    {
      description: 'Peri-procedural evaluation (ICD)',
      value: '93287',
      label: '93287',
    },
    {
      description: 'Interrogation evaluation (ICD)',
      value: '93289',
      label: '93289',
    },
  ]

  const subcutaneousCodes = [
    {
      description:
        'Programming evaluation (subcutaneous cardiac rhythm monitor)',
      value: '93285',
      label: '93285',
    },
    {
      description:
        'Interrogation evaluation (subcutaneous cardiac rhythm monitor)',
      value: '93291',
      label: '93291',
    },
  ]

  const ilrCodes = [
    {
      description: 'Interrogation evaluation (ILR)',
      value: '93290',
      label: '93290',
    },
  ]

  // Based on device type, filter the CPT codes
  switch (deviceType) {
    case 'ILR':
      return [...ilrCodes]
    case 'PM':
      return [...pacemakerCodes, ...subcutaneousCodes]
    case 'ICD':
      return [...icdCodes, ...subcutaneousCodes]
    case 'CRT-D':
    case 'CRT-P':
      return [...pacemakerCodes, ...icdCodes, ...subcutaneousCodes]
    default:
      return []
  }
}
