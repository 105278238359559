import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'

export const useGetSetting = ({ settingName }: { settingName: string }) => {
  const Api = useApiContext()
  return useQuery(
    ['getSetting'],
    async () => {
      const res = await Api.settingControllerGetSetting({
        name: settingName,
      })
      return res.data
    },
    {
      enabled: !!settingName,
    },
  )
}
