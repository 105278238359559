import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'

import { RhythmThemeProvider, Text } from '@rhythm/components'

import '@rhythm/components/dist/styles.css'

import App from './App'
import { defaultConfig, globalConfig, globalConfigUrl } from './config'
import reportWebVitals from './reportWebVitals'
import loadDayJsPlugins from './utils/loadDayJsPlugins'

loadDayJsPlugins()

const app: ReactElement = (
  <React.StrictMode>
    <RhythmThemeProvider>
      <App />
    </RhythmThemeProvider>
  </React.StrictMode>
)

fetch(globalConfigUrl)
  .then(response => response.json())

  .then(data => {
    globalConfig.set(data)

    return app
  })

  .catch(e => {
    if (process.env.NODE_ENV === 'development') {
      // You cannot change the value of NODE_ENV. To test this if, change "development"

      console.warn(
        // eslint-disable-next-line max-len

        `Failed to load global configuration from '${globalConfigUrl}', using the default configuration instead:`,

        defaultConfig,
      )

      globalConfig.set(defaultConfig)

      return app
    } else {
      const errorMessage =
        // eslint-disable-next-line max-len

        'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)'

      console.error(
        errorMessage,
        `Have you provided the config file '${globalConfigUrl}'?`,
        e,
      )

      return (
        <Text style={{ color: 'red', textAlign: 'center' }}>
          {errorMessage}
        </Text>
      )
    }
  })

  .then((reactElement: ReactElement) => {
    ReactDOM.render(reactElement, document.getElementById('root'))
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
