import { useMemo } from 'react'

import {
  Box,
  DataTable,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  TableCell,
  TableHeader,
  TableRow,
  useToast,
} from '@rhythm/components'

import CustomPagination from '../../../../components/CustomPagination/CustomPagination'
import {
  QueryParams,
  useAnalyticsContext,
} from '../../../../context/AnalyticsContext'

import { ActiveDot } from './constants'
import { NoResultsFound } from './NoResultsFound'

const DEFAULT_PAGE_SIZE = 50

export const AnalyticsDataTable = ({
  columns,
  data: tableData,
  isLoading,
  isRefetching,
}: {
  columns: any
  data: any
  isLoading: boolean
  isRefetching?: boolean
}) => {
  const toast = useToast()

  const { activeAnalyticsQueryParams, updateActiveAnalyticsQueryParams } =
    useAnalyticsContext() as {
      activeAnalyticsQueryParams: QueryParams
      updateActiveAnalyticsQueryParams: (
        update: QueryParams | ((prev: QueryParams) => QueryParams),
        isOffsetUpdated?: boolean,
      ) => void
    }
  const { limit, offset } = activeAnalyticsQueryParams
  const currentPage = useMemo(() => {
    const page = Math.floor((offset || 0) / (limit || DEFAULT_PAGE_SIZE)) + 1
    return page
  }, [limit, offset])
  const totalRowCount = tableData?.pagination?.total || 0
  const totalPageCount = useMemo(() => {
    const count = Math.ceil(
      totalRowCount / (activeAnalyticsQueryParams.limit || DEFAULT_PAGE_SIZE),
    )
    return isNaN(count) ? 0 : count
  }, [activeAnalyticsQueryParams.limit, totalRowCount])

  const orderBy = useMemo(() => {
    const orderBy = activeAnalyticsQueryParams.orderBy
    if (orderBy) {
      return (orderBy as any)['1']
    }
    return null
  }, [activeAnalyticsQueryParams.orderBy])

  if (isLoading) {
    return (
      <Box width={'calc(97vw - 340px)'} height={'calc(100vh - 350px)'}>
        <Spinner
          size="xl"
          position="relative"
          top="50%"
          left="50%"
          zIndex="1"
          color="primary.500"
        />
      </Box>
    )
  }

  const CustomHeaderIconButton = ({ id }: { id: string }) => (
    <Menu autoSelect={false}>
      <MenuButton position={'relative'} onClick={() => console.log('id', id)}>
        <IconButton
          variant="ghost"
          size={'sm'}
          aria-label="Refresh Data"
          icon={'drop-down'}
        />
        {id === orderBy?.field && (
          <ActiveDot sx={{ position: 'absolute', right: '0' }} />
        )}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={id === orderBy?.field ? orderBy?.direction : undefined}
          title="Order"
          type="radio"
          onClick={() => console.log('das')}
          onChange={value => {
            let orderObj: any = null
            if (value) {
              orderObj = {
                '1': { field: id, direction: value },
              }
            }
            updateActiveAnalyticsQueryParams(prev => {
              const { orderBy, ...rest } = prev
              return {
                ...rest,
                ...(orderObj ? { orderBy: orderObj } : {}),
              }
            })
          }}
        >
          {id === orderBy?.field && (
            <MenuItemOption value="" color={'red.400'}>
              Clear
            </MenuItemOption>
          )}
          <MenuItemOption value="asc">Ascending</MenuItemOption>
          <MenuItemOption value="desc">Descending</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )

  if (tableData?.data?.length === 0 && !isLoading && !isRefetching) {
    return <NoResultsFound />
  }

  return (
    <Box>
      <Box
        width={'calc(97vw - 340px)'}
        height={'calc(100vh - 350px)'}
        overflow={'hidden'}
        _hover={{
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.55em',
            height: '0.55em',
            cursor: 'pointer',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.400',
            cursor: 'pointer',
            borderRadius: '0.25em',
            '&:hover': {
              background: 'gray.500',
            },
          },
        }}
        __css={{
          thead: {
            position: 'sticky',
            top: '0px',
            margin: '0 0 0 0',
            display: 'grid',
            zIndex: '1',
          },
          'tr:nth-of-type(odd) td': {
            // they are being overridden by the chakra styles
            background: 'var(--chakra-colors-gray-50) !important',
            borderColor: 'var(--chakra-colors-gray-50) !important',
          },
          tr: {
            borderBottomColor: 'var(--chakra-colors-gray-50) !important',
          },
        }}
      >
        <DataTable
          // hasPagination
          columns={columns}
          variant="striped"
          isLoading={isRefetching}
          data={tableData?.data ?? []}
          totalRowCount={totalRowCount}
          totalPageCount={totalPageCount}
          initialPageSize={DEFAULT_PAGE_SIZE}
          initialPageIndex={0}
          tableBodyCell={<TableCell px="lg" py="xl" />}
          tableBodyRow={() => (
            <TableRow
              borderBottomColor="neutral.200"
              borderBottomStyle="solid"
              borderBottomWidth="2px"
              bg="neutral.white"
              borderRadius="4px"
              position="relative"
              sx={{
                'td:first-of-type': {
                  borderRadius: '4px 0 0 4px',
                },
                'td:last-of-type': {
                  borderRadius: '0 4px 4px 0',
                },
              }}
            />
          )}
          tableHeadCell={
            <TableHeader
              color="neutral.800"
              bg="neutral.white"
              borderBottomColor="neutral.200"
              borderBottomStyle="solid"
              borderBottomWidth="2px"
              px="lg"
              height={'50px'}
              display={'flex'}
              alignItems={'center'}
            />
          }
          customHeaderIconButton={({ id }) => (
            <CustomHeaderIconButton id={id} />
          )}
        />
      </Box>
      <CustomPagination
        currentPage={currentPage}
        rowsPerPage={activeAnalyticsQueryParams.limit ?? DEFAULT_PAGE_SIZE}
        startIndex={offset ?? 0}
        totalPageCount={totalPageCount}
        setPageIndex={(data: number) => {
          updateActiveAnalyticsQueryParams(
            {
              ...activeAnalyticsQueryParams,
              offset: (data - 1) * (limit ?? DEFAULT_PAGE_SIZE),
            },
            true,
          )
        }}
        setRowsPerPage={(data: number) => {
          if (data === totalRowCount && totalRowCount > 100) {
            toast({
              title: 'Warning',
              description:
                'You are trying to fetch all the records at once. Please wait while we fetch the data.',
              status: 'warning',
              duration: 5000,
              isClosable: true,
            })
          }
          updateActiveAnalyticsQueryParams({
            ...activeAnalyticsQueryParams,
            limit: data,
            offset: 0,
          })
        }}
        total={tableData?.pagination?.total || 0}
        style={{}}
      />
    </Box>
  )
}
