import React from 'react'

import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  Stack,
  Text,
} from '@rhythm/components'

import { Device, Patient } from '../../../../../types'
import displayValue from '../../../../../utils/displayValue'

import PriorityTagSelector from './PriorityTagSelector'

export interface EditPatientDetailsModalProps {
  patient: Patient
  device: Device[]
  isOpen: boolean
  onClose: () => void
  onSave: () => void
}

const EditPatientDetailsModal = ({
  patient,
  device,
  isOpen,
  onClose,
  onSave,
}: EditPatientDetailsModalProps): React.ReactElement => {
  return (
    <Modal
      width="480px"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save Changes</Button>
        </HStack>
      }
    >
      <HStack spacing="xl" mb="4xl">
        <Avatar
          name={`${patient.givenName} ${patient.familyName}`}
          boxSize="56px"
        />
        <Stack spacing="sm">
          <Text fontWeight="bold">{`${patient.givenName} ${patient.familyName}`}</Text>
          <Heading variant="h4">Edit Patient Details</Heading>
        </Stack>
      </HStack>

      <Flex mb="3xl">
        <PriorityTagSelector defaultValue="" onChange={() => {}} />
      </Flex>

      <Stack spacing="2xl">
        <FormControl>
          <FormLabel>MRN</FormLabel>
          <Input value={patient.mrn2} isDisabled />
        </FormControl>
        <FormControl>
          <FormLabel>Device</FormLabel>
          <Input value={device[0]?.model} isDisabled />
        </FormControl>
        e
        <FormControl>
          <FormLabel>Insurance Carrier</FormLabel>
          <Input value={displayValue(patient.insuranceCarrier)} isDisabled />
        </FormControl>
      </Stack>
    </Modal>
  )
}

export default EditPatientDetailsModal
