import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import { DefaultApiTransmissionReportsControllerDeleteAlertImageRequest } from '../../../../lib/api'
import { queryClient } from '../../../../lib/api/react-query'

export const useDeleteTransmissionReportAlertImage = () => {
  const Api = useApiContext()
  return useMutation(
    (
      transmissionReportDeleteAlertImageParams: DefaultApiTransmissionReportsControllerDeleteAlertImageRequest,
    ) =>
      Api.transmissionReportsControllerDeleteAlertImage(
        transmissionReportDeleteAlertImageParams,
      ),
    {
      onSuccess: (_, variables) => {
        const { transmissionReportId } = variables
        queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReportId,
        ])
      },
    },
  )
}
