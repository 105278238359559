import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'

const loadDayJsPlugins = () => {
  dayjs.extend(isBetween)
  dayjs.extend(isSameOrAfter)
  dayjs.extend(utc)
}

export default loadDayJsPlugins
