import React, { useState } from 'react'

import {
  Avatar,
  Badge,
  Card,
  Flex,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@rhythm/components'
import { capitalize } from 'lodash'

import AttributeDisplay from '../../../../../components/AttributeDisplay'
import PatientStatusModal from '../../../../../features/patients/components/PatientForm/PatientStatusModal'
import {
  Device,
  Patient,
  PatientActiveInactiveStatus,
  PatientActiveStatusEnum,
} from '../../../../../types'

import PatientStatusBadge from './PatientStatusBadge'

export const defaultActiveInactiveStatus: PatientActiveInactiveStatus = {
  status: PatientActiveStatusEnum.Active,
  reason: null,
  note: null,
}

export interface PatientDetailsCardProps {
  patient: Patient
  device: Device[]
  refetchPatient: () => void
  isManagedInRS: boolean
  isLoadingOrgData: boolean
}

export const getPatientStatus = (status?: string) => {
  if (!status || !['Paused', 'Inactive'].includes(status))
    return PatientActiveStatusEnum.Active
  else if (status === 'Paused') return PatientActiveStatusEnum.Paused
  return PatientActiveStatusEnum.Inactive
}

export const DEFAULT_NOTES =
  'This patient is clinically managed outside of RhythmSynergy'

const PatientDetailsCard = ({
  patient,
  device,
  refetchPatient,
  isManagedInRS,
  isLoadingOrgData,
}: PatientDetailsCardProps): React.ReactElement => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)

  const getStatusNotes = () => {
    let statusNotes = patient.statusNotes ?? ''

    if (patient.statusNotes !== undefined && patient.statusNotes !== null) {
      statusNotes = patient.statusNotes ?? ''
    } else {
      if (!isManagedInRS) {
        statusNotes = DEFAULT_NOTES
      } else {
        statusNotes = ''
      }
    }
    return statusNotes
  }

  return (
    <Card>
      <Stack alignItems="flex-start" spacing="lg" mb="2xl">
        <Flex justifyContent="space-between" width="100%">
          <Avatar
            name={`${patient.givenName} ${patient.familyName}`}
            boxSize="56px"
          />
          <Icon icon="edit" boxSize="sm" color="neutral.800" />
        </Flex>
        <Heading variant="h3">{`${patient.givenName} ${patient.familyName}`}</Heading>

        {patient.priority && <Badge variant="error">{patient.priority}</Badge>}
      </Stack>
      <Stack spacing="20px">
        <VStack alignItems="flex-start" spacing="sm" mb="sm">
          {patient.patientStatus && (
            <HStack alignItems="flex-start" spacing="md" mb="sm">
              <PatientStatusBadge
                patientStatus={getPatientStatus(patient?.patientStatus)}
              />
              <Icon icon="edit" boxSize="sm" color="neutral.800" />
            </HStack>
          )}
          {patient?.patientStatus?.toUpperCase() ===
            PatientActiveStatusEnum.Inactive && (
            <HStack alignItems="flex-start">
              <Text fontSize={'12px'} color={'#455468'} fontWeight="bold">
                Reason:
              </Text>
              <Text fontSize={'12px'} fontWeight="normal">
                {capitalize(patient?.statusReason ?? '')}
              </Text>
            </HStack>
          )}
          <HStack alignItems="flex-start">
            <Skeleton isLoaded={!isLoadingOrgData}>
              <Text fontSize={'12px'} fontWeight="normal">
                {getStatusNotes() ?? ''}
              </Text>
            </Skeleton>
          </HStack>
        </VStack>
        {!isLoadingOrgData && (
          <PatientStatusModal
            isManagedInRS={isManagedInRS}
            isOpen={isOpenEditModal}
            patient={patient}
            onClose={() => setIsOpenEditModal(false)}
            refetchPatient={() => refetchPatient()}
          />
        )}

        <AttributeDisplay label="Rhythm ID">
          {patient.rhythmId}
        </AttributeDisplay>
        {device.length !== 0 ? (
          device.map(p => (
            <AttributeDisplay label="MODEL NAME" key={`device-${p.id}`}>
              {p.modelName}
            </AttributeDisplay>
          ))
        ) : (
          <AttributeDisplay label="MODEL NAME">-</AttributeDisplay>
        )}

        <AttributeDisplay label="Insurance">
          {patient.insuranceCarrier}
        </AttributeDisplay>
      </Stack>
      <Stack spacing="20px"></Stack>
    </Card>
  )
}

export default PatientDetailsCard
