import { Heading, SimpleGrid, Stack } from '@rhythm/components'

import AttributeDisplay from '../../../../../../components/AttributeDisplay'
import { MatchDevice } from '../../../../../../lib/api'
import { VENDOR_MAPPING } from '../constants'

const DeviceAttributes = ({ device }: { device: MatchDevice }) => (
  <Stack spacing={4}>
    <Heading variant="h5">Device Details</Heading>
    <SimpleGrid columns={{ base: 2, md: 5 }} spacing={4}>
      <AttributeDisplay label="Manufacturer">
        {VENDOR_MAPPING[device.vendor] ?? device.vendor}
      </AttributeDisplay>
      <AttributeDisplay label="Device Type">{device.type}</AttributeDisplay>
      <AttributeDisplay label="Serial No.">
        {device.serialNumber}
      </AttributeDisplay>
      <AttributeDisplay label="Model Name">{device.modelName}</AttributeDisplay>
      <AttributeDisplay label="Model Number">
        {device.modelNumber}
      </AttributeDisplay>
    </SimpleGrid>
  </Stack>
)

export default DeviceAttributes
