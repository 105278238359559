import { useQuery } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiOrganizationsControllerFindOneRequest,
  FindOneOrganizationResponse,
} from '../../../lib/api/generated-client'

import { getOrganizationResponseMapper } from './helpers'

export const useOrganization = ({
  organizationId,
}: DefaultApiOrganizationsControllerFindOneRequest) => {
  const Api = useApiContext()
  return useQuery(
    ['organizations', { id: organizationId }],
    async () => {
      const res = await Api.organizationsControllerFindOne({ organizationId })
      const response: FindOneOrganizationResponse =
        getOrganizationResponseMapper(res.data)
      return response
    },
    { enabled: !!organizationId },
  )
}
