import React from 'react'

import { Flex } from '@rhythm/components'

import {
  TransmissionReportNote,
  VtDeviceLead,
  VtDeviceMeasurement,
} from '../../../../../lib/api'
import NarrativeCard from '../NarrativeCard'

import CountersValuesNotes from './CountersValuesNotes'
import CountersValuesTable from './CountersValuesTable'

export interface CountersValuesCardProps {
  notes: TransmissionReportNote[]
  deviceLeads?: VtDeviceLead[]
  deviceMeasurement?: VtDeviceMeasurement
}

const CountersValuesCard = (): React.ReactElement => {
  return (
    <NarrativeCard icon="monitored-values" title="Counters">
      <Flex width="100%" direction="column" alignItems="stretch">
        <CountersValuesNotes />
      </Flex>
      <Flex width="100%" direction="column" alignItems="stretch">
        <CountersValuesTable />
      </Flex>
    </NarrativeCard>
  )
}

export default CountersValuesCard
