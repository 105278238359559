import { Badge, Box, Text } from '@rhythm/components'

import { TransmissionReportColorBadge } from '../../../../../features/transmissionReports'
import { UnsignedReportSearchResult } from '../../../../../lib/api'
import formatUtcDate from '../../../../../utils/formatUtcDate'

const TransmissionSearch = ({
  transmission,
  selectedTransmissionReportId,
  onSelectTransmissionReport,
}: {
  selectedTransmissionReportId: number
  onSelectTransmissionReport: (id: number) => void
  transmission: UnsignedReportSearchResult
}) => {
  return (
    <Box
      display={'flex'}
      py={6}
      px={4}
      width={'100%'}
      bg={
        selectedTransmissionReportId === transmission.id
          ? 'neutral.200'
          : 'neutral.100'
      }
      _hover={{
        background: 'neutral.200',
        cursor: 'pointer',
      }}
      onClick={() => onSelectTransmissionReport(transmission.id)}
    >
      <Box>
        <TransmissionReportColorBadge
          color={transmission.color}
          size={'md'}
          mr="xl"
        />
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
        <Box>
          <Text as="b" mb={2} fontWeight={'bold'}>
            {`${transmission.givenName} ${transmission.familyName}`}
          </Text>
          <Text>{formatUtcDate(transmission.transmissionDate)}</Text>
        </Box>
        <Box>
          <Badge>{transmission.deviceType}</Badge>
        </Box>
      </Box>
    </Box>
  )
}

export default TransmissionSearch
