import { PatientActiveStatusEnum } from '../../../types'

import { DeviceScheduleFilterOptions } from './constants'

export const saveOrUpdateFilterTable = (tableName: string, objInfo: any) => {
  const existingObj = sessionStorage.getItem(tableName)
  if (existingObj) {
    const existing = JSON.parse(existingObj)
    sessionStorage.setItem(
      tableName,
      JSON.stringify({ ...existing, ...objInfo }),
    )
  } else {
    sessionStorage.setItem(tableName, JSON.stringify({ ...objInfo }))
  }
}

export const getSavedTableInfo = (tableName: string) => {
  const existingObj = sessionStorage.getItem(tableName)
  if (existingObj) {
    return JSON.parse(existingObj)
  } else {
    return {
      pageIndex: 0,
      pageSize: 10,
      reportOptions: { value: 'ALL_Reports', label: 'All' },
      patientOptions: { value: 'ALL_Patients', label: 'All' },
      statusOptions: { value: PatientActiveStatusEnum.All, label: 'All' },
      deviceScheduleOptions: DeviceScheduleFilterOptions.ALL,
    }
  }
}

export const resetSavedTableInfo = (tableName: string, objInfo: any) => {
  sessionStorage.setItem(
    tableName,
    JSON.stringify({ ...objInfo, pageIndex: 0, pageSize: 10 }),
  )
}

export const TablesMapName = {
  PATIENTS: 'PATIENTS',
}
