import { Box, Icon, Tooltip, useDisclosure } from '@rhythm/components'

export interface ImagePreviewProps {
  children: React.ReactNode
  onDelete: () => void
  onToggleExpanded?: (index: number) => void
  expandedIndex: number
  isOpen: boolean
}

export const ImagePreview = ({
  children,
  onDelete,
  onToggleExpanded,
  expandedIndex,
  isOpen,
}: ImagePreviewProps): React.ReactElement => {
  const { onToggle } = useDisclosure()
  const handleToggleExpanded = () => {
    onToggle()
    onToggleExpanded && onToggleExpanded(expandedIndex)
  }

  return (
    <Box
      maxHeight={isOpen ? '100%' : '300px'}
      maxWidth="100%"
      minWidth={'100px'}
      minHeight={'100px'}
      overflow="hidden"
      borderWidth="1px"
      borderRadius="md"
    >
      {children}

      <Tooltip label={isOpen ? 'Collapse' : 'Expand'} position="sticky">
        <Box
          as="span"
          display={'inline-block'}
          position="sticky"
          left="md"
          bottom="xs"
        >
          <Icon
            icon={isOpen ? 'pinch' : 'expand'}
            borderRadius="6px"
            bg="primary.500"
            color="neutral.white"
            p="sm"
            filter="drop-shadow(0px 0px 4px #CACCD3)"
            cursor="pointer"
            onClick={handleToggleExpanded}
          />
        </Box>
      </Tooltip>
      <Tooltip label="Delete" position="sticky">
        <Box
          as="span"
          display="inline-block"
          position="sticky"
          left="md"
          bottom="xs"
          ml="lg"
        >
          <Icon
            icon="delete"
            borderRadius="6px"
            bg="primary.500"
            color="neutral.white"
            p="sm"
            filter="drop-shadow(0px 0px 4px #CACCD3)"
            cursor="pointer"
            onClick={onDelete}
          />
        </Box>
      </Tooltip>
    </Box>
  )
}
