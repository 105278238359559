const routes = {
  root: '/',
  login: {
    callback: '/login/callback',
  },
  logout: {
    callback: '/logout',
  },
  physician: {
    root: '/physician',
    dashboard: '/physician/dashboard',
    patients: '/physician/patients',
    analytics: {
      root: '/physician/analytics',
      billing: '/physician/analytics/billing',
      accountOverview: '/physician/analytics/account-overview',
      alerts: '/physician/analytics/alerts',
      scheduling: '/physician/analytics/scheduling',
      signatureStatus: '/physician/analytics/signature-status',
    },
    patientProfile: '/physician/patient/profile/:id',
    reports: '/physician/reports',
    transmissionReport: '/physician/transmissions/:id',
    transmissionReview: '/physician/transmissions/review',
  },
  operations: {
    root: '/operations',
  },
  user: {
    root: '/user',
    settings: '/user/settings',
  },
}

export default routes
