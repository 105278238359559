import { User } from '../lib/api'
import { User as TspUser } from '../types'

export const tspBuildUserName = (user: TspUser): string => {
  let userName = `${user.firstName} ${user.lastName}`
  if (user.suffix) {
    userName += `, ${user.suffix}`
  }
  return userName
}

export const buildUserName = (user?: User): string => {
  let userName = ''

  if (!user) return userName

  if (user.prefix?.length > 0) {
    userName += `${user.prefix[0]} `
  }

  userName += `${user.given} ${user.family}`

  if (user.suffix?.length > 0) {
    userName += `, ${user.suffix[0]}`
  }
  return userName
}

export default buildUserName
