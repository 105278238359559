import { OrderStatus } from '../types'

const getOrderStatusName = (status?: OrderStatus) => {
  switch (status) {
    case OrderStatus.EmptyAsPending:
    case OrderStatus.PendingOrder:
      return 'Pending Order'
    case OrderStatus.OrderFailedToPost:
      return 'Order: Failed To Post'
    case OrderStatus.OrderRequiresSupport:
      return 'Order: Failed To Post - Requires Support'
    case OrderStatus.ReadyToTransmit:
      return 'Ready To Transmit'
    case OrderStatus.ReportFailedToPost:
      return 'Report: Failed To Post'
    case OrderStatus.ReportRequiresSupport:
      return 'Report: Failed To Post - Requires Support'
    case OrderStatus.SuccessfullyTransmitted:
      return 'Successfully Transmitted'
    case OrderStatus.OrderModified:
      return 'Order Modified'

    default:
      return ''
  }
}

export default getOrderStatusName
