export interface DynamicConfig {
  API_SERVER: string
  INTERCOM_APP_ID: string
  OKTA_AUTH_CLIENT_ID: any
  OKTA_AUTH_ISSUER?: string
  OPERATIONS_DOMAIN: string
}

export const defaultConfig: DynamicConfig = {
  API_SERVER: process.env.REACT_APP_API_SERVER ?? 'http://localhost:8001',

  INTERCOM_APP_ID: process.env.INTERCOM_APP_ID ?? 'dap5uw2t',

  OKTA_AUTH_CLIENT_ID: '0oa1aovk1a1h58yH11d7',

  OKTA_AUTH_ISSUER: 'https://login-uat.rhythmsynergy.com/oauth2/default',

  OPERATIONS_DOMAIN: 'http://localhost:3001',
}

class GlobalConfig {
  config: DynamicConfig = defaultConfig

  notDefinedYet = true

  public get(): DynamicConfig {
    if (this.notDefinedYet) {
      throw new Error(
        // eslint-disable-next-line max-len

        'Global config has not been defined yet. Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing globalConfig in static context.',
      )
    } else {
      return this.config
    }
  }

  public set(value: DynamicConfig): void {
    if (this.notDefinedYet) {
      this.config = value

      this.notDefinedYet = false
    } else {
      throw new Error(
        // eslint-disable-next-line max-len

        'Global config has already been defined and now has been called second time. This is probably not intended.',
      )
    }
  }
}

export const globalConfig = new GlobalConfig()

export const globalConfigUrl = '/settings.json'
