import {
  ComponentProps,
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
} from 'react'

import { useGetTransmissionReportById } from '../../../features/transmissionReports'
import { TransmissionReportDto } from '../../../lib/api'

interface TransmissionReportContextInterface {
  setCurrentTransmission: (transmission: TransmissionReportDto) => void
  currentTransmission?: TransmissionReportDto
  transmissionReport?: TransmissionReportDto
  setCurrentTemplateType: (value: string) => void
  currentTemplateType: string
  isLoading: boolean
  isError: boolean
  error?: unknown
  isRefetching?: boolean
  isBillingValid: boolean
  setIsBillingValid: (value: boolean) => void
  isBillingLoading: boolean
  setIsBillingLoading: (value: boolean) => void
  clinicalNotesSideBarIsOpen: boolean
  setClinicalNotesSideBarIsOpen: (value: boolean) => void
}

const initialState: TransmissionReportContextInterface = {
  setCurrentTransmission: (transmission: TransmissionReportDto) => {},
  currentTransmission: undefined,
  setCurrentTemplateType: (value: string) => {},
  currentTemplateType: '',
  transmissionReport: undefined,
  isLoading: false,
  isError: false,
  error: undefined,
  isRefetching: false,
  isBillingValid: false,
  setIsBillingValid: (value: boolean) => {},
  isBillingLoading: false,
  setIsBillingLoading: (value: boolean) => {},
  clinicalNotesSideBarIsOpen: false,
  setClinicalNotesSideBarIsOpen: (value: boolean) => {},
}

export const TransmissionReportContext =
  createContext<TransmissionReportContextInterface>(initialState)

export const useTransmissionReportContext =
  (): TransmissionReportContextInterface =>
    useContext(TransmissionReportContext)

export const TransmissionReportContextProvider = ({
  children,
}: ComponentProps<FC>) => {
  const [currentTransmission, setCurrentTransmission] =
    useState<TransmissionReportDto>()
  // temporary fix to capture change in billingCard  component to instantly
  // disable sign reports button in transmissionActionBar
  const [isBillingValid, setIsBillingValid] = useState(true)
  const [isBillingLoading, setIsBillingLoading] = useState(false)
  const [clinicalNotesSideBarIsOpen, setClinicalNotesSideBarIsOpen] =
    useState(false)

  const [currentTemplateType, setCurrentTemplateType] = useState('')

  /**
   * We need to fetch by id to get the alerts and notes
   */
  const {
    data: transmissionReport,
    isError,
    isLoading,
    isRefetching,
    error,
  } = useGetTransmissionReportById(
    {
      transmissionReportId: currentTransmission?.id as number,
    },
    {
      enabled: !!currentTransmission?.id,
    },
  )

  const contextValue = useMemo(
    () => ({
      setCurrentTransmission,
      currentTransmission,
      transmissionReport,
      isError,
      isLoading,
      isRefetching,
      currentTemplateType,
      setCurrentTemplateType,
      error,
      isBillingValid,
      setIsBillingValid,
      isBillingLoading,
      setIsBillingLoading,
      clinicalNotesSideBarIsOpen,
      setClinicalNotesSideBarIsOpen,
    }),
    [
      currentTemplateType,
      currentTransmission,
      transmissionReport,
      isError,
      isLoading,
      isRefetching,
      error,
      isBillingValid,
      isBillingLoading,
      clinicalNotesSideBarIsOpen,
    ],
  )

  return (
    <TransmissionReportContext.Provider value={contextValue}>
      {children}
    </TransmissionReportContext.Provider>
  )
}
