import React, { useEffect, useState } from 'react'

import { Button, Flex, Heading, Progress, Text } from '@rhythm/components'

import { TransmissionReportIcon } from '../../../../../assets'
import { useGetTransmissionReportsCountByColor } from '../../../../../features/transmissionReports'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import { TransmissionReportDtoColorEnum } from '../../../../../lib/api'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../../../utils/constants/transmissionReports'

export interface TransmissionReviewStatusBarProps {
  onOpenSideBar: () => void
  onReturn: () => void
}

const TransmissionReviewStatusBar = ({
  onOpenSideBar,
  onReturn,
}: TransmissionReviewStatusBarProps): React.ReactElement => {
  const [reportsCount, setReportsCount] = useState<number>(0)

  const { data: countData, isLoading } = useGetTransmissionReportsCountByColor()

  //const total = transmissionReportsData?.total
  const { currentUser } = useCurrentUser()

  let total = 0
  if (countData?.counts && !isLoading) {
    Object.values(countData?.counts as object).forEach(val => (total += val))
    if (
      currentUser &&
      currentUser.role &&
      PRACTITIONER_SIGNATURE_ROLES.includes(currentUser.role)
    ) {
      const grayReportsCount = (countData?.counts as any)?.[
        TransmissionReportDtoColorEnum.Gray
      ]
      total -= grayReportsCount
    }
  }
  useEffect(() => {
    if ((total && reportsCount === 0) || total > reportsCount)
      setReportsCount(total)
  }, [total, reportsCount, setReportsCount])

  const reviewedCount = reportsCount - (total || 0)

  return (
    <Flex
      bg="neutral.black"
      height="64px"
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
    >
      <Flex alignItems="center" height="100%" width={1 / 3}>
        <Flex
          bg="primary.600"
          borderRightRadius="40px"
          height="100%"
          width="68px"
          mr="2xl"
          alignItems="center"
          justifyContent="center"
          _hover={{
            cursor: 'pointer',
          }}
          onClick={reportsCount ? onOpenSideBar : () => {}}
        >
          <TransmissionReportIcon height="32px" width="32px" />
        </Flex>
        <Heading variant="h4" color="neutral.white">
          {reportsCount > 1
            ? `Review ${reportsCount} Reports`
            : 'Review Report'}
        </Heading>
      </Flex>
      <Flex
        alignItems="center"
        pr="2xl"
        justifyContent="flex-end"
        width={2 / 3}
      >
        {reportsCount ? (
          <Flex alignItems="center" mr="3xl">
            <Text
              fontWeight="bold"
              color="neutral.white"
              mr="xl"
            >{`${reviewedCount}/${reportsCount} Reviewed`}</Text>
            <Progress
              bg="neutral.900"
              width="150px"
              max={reportsCount}
              value={reviewedCount}
            />
          </Flex>
        ) : null}
        <Button variant="secondaryDark" rightIcon="sign-out" onClick={onReturn}>
          Return to Dashboard
        </Button>
      </Flex>
    </Flex>
  )
}

export default TransmissionReviewStatusBar
