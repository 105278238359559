import React from 'react'

import {
  Box,
  Card,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from '@rhythm/components'

import {
  TransmissionReportDtoFilesUploadStatus,
  TransmissionReportSignatureStatusEnum,
} from '../../../../../lib/api'
import {
  OrderStatus,
  TramssionReportColor,
  TransmissionStatus,
} from '../../../../../types'
import formatDate from '../../../../../utils/formatDate'
import getOrderStatusName from '../../../../../utils/getOrderStatusName'
import getReportName from '../../../../../utils/getReportName'

import '../../../TransmissionReportPage/components/BillingCard/BillingCardStyle.css'

const getStatusColor = (
  signatureStatus?: TransmissionReportSignatureStatusEnum,
  status?: TramssionReportColor | TransmissionStatus,
) => {
  if (signatureStatus === TransmissionReportSignatureStatusEnum.Signed) {
    switch (status) {
      case TramssionReportColor.RED:
        return 'feedback.error'
      case TramssionReportColor.YELLOW:
        return 'feedback.warning'
      default:
        return 'transparent'
    }
  }
  return 'transparent'
}

export interface ReportCardProps {
  children?: React.ReactNode
  status?: TramssionReportColor | TransmissionStatus
  signatureStatus?: TransmissionReportSignatureStatusEnum
  orderStatus?: string
  showOrderStatus?: boolean
  reportName?: string
  billingDate?: string
  reportCreatedDate?: string
  editedUser?: string
  editedDate?: string
  transmissionType?: string
  filesUploadStatus?: FilesUploadStatus
}

interface Status {
  fileName: string
  status: TransmissionReportDtoFilesUploadStatus
}

export interface FilesUploadStatus {
  fileStatus: Status[]
}

const ReportCard = ({
  children,
  reportName,
  billingDate,
  reportCreatedDate,
  status,
  signatureStatus,
  orderStatus,
  showOrderStatus,
  editedUser,
  editedDate,
  transmissionType,
  filesUploadStatus,
}: ReportCardProps): React.ReactElement => {
  return (
    <Card px="xl" py="20px" style={{ cursor: 'unset' }}>
      <Flex>
        <Box
          width="4px"
          borderRadius="md"
          bg={getStatusColor(signatureStatus, status)}
          mr="2xl"
        />
        <Stack flex={4} spacing="sm">
          <HStack spacing="md" style={{ paddingBottom: '4px' }}>
            <Heading variant="h5">
              {reportName ?? getReportName(status)}
            </Heading>
            <br />
            {showOrderStatus && (
              <>
                <Icon icon="chevron-right" boxSize="sm" />
                <label>
                  {getOrderStatusName((orderStatus ?? '') as OrderStatus)}
                </label>
              </>
            )}
          </HStack>

          {transmissionType &&
          ['in-clinic', 'manual'].includes(transmissionType) ? (
            <>
              {filesUploadStatus?.fileStatus &&
                filesUploadStatus.fileStatus.length &&
                filesUploadStatus.fileStatus.map((e: Status) => (
                  <HStack spacing="md" key={e.fileName}>
                    <Text
                      variant="secondary"
                      wordBreak={'break-all'}
                      style={{ width: '100%', paddingRight: '10px' }}
                    >
                      {e.fileName}
                    </Text>
                  </HStack>
                ))}
              <Text variant="secondary" style={{ marginTop: '5px' }}>
                {' Uploaded on '}
                {formatDate(reportCreatedDate)}
                <br />
                {billingDate && (
                  <>
                    {' Date of Service: '}
                    {formatDate(billingDate)}
                  </>
                )}
                {signatureStatus === 'NON_PRACTITIONER_SIGNED' ? (
                  <>
                    <br /> - Awaiting Physician Signature
                  </>
                ) : (
                  ''
                )}
                <br />
                {editedUser !== 'null' &&
                  editedUser != null &&
                  typeof editedUser != 'undefined' &&
                  `Edited on ${formatDate(editedDate)} by ${editedUser}`}
              </Text>
            </>
          ) : (
            <Text variant="secondary" style={{ marginTop: '0px' }}>
              {' Transmitted on '}
              {formatDate(reportCreatedDate)}
              <br />
              {billingDate && (
                <>
                  {' Date of Service: '}
                  {formatDate(billingDate)}
                </>
              )}
              {signatureStatus === 'NON_PRACTITIONER_SIGNED' ? (
                <>
                  <br /> - Awaiting Physician Signature
                </>
              ) : (
                ''
              )}
              <br />
              {editedUser !== 'null' &&
                editedUser != null &&
                typeof editedUser != 'undefined' &&
                `Edited on ${formatDate(editedDate)} by ${editedUser}`}
            </Text>
          )}

          {/* <Text variant="secondary">
            {formatDate(reportDate)}{' '}
            {signatureStatus === 'NON_PRACTITIONER_SIGNED'
              ? <><br/> - Awaiting Physician Signature</>
              : ''}
          </Text> */}
        </Stack>
        <Flex flex={1}>{children}</Flex>
      </Flex>
    </Card>
  )
}

export default ReportCard
