import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
} from '@rhythm/components'
import { Control, useForm } from 'react-hook-form'

import MultiSelectWithFormControl, {
  CustomValueContainer,
} from '../../../../components/GlobalFiltersModal/MultiSelectWithFormControl'
import { useAnalyticsContext } from '../../../../context/AnalyticsContext'
import { useGetPractitioners } from '../../../../features/reportGeneration/useGetPractitioners'

import { OptionComponent } from './AccountsDropdown'
import { fetchAnalyticsProps } from './columns'
import { ActiveDot } from './constants'
import InnerMenu from './InnerMenu'

type OptionType = {
  label: string
  value: string
}
type FormData = {
  practitioners: OptionType[]
}

interface ColumnProps {
  accessor: string
  hasInnerMenu?: boolean
  innerMenuOptions?: OptionType[]
  label?: string
  header?: string
}

interface FilterProps {
  isActive: boolean
  selectedOptions?: string[]
  onSubCheckboxGroupChange?: (value: string[]) => void
}

interface CustomMenuOptionProps {
  columnProps: ColumnProps
  filterProps: FilterProps
  children: React.ReactNode
  onCheckboxChange: (value: { accessor: string; checked: boolean }) => void
}
type SelectedFilter = {
  columnId: string
  value: string[]
}

export const FilterButton = () => {
  const location = useLocation()
  const {
    isFilterSubmenuOpen,
    activeAnalyticsQueryParams,
    updateActiveAnalyticsQueryParams,
  } = useAnalyticsContext()

  const { data: practitionersData, isRefetching: isLoadingPractitioners } =
    useGetPractitioners({
      clinicIds: activeAnalyticsQueryParams?.clinicIds ?? [],
    })
  const practitioners = useMemo(() => {
    return (practitionersData?.data.map((practitioner: any) => ({
      label: practitioner.name,
      value: practitioner.id,
    })) ?? []) as OptionType[]
  }, [practitionersData])
  const {
    columns: defaultColumns,
    columnNameMapping,
    key,
  } = fetchAnalyticsProps(location.pathname)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [isSelectAllIndeterminate, setIsSelectAllIndeterminate] =
    useState(false)
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    ...(activeAnalyticsQueryParams?.columns ?? []),
    'selectAll',
  ])
  const columns = useMemo(() => {
    const res = defaultColumns.map(column => {
      if (
        activeAnalyticsQueryParams?.columns?.includes(column.accessor as never)
      ) {
        return { ...column, isActive: true }
      }
      return column
    })
    return res
  }, [activeAnalyticsQueryParams?.columns, defaultColumns])
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>(
    columns
      .filter(column => column.hasInnerMenu)
      .map(column => ({
        columnId: column.accessor,
        value: column.innerMenuOptions?.map(option => option.value) ?? [],
      })),
  )
  const { control, getValues, setValue, watch } = useForm<FormData>({
    defaultValues: {
      practitioners,
    },
  })
  const practitionersWatch = watch('practitioners')
  const innerMenuColumns = useMemo(
    () => columns.filter(column => column.hasInnerMenu),
    [columns],
  )
  const normalColumns = useMemo(
    () => columns.filter(column => !column.hasInnerMenu),
    [columns],
  )
  const columnAccessorMap: Map<string, ColumnProps> = useMemo(() => {
    const map = new Map<string, ColumnProps>()
    columns.forEach(column => {
      map.set(column.accessor, column)
    })
    return map
  }, [columns])

  // reset selected filters when location changes
  useEffect(() => {
    setSelectedFilters(
      columns
        .filter(column => column.hasInnerMenu)
        .map(column => ({
          columnId: column.accessor,
          value: column.innerMenuOptions?.map(option => option.value) ?? [],
        })),
    )
  }, [columns, location.pathname])

  useEffect(() => {
    if (activeAnalyticsQueryParams?.columns)
      setSelectedColumns([...activeAnalyticsQueryParams.columns, 'selectAll'])
  }, [activeAnalyticsQueryParams?.columns])

  useEffect(() => {
    setIsSelectAllIndeterminate(
      selectedColumns.filter(col => col !== 'selectAll').length <
        columnAccessorMap.size && selectedColumns.length !== 0,
    )
    let filterActive = false
    if (
      selectedColumns.length !== columnAccessorMap.size + 1 ||
      practitionersWatch.length < practitioners.length
    ) {
      filterActive = true
    }
    selectedFilters.forEach(filter => {
      const column = columnAccessorMap.get(filter.columnId)
      if (column?.innerMenuOptions) {
        if (filter.value.length !== column.innerMenuOptions.length) {
          filterActive = true
          return
        }
      }
    })
    setIsFilterActive(filterActive)
  }, [
    columnAccessorMap,
    practitioners,
    practitionersWatch.length,
    selectedColumns,
    selectedFilters,
  ])

  useEffect(() => {
    setValue('practitioners', practitioners)
  }, [practitioners, setValue])

  useEffect(() => {
    const filters = activeAnalyticsQueryParams?.filters as any[]
    if (filters?.length) {
      const selectedFiltersArr: SelectedFilter[] = filters.map(filter => ({
        columnId: filter.field,
        value: filter.arguments,
      }))
      setSelectedFilters(prev => {
        const newFilters = [...prev]
        selectedFiltersArr.forEach(selectedFilter => {
          const index = newFilters.findIndex(
            f => f.columnId === selectedFilter.columnId,
          )
          if (index > -1) {
            newFilters[index] = selectedFilter
          } else {
            newFilters.push(selectedFilter)
          }
        })
        return newFilters
      })
    }
  }, [activeAnalyticsQueryParams?.filters])

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedColumns([
        ...columns.map(column => column.accessor),
        'selectAll',
      ])
      setSelectedFilters(
        columns
          .filter(column => column.hasInnerMenu)
          .map(column => ({
            columnId: column.accessor,
            value: column.innerMenuOptions?.map(option => option.value) ?? [],
          })),
      )
    } else {
      setSelectedColumns([])
    }
  }

  const handleFollowingPractitioner = (checked: boolean) => {
    setValue('practitioners', checked ? practitioners : [])
  }

  const handleInnerMenuColumn = (accessor: string, checked: boolean) => {
    const column = columnAccessorMap.get(accessor)
    if (column?.hasInnerMenu) {
      if (checked) {
        setSelectedFilters([
          ...selectedFilters,
          {
            columnId: accessor,
            value: column.innerMenuOptions?.map(option => option.value) ?? [],
          },
        ])
      } else {
        setSelectedFilters(selectedFilters.filter(f => f.columnId !== accessor))
      }
    }
  }

  const updateSelectedColumns = (accessor: string, checked: boolean) => {
    setSelectedColumns(prev =>
      checked
        ? [...prev, accessor]
        : prev.filter(column => column !== accessor),
    )
  }

  const onCheckboxChange = (value: { accessor: string; checked: boolean }) => {
    const { accessor, checked } = value

    if (accessor === 'selectAll') {
      handleSelectAll(checked)
      return
    }

    if (accessor === 'followingPractitioner') {
      handleFollowingPractitioner(checked)
    }

    handleInnerMenuColumn(accessor, checked)
    updateSelectedColumns(accessor, checked)
  }

  const fetchCustomMenuOptionProps = (column: any) => {
    const columnProps = {
      accessor: column.accessor,
      hasInnerMenu: column.hasInnerMenu,
      innerMenuOptions: (column.innerMenuOptions ?? []) as OptionType[],
      label: columnNameMapping[column.accessor],
      header: column.Header,
      value: column.accessor,
    }

    const filterProps = {
      isActive: selectedColumns.includes(column.accessor),
      selectedOptions:
        selectedFilters.find(f => f.columnId === column.accessor)?.value ?? [],
      onSubCheckboxGroupChange: (value: string[]) => {
        if (value.length === 0) {
          setSelectedFilters(
            selectedFilters.filter(f => f.columnId !== column.accessor),
          )
          setSelectedColumns(selectedColumns.filter(c => c !== column.accessor))
          return
        }
        if (selectedFilters.some(f => f.columnId === column.accessor)) {
          setSelectedFilters(
            selectedFilters.map(f =>
              f.columnId === column.accessor
                ? { columnId: column.accessor, value }
                : f,
            ),
          )
        } else {
          setSelectedFilters([
            ...selectedFilters,
            { columnId: column.accessor, value },
          ])
        }
        if (!selectedColumns.includes(column.accessor)) {
          setSelectedColumns([...selectedColumns, column.accessor])
        }
      },
    }
    return { columnProps, filterProps }
  }

  const onApplyClick = () => {
    let finalSelectedColumns = selectedColumns.filter(
      column => column !== 'selectAll',
    )
    const selectedPractitioners = selectedColumns.includes(
      'followingPractitioner',
    )
      ? getValues('practitioners')
      : []

    // if selectedPractitioners is empty, remove followingPractitioner from selectedColumns
    if (selectedPractitioners.length === 0) {
      finalSelectedColumns = finalSelectedColumns.filter(
        column => column !== 'followingPractitioner',
      )
    }
    const finalSelectedFilters = selectedFilters
      .filter(
        filter =>
          filter.value.length !==
          columnAccessorMap.get(filter.columnId)?.innerMenuOptions?.length,
      )
      .map(filter => {
        if (filter.columnId === 'followingPractitioner') {
          return {
            columnId: 'followingPractitioner',
            value:
              selectedPractitioners.length !== practitioners.length &&
              selectedPractitioners.length > 0
                ? selectedPractitioners.map(({ value }) => value)
                : [],
          }
        }
        return filter
      })
      .filter(filter => filter.value.length > 0)
    const filters = finalSelectedFilters.length
      ? (finalSelectedFilters.map(filter => ({
          field: filter.columnId,
          operator: 'in',
          arguments: filter.value,
        })) as any[])
      : undefined
    updateActiveAnalyticsQueryParams(prev => ({
      ...prev,
      columns: finalSelectedColumns as any[],
      filters,
    }))
    filters && sessionStorage.setItem(`${key}_filters`, JSON.stringify(filters))
    sessionStorage.setItem(`${key}_columns`, finalSelectedColumns.join())

    setIsFilterOpen(false)
  }

  const onCancelClick = () => {
    setIsFilterOpen(false)
    setSelectedColumns([
      ...(activeAnalyticsQueryParams?.columns ?? []),
      'selectAll',
    ])
    const prevFilters =
      columns
        .filter(({ hasInnerMenu }) => hasInnerMenu)
        .map(({ accessor, innerMenuOptions }) => {
          const isActiveColumn = activeAnalyticsQueryParams?.columns?.includes(
            accessor as never,
          )

          const filter = isActiveColumn
            ? (activeAnalyticsQueryParams?.filters as any)?.find(
                (f: any) => f.field === accessor,
              )
            : null

          return {
            columnId: accessor,
            value:
              filter?.arguments ??
              innerMenuOptions?.map(({ value }) => value) ??
              [],
          }
        }) ?? []
    setSelectedFilters(prevFilters)
    setValue('practitioners', practitioners)
    setIsSelectAllIndeterminate(false)
  }

  return (
    <Menu
      placement="bottom-end"
      isLazy
      closeOnSelect={false}
      isOpen={isFilterOpen}
      lazyBehavior={'keepMounted'}
    >
      <MenuButton
        _active={{ bg: 'neutral.400' }}
        position={'relative'}
        onClick={() => setIsFilterOpen(true)}
      >
        <IconButton
          variant="ghost"
          size={'sm'}
          position={'relative'}
          top={'4px'}
          aria-label="Filter Data"
          icon={'filter'}
        />
        {isFilterActive && (
          <ActiveDot sx={{ position: 'absolute', right: '0' }} />
        )}
      </MenuButton>
      <MenuList
        minWidth="300px"
        maxHeight={'550px'}
        overflowX={'hidden'}
        zIndex={2}
      >
        <CheckboxGroup value={selectedColumns}>
          <NormalMenuOption
            accessor={'selectAll'}
            onChange={value => {
              onCheckboxChange(value)
            }}
            isIndeterminate={isSelectAllIndeterminate}
          >
            Select All
          </NormalMenuOption>
          <MenuDivider />
          <Box
            maxH={'370px'}
            overflowY={isFilterSubmenuOpen ? 'hidden' : 'auto'}
          >
            {columns.find(
              column => column.accessor === 'followingPractitioner',
            ) && (
              <PractitionerMenuOption
                label={columnNameMapping['followingPractitioner']}
                value={'followingPractitioner'}
                control={control}
                isLoading={isLoadingPractitioners}
                practitioners={practitioners}
                isActive={selectedColumns.includes('followingPractitioner')}
                onCheckboxChange={onCheckboxChange}
              />
            )}
            {innerMenuColumns
              .filter(column => column.accessor !== 'followingPractitioner')
              .map(column => {
                const { columnProps, filterProps } =
                  fetchCustomMenuOptionProps(column)
                return (
                  <InnerMenuOption
                    key={column.accessor}
                    columnProps={columnProps}
                    filterProps={filterProps}
                    onCheckboxChange={onCheckboxChange}
                  >
                    {column.Header}
                  </InnerMenuOption>
                )
              })}
            {normalColumns.map(column => (
              <NormalMenuOption
                key={column.accessor}
                accessor={column.accessor}
                onChange={value => {
                  onCheckboxChange(value)
                }}
              >
                {columnNameMapping[column.accessor]}
              </NormalMenuOption>
            ))}
          </Box>
        </CheckboxGroup>
        <MenuDivider />

        <Flex height={'60px'} mr={5} pt={4} justifyContent={'flex-end'}>
          <Button
            size={'md'}
            onClick={onCancelClick}
            variant={'secondaryLight'}
            mr={4}
          >
            Cancel
          </Button>
          <Button
            disabled={selectedColumns.length === 0}
            size={'md'}
            onClick={onApplyClick}
          >
            Apply
          </Button>
        </Flex>
      </MenuList>
    </Menu>
  )
}

const NormalMenuOption = ({
  accessor,
  children,
  onChange,
  isIndeterminate,
}: {
  accessor: string
  children: React.ReactNode
  onChange?: (value: { accessor: string; checked: boolean }) => void
  isIndeterminate?: boolean
}) => {
  return (
    <Box _hover={{ bg: 'gray.100' }} pl={4} cursor={'pointer'}>
      <Checkbox
        spacing={'4'}
        value={accessor}
        py={5}
        width={'100%'}
        height={'100%'}
        onChange={e => {
          if (onChange) {
            const checked = e.target.checked
            onChange({ accessor, checked })
          }
        }}
        isIndeterminate={isIndeterminate}
      >
        <Box fontSize={'lg'}>{children}</Box>
      </Checkbox>
    </Box>
  )
}

const InnerMenuOption: React.FC<CustomMenuOptionProps> = ({
  columnProps,
  filterProps,
  children,
  onCheckboxChange,
}) => {
  const { accessor, hasInnerMenu, innerMenuOptions, label } = columnProps
  const { isActive, selectedOptions, onSubCheckboxGroupChange } = filterProps
  if (hasInnerMenu && innerMenuOptions) {
    return (
      <InnerMenu
        label={label}
        columnId={accessor}
        isActive={isActive}
        onCheckboxChange={onCheckboxChange}
        onToggleAll={value => {
          if (value)
            onSubCheckboxGroupChange &&
              onSubCheckboxGroupChange(
                innerMenuOptions.map(option => option.value),
              )
          else onSubCheckboxGroupChange && onSubCheckboxGroupChange([])
        }}
        isFilterApplied={
          selectedOptions && selectedOptions.length !== innerMenuOptions.length
        }
      >
        <CheckboxGroup
          onChange={onSubCheckboxGroupChange}
          value={selectedOptions}
        >
          {innerMenuOptions.map(option => (
            <NormalMenuOption key={option.value} accessor={option.value}>
              {option.label}
            </NormalMenuOption>
          ))}
        </CheckboxGroup>
      </InnerMenu>
    )
  }
  return (
    <Box _hover={{ bg: 'gray.100' }} pl={4} cursor={'pointer'}>
      <Checkbox
        spacing={'4'}
        value={accessor}
        py={5}
        width={'100%'}
        height={'100%'}
      >
        <Box fontSize={'lg'}>{children}</Box>
      </Checkbox>
    </Box>
  )
}

const PractitionerMenuOption = ({
  label,
  value,
  control,
  practitioners,
  isActive,
  onCheckboxChange,
  isLoading,
}: {
  label?: string
  value: string
  control?: Control<any, any>
  practitioners: OptionType[]
  isActive: boolean
  onCheckboxChange: (value: { accessor: string; checked: boolean }) => void
  isLoading: boolean
}) => {
  if (!control) return <div />
  return (
    <InnerMenu
      label={label}
      columnId={value}
      isActive={isActive && practitioners.length > 0}
      onCheckboxChange={onCheckboxChange}
      isLoading={isLoading}
    >
      <MultiSelectWithFormControl
        name={'practitioners'}
        options={practitioners}
        placeholder={'Filter Practitioners'}
        isDisabled={practitioners.length < 1}
        components={{
          ValueContainer: props => (
            <CustomValueContainer {...props} max={1} label={'practitioners'}>
              {props?.children}
            </CustomValueContainer>
          ),
          Option: props => <OptionComponent props={props} />,
        }}
        maxOptionsBeforeClearAll={1}
        withSelectAll
        control={control}
        menuIsOpen={true}
        showLabel={false}
        highlightSelectedOptions={false}
      />
    </InnerMenu>
  )
}
