import {
  useCreateTransmissionReportCounters,
  usePatchTransmissionReportCounters,
} from '../api/counters'

export function useTransmissionReportCounters() {
  const { isLoading: isCreatingCounters, mutateAsync: createCounters } =
    useCreateTransmissionReportCounters()
  const { isLoading: isUpdatingCounters, mutateAsync: updateCounters } =
    usePatchTransmissionReportCounters()

  return {
    isLoading: (isCreatingCounters || isUpdatingCounters) ?? false,
    createCounters,
    updateCounters,
  }
}
