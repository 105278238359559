import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiTransmissionReportsControllerSearchUnsignedReportsRequest } from '../../../lib/api'

export const useGetTransmissionReportSearchUnsigned = (
  params: DefaultApiTransmissionReportsControllerSearchUnsignedReportsRequest,
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const Api = useApiContext()
  return useQuery(
    [
      'transmissionReportSearchUnsigned',
      params.transmissionReportSearchUnsignedReportsRequestDto.query,
    ],
    async () => {
      if (!params.transmissionReportSearchUnsignedReportsRequestDto.query)
        return
      const { data } =
        await Api.transmissionReportsControllerSearchUnsignedReports(params)
      return data
    },
    {
      ...options,
    },
  )
}
