import { useQuery } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiScheduledTransmissionControllerFindUpcomingScheduledTransmissionRequest } from '../../../lib/api'

export const useGetAllNextScheduledTransmissions = ({
  patientId,
}: DefaultApiScheduledTransmissionControllerFindUpcomingScheduledTransmissionRequest) => {
  const Api = useApiContext()
  return useQuery(
    ['find-upcoming-Scheduled-transmission', { patientId }],
    async () => {
      const data =
        await Api.scheduledTransmissionControllerFindUpcomingScheduledTransmission(
          {
            patientId,
          },
        )
      return data.data
    },
    {
      retry: 2,
    },
  )
}
