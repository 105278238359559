import '@fontsource/bad-script'

import {
  Box,
  Button,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  Text,
  VStack,
} from '@rhythm/components'

import useCurrentUser from '../../../../../hooks/useCurrentUser'

export interface SignReportModalProps {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
  reportType: string | undefined
  signReportText?: string | undefined
}

const SignReportModal = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  reportType,
  signReportText,
}: SignReportModalProps) => {
  const {
    currentUser: { firstName, lastName, credentials },
  } = useCurrentUser()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm} isLoading={isLoading}>
            {signReportText ?? 'Sign Report'}
          </Button>
        </HStack>
      }
    >
      <HStack spacing="xl" mb="3xl">
        <Box p="xl" bg="neutral.200" borderRadius="lg">
          <Icon icon="patients" boxSize="md" color="primary.500" />
        </Box>
        <VStack alignItems="flex-start">
          <Heading variant="h6">{reportType}</Heading>
          <Heading variant="h4">{signReportText ?? 'Sign Report'}</Heading>
        </VStack>
      </HStack>
      <VStack spacing="2xl">
        <Box w="full">
          <FormLabel color="neutral.800">Your Name</FormLabel>
          <Input
            value={`${firstName} ${lastName}${
              credentials ? ', ' + credentials : ''
            }`}
            isDisabled={true}
            _disabled={{
              color: 'neutral.800',
              background: 'neutral.white',
              pointerEvents: 'none',
            }}
          />
        </Box>
        <Box w="full">
          <FormLabel color="neutral.800">Signature Preview</FormLabel>
          <Box
            padding="2xl"
            background="neutral.200"
            borderRadius="md"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="neutral.600"
          >
            <Text
              fontSize="4xl"
              fontFamily="Bad Script"
              textAlign="center"
            >{`${firstName} ${lastName}${
              credentials ? ', ' + credentials : ''
            }`}</Text>
          </Box>
        </Box>
      </VStack>
    </Modal>
  )
}

export default SignReportModal
