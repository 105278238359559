import React, { useState } from 'react'

import {
  Badge,
  Box,
  HStack,
  IconBadge,
  Link,
  MenuItem,
  Spinner,
  Text,
} from '@rhythm/components'
import saveAs from 'file-saver'
import moment from 'moment'

import { useApiContext } from '../../../../../../context/ApiContext'
import { Patient, queryClient } from '../../../../../../lib/api'
import { ReportType } from '../../../../../../types'
import {
  getDateTimeWithTimezone,
  getReportDisplayName,
  getReportDownloadName,
  getSignedPDF,
} from '../../../../../../utils/transmissionReportUtil'
import { NavigationIcon } from '../../../../TransmissionReportPage/components/BillingCard/CustomIcons'

interface ReportMenuItemProps {
  reportItem: any
  patient: Patient
}

export interface SignedReport {
  pdfUrl: string
}

export const ReportMenuItem = ({
  reportItem,
  patient,
}: ReportMenuItemProps): React.ReactElement => {
  const { clinic, rhythmId, givenName, familyName } = patient
  const [isDownloading, setIsDownloading] = useState(false)
  const [fetchingSignPDF, setFetchingSignPDF] = useState(false)
  const Api = useApiContext()
  let requiredpdfInfo: any = []

  const hideReportStatus = (reportType: string) =>
    clinic.ehrIntegration && reportType === ReportType.Remote

  const getDownloadName = (
    reportType: string,
    reportDate: string,
    flagHeartFailure: boolean,
  ) => {
    return getReportDownloadName(
      reportType,
      givenName,
      familyName,
      reportDate,
      flagHeartFailure,
    )
  }

  const getReportItemDisplayName = (
    reportType: string,
    reportColor: string,
    transmissionDate: string,
    deviceType: string,
    flagHeartFailure: boolean,
  ) => {
    const reportDate = transmissionDate
      ? moment(transmissionDate).format('MM/DD/YYYY')
      : ''

    const reportTypeDisplayName = getReportDisplayName(
      reportType,
      deviceType,
      reportColor,
      flagHeartFailure,
    )
    return reportTypeDisplayName + ' ' + reportDate
  }

  async function downloadMerge(
    signedReport: any,
    transmissionReportId: number,
    reportType: string,
    reportDate: string,
    isDownloaded: boolean,
    flagHeartFailure: boolean,
  ) {
    if (signedReport) {
      const blob = await fetch(signedReport.pdfUrl).then(r => r.blob())

      const downloadDate = getDateTimeWithTimezone()
      if (!isDownloaded) {
        await Api.transmissionReportsControllerStorePDFDownloadInfo({
          transmissionReportId,
          downloadDate,
        })
        await queryClient.invalidateQueries('patients')
      }
      return saveAs(
        blob,
        getDownloadName(reportType, reportDate, flagHeartFailure),
      )
    }
  }

  const handleDownload = async (
    id: number,
    reportType: string,
    reportDate: string,
    isDownloaded: boolean,
    flagHeartFailure: boolean,
  ) => {
    setIsDownloading(true)
    try {
      const { data } =
        await Api.transmissionReportsControllerVendorTransmissionsReports({
          transmissionReportId: id,
        })

      const vendorTransmissionReportsList = data.vendorTransmissionReports
      if (
        vendorTransmissionReportsList &&
        vendorTransmissionReportsList.length !== 0
      ) {
        vendorTransmissionReportsList.sort((a: any, b: any) =>
          moment(b.lastModified).valueOf() > moment(a.lastModified).valueOf()
            ? 1
            : -1,
        )

        let hasLatestTransmissionReportItem = false
        /*eslint-disable */
        requiredpdfInfo = vendorTransmissionReportsList?.filter(
          (report: any) => {
            let prefixName = JSON.parse(JSON.stringify(report))['name'].split(
              '.',
            )
            if (prefixName[prefixName.length - 1] === 'pdf') {
              if (report.name.includes(rhythmId)) {
                if (!hasLatestTransmissionReportItem) {
                  hasLatestTransmissionReportItem = true
                  return report
                }
              } else {
                return report
              }
            }
          },
        )

        let hasLatestTransmissionReport = false
        const signedReport = requiredpdfInfo?.find((report: any) => {
          if (report.name.includes(rhythmId) && !hasLatestTransmissionReport) {
            hasLatestTransmissionReport = true
            return report
          } else if (
            JSON.parse(JSON.stringify(report))['name'].indexOf(
              'Signed_Rhythm_Report',
            ) > -1
          ) {
            return report
          }
        })
        await downloadMerge(
          signedReport,
          id,
          reportType,
          reportDate,
          isDownloaded,
          flagHeartFailure,
        )
      }
    } catch (e) {
      console.log('Download Failed:', e)
    } finally {
      setIsDownloading(false)
    }
  }

  const openReport = async (recentReportId: number) => {
    setFetchingSignPDF(true)
    try {
      const { data: vendorTransmissionsReports } =
        await Api.transmissionReportsControllerVendorTransmissionsReports({
          transmissionReportId: recentReportId,
        })
      if (
        !vendorTransmissionsReports ||
        vendorTransmissionsReports.vendorTransmissionReports.length === 0
      )
        return
      const signedReport: SignedReport | undefined = (await getSignedPDF(
        vendorTransmissionsReports.vendorTransmissionReports,
        rhythmId,
      )) as SignedReport

      if (signedReport) {
        let blob = await fetch(signedReport.pdfUrl as RequestInfo).then(r =>
          r.blob(),
        )

        const pdfURL = URL.createObjectURL(blob)
        window.open(pdfURL)
      }
    } catch (e) {
      console.log('Open report Failed for Reports column item:', e)
    } finally {
      setFetchingSignPDF(false)
    }
  }

  return (
    <Box>
      <MenuItem
        fontSize={14}
        fontWeight={700}
        color={'#455468'}
        closeOnSelect={false}
        cursor={'default'}
      >
        <HStack
          w="full"
          direction="row"
          position="relative"
          style={{ display: 'inline-block' }}
        >
          <Text style={{ float: 'left' }}>
            <Text
              style={{ float: 'left' }}
              cursor={'pointer'}
              color={'#1083CB'}
              onClick={() => openReport(reportItem.id)}
            >
              {getReportItemDisplayName(
                reportItem.transmissiontype,
                reportItem.t_color,
                reportItem.transmissiondate,
                reportItem.device_type,
                reportItem.flagheartfailure,
              )}
            </Text>

            <Box paddingLeft={'2px'} style={{ float: 'right' }}>
              <Box style={{ float: 'left' }} marginTop={'2.5px'}>
                {fetchingSignPDF && <Spinner marginLeft={'3px'} size={'sm'} />}
                {!fetchingSignPDF && (
                  <Link
                    as={'div'}
                    fontSize={'sm'}
                    onClick={() => openReport(reportItem.id)}
                  >
                    <HStack>
                      <NavigationIcon />
                    </HStack>
                  </Link>
                )}
              </Box>

              {!hideReportStatus(reportItem.transmissionType) && (
                <Box paddingLeft={'10px'} style={{ float: 'left' }}>
                  {reportItem.downloaded && (
                    <Text fontSize={12} fontFamily={'Lato'} color="#6C7789">
                      (Downloaded)
                    </Text>
                  )}
                  {!reportItem.downloaded &&
                    !patient.clinic?.ehrIntegration && (
                      <Badge
                        fontSize={11}
                        fontFamily={'Lato'}
                        backgroundColor="#E5F9ED"
                        color="#068923"
                        fontWeight={700}
                      >
                        New
                      </Badge>
                    )}
                </Box>
              )}
            </Box>
          </Text>

          <Box style={{ float: 'right' }}>
            {isDownloading && <Spinner marginRight={'5px'} size={'sm'} />}
            {!isDownloading && (
              <IconBadge
                icon={'download'}
                size="sm"
                cursor={'pointer'}
                onClick={() =>
                  handleDownload(
                    reportItem.id,
                    reportItem.transmissiontype,
                    reportItem.transmissiondate,
                    reportItem.downloaded,
                    reportItem.flagheartfailure,
                  )
                }
              />
            )}
          </Box>
        </HStack>
      </MenuItem>
    </Box>
  )
}
