import React, { useState } from 'react'

import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Spinner,
} from '@rhythm/components'

import { useAnalyticsContext } from '../../../../context/AnalyticsContext'

import { ActiveDot } from './constants'

const InnerMenu = ({
  children,
  isOpen,
  label,
  columnId,
  isActive,
  onCheckboxChange,
  onToggleAll,
  isIndeterminate,
  isLoading = false,
  isFilterApplied = false,
}: {
  children: React.ReactNode
  isOpen?: boolean
  label?: string
  columnId: string
  isActive?: boolean
  onCheckboxChange: (value: { accessor: string; checked: boolean }) => void
  onToggleAll?: (value: boolean) => void
  isIndeterminate?: boolean
  isLoading?: boolean
  isFilterApplied?: boolean
}) => {
  const { setIsFilterSubmenuOpen } = useAnalyticsContext()
  const [isSelectedAll, setIsSelectedAll] = useState(true)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)

  const onSelectAllClick = () => {
    onToggleAll && onToggleAll(!isSelectedAll)
    setIsSelectedAll(!isSelectedAll)
  }

  return (
    <Menu
      autoSelect={false}
      isLazy
      offset={[0, 2]}
      isOpen={isOpen}
      placement="right-start"
      onOpen={() => setIsFilterSubmenuOpen(true)}
      onClose={() => setIsFilterSubmenuOpen(false)}
    >
      <Flex _hover={{ bg: 'gray.100' }} cursor={'pointer'}>
        <Checkbox
          spacing={'4'}
          value={columnId}
          ml={4}
          py={3}
          isIndeterminate={isIndeterminate}
          onChange={e => {
            const checked = e.target.checked
            onCheckboxChange({ accessor: columnId, checked })
          }}
        >
          {' '}
        </Checkbox>
        <MenuButton
          py={3}
          flexGrow={1}
          __css={{
            '.chakra-checkbox__label': {
              width: '100%',
            },
          }}
          height={'100%'}
          disabled={!isActive || isLoading}
          onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
        >
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            fontSize={'lg'}
            width={'100%'}
            height={'100%'}
            color={isActive ? 'black' : 'gray.400'}
          >
            <span>
              {isLoading && <Spinner size="sm" color="primary.500" mr={2} />}
              {isFilterApplied && <ActiveDot sx={{ marginRight: '5px' }} />}
              {label}
            </span>
            <IconButton
              variant="ghost"
              size={'sm'}
              aria-label="Filter Data"
              icon={'chevron-right'}
            />
          </Flex>
        </MenuButton>
      </Flex>
      <MenuList
        minW={columnId === 'followingPractitioner' ? '400px' : '250px'}
        minH={columnId === 'followingPractitioner' ? '400px' : 'auto'}
        zIndex={999}
        maxHeight={'400px'}
        overflowY={'auto'}
        p={4}
        _hover={{ zIndex: 1000 }}
      >
        <Flex
          fontSize={16}
          pl={2}
          justifyContent={onToggleAll ? 'space-between' : 'flex-start'}
        >
          {label}
          {onToggleAll && (
            <Button
              size={'xs'}
              variant={'plain'}
              bg={'transparent'}
              fontStyle={'medium'}
              color={'text.secondary'}
              border={'none'}
              onClick={onSelectAllClick}
            >
              {isSelectedAll ? 'Deselect All' : 'Select All'}
            </Button>
          )}
        </Flex>
        <MenuDivider />
        {children}
      </MenuList>
    </Menu>
  )
}

export default InnerMenu
