import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Tooltip,
} from '@rhythm/components'

import { useUpdateTransmissionReport } from '../../../../../features/transmissionReports/api'
import { TransmissionReportColorBadge } from '../../../../../features/transmissionReports/components'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import {
  TransmissionReportColors,
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
} from '../../../../../lib/api'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../../../utils/constants/transmissionReports'
import { TransmissionReportPageLocationState } from '../../TransmissionReportPage'

const backgroundColor = 'rgba(33, 49, 71, 0.7)'

interface UpdateTransmissionColorInputProps {
  transmissionReport?: TransmissionReportDto
}

const UpdateTransmissionColorInput = ({
  transmissionReport,
}: UpdateTransmissionColorInputProps) => {
  const location = useLocation<TransmissionReportPageLocationState>()
  const { currentUser } = useCurrentUser()
  const cloneTransmissionReportDtoColorEnum = JSON.parse(
    JSON.stringify(TransmissionReportDtoColorEnum),
  )
  let existingRole: boolean
  if (currentUser && currentUser.role) {
    existingRole = PRACTITIONER_SIGNATURE_ROLES.includes(currentUser.role)
    if (existingRole) {
      delete cloneTransmissionReportDtoColorEnum.Gray
    }
  }

  const [showMenu, setShowMenu] = useState(false)
  const { mutate, isLoading } = useUpdateTransmissionReport()
  const elRef = useRef<HTMLDivElement>(null)

  const onElementClick = useCallback(
    e => {
      if (elRef.current && !elRef.current.contains(e.target)) {
        setShowMenu(false)
      }
    },
    [elRef, setShowMenu],
  )

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', onElementClick)
    } else {
      document.removeEventListener('click', onElementClick)
    }

    return () => document.removeEventListener('click', onElementClick)
  }, [showMenu, onElementClick])

  const getExpandedIndex = (status?: TransmissionReportDtoColorEnum) => {
    return [
      TransmissionReportDtoColorEnum.Red,
      TransmissionReportDtoColorEnum.Yellow,
      TransmissionReportDtoColorEnum.Green,
      TransmissionReportDtoColorEnum.Gray,
    ].findIndex(s => s === status)
  }

  const handleColorSelect = ({
    color,
    shouldUpdate,
  }: {
    color: TransmissionReportColors
    shouldUpdate: boolean
  }) => {
    if (shouldUpdate) {
      mutate({
        transmissionReportId: transmissionReport?.id ?? 0,
        updateTransmissionReportParams: { color },
      })

      // This line opens the side navigation bar accordion based on the color selected by the user.
      location.state.expandedIndexOverride = getExpandedIndex(
        color as unknown as TransmissionReportDtoColorEnum,
      )
      // To ensure that the transmission reports page is aware of the triage color change and
      // doesn't perform its regular behavior of opening the top report of the accordion
      location.state.triageColorChangedTransmissionId = String(
        transmissionReport?.id ?? 0,
      )
      location.state.defaultExpanded =
        color as unknown as TransmissionReportDtoColorEnum
    }
    setShowMenu(false)
  }

  const editIconButton = (
    <IconButton
      aria-label="edit transmission color"
      w="100%"
      h="100%"
      background={backgroundColor}
      icon="edit"
      color="neutral.white"
      borderRadius="lg"
      _hover={{
        background: backgroundColor,
      }}
      _focus={{
        background: backgroundColor,
      }}
      onClick={() => setShowMenu(!showMenu)}
    />
  )

  return (
    <Box
      ref={elRef}
      position="relative"
      height="56px"
      width="56px"
      _hover={{
        '#badge-menu-toggle': {
          display: 'block',
        },
      }}
    >
      <TransmissionReportColorBadge
        color={transmissionReport?.color}
        size={'lg'}
      />
      <Flex
        id="badge-menu-toggle"
        borderRadius="lg"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        display={showMenu || isLoading ? 'block' : 'none'}
      >
        {isLoading ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            w="100%"
            h="100%"
            background={backgroundColor}
            color="neutral.white"
            borderRadius="lg"
          >
            <Spinner color="neutral.white" />
          </Flex>
        ) : (
          <Tooltip
            hasArrow
            label={
              transmissionReport?.color === TransmissionReportDtoColorEnum.Gray
                ? 'Select a triage color (Required)'
                : ''
            }
            placement="bottom-start"
          >
            {editIconButton}
          </Tooltip>
        )}
      </Flex>
      {showMenu && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          background="neutral.white"
          borderRadius="md"
          p="md"
          position="absolute"
          left="40px"
          bottom="-55px"
          sx={{
            boxShadow: '0px 2px 16px rgba(108, 119, 137, 0.32)',
          }}
        >
          {Object.values(cloneTransmissionReportDtoColorEnum).map(
            (color: any, i: number) => (
              <Button
                key={color}
                px={0}
                py={0}
                background="none"
                _hover={{
                  background: 'none',
                }}
                _focus={{
                  background: 'none',
                }}
                onClick={() =>
                  handleColorSelect({
                    color: color as unknown as TransmissionReportColors,
                    shouldUpdate: transmissionReport?.color !== color,
                  })
                }
              >
                <TransmissionReportColorBadge
                  color={color}
                  showCheck={color === transmissionReport?.color}
                  ml={i !== 0 ? 'md' : 0}
                  size={'lg'}
                  mb="0"
                />
              </Button>
            ),
          )}
        </Flex>
      )}
    </Box>
  )
}

export default UpdateTransmissionColorInput
