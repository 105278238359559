import React from 'react'

import {
  AccordionButton,
  AccordionIcon,
  Flex,
  Heading,
  Spinner,
} from '@rhythm/components'

import { TransmissionReportColorBadge } from '../../../../../features/transmissionReports/components'
import { TransmissionReportDtoColorEnum } from '../../../../../lib/api'

interface AccordionItemHeaderProps {
  children?: React.ReactNode
  label: string
  count: number
  color: TransmissionReportDtoColorEnum
  isLoading?: boolean
  otherAccordionLoading?: boolean
}

export const AccordionItemHeader = ({
  children,
  label,
  count,
  color,
  isLoading,
  otherAccordionLoading,
}: AccordionItemHeaderProps): React.ReactElement => {
  return (
    <h2>
      <AccordionButton
        as="div"
        flexDirection="column"
        alignItems="flex-start"
        px="xl"
        py="2xl"
        _hover={{
          background: 'neutral.200',
          cursor: otherAccordionLoading ? 'progress' : 'pointer',
        }}
      >
        <Flex alignItems="center" width="100%">
          <Flex flexGrow={1} alignItems="center">
            <TransmissionReportColorBadge color={color} size={'sm'} mr="xl" />
            <Heading
              display="inline-block"
              variant="h6"
              mr="lg"
              textTransform={'capitalize'}
            >
              {label}
            </Heading>
            <Heading display="inline-block" variant="h6" color="neutral.800">
              {isLoading ? <Spinner size="sm" color="primary.400" /> : count}
            </Heading>
          </Flex>
          <AccordionIcon />
        </Flex>
        <Flex>{children}</Flex>
      </AccordionButton>
    </h2>
  )
}
