import { DeviceTypes, DeviceVendors } from '../lib/api/generated-client'

export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const sentToEHROptions = [
  { label: 'Receipt of 1st Signature', value: 'firstSign' },
  { label: 'Receipt of 2nd Signature', value: 'secondSign' },
]

export const billingCadenceOptions = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
]

export const deviceSchedulingPrefsOptions = {
  options: ['pm', 'icd', 'crt-d', 'crt-p', 'ilr'],
  intervals: [31, 35, 91, 105],
}

export const heartFailureSchedulingPrefsOptions = {
  options: ['pm', 'icd', 'crt-d', 'crt-p', 'ilr'],
  intervals: [31, 35, 91, 0],
}

export const devicesVendorOptions = [
  {
    label: 'Abbott',
    url: 'https://www.merlin.net',
    value: DeviceVendors.Abbott,
  },
  {
    label: 'BIOTRONIK',
    url: 'https://www.biotronik-homemonitoring.com/hmsc_guiWeb/user/site/SignIn.jsf',
    value: DeviceVendors.Biotronik,
  },
  {
    label: 'Boston Scientific',
    url: 'https://www.latitude.bostonscientific.com/',
    value: DeviceVendors.BostonScientific,
  },
  {
    label: 'Medtronic',
    url: 'https://clc.medtroniccarelink.net/Clinician/Login/login.aspx?ReturnUrl=%2fClinician%2fhome.aspx',
    value: DeviceVendors.Medtronic,
  },
]

export const devicesTypesOptions = Object.values(DeviceTypes).map(device => ({
  label: device,
  value: device,
}))

export const MATCH_DEVICE_FIELDS_KEY = 'matchDevices'
export const PHONE_NUMBER_DEFAULT_COUNTRY = 'US'

export const VENDOR_FILTER_OPTIONS: Record<
  string,
  { value: string; label: string }
> = {
  [DeviceVendors.Abbott]: {
    label: 'Abbott',
    value: DeviceVendors.Abbott,
  },
  [DeviceVendors.Biotronik]: {
    label: 'BIOTRONIK',
    value: DeviceVendors.Biotronik,
  },
  [DeviceVendors.BostonScientific]: {
    label: 'Boston Scientific',
    value: DeviceVendors.BostonScientific,
  },
  [DeviceVendors.Medtronic]: {
    label: 'Medtronic',
    value: DeviceVendors.Medtronic,
  },
}
