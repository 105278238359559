import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'

export const useGetTaxonomyCodes = (
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const Api = useApiContext()
  return useQuery(
    ['taxonomyCodes'],
    async () => {
      const res = await Api.registeredNpisControllerGetAllTaxonomyData()
      const { taxonomyCodes } = res.data
      const transformedCodes = [
        ...taxonomyCodes.map(({ nucc_code, display_name }) => ({
          label: `${display_name}`,
          value: `${nucc_code}`,
        })),
      ]
      return transformedCodes
    },
    {
      ...options,
      staleTime: twentyFourHoursInMs,
    },
  )
}
