import { AnalyticReportType } from '../../../../types'

import exampleBarChart from './example-bar-chart.svg'
import exampleFanChart from './example-fan-chart.svg'
import exampleGroupedBarChart from './example-grouped-bar-chart.svg'
import exampleLineChart from './example-line-chart.svg'
import exampleScatterChart from './example-scatter-chart.svg'
import exampleStackedVerticalBarChart from './example-stacked-vertical-bar-chart.svg'
import exampleWaterfallChart from './example-waterfall-chart.svg'

const reportsData = [
  {
    title: 'Monitored by Device Type',
    lastUpdatedDate: '2021-07-28',
    chartImageSrc: exampleBarChart,
  },

  {
    title: 'Devices at ERI',
    lastUpdatedDate: '2021-07-15',
    chartImageSrc: exampleFanChart,
    isBookmarked: true,
  },
  {
    title: 'Total q30 HF Monitoring',
    lastUpdatedDate: '2021-07-22',
    chartImageSrc: exampleWaterfallChart,
  },
  {
    title: 'Monitored by Manufacturer',
    lastUpdatedDate: '2021-07-15',
    chartImageSrc: exampleBarChart,
  },
  {
    title: 'Signed Billable Reports',
    lastUpdatedDate: '2021-07-30',
    type: AnalyticReportType.PRODUCTIVITY,
    chartImageSrc: exampleGroupedBarChart,
  },
  {
    title: 'Signed Non-Billable Reports',
    lastUpdatedDate: '2021-07-06',
    type: AnalyticReportType.PRODUCTIVITY,
    chartImageSrc: exampleStackedVerticalBarChart,
  },
  {
    title: 'New Onset Afib',
    lastUpdatedDate: '2021-07-09',
    chartImageSrc: exampleLineChart,
  },
  {
    title: 'Devices with >40% RVp',
    lastUpdatedDate: '2021-07-12',
    chartImageSrc: exampleScatterChart,
  },
]

export default reportsData
