import {
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
} from '../lib/api'
import { TransmissionReportPageLocationState } from '../pages/physician/TransmissionReportPage/TransmissionReportPage'

import { getExpandedIndex } from './transmissionReportUtil'

interface getNextTransmissionIndex {
  location: { state: TransmissionReportPageLocationState }
  transmissionReports: TransmissionReportDto[]
  currentTransmissionId?: number
  nextTransmissionColor?: TransmissionReportDtoColorEnum
}

export const getNextTransmission = ({
  location,
  transmissionReports,
  currentTransmissionId,
  nextTransmissionColor,
}: getNextTransmissionIndex): TransmissionReportDto | undefined => {
  if (!transmissionReports.length) {
    return undefined
  }
  let selectedTransmissionInd: number = -1
  if (nextTransmissionColor) {
    location.state.defaultExpanded = nextTransmissionColor
    location.state.expandedIndexOverride = getExpandedIndex(
      nextTransmissionColor,
    )
  }
  if (!location.state?.defaultExpanded) {
    location.state = {
      ...location.state,
      defaultExpanded: TransmissionReportDtoColorEnum.Red,
    }
  }

  const colorToFind = location.state?.defaultExpanded
  if (selectedTransmissionInd < 0 && colorToFind) {
    if (currentTransmissionId) {
      const sameColorReports = transmissionReports.filter(
        t => t.color === colorToFind,
      )
      const nextSameColorReport =
        sameColorReports.findIndex(t => t.id === currentTransmissionId) + 1
      selectedTransmissionInd = transmissionReports.findIndex(
        t => t.id === sameColorReports[nextSameColorReport]?.id,
      )
    } else
      selectedTransmissionInd = transmissionReports.findIndex(
        t => t.color === colorToFind,
      )
  }

  selectedTransmissionInd =
    selectedTransmissionInd === -1 ? 0 : selectedTransmissionInd
  return (
    location?.state?.currentReport ??
    transmissionReports[selectedTransmissionInd]
  )
}
