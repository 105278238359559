import { Box } from '@rhythm/components'

export interface ImagePreviewProps {
  children: React.ReactNode
  onClick: () => void
  isActive: boolean
}

export const ImageThumbnail = ({
  children,
  onClick,
  isActive,
}: ImagePreviewProps): React.ReactElement => {
  return (
    <Box
      cursor="pointer"
      maxHeight="64px"
      maxWidth="88px"
      overflow="hidden"
      borderWidth={isActive ? '3px' : '1px'}
      borderRadius="md"
      borderColor={isActive ? 'primary.400' : 'primary.600'}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}
