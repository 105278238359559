import React, { ReactNode, useCallback } from 'react'

import { Editable, Error, Flex } from '@rhythm/components'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { useController, useForm } from 'react-hook-form'

import {
  ColumnHeader,
  TransmissionReportValuesGrid,
  usePatchTransmissionReportDevice,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import { VendorDataOverrides, VtDevice } from '../../../../../lib/api'
import { DATE_FORMAT } from '../../../../../utils/constants'
import { VENDOR_DATA_OVERRIDE_KEYS } from '../../../../../utils/constants/transmissionReports'
import displayValue from '../../../../../utils/displayValue'
import formatUtcDate from '../../../../../utils/formatUtcDate'
import { DATE_FORMAT_REGEX } from '../../../../../utils/regex'

const EditableContainer = (props: { children: ReactNode }) => (
  <Flex alignItems="center" pr="lg">
    {props.children}
  </Flex>
)

const DeviceBatteryTable = (): React.ReactElement => {
  const { control } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  })
  const { transmissionReport, isLoading: isLoadingTransmissionReport } =
    useTransmissionReportContext()
  const { mutate: patchTransmissionReportDevice } =
    usePatchTransmissionReportDevice()
  const { device } = transmissionReport ?? {}
  const {
    batteryMeasurementTime,
    batteryStatus,
    batteryVoltage,
    batteryRemainingLongevity,
    batteryPercentRemaining,
    capacitorChargeTime,
  }: Partial<VtDevice> = device ?? {}

  const {
    field: { onChange, onBlur, name, value },
    fieldState: { invalid },
    formState: { errors },
  } = useController({
    name: 'batteryMeasurementTime',
    control,
    rules: {
      pattern: {
        value: DATE_FORMAT_REGEX,
        message: 'Date is invalid or has wrong format (month/day/year)',
      },
    },
  })

  const handleSubmit = useCallback(
    (data: { name: keyof VendorDataOverrides; value: string }) => {
      // eslint-disable-next-line prefer-const
      let { name, value } = data
      if (name === 'device.batteryPercentRemaining') {
        const SPECIAL_CHAR = new RegExp(/[`~pr]/) // eslint-disable-line
        const testing = SPECIAL_CHAR.test(value!)
        if (testing) {
          value = value?.split('~pr')[0]
          value = value + '%'
        }
      }
      if (transmissionReport?.id && VENDOR_DATA_OVERRIDE_KEYS.includes(name)) {
        const vendorDataOverrides = { [name]: value }
        patchTransmissionReportDevice({
          transmissionReportId: transmissionReport.id,
          vendorDataOverrides,
        })
      }
    },
    [patchTransmissionReportDevice, transmissionReport],
  )

  const onSubmit = useCallback(
    (value: string) => {
      if (!invalid) {
        if (value) {
          const parsedDate = dayjs.utc(value, DATE_FORMAT)
          if (parsedDate.isValid()) {
            handleSubmit({
              name: 'device.batteryMeasurementTime' as keyof VendorDataOverrides,
              value: parsedDate.toISOString(),
            })
          }
        } else {
          handleSubmit({
            name: 'device.batteryMeasurementTime' as keyof VendorDataOverrides,
            value: '',
          })
        }
      }
    },
    [handleSubmit, invalid],
  )

  return (
    <>
      <TransmissionReportValuesGrid
        key={`device-battery-${!device && 'loading'}`}
        gridTemplateColumns="repeat(6, 1fr)"
        gridTemplateRows="repeat(2, minmax(40px, auto))"
        loading={{
          isLoading: isLoadingTransmissionReport,
          numOfColumns: 6,
          numOfRows: 1,
        }}
      >
        <ColumnHeader>Date/Time</ColumnHeader>
        <ColumnHeader>Battery Status</ColumnHeader>
        <ColumnHeader>Battery Voltage (V)</ColumnHeader>
        <ColumnHeader>Remaining Longevity</ColumnHeader>
        <ColumnHeader>Battery Percent Remaining (%)</ColumnHeader>
        <ColumnHeader>Battery Charge Time (S)</ColumnHeader>

        <EditableContainer>
          <Editable
            key={`batteryMeasurementTime-${device?.deviceId}`}
            name={name}
            defaultValue={formatUtcDate(batteryMeasurementTime)}
            fontSize="sm"
            width="full"
            value={value}
            onSubmit={onSubmit}
            onBlur={onBlur}
            onChange={onChange}
            isInvalid={invalid}
          />
        </EditableContainer>

        {[
          { batteryStatus },
          { batteryVoltage },
          { batteryRemainingLongevity },
          { batteryPercentRemaining },
          { capacitorChargeTime },
        ].map((data: Record<string, string | null | undefined>, _i: number) => {
          const [[overrideKey, defaultValue]] = Object.entries(data)

          return (
            <EditableContainer key={`${overrideKey}-${device?.deviceId}`}>
              <Editable
                name={`device.${overrideKey}`}
                defaultValue={displayValue(defaultValue)}
                fontSize="sm"
                width="full"
                onSubmit={value =>
                  handleSubmit({
                    name: `device.${overrideKey}` as keyof VendorDataOverrides,
                    value,
                  })
                }
              />
            </EditableContainer>
          )
        })}
      </TransmissionReportValuesGrid>
      {errors['batteryMeasurementTime']?.message && (
        <Error
          message={get(errors, `batteryMeasurementTime.message`, '') as string}
        />
      )}
    </>
  )
}

export default DeviceBatteryTable
