import React, { useContext, useState } from 'react'

import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Link,
  Spinner,
  Text,
  Tooltip,
} from '@rhythm/components'

import { Patient, TransmissionData } from '../../../../../../lib/api'
import formatDate from '../../../../../../utils/formatDate'

import './CellStyle.css'

import { generatePath } from 'react-router-dom'

import _ from 'lodash'
import moment from 'moment'

import {
  ScheduleNotSetIcon,
  ScheduleRequiresCorrectionIcon,
} from '../../../../../../assets'
import { useApiContext } from '../../../../../../context/ApiContext'
import { TransmissionReportColorBadge } from '../../../../../../features/transmissionReports'
import { routes } from '../../../../../../routes'
import {
  CheckedPatients,
  PatientActiveStatusEnum,
  ReportType,
} from '../../../../../../types'
import {
  getReportDisplayName,
  getSignedPDF,
} from '../../../../../../utils/transmissionReportUtil'
import { getPatientStatus } from '../../../../PatientProfilePage/components/PatientDetailsCard/PatientDetailsCard'
import PatientStatusBadge from '../../../../PatientProfilePage/components/PatientDetailsCard/PatientStatusBadge'
import { NavigationIcon } from '../../../../TransmissionReportPage/components/BillingCard/CustomIcons'
import { patientSelectionContext } from '../../../PatientSelectionProvider'

import { SignedReport } from './ReportMenuItem'
import { ReportsMenu } from './ReportsMenu'

export type ColumnCellProps = {
  row: {
    original: Patient
  }
}

export const CheckBoxHeader = (): React.ReactElement => {
  const [checkedPatients, setCheckedPatients] = useContext(
    patientSelectionContext,
  )

  const currentPagePatients =
    localStorage.getItem('currentPagePatients') ?? '[]'
  const pagePatientData = JSON.parse(currentPagePatients) as CheckedPatients[]

  const checkAllChangeHandler = (isChecked: boolean) => {
    let checkedRowItems = checkedPatients as CheckedPatients[]
    pagePatientData.forEach((rowData: CheckedPatients) => {
      const itemIndex = checkedRowItems.findIndex(
        x => x.patientId === rowData.patientId,
      )
      if (isChecked && itemIndex === -1) {
        checkedRowItems = [...checkedRowItems, rowData]
      } else if (itemIndex > -1) {
        checkedRowItems = [
          ...checkedRowItems.slice(0, itemIndex),
          ...checkedRowItems.slice(itemIndex + 1, checkedRowItems.length),
        ]
      }
    })

    setCheckedPatients(checkedRowItems ?? [])
  }

  const currentPageChecked = _.intersection(
    _.map(pagePatientData, 'patientId'),
    _.map(checkedPatients, 'patientId'),
  )

  const checkedItemCount: number = currentPageChecked?.length ?? 0
  const currentPageCount: number = pagePatientData?.length ?? 0

  const isCheckedAllSelected = (): boolean => {
    return checkedItemCount > 0 && currentPageCount === checkedItemCount
  }

  const isPartialSelected = (): boolean => {
    return checkedItemCount > 0 && currentPageCount !== checkedItemCount
  }

  return (
    <Checkbox
      paddingLeft="3px"
      isChecked={isCheckedAllSelected()}
      isIndeterminate={isPartialSelected()}
      onChange={e => checkAllChangeHandler(e.target.checked)}
    />
  )
}

export const CheckBoxCell = ({
  row: { original },
}: {
  row: { original: Patient }
}): React.ReactElement => {
  const { id } = original
  const [checkedPatients, setCheckedPatients] = useContext(
    patientSelectionContext,
  )

  const checkChangeHandler = (rowData: Patient, isChecked: boolean) => {
    let checkedRowItems = checkedPatients as CheckedPatients[]
    const itemIndex = checkedRowItems.findIndex(x => x.patientId === rowData.id)
    if (isChecked) {
      if (itemIndex === -1) {
        const transmissionData: Array<TransmissionData> = []
        if (rowData.reportData) {
          const reportDataArray = rowData.reportData as unknown as ReportType[]
          reportDataArray.forEach((reportItem: any) => {
            if (reportItem.downloaded === !1) {
              if (rowData?.clinic?.ehrIntegration) {
                if (reportItem?.transmissiontype !== ReportType.Remote) {
                  transmissionData.push({
                    transmissionId: reportItem.id,
                  })
                }
              } else {
                transmissionData.push({
                  transmissionId: reportItem.id,
                })
              }
            }
          })
        }
        const checkedRowData: CheckedPatients = {
          patientId: rowData.id,
          transmissionData,
        }
        checkedRowItems = [...checkedRowItems, checkedRowData]
      }
    } else {
      if (itemIndex > -1) {
        checkedRowItems = [
          ...checkedRowItems.slice(0, itemIndex),
          ...checkedRowItems.slice(itemIndex + 1, checkedRowItems.length),
        ]
      }
    }
    setCheckedPatients(checkedRowItems)
  }

  const isCheckedPatient = (id: string): boolean => {
    return (
      checkedPatients.findIndex((x: CheckedPatients) => x.patientId === id) > -1
    )
  }

  return (
    <Checkbox
      paddingTop={18}
      paddingLeft={'2px'}
      isChecked={isCheckedPatient(id)}
      onChange={e => checkChangeHandler(original, e.target.checked)}
    />
  )
}

export const PatientCell = ({
  row: { original },
}: {
  row: { original: Patient }
}): React.ReactElement => {
  const { id, mrn2, birthDate } = original

  const cellClickHandler = () => {
    if (id) {
      window.open(
        generatePath(routes.physician['patientProfile'], {
          id: id,
        }),
        '_blank',
      )
    }
  }

  const fullName = `${original.familyName}, ${original.givenName}`
  const formattedBirthDate = formatDate(birthDate, 'MM/DD/YYYY')
  return (
    <>
      <Flex alignItems="center">
        <Flex direction="row" alignItems="baseline" onClick={cellClickHandler}>
          <span>
            <Text
              mb="xs"
              className="patientText"
              color={'#1083CB'}
              fontWeight={700}
            >
              {fullName}
            </Text>
          </span>
          <span style={{ marginTop: '7px' }}>
            <NavigationIcon />
          </span>
        </Flex>
      </Flex>
      <Text
        variant="secondary"
        className="patientSubText"
        color={'#6C7789'}
        fontSize={12}
        fontWeight={600}
        marginTop={'2px'}
      >
        {(formattedBirthDate?.length ?? 0) > 0 ? formattedBirthDate : '-'}
      </Text>
      <Text
        variant="secondary"
        className="patientSubText"
        color={'#6C7789'}
        fontSize={12}
        fontWeight={600}
        marginTop={'2px'}
      >
        {(mrn2?.length ?? 0) > 0 ? mrn2 : '-'}
      </Text>
    </>
  )
}

const PatientStatusCell = ({ row: { original } }: ColumnCellProps) => {
  return (
    <PatientStatusBadge
      patientStatus={getPatientStatus(original?.patientStatus)}
    />
  )
}

const DateOfBirthCell = ({ row: { original } }: ColumnCellProps) => {
  const { birthDate } = original

  return <Text variant="secondary">{formatDate(birthDate, 'MM/DD/YYYY')}</Text>
}

const ClinicCell = ({ row: { original } }: ColumnCellProps) => {
  const { clinic } = original
  const clinicName = clinic?.name

  return <Text fontWeight="bold">{clinicName}</Text>
}

const TypeCell = ({ row: { original } }: ColumnCellProps) => {
  const { matchDevices, patientStatus } = original
  if (!matchDevices || matchDevices?.length === 0)
    return <Text fontWeight="bold">-</Text>
  console.log('orig', (original as any).scheduleReadOnly)
  return (
    <>
      {matchDevices?.map(e => (
        <Box
          display={'flex'}
          key={`device-type-col-${e.id}`}
          gap={'1'}
          alignItems={'center'}
        >
          <Text fontWeight="bold">{e.type ? e.type : '-'}</Text>
        </Box>
      ))}
    </>
  )
}
const DeviceTypeCell = ({ row: { original } }: ColumnCellProps) => {
  // eslint-disable-next-line
  const { matchDevices }: any = original
  const venderCell: any[] = []
  if (matchDevices && matchDevices.length > 0) {
    matchDevices.forEach((e: any) => {
      venderCell.push({
        date: e?.deviceImplantDate
          ? moment(e?.deviceImplantDate).format('MM/DD/YYYY')
          : '-',
        text: `${
          VendorCellMap[e.vendor] ? VendorCellMap[e.vendor] : e.vendor
        }  ${e.modelName}`,
      })
    })
  }

  return (
    <>
      {venderCell.length > 0
        ? venderCell.map((e: any) => (
            <div key={e}>
              <Text fontWeight="bold">{e.text}</Text>
              <Text variant="secondary">{e.date}</Text>
            </div>
          ))
        : '-'}
    </>
  )
}

const MostRecentReportCell = ({ row: { original } }: ColumnCellProps) => {
  const [fetchingSignPDF, setFetchingSignPDF] = useState(false)
  const Api = useApiContext()
  // eslint-disable-next-line
  const {
    rhythmId,
    recentReportColor,
    recentReportDate,
    recentReportId,
    recentReportType,
    recentReportDeviceType,
    recentHeartFailureStatus,
  } = original

  if (!recentReportId) {
    return <Text fontWeight="bold">-</Text>
  }

  const mostRecentDate = recentReportDate
    ? moment(recentReportDate).format('MM/DD/YYYY')
    : ''

  const openReport = async () => {
    setFetchingSignPDF(true)
    try {
      const { data: vendorTransmissionsReports } =
        await Api.transmissionReportsControllerVendorTransmissionsReports({
          transmissionReportId: recentReportId,
        })
      if (
        !vendorTransmissionsReports ||
        vendorTransmissionsReports.vendorTransmissionReports.length === 0
      )
        return
      const signedReport = (await getSignedPDF(
        vendorTransmissionsReports.vendorTransmissionReports,
        rhythmId,
      )) as SignedReport | undefined

      if (signedReport) {
        const blob = await fetch(signedReport.pdfUrl as RequestInfo).then(r =>
          r.blob(),
        )

        const pdfURL = URL.createObjectURL(blob)
        window.open(pdfURL)
      }
    } catch (e) {
      console.log('Open report Failed for Most Recent Report column:', e)
    } finally {
      setFetchingSignPDF(false)
    }
  }
  const reportTypeDisplayName = getReportDisplayName(
    recentReportType,
    recentReportDeviceType,
    recentReportColor,
    recentHeartFailureStatus,
  )

  return (
    <Text
      color={'#1083CB'}
      cursor={'pointer'}
      fontWeight="bold"
      onClick={() => openReport()}
    >
      <HStack>
        {recentReportColor && (
          <TransmissionReportColorBadge
            mb={'sm'}
            color={recentReportColor}
            size={'sm'}
          />
        )}
        <Box>
          {reportTypeDisplayName} {mostRecentDate}
        </Box>

        {fetchingSignPDF && <Spinner marginRight={'5px'} size={'sm'} />}
        {!fetchingSignPDF && (
          <Link className="lnkMostRecentReport" as={'div'} fontSize={'sm'}>
            <HStack>
              <NavigationIcon />
            </HStack>
          </Link>
        )}
      </HStack>
    </Text>
  )
}

const ReportsCell = ({ row: { original } }: ColumnCellProps) => {
  // eslint-disable-next-line
  const { count, reportData } = original

  if (reportData === null || reportData === undefined) {
    return (
      <Text fontWeight="bold">
        {count ? (
          <HStack>
            <Box>{count} Not Downloaded</Box>
          </HStack>
        ) : (
          '-'
        )}
      </Text>
    )
  }

  return (
    <Text fontWeight="bold">
      <HStack>
        <ReportsMenu patient={original} />
      </HStack>
    </Text>
  )
}

const VendorCellMap: any = {
  abbott: 'Abbott',
  biotronik: 'BIOTRONIK',
  boston_scientific: 'Boston Scientific',
  medtronic: 'Medtronic',
}

export const cells = {
  CheckBoxCell,
  PatientStatusCell,
  DateOfBirthCell,
  ClinicCell,
  TypeCell,
  DeviceTypeCell,
  MostRecentReportCell,
  ReportsCell,
}

export const headerCells = {
  CheckBoxHeader,
}
