import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiTransmissionReportsControllerStorePDFDownloadInfoRequest } from '../../../lib/api'

export const useSendDownloadData = () => {
  const Api = useApiContext()
  return useMutation(
    ({
      transmissionReportId,
      downloadDate,
    }: DefaultApiTransmissionReportsControllerStorePDFDownloadInfoRequest) =>
      Api.transmissionReportsControllerStorePDFDownloadInfo({
        transmissionReportId,
        downloadDate,
      }),
  )
}
