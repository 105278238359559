import '@fontsource/bad-script'

import { useEffect } from 'react'

import {
  Button,
  Form,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  //Select,
  TextareaField,
  VStack,
} from '@rhythm/components'
import { capitalize } from 'lodash'
import { Controller, useForm } from 'react-hook-form'

import { useApiContext } from '../../../../context/ApiContext'
import { queryClient, UpdatePatientParams } from '../../../../lib/api'
import {
  DEFAULT_NOTES,
  defaultActiveInactiveStatus,
} from '../../../../pages/physician/PatientProfilePage/components/PatientDetailsCard/PatientDetailsCard'
import {
  Patient,
  PatientActiveInactiveStatus,
  PatientActiveStatusEnum,
} from '../../../../types'

//import { useUpdatePatient } from '../../api/updatePatient'

export interface PatientStatusModalProps {
  isManagedInRS: boolean
  patient: Patient
  isOpen: boolean
  onClose: () => void
  refetchPatient: () => void
}

const contentMaxLength: number = 150

const PatientStatusModal = ({
  isManagedInRS,
  patient,
  isOpen,
  onClose,
  refetchPatient,
}: PatientStatusModalProps) => {
  const patientActiveInactiveStatus: PatientActiveInactiveStatus =
    defaultActiveInactiveStatus

  useEffect(() => {
    if (patient && patient.patientStatus) {
      patientActiveInactiveStatus.status = patient.patientStatus
    }
    if (patient && patient.statusReason) {
      patientActiveInactiveStatus.reason = patient.statusReason
    }
    if (patient.statusNotes !== undefined && patient.statusNotes !== null) {
      patientActiveInactiveStatus.note = patient.statusNotes ?? ''
    } else {
      if (!isManagedInRS) {
        patientActiveInactiveStatus.note = DEFAULT_NOTES
      } else {
        patientActiveInactiveStatus.note = ''
      }
    }
    //   patientActiveInactiveStatus.note = statusNotes ?? ''
  }, [patient, patientActiveInactiveStatus, isManagedInRS])

  const defaultValues: Record<string, any> =
    patientActiveInactiveStatus as PatientActiveInactiveStatus

  const Api = useApiContext()

  const methods = useForm({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const {
    //watch,
    control,
    formState: { dirtyFields, errors, isValid },
    getValues,
    reset,
  } = methods

  useEffect(() => {
    methods.setValue('status', {
      label: capitalize(
        patientActiveInactiveStatus.status ?? PatientActiveStatusEnum.Active,
      ),
      value:
        patientActiveInactiveStatus.status ?? PatientActiveStatusEnum.Active,
    })
    methods.setValue('reason', {
      label: capitalize(patientActiveInactiveStatus.reason ?? ''),
      value: patientActiveInactiveStatus.reason ?? '',
    })
    methods.setValue('note', patientActiveInactiveStatus.note ?? '')
  }, [methods, patient, patientActiveInactiveStatus, defaultValues])

  const onSaveModal = async () => {
    const isDirty = Object.keys(dirtyFields).length > 0

    if (isDirty) {
      const updatedStatus: string =
        getValues('status').value ?? PatientActiveStatusEnum.Active

      const updatedNotes: string =
        getValues('note')?.length > 0 ? getValues('note') : null

      const updatedParam = {
        Status: updatedStatus,
        Note: updatedNotes,
        Reason: undefined,
      }

      if (updatedStatus === PatientActiveStatusEnum.Inactive) {
        updatedParam.Reason = getValues('reason').value ?? null
      }

      if (isValid) {
        await Api.patientsControllerUpdate({
          patientId: patient.id as string,
          updatePatientParams: {
            statusNotes: updatedNotes,
          } as unknown as UpdatePatientParams,
        }).then(data => {
          const {
            data: { patient },
          } = data

          queryClient.invalidateQueries('patients')
          queryClient.setQueryData(['patient', patient.id], patient)
          queryClient.setQueriesData(
            ['transmissionReport'],
            (previous: any) => {
              if (previous?.patient?.id === patient.id) {
                previous.patient = patient
              }
              return previous
            },
          )
        })
      }

      refetchPatient()
    }
    onClose()
  }

  const onCloseModal = () => {
    reset({
      status: {
        label: capitalize(defaultValues.status),
        value: defaultValues.status,
      },
      reason: {
        label: capitalize(defaultValues.reason),
        value: defaultValues.reason,
      },
      note: defaultValues.note,
    })
    onClose()
  }

  return (
    <Modal
      width={385}
      isOpen={isOpen}
      onClose={onCloseModal}
      closeOnOverlayClick={false}
      isCentered={true}
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button onClick={onSaveModal} disabled={!isValid}>
            Save
          </Button>
        </HStack>
      }
    >
      <HStack spacing="xl" mb="xl">
        <Heading variant="h5">Edit Status Notes</Heading>
      </HStack>
      <Form methods={methods} onSubmit={onClose} apiError={errors}>
        <VStack spacing="lg">
          <FormControl>
            <FormLabel>Note (Optional)</FormLabel>
            <Controller
              name={'note'}
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
                formState: { isValid },
              }) => (
                <>
                  <TextareaField
                    label=""
                    height={'100px'}
                    w="full"
                    defaultValue={value ?? ''}
                    name="note"
                    variant="default"
                    placeholder="Notes ..."
                    onChange={onChange}
                    //maxLength={150}
                    onBlur={e => {
                      onBlur()
                    }}
                  />
                </>
              )}
              rules={{
                maxLength: {
                  value: contentMaxLength,
                  message: `Notes cannot be longer than ${contentMaxLength} characters.`,
                },
              }}
            />
          </FormControl>
        </VStack>
      </Form>
    </Modal>
  )
}

export default PatientStatusModal
