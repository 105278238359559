import React from 'react'

import { Flex, IconButton } from '@chakra-ui/react'
import {
  Heading,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@rhythm/components'
import { DatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

export const DatePickerHeader: DatePickerProps['renderCustomHeader'] = ({
  date,
  decreaseMonth,
  increaseMonth,
}: {
  date: Date
  decreaseMonth: () => void
  increaseMonth: () => void
}) => {
  const monthName = date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  })

  return (
    <Flex alignItems="center" justifyContent="space-between" mb="lg">
      <IconButton
        variant="ghost"
        size={'sm'}
        aria-label="Previous Month"
        icon={<Icon icon="chevron-left" color="neutral.black" />}
        onClick={decreaseMonth}
      />
      <Heading variant="h6" fontWeight="regular">
        {monthName}
      </Heading>
      <IconButton
        variant="ghost"
        size={'sm'}
        aria-label="Next Month"
        icon={<Icon icon="chevron-right" color="neutral.black" />}
        onClick={increaseMonth}
      />
    </Flex>
  )
}

export interface DatePickerInputProps extends InputProps {
  setCalenderOpen: (v: boolean) => void
}

export const DatePickerInput = React.forwardRef<
  HTMLInputElement,
  DatePickerInputProps
>((props, ref) => {
  const { setCalenderOpen, ...restProps } = props
  return (
    <InputGroup>
      <Input ref={ref} {...restProps} bgColor={'white'} />
      <InputRightElement zIndex={1}>
        <IconButton
          variant="ghost"
          size={'sm'}
          aria-label="Open Calender"
          icon={<Icon icon="calendar" color="neutral.800" boxSize="sm" />}
          onClick={() => setCalenderOpen(true)}
        />
      </InputRightElement>
    </InputGroup>
  )
})
DatePickerInput.displayName = 'DatePickerInput'
