import { useQuery } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'

export const useGetAllFlags = () => {
  const Api = useApiContext()
  return useQuery('featureFlags', async () => {
    const res = await Api.featureFlagControllerGetAllFlags()
    return res
  })
}
