import {
  ComponentProps,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

import { User } from '../types'

export interface CurrentUser extends User {
  isExternal: boolean
}

export type UserContextType = {
  currentUser: Partial<CurrentUser>
  setCurrentUser: Dispatch<SetStateAction<Partial<CurrentUser>>>
}

export const UserContext = createContext<UserContextType>({
  currentUser: {},
  setCurrentUser: () => {},
})

export const useUserContext = (): UserContextType => useContext(UserContext)

export const UserContextProvider = ({
  children,
}: ComponentProps<FC>): JSX.Element => {
  const [currentUser, setCurrentUser] = useState({})

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
    }),
    [currentUser],
  )
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
