import { Toast, useToast } from '@rhythm/components'

export const useToastHook = () => {
  const toast = useToast()
  const showToast = (
    message: any,
    variant:
      | 'default'
      | 'info'
      | 'success'
      | 'warning'
      | 'error'
      | undefined = 'success',
  ) =>
    toast({
      position: 'bottom-right',
      render: ({ onClose }) => (
        <Toast
          variant={variant}
          onClose={onClose}
          style={{
            position: 'relative',
            bottom: '50px',
            border: '1px solid',
            borderColor: ['success', 'info'].includes(variant)
              ? 'green'
              : 'red',
          }}
        >
          {message}
        </Toast>
      ),
    })

  return { showToast }
}
