import React, { useEffect, useState } from 'react'

import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Search,
  SideBar,
  SideBarProps,
  Skeleton,
  Text,
} from '@rhythm/components'

import { TransmissionReportIcon } from '../../../../../assets'
import { useGetTransmissionReportsCountByColor } from '../../../../../features/transmissionReports'
import { useGetTransmissionReportSearchUnsigned } from '../../../../../features/transmissionReports/api/getTransmissionReportSearchUnsigned'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import { TransmissionReportDtoColorEnum } from '../../../../../lib/api'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../../../utils/constants/transmissionReports'

import TransmissionReportsAccordion from './TransmissionReportsAccordion'
import TransmissionSearch from './TransmissionSearch'

export interface TransmissionReviewSideBarProps extends SideBarProps {
  expandedIndexOverride: number | undefined
  selectedTransmissionReportId: number
  onClose: () => void
  onSelectTransmissionReport: (id: number) => void
}

const TransmissionReviewSideBar = ({
  expandedIndexOverride,
  selectedTransmissionReportId,
  isOpen,
  onClose,
  onSelectTransmissionReport,
}: TransmissionReviewSideBarProps): React.ReactElement => {
  const { data: countData, isLoading } = useGetTransmissionReportsCountByColor()
  const { currentUser } = useCurrentUser()

  const [searchQuery, setSearchQuery] = useState<string>('')

  const {
    data: searchUnsignedData,
    isLoading: isSearchUnsignedLoading,
    isSuccess: isSearchUnsignedSuccess,
    isError: isSearchUnsignedError,
    refetch: refetchSearchUnsigned,
  } = useGetTransmissionReportSearchUnsigned(
    {
      transmissionReportSearchUnsignedReportsRequestDto: {
        query: searchQuery,
      },
    },
    {
      enabled: !!searchQuery && searchQuery?.length >= 2,
    },
  )

  useEffect(() => {
    if (searchQuery?.length < 2) return
    refetchSearchUnsigned()
  }, [refetchSearchUnsigned, searchQuery])

  let total = 0
  if (countData?.counts && !isLoading) {
    Object.values(countData?.counts as object).forEach(val => (total += val))
    if (
      currentUser &&
      currentUser.role &&
      PRACTITIONER_SIGNATURE_ROLES.includes(currentUser.role)
    ) {
      const grayReportsCount = (countData?.counts as any)?.[
        TransmissionReportDtoColorEnum.Gray
      ]
      total -= grayReportsCount
    }
  }

  return (
    <SideBar isOpen={isOpen} position="fixed" top={0} left={0} bottom={0}>
      {isOpen && (
        <Flex
          bg="primary.600"
          borderRightRadius="40px"
          height="65px"
          width="68px"
          mr="2xl"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          right="-20"
          _hover={{
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          <Icon icon="sidebar-left" color="neutral.white" />
        </Flex>
      )}
      <Flex
        flexDirection="column"
        bg="primary.600"
        p="2xl"
        height={'fit-content'}
      >
        <HStack spacing="xl" mb="xl">
          <Box height="lg" width="lg">
            <TransmissionReportIcon />
          </Box>
          <Heading variant="h4" color="neutral.white">
            <Skeleton isLoaded={!isLoading}>{`Review ${total} Report${
              (!isLoading && total) > 1 ? 's' : ''
            }`}</Skeleton>
          </Heading>
        </HStack>
        <Text color="neutral.200">
          Select Patients from the list below to view their report.
        </Text>

        <Box marginTop={4}>
          <Search
            size="md"
            width={700}
            float={'left'}
            placeholderColor="neutral.800"
            value={searchQuery}
            minLength={1}
            style={{
              letterSpacing: '0.5px',
            }}
            onDebouncedSearch={(value: string) => {
              setSearchQuery(value)
            }}
            id={'transmissionSearch'}
            minCharactersRequired={1}
            debounceTime={1000}
          />
          {searchQuery?.length == 1 && (
            <Text color="neutral.200" mt={1} fontSize={'sm'}>
              Please enter at least 2 characters
            </Text>
          )}
        </Box>
      </Flex>
      {searchQuery.length < 2 ? (
        <TransmissionReportsAccordion
          expandedIndexOverride={expandedIndexOverride}
          selectedTransmissionReportId={selectedTransmissionReportId}
          onSelectTransmissionReport={onSelectTransmissionReport}
        />
      ) : (
        // Search Results
        <Box overflowY={'auto'}>
          {isSearchUnsignedError && <Text p={4}>Error getting results</Text>}
          {isSearchUnsignedLoading && (
            <Box p={4}>
              {Array.from({ length: 7 }).map((_, i) => (
                <Skeleton
                  key={`search-unsigend-result-loading-${i}`}
                  height="20"
                />
              ))}
            </Box>
          )}
          {isSearchUnsignedSuccess &&
          searchUnsignedData?.transmissionReports.length === 0 ? (
            <Text p={4}>No results found</Text>
          ) : (
            searchUnsignedData?.transmissionReports.map(e => (
              <TransmissionSearch
                key={e.id}
                onSelectTransmissionReport={onSelectTransmissionReport}
                selectedTransmissionReportId={selectedTransmissionReportId}
                transmission={e}
              />
            ))
          )}
        </Box>
      )}
    </SideBar>
  )
}

export default TransmissionReviewSideBar
