import React, { useEffect, useRef, useState } from 'react'

import { Box, HStack, Text } from '@rhythm/components'

export interface NoteProps {
  value: string
  isDisabled?: boolean
  charLimit?: number
  shouldFocus?: boolean
  onChange?: (updatedValue: string) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: () => void
}

const Note = ({
  value,
  isDisabled = false,
  charLimit = 2000,
  shouldFocus = false,
  onChange,
  onBlur,
  onFocus,
}: NoteProps): React.ReactElement => {
  const [isFocused, setIsFocused] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [triggerOnce, setTriggerOnce] = useState<boolean>(false)
  useEffect(() => {
    if (textAreaRef.current && shouldFocus && !triggerOnce) {
      textAreaRef.current.focus()
      textAreaRef.current.setSelectionRange(value.length, value.length)
      setTriggerOnce(true)
    }
  }, [shouldFocus, triggerOnce, value.length])

  const handleFocus = () => {
    if (!isDisabled) setIsFocused(true)
    onFocus && onFocus()
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!isDisabled && isFocused) setIsFocused(false)
    onBlur && onBlur(e)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedValue = e.target.value
    onChange && onChange(updatedValue)
  }
  let borderColor = 'neutral.800' // Default value

  if (isDisabled) {
    borderColor = 'neutral.600'
  } else if (isFocused) {
    borderColor = 'primary.400'
  }

  const fetchHeight = () => {
    const lines = value.split('\n').length
    if (lines > 5 || value.length > 500) {
      return '200px'
    }
    return '100px'
  }

  return (
    <Box
      py="xl"
      px="lg"
      borderRadius="md"
      width="100%"
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: isFocused ? 'primary.400' : 'neutral.600',
      }}
      _hover={{
        borderColor: borderColor,
      }}
    >
      <HStack spacing="none" alignItems="flex-end" justifyContent="center">
        <Box position="relative" w="100%" minW="100%" wordBreak="break-word">
          <textarea
            ref={textAreaRef}
            value={value}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isDisabled}
            style={{
              width: '100%',
              fontSize: 'lg',
              lineHeight: '1.6',
              minHeight: '100px',
              height: fetchHeight(),
              outline: 'none',
              resize: 'none',
            }}
          />
          <Text
            variant="caption"
            fontSize={12}
            color={value.length > charLimit ? 'red.500' : 'neutral.600'}
            position="absolute"
            bottom="-14px"
            right="-8px"
            cursor={'default'}
            userSelect={'none'}
          >
            {value.length}/{charLimit}
          </Text>
        </Box>
      </HStack>
    </Box>
  )
}

export default Note
