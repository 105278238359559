import React from 'react'

import { Flex, HStack } from '@rhythm/components'

import {
  TransmissionReportNote,
  VtDeviceLead,
  VtDeviceMeasurement,
} from '../../../../../lib/api'
import NarrativeCard from '../NarrativeCard'

import DeviceMonitoredValuesNotes from './DeviceMonitoredValuesNotes'
import DeviceMonitoredValuesTable from './DeviceMonitoredValuesTable'

export interface DeviceMonitoredValuesCardProps {
  notes: TransmissionReportNote[]
  deviceLeads?: VtDeviceLead[]
  deviceMeasurement?: VtDeviceMeasurement
}

const DeviceMonitoredValuesCard = (): React.ReactElement => {
  return (
    <NarrativeCard icon="monitored-values" title="Monitored Values">
      <HStack alignItems="top" spacing="4xl">
        <Flex width="40%" direction="column" alignItems="stretch">
          <DeviceMonitoredValuesNotes />
        </Flex>
        <Flex width="60%" direction="column" alignItems="stretch">
          <DeviceMonitoredValuesTable />
        </Flex>
      </HStack>
    </NarrativeCard>
  )
}

export default DeviceMonitoredValuesCard
