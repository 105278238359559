import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  BillableQuery,
  DefaultApiAnalyticsControllerBillableReportRequest,
} from '../../lib/api'

export const useGetBillingAnalyticsData = (billableQuery: BillableQuery) => {
  const Api = useApiContext()
  return useQuery(
    'billableAnalyticsData',
    async () => {
      const res = await Api.analyticsControllerBillableReport({
        billableQuery,
      } as DefaultApiAnalyticsControllerBillableReportRequest)
      return res.data
    },
    {
      enabled:
        !!billableQuery &&
        !!billableQuery.clinicIds &&
        billableQuery.clinicIds.length > 0 &&
        billableQuery.dateRange &&
        billableQuery.dateRange.length > 0 &&
        billableQuery.columns &&
        billableQuery.columns.length > 0,
    },
  )
}
