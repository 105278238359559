import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useGetAllFlags } from '../features/featureFlags/api/useGetAllFlags'
import { Roles } from '../lib/api'

import { useUserContext } from './UserContext'

// Context value type
interface FeatureFlagsContextType {
  featureFlags: Array<string> | []
  isLoading: boolean
  error: unknown
  hasFeatureFlag: (shortCode: string) => boolean
}

// Context with an initial default value
const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  featureFlags: [],
  isLoading: false,
  error: null,
  hasFeatureFlag: () => false,
})

// Custom hook to use the FeatureFlags context
export const useFeatureFlagsContext = (): FeatureFlagsContextType =>
  useContext(FeatureFlagsContext)

// Props for the provider component
interface FeatureFlagsProviderProps {
  children: ReactNode
}

// Provider component
export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => {
  const { currentUser } = useUserContext()
  const [featureFlags, setFeatureFlags] = useState<Array<string>>([])
  const { data: apiFeatureFlags, isLoading, error } = useGetAllFlags()

  function getRoleSetting(role?: Roles) {
    const Internal = [
      Roles.Internaladmin,
      Roles.Internalclinical,
      Roles.InternalsuperAdmin,
      Roles.InternalnonClinical,
    ]
    const testing = [Roles.Internaladmin, Roles.InternalsuperAdmin]
    if (role && testing.includes(role)) {
      return 2
    }
    if (role && Internal.includes(role)) {
      return 3
    }
    return 4
  }

  const userRoleSetting =
    currentUser.featureFlagRole || getRoleSetting(currentUser.role)
  useEffect(() => {
    const localFeatureFlags = localStorage.getItem('featureFlags') ?? '[]'
    if (JSON.parse(localFeatureFlags || '')?.length && localFeatureFlags) {
      setFeatureFlags(JSON.parse(localFeatureFlags))
    } else {
      const flags =
        apiFeatureFlags?.data
          .filter(flag => {
            if (userRoleSetting > 1) return userRoleSetting <= flag.setting
            else if (userRoleSetting < 2) return false
          })
          .map(flag => flag.shortCode) ?? []
      setFeatureFlags(flags)
      localStorage.setItem('featureFlags', JSON.stringify(flags))
    }
  }, [currentUser])

  const hasFeatureFlag = (shortCode: string): boolean =>
    featureFlags.includes(shortCode)

  const contextValue = useMemo(
    () => ({
      featureFlags,
      isLoading: !localStorage.getItem('featureFlags') && isLoading,
      error,
      hasFeatureFlag,
    }),
    [featureFlags, isLoading, error],
  )

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
