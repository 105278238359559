import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApi,
  DefaultApiTransmissionReportsControllerCreateNoteRequest,
  queryClient,
  TransmissionReport,
  TransmissionReportNoteLocationEnum,
} from '../../../../lib/api'

export interface AlertNote {
  alertId?: string
  transmissionReportId: number
}

const createTransmissionReportAlertNote = async (
  Api: DefaultApi,
  noteInfo: AlertNote,
  onCardUpdate?: any,
) => {
  return new Promise(async (resolve: any, reject) => {
    const { data } = (await Api.transmissionReportsControllerCreateNote(
      noteInfo as unknown as DefaultApiTransmissionReportsControllerCreateNoteRequest,
    )) as unknown as any
    const { note } = data
    const { alertId, transmissionReportId } = noteInfo
    if (onCardUpdate) {
      onCardUpdate({ note, alertId })
      return
    }
    queryClient.setQueryData<Partial<TransmissionReport | undefined>>(
      ['transmissionReport', transmissionReportId],
      transmissionReport => {
        if (
          transmissionReport?.alerts &&
          note.location === TransmissionReportNoteLocationEnum.Alert
        ) {
          const { alerts } = transmissionReport
          const currentAlert = alerts.find(alert => alert.id === note.alert?.id)
          if (!currentAlert) return

          currentAlert.notes = (currentAlert.notes || []).concat(note)
          transmissionReport.alerts = alerts.map(alert =>
            alert.id === currentAlert.id ? currentAlert : alert,
          )
        } else if (
          transmissionReport?.notes &&
          [
            TransmissionReportNoteLocationEnum.BatteryStatus,
            TransmissionReportNoteLocationEnum.MonitoredValues,
            TransmissionReportNoteLocationEnum.Transmission,
          ].includes(note.location)
        ) {
          transmissionReport.notes = (transmissionReport.notes || []).concat(
            note,
          )
        }
        return transmissionReport
      },
    )

    queryClient
      .invalidateQueries(['transmissionReport', transmissionReportId])
      .then(() => setTimeout(resolve, 100))
      .catch(() => setTimeout(resolve, 100))
  })
}

export const useCreateTransmissionReportAlertNote = (onCardUpdate?: any) => {
  const Api = useApiContext()
  return useMutation(
    (noteInfo: DefaultApiTransmissionReportsControllerCreateNoteRequest) =>
      createTransmissionReportAlertNote(Api, noteInfo, onCardUpdate),
    {
      onSuccess: (data, variables) => {},
      onError: (error, variables, context) => {},
    },
  )
}
