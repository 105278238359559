import { useQuery } from 'react-query'

import { useApiContext } from '../../../../../context/ApiContext'

export const useGetSignAllStatus = () => {
  const Api = useApiContext()
  return useQuery(['getSignAllStatus'], async () => {
    const res = await Api.transmissionReportsControllerSignAllStatus()
    return res.data ?? []
  })
}
