import React from 'react'

import { Box, Editable, EditableProps, Flex, Text } from '@rhythm/components'

import {
  baseStyles,
  ColumnHeader,
  RowTitle,
  TransmissionReportValuesGrid,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import { usePatchProgrammedParameters } from '../../../../../features/transmissionReports/api/programmedParameters/patchProgrammedParameters'
import { ProgrammedParametersDto } from '../../../../../lib/api'
import displayMonitoredValue from '../../../../../utils/displayMonitoredValue'

interface CellProps extends EditableProps {
  name: string
}
const Cell = (props: CellProps) => (
  <Flex {...baseStyles}>
    <Editable w="full" fontSize="sm" {...props} />
  </Flex>
)

const EmptyCell = () => (
  <Flex {...baseStyles} paddingLeft="md">
    {displayMonitoredValue('')}
  </Flex>
)

const DeviceProgrammedParametersTable = (): React.ReactElement => {
  const { transmissionReport, isLoading: isLoadingTransmissionReport } =
    useTransmissionReportContext()
  const { programmedParameter } = transmissionReport ?? {}
  const { mutate: patchProgrammedParameters } = usePatchProgrammedParameters()

  const handleSubmit = (data: {
    name: keyof ProgrammedParametersDto
    value: string
  }) => {
    const { name, value } = data
    if (transmissionReport?.id || transmissionReport?.programmedParameter?.id) {
      const programmedParametersDto: ProgrammedParametersDto = {
        [name]: value,
        id: transmissionReport?.programmedParameter?.id ?? undefined,
      }

      patchProgrammedParameters({
        transmissionReportId: transmissionReport?.id,
        programmedParametersDto,
      })
    }
  }

  // const { deviceLeads, deviceMeasurement } = transmissionReport ?? {}

  return (
    <Box>
      <Box display={'flex'} justifyContent="space-between" py={4}>
        <Text fontSize={'lg'} fontWeight={'bold'}>
          Programmed Parameters
        </Text>
      </Box>
      <TransmissionReportValuesGrid
        key={`device-leads-${programmedParameter?.id}${
          !programmedParameter && '-loading'
        }`}
        gridTemplateColumns="1fr repeat(1, 40%)"
        gridTemplateRows="repeat(8, minmax(44px, auto))"
        loading={{
          isLoading: isLoadingTransmissionReport,
          numOfColumns: 2,
          numOfRows: 8,
        }}
      >
        <ColumnHeader>Type</ColumnHeader>
        <ColumnHeader>&nbsp;&nbsp;&nbsp;Values</ColumnHeader>

        <RowTitle>Mode</RowTitle>
        <Cell
          key={`mode-${programmedParameter?.id}`}
          name="mode"
          defaultValue={displayMonitoredValue(programmedParameter?.mode)}
          onSubmit={value =>
            handleSubmit({
              name: 'mode',
              value,
            })
          }
        />

        <RowTitle>Lower Rate (BPM)</RowTitle>
        <Cell
          key={`lowerRate-${programmedParameter?.id}`}
          name="mode"
          defaultValue={displayMonitoredValue(programmedParameter?.lowerRate)}
          onSubmit={value =>
            handleSubmit({
              name: 'lowerRate',
              value,
            })
          }
        />

        <RowTitle>Upper Rate (BPM)</RowTitle>
        <Cell
          key={`upperRate-${programmedParameter?.id}`}
          name="upperRate"
          defaultValue={displayMonitoredValue(programmedParameter?.upperRate)}
          onSubmit={value =>
            handleSubmit({
              name: 'upperRate',
              value,
            })
          }
        />

        <RowTitle>AT/AF</RowTitle>
        <Cell
          key={`atoraf-${programmedParameter?.id}`}
          name="atoraf"
          defaultValue={displayMonitoredValue(programmedParameter?.atOrAf)}
          onSubmit={value =>
            handleSubmit({
              name: 'atOrAf',
              value,
            })
          }
        />

        <RowTitle>VT Zone 1 (BPM)</RowTitle>
        <Cell
          key={`vtZone1-${programmedParameter?.id}`}
          name="vtZone1"
          defaultValue={displayMonitoredValue(programmedParameter?.vtZone1)}
          onSubmit={value =>
            handleSubmit({
              name: 'vtZone1',
              value,
            })
          }
        />

        <RowTitle>VT Zone 2 (BPM)</RowTitle>
        <Cell
          key={`vtZone2-${programmedParameter?.id}`}
          name="vtZone2"
          defaultValue={displayMonitoredValue(programmedParameter?.vtZone2)}
          onSubmit={value =>
            handleSubmit({
              name: 'vtZone2',
              value,
            })
          }
        />

        <RowTitle>VF (BPM)</RowTitle>
        <Cell
          key={`vf-${programmedParameter?.id}`}
          name="vf"
          defaultValue={displayMonitoredValue(programmedParameter?.vf)}
          onSubmit={value =>
            handleSubmit({
              name: 'vf',
              value,
            })
          }
        />
      </TransmissionReportValuesGrid>
    </Box>
  )
}

export default DeviceProgrammedParametersTable
