import { useEffect, useMemo } from 'react'

import { Box } from '@rhythm/components'

import { useAnalyticsContext } from '../../../context/AnalyticsContext'

import '../../../features/reportGeneration/useGetAnalyticsAccountOverviewData'

import { useLocation } from 'react-router-dom'

import { useGetAlertsAnalyticsData } from '../../../features/reportGeneration/useGetAlertsAnalyticsData'
import { AlertsQuery } from '../../../lib/api'

import { AnalyticsDataTable } from './components/AnalyticsDataTable'
import { AnalyticsFiltersBar } from './components/AnalyticsFiltersBar'
import { AnalyticsLayout } from './components/AnalyticsLayout'
import { alertsColumns, fetchAnalyticsProps } from './components/columns'

export const AlertsAnalytics = () => {
  const { pathname } = useLocation()
  const { title } = fetchAnalyticsProps(pathname)
  const { activeAnalyticsQueryParams } = useAnalyticsContext()
  const { data, isLoading, refetch, isRefetching } = useGetAlertsAnalyticsData(
    activeAnalyticsQueryParams as AlertsQuery,
  )
  useEffect(() => {
    if (
      !!activeAnalyticsQueryParams &&
      !!activeAnalyticsQueryParams.clinicIds &&
      activeAnalyticsQueryParams.clinicIds.length > 0 &&
      activeAnalyticsQueryParams.dateRange &&
      activeAnalyticsQueryParams.dateRange.length > 0 &&
      activeAnalyticsQueryParams.columns &&
      activeAnalyticsQueryParams.columns.length > 0
    ) {
      refetch()
    }
  }, [activeAnalyticsQueryParams, refetch])

  const visibleColumns = useMemo(() => {
    return alertsColumns
      .map(column => {
        if (
          activeAnalyticsQueryParams?.columns?.includes(
            column.accessor as never,
          )
        ) {
          return column
        }
      })
      .filter(Boolean)
  }, [activeAnalyticsQueryParams?.columns])

  const isTableDataLoading =
    (!!activeAnalyticsQueryParams &&
      activeAnalyticsQueryParams.clinicIds.length < 1) ||
    isLoading

  return (
    <AnalyticsLayout title={title}>
      <AnalyticsFiltersBar />
      <Box>
        <AnalyticsDataTable
          columns={visibleColumns}
          data={data}
          isLoading={isTableDataLoading}
          isRefetching={isRefetching}
        />
      </Box>
    </AnalyticsLayout>
  )
}
