import React from 'react'

import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const showToast = (statusCode: number) => {
  let type: 'error' | 'warn' = 'warn'
  if (statusCode >= 400 && statusCode < 500) {
    type = 'error'
  }

  toast[type](
    <div style={{ width: '200px' }}>
      <span>
        <b>{type === 'error' ? 'Access Denied' : 'Server Error'}</b>
      </span>
      <br />
      <input
        style={{
          background: 'transparent',
          border: 'none',
          outline: 'none',
          width: '100%',
        }}
        autoFocus
        value={
          type === 'error'
            ? 'Insufficient Role Permissions'
            : 'Please try again later'
        }
      />
    </div>,
    {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 3000,
      className:
        type === 'error' ? 'toast-message-error' : 'toast-message-warn',
    },
  )
}

export default showToast
