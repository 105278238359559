import { useMutation } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  CreateNoteRequest,
  DefaultApiClinicalNotesControllerCreateNoteRequest,
  queryClient,
} from '../../lib/api'

export const useCreateClinicalNote = () => {
  const Api = useApiContext()
  return useMutation(
    (params: CreateNoteRequest) =>
      Api.clinicalNotesControllerCreateNote({
        createNoteRequest: params,
      } as DefaultApiClinicalNotesControllerCreateNoteRequest),
    {
      onSuccess: async ({ data }, { patientId }) => {
        try {
          const { data: noteData } =
            await Api.clinicalNotesControllerGetClinicalNoteById({
              noteId: data.id,
            })
          queryClient.setQueryData(
            ['getNotesByPatientId', { id: patientId }],
            (oldData: any) => {
              if (!oldData) {
                return { pages: [[noteData.note]], pageParams: [] }
              }
              const newPages = [...oldData.pages]
              newPages[0] = [noteData.note, ...newPages[0]]

              return { ...oldData, pages: newPages }
            },
          )
          queryClient.invalidateQueries([
            'getPinnedNotesByPatientId',
            { id: patientId },
          ])
        } catch (error) {
          queryClient.invalidateQueries('getNotesByPatientId')
          queryClient.invalidateQueries('getPinnedNotesByPatientId')
        }
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
