import React, { useState } from 'react'

import { Box, Flex, Icon, SideBar, SideBarProps } from '@rhythm/components'

import { ClinicalNote } from '../../../../../lib/api'
import { CLINICAL_NOTES_SIDEBAR_OPEN_WIDTH } from '../../../../../utils/constants'
import { ViewClinicalNotes } from '../../../PatientProfilePage/components/ClinicalNotes/ViewClinicalNotes'

export interface ClinicalNotesSideBarProps extends SideBarProps {
  selectedReportPatientId: string
  onClose: () => void
}

const ClinicalNotesSideBar = ({
  selectedReportPatientId,
  isOpen,
  onClose,
}: ClinicalNotesSideBarProps): React.ReactElement => {
  const [selectedNote, setSelectedNote] = useState<ClinicalNote | null>(null)
  return (
    <SideBar
      isOpen={isOpen}
      width={isOpen ? CLINICAL_NOTES_SIDEBAR_OPEN_WIDTH - 100 + 'px' : 0}
      position="fixed"
      top={65}
      right={0}
      bottom={0}
      side="right"
    >
      {isOpen && (
        <Flex
          bg="neutral.white"
          borderLeftRadius="40px"
          height="50px"
          width="40px"
          mr="2xl"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left="-40px"
          _hover={{
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          <Icon icon="sidebar-right" color="primary.600" ml={3} />
        </Flex>
      )}
      <Box style={{ overflowY: 'auto' }} px={15} pb={15} pt={'5px'}>
        {selectedReportPatientId && (
          <ViewClinicalNotes
            patientId={selectedReportPatientId}
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
          />
        )}
      </Box>
    </SideBar>
  )
}

export default ClinicalNotesSideBar
