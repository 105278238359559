import React from 'react'

import { Box, Text } from '@rhythm/components'

import displayValue from '../../utils/displayValue'

export interface AttributeDisplayProps {
  children: React.ReactNode
  label: string
  emptyPlaceholder?: string
}

const AttributeDisplay = ({
  children,
  label,
  emptyPlaceholder,
}: AttributeDisplayProps): React.ReactElement => {
  return (
    <Box>
      <Text color="neutral.800" variant="smallCaps" mb="sm">
        {label}
      </Text>
      <Text>{displayValue(children, emptyPlaceholder)}</Text>
    </Box>
  )
}

export default AttributeDisplay
