import {
  ComponentProps,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { useQuery } from 'react-query'

import useCurrentUser from '../../../hooks/useCurrentUser'
import {
  TransmissionReportListResponseDto,
  TransmissionReportSignatureStatuses,
} from '../../../lib/api'
import { TransmissionReportPageLocationState } from '../../../pages/physician/TransmissionReportPage/TransmissionReportPage'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../utils/constants/transmissionReports'
import { useTransmissionReportList, useUpdateTransmissionReport } from '../api'

interface TransmissionReportsListContextInterface {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  data?: TransmissionReportListResponseDto | undefined
  setLoadedTransmissionReports: (loadedTransmissionReports: any) => void
  loadedTransmissionReports: any
}

export const TransmissionReportsListContext =
  createContext<TransmissionReportsListContextInterface>({
    isLoading: false,
    setIsLoading: () => {},
    setLoadedTransmissionReports: () => {},
    loadedTransmissionReports: { transmissionReports: [] },
  })

export const useTransmissionReportsListContext =
  (): TransmissionReportsListContextInterface =>
    useContext(TransmissionReportsListContext)

export const TransmissionReportsListContextProvider = ({
  children,
}: ComponentProps<FC>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loadedTransmissionReports, setLoadedTransmissionReports] = useState({
    transmissionReports: [],
  })
  const [signatureStatus, setSignatureStatus] =
    useState<TransmissionReportSignatureStatuses>()

  const { currentUser } = useCurrentUser()
  const location = useLocation<TransmissionReportPageLocationState>()

  useEffect(() => {
    if (!currentUser?.role) return
    if (PRACTITIONER_SIGNATURE_ROLES.includes(currentUser.role)) {
      setSignatureStatus(
        TransmissionReportSignatureStatuses.NonPractitionerSigned,
      )
    } else {
      setSignatureStatus(TransmissionReportSignatureStatuses.NotSigned)
    }
  }, [currentUser?.role])

  const { isLoading: isLoadingTransmissionReportList, mutate } =
    useTransmissionReportList()

  const { isLoading: isLoadingUpdateTransmissionReport } =
    useUpdateTransmissionReport()

  const getTransmissionReportList = useCallback(() => {
    if (!signatureStatus) return
    mutate({
      transmissionReportListRequestDto: {
        signatureStatus,
      },
    })
  }, [mutate, signatureStatus])

  const { data } = useQuery<TransmissionReportListResponseDto | undefined>(
    ['transmissionReportsList'],
    () => undefined,
  )

  useEffect(() => {
    // Added log to verify edit report
    if (
      data === undefined &&
      (!location.state?.editReportId || !location.state?.isEditing)
    ) {
      getTransmissionReportList()
    }
  }, [
    getTransmissionReportList,
    data,
    location.state?.editReportId,
    location.state?.isEditing,
  ])

  const TransmissionReportsValue = {
    isLoading:
      data === undefined ||
      isLoading ||
      isLoadingTransmissionReportList ||
      isLoadingUpdateTransmissionReport,
    data,
    setIsLoading,
    loadedTransmissionReports,
    setLoadedTransmissionReports,
  }

  return (
    <TransmissionReportsListContext.Provider value={TransmissionReportsValue}>
      {children}
    </TransmissionReportsListContext.Provider>
  )
}
