import React, { useCallback, useEffect, useState } from 'react'

import { AccordionItem } from '@rhythm/components'

import { useApiContext } from '../../../../../context/ApiContext'
import { useGetTransmissionReportsAreBillable } from '../../../../../features/transmissionReports/api/billing/getTransmissionReportsAreBillable'
import { useOrganization } from '../../../../../features/transmissionReports/api/getOrganization'
import { useTransmissionReportsListContext } from '../../../../../features/transmissionReports/context/TransmissionReportsListContext'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import {
  DefaultApiTransmissionReportsControllerListRequest,
  IsBillableTransmissionReports,
  TransmissionReportColors,
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportSignatureStatuses,
} from '../../../../../lib/api'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../../../utils/constants/transmissionReports'
import { transmissionColorText } from '../../../../../utils/transmissionColorText'

import { AccordionItemHeader } from './AccordionItemHeader'
import { AccordionItemPanel } from './AccordionItemPanel'
import { AccordionSelectAllPanel } from './AccordionSelectAll'

export interface TransmissionReportSidebarAccordionWithPageProps {
  defaultExpanded?: TransmissionReportDtoColorEnum
  selectedTransmissionReportId: number
  onSelectTransmissionReport?: (id: number) => void
  isExpanded: boolean
  colorType: TransmissionReportDtoColorEnum
  defaultCount: number
  onSelect: (id: number) => void
  onCheckChange?: (id: number, isChecked: boolean) => void
  selectedTransmissionIds?: number[]
  onCheckChangeAll?: any
  setGrayIds?: any
  existingRole?: boolean
  setLoadedTransmissionReports?: any
  otherAccordionLoading?: boolean
  setOtherAccordionLoading?: any
}

const TransmissionReportSidebarAccordionWithPage = ({
  selectedTransmissionReportId,
  isExpanded,
  colorType,
  defaultCount,
  onSelect,
  onCheckChange,
  selectedTransmissionIds,
  onCheckChangeAll,
  setGrayIds,
  existingRole,
  otherAccordionLoading,
  setOtherAccordionLoading,
}: TransmissionReportSidebarAccordionWithPageProps): React.ReactElement => {
  const Api = useApiContext()
  const { currentUser } = useCurrentUser()
  let isPractitioner = false
  if (currentUser && currentUser.role) {
    isPractitioner = PRACTITIONER_SIGNATURE_ROLES.includes(currentUser.role)
  }
  const organizationId = currentUser.organizations?.length
    ? currentUser.organizations[0].parentId
    : 'null'
  const { data: parentOrg } = useOrganization({
    organizationId: organizationId,
  })
  const appDeterminedBillableUi = currentUser.isExternal
    ? parentOrg?.organization.appDeterminedBillableUI
    : true

  const { loadedTransmissionReports, setLoadedTransmissionReports } =
    useTransmissionReportsListContext()
  const [transmissionReports, setTransmissionReports] = useState<
    TransmissionReportDto[]
  >([])

  const [pageInfo, setPageInfo] = useState<any>({
    totalCount: defaultCount,
    limit: 50,
  })
  const [prevPageNo, setPrevPageNo] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (
      colorType === TransmissionReportDtoColorEnum.Gray &&
      transmissionReports.length > 0
    ) {
      setGrayIds(
        transmissionReports.map(
          (transmissionReport: TransmissionReportDto) => transmissionReport.id,
        ),
      )
    }
  }, [transmissionReports, colorType, setGrayIds])

  useEffect(() => {
    if (transmissionReports.length !== 0 && !isExpanded) {
      setTransmissionReports([])
      setPageInfo({
        totalCount: defaultCount,
        limit: 50,
      })
      onCheckChangeAll && onCheckChangeAll(false)
    }
  }, [isExpanded, transmissionReports, defaultCount, onCheckChangeAll])

  useEffect(() => {
    if (
      loadedTransmissionReports.transmissionReports &&
      loadedTransmissionReports.transmissionReports.length > 0 &&
      loadedTransmissionReports.transmissionReports[0].color === colorType
    ) {
      setTransmissionReports(loadedTransmissionReports.transmissionReports)
    }
  }, [colorType, loadedTransmissionReports.transmissionReports])

  const loadTransmissionReports = useCallback(
    async (targetElement?: any) => {
      // if (isLoading) return
      // if (hasLoaded) return
      setIsLoading(true)
      setOtherAccordionLoading && setOtherAccordionLoading(true)
      const requestParams: DefaultApiTransmissionReportsControllerListRequest =
        {
          transmissionReportListRequestDto: {
            offset: transmissionReports.length,
            limit: pageInfo.limit,
            color: colorType as unknown as TransmissionReportColors,
            // Here the existing role is the flag that determines if the user is a practitioner or not
            // If the user is a practitioner, the signature status is set to NonPractitionerSigned
            // If the user is not a practitioner, the signature status is set to NotSigned
            signatureStatus: isPractitioner
              ? TransmissionReportSignatureStatuses.NonPractitionerSigned
              : TransmissionReportSignatureStatuses.NotSigned,
          },
        }
      const results = await Api.transmissionReportsControllerList(requestParams)
      if (results?.data?.transmissionReports) {
        const updatedTransmissionReports = [
          ...transmissionReports,
          ...results.data.transmissionReports,
        ]

        setLoadedTransmissionReports({
          transmissionReports: updatedTransmissionReports,
        })
        setPrevPageNo(transmissionReports.length)
        setPageInfo({
          totalCount: results.data.total,
          limit: pageInfo.limit,
        })
        setIsLoading(false)
        setOtherAccordionLoading && setOtherAccordionLoading(false)
        if (transmissionReports.length === pageInfo.totalCount && targetElement)
          return (targetElement.scrollTop -= 5)
      }
    },
    [
      transmissionReports,
      colorType,
      isPractitioner,
      pageInfo.limit,
      pageInfo.totalCount,
      Api,
      setLoadedTransmissionReports,
      setOtherAccordionLoading,
    ],
  )

  useEffect(() => {
    if (isExpanded && defaultCount && transmissionReports.length === 0) {
      loadTransmissionReports()
    }
  }, [isExpanded, defaultCount, loadTransmissionReports, transmissionReports])

  const onScroll = useCallback(
    e => {
      const { offsetHeight, scrollTop, scrollHeight } = e.target

      // Check if the accordion is expanded, the number of loaded transmission reports is less than the default count,
      // and the user has scrolled to the bottom of the element
      if (
        isExpanded &&
        transmissionReports.length < defaultCount &&
        offsetHeight + scrollTop >= scrollHeight - 1
      ) {
        loadTransmissionReports()
      }
    },
    [
      defaultCount,
      isExpanded,
      loadTransmissionReports,
      transmissionReports.length,
    ],
  )

  return (
    <AccordionItem
      borderTop="none"
      borderBottomWidth="0.5px"
      // Hide the Require Triage accordion for the practitioner role.
      hidden={
        isPractitioner && colorType === TransmissionReportDtoColorEnum.Gray
      }
    >
      <AccordionItemHeader
        otherAccordionLoading={otherAccordionLoading}
        color={colorType}
        label={transmissionColorText(colorType)}
        count={defaultCount}
        isLoading={false}
      />
      {colorType === TransmissionReportDtoColorEnum.Gray &&
        selectedTransmissionIds && (
          <AccordionSelectAllPanel
            reportCount={transmissionReports.length}
            selectedTransmissionIds={selectedTransmissionIds}
            onCheckChangeAll={onCheckChangeAll}
          />
        )}
      <AccordionItemPanel
        // Set the height of the accordion to 300px if there are transmission reports to display and the data is not loading.
        h={transmissionReports.length === 0 && !isLoading ? 'auto' : '300px'}
        transmissions={transmissionReports}
        selectedTransmissionId={selectedTransmissionReportId}
        onSelect={onSelect}
        onScroll={onScroll}
        isLoading={isLoading}
        onCheckChange={onCheckChange}
        selectedTransmissionIds={selectedTransmissionIds}
        pageNo={prevPageNo}
      />
    </AccordionItem>
  )
}

export default TransmissionReportSidebarAccordionWithPage
