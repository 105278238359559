import {
  useCreateTransmissionReportAlert,
  useDeleteTransmissionReportAlert,
  useUpdateTransmissionReportAlert,
} from '../api'

export interface UseTransmissionReportAlertsProps {
  transmissionReportId: number
}

export function useTransmissionReportAlerts() {
  const { isLoading: isCreatingAlert, mutateAsync: createAlert } =
    useCreateTransmissionReportAlert()
  const { isLoading: isDeletingAlert, mutateAsync: deleteAlert } =
    useDeleteTransmissionReportAlert()
  const { isLoading: isUpdatingAlert, mutateAsync: updateAlert } =
    useUpdateTransmissionReportAlert()

  return {
    isLoading: (isCreatingAlert || isUpdatingAlert || isDeletingAlert) ?? false,
    createAlert,
    deleteAlert,
    updateAlert,
  }
}
