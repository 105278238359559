import { useCallback, useEffect, useState } from 'react'

import { TransmissionReportDto, UploadFileResponse } from '../../../lib/api'
import {
  useCreateTransmissionReportAlertImage,
  useDeleteTransmissionReportAlertImage,
  useDocumentUpload,
  useGetDocuments,
} from '../api'

interface UseTransmissionReportImagesProps {
  transmissionReport: TransmissionReportDto | undefined
}

export interface ExtendedTransmissionReportImage extends UploadFileResponse {}

export function useTransmissionReportImages({
  transmissionReport,
}: UseTransmissionReportImagesProps) {
  const [idsToFetch, setIdsToFetch] = useState<string[]>([])
  const { isLoading: isUploadingImage, mutateAsync: uploadImage } =
    useDocumentUpload()
  const { isLoading: isAddingAlertImage, mutateAsync: addAlertImage } =
    useCreateTransmissionReportAlertImage()

  const { mutateAsync: deleteAlertImage, isLoading: isDeletingImage } =
    useDeleteTransmissionReportAlertImage()

  const { isLoading: isGettingDocuments, data: imagesWithUrl } =
    useGetDocuments({
      fileIds: idsToFetch,
    })

  const getAlertImages = useCallback(
    (alertId: string | undefined) => {
      if (!alertId) return
      const { alerts } = transmissionReport ?? {}
      return imagesWithUrl?.filter(
        ({ file }) =>
          alerts
            ?.filter(alert => alert.id === alertId)
            .find(
              ({ images }) => images?.find(({ image }) => image.id === file.id),
            ),
      )
    },
    [transmissionReport, imagesWithUrl, isDeletingImage],
  )

  useEffect(() => {
    if (
      transmissionReport?.alerts &&
      !isGettingDocuments &&
      !isUploadingImage &&
      !isAddingAlertImage &&
      !isDeletingImage
    ) {
      const { alerts } = transmissionReport
      if (alerts.length > 0) {
        const allAlertsImagesIds = alerts.reduce((allIds, alert) => {
          if (alert?.images && alert.images.length > 0) {
            const alertImagesIds: string[] =
              alert.images.map(image => image.image.id) ?? []
            allIds = [...allIds, ...alertImagesIds]
          }
          return allIds
        }, [] as string[])
        setIdsToFetch(allAlertsImagesIds)
      } else {
        setIdsToFetch([])
      }
    }
  }, [
    transmissionReport,
    isGettingDocuments,
    isUploadingImage,
    isAddingAlertImage,
    isDeletingImage,
  ])

  return {
    uploadImage,
    addAlertImage,
    imagesWithUrl,
    deleteAlertImage,
    getAlertImages,
    isDeletingImage,
  }
}
