import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiReportTemplateControllerRemoveTemplateRequest,
  queryClient,
} from '../../../lib/api'

export const useRemoveReportTemplate = (toast: any) => {
  const Api = useApiContext()
  return useMutation(
    async (
      reportTemplateRemove: DefaultApiReportTemplateControllerRemoveTemplateRequest,
    ) => {
      const { data } =
        await Api.reportTemplateControllerRemoveTemplate(reportTemplateRemove)
      return {
        data,
        transmissionReportId:
          reportTemplateRemove.removeTemplateRequest.reportId,
      }
    },
    {
      onSuccess: ({ data, transmissionReportId }) => {
        queryClient.invalidateQueries('transmissionReport').then(() => {
          toast(data.template)
        })
        queryClient.invalidateQueries(['billing', transmissionReportId])
      },
      onError: (error, variables, context) => {
        console.log('onError', error, variables, context)
      },
    },
  )
}
