import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { TransmissionFrequency } from '../../../pages/physician/PatientProfilePage/components/DeviceDetailsWithTransmissionSchedule/constants'

export const useGetScheduleTermDays = (
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const Api = useApiContext()
  return useQuery(
    ['scheduleTerms'],
    async () => {
      const res = await Api.scheduledTransmissionControllerFetchTermDays()
      const { scheduleTerms } = res.data
      const transformedScheduleTerms: Record<string, TransmissionFrequency> = {}

      scheduleTerms.forEach(term => {
        const transmissions = Math.round(365 / term.termDays)
        transformedScheduleTerms[term.termDays.toString()] = {
          label: `${term.termDays} Days`,
          freq: term.termDays,
          transmissions: transmissions,
          value: `${term.termDays},${transmissions}`,
        }
      })
      return transformedScheduleTerms
    },
    {
      ...options,
      staleTime: twentyFourHoursInMs,
    },
  )
}
