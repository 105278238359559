import { createContext, Dispatch, SetStateAction, useContext } from 'react'

import Axios from 'axios'

import { DocumentType } from '../features/documents'
import { DefaultApi } from '../lib/api'

export const ApiContext = createContext<DefaultApi>(
  new DefaultApi(Axios.create({})),
)

export type ApiContextType = {
  uploadedDocuments: DocumentType[]
  setUploadedDocuments: Dispatch<SetStateAction<any[]>>
}

export const useApiContext = (): DefaultApi => useContext(ApiContext)
