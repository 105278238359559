import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import { DefaultApiFilesControllerUploadRequest } from '../../../../lib/api'
import showToast from '../../../../showToast'

export const useDocumentUpload = () => {
  const Api = useApiContext()
  return useMutation(
    ({ file, path }: DefaultApiFilesControllerUploadRequest) => {
      return Api.filesControllerUpload({ file, path })
    },
    {
      onError: (error: any) => {
        showToast(error?.status) // Handle the error as needed
      },
    },
  )
}
