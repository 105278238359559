import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Button,
  Card,
  CardProps,
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  SkeletonSquare,
  VStack,
  Wrap,
  WrapItem,
} from '@rhythm/components'

import { TransmissionReportColorBadge } from '../../../../../features/transmissionReports/components'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import {
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportDtoSignatureStatusEnum,
} from '../../../../../lib/api'
import routes from '../../../../../routes/config'
import { SIGNALL_ROLES } from '../../../../../utils/constants/transmissionReports'
import { transmissionColorText } from '../../../../../utils/transmissionColorText'

export interface TransmissionReportsStatusCardProps extends CardProps {
  colorEnum: TransmissionReportDtoColorEnum
  counts: any
  isLoading: boolean
  onSignAllRoutine: () => void
  transmissionReports: TransmissionReportDto[]
  enableSignAll: boolean
}

const TransmissionReportsStatusCard = ({
  colorEnum,
  counts,
  isLoading,
  onSignAllRoutine,
  transmissionReports,
  enableSignAll,
  ...restOfCardProps
}: TransmissionReportsStatusCardProps): React.ReactElement => {
  const count = counts ? counts[colorEnum] : undefined
  const colorText: string = transmissionColorText(colorEnum)

  const onClickSignAllRoutine: React.EventHandler<React.MouseEvent> = event => {
    event.stopPropagation()
    onSignAllRoutine()
  }

  // const { data: transmissionReportsData } = useTransmissionReportsListContext()
  // // console.log('transmissionReportsData ',transmissionReportsData)
  // const transmissionReports = useMemo(
  //   () =>
  //     isLoading || !transmissionReportsData
  //       ? []
  //       : transmissionReportsData.transmissionReports,
  //   [transmissionReportsData, isLoading],
  // )

  const greenReports = transmissionReports?.filter(
    t => t.color === TransmissionReportDtoColorEnum.Green,
  )
  const tableDat = greenReports?.filter(
    n =>
      n.signatureStatus ===
      TransmissionReportDtoSignatureStatusEnum.NonPractitionerSigned,
  )

  const { currentUser } = useCurrentUser()
  let hideSignAll = true

  if (currentUser?.role!) {
    if (SIGNALL_ROLES.includes(currentUser.role)) {
      hideSignAll = false
      if (tableDat.length < 1) {
        hideSignAll = true
      }
    }
  }

  return (
    <Card
      py={{ md: '3xl' }}
      d={'flex'}
      justifyContent={
        count === 0 || count === undefined ? 'center' : 'flex-start'
      }
      {...restOfCardProps}
    >
      {isLoading ? (
        <VStack spacing="lg" justifyContent={'center'}>
          <SkeletonSquare w={58} />
          <Skeleton noOfLines={2} w={200} />
        </VStack>
      ) : (
        <VStack
          alignItems={count === 0 ? 'center' : 'flex-start'}
          spacing="lg"
          justifyContent={'center'}
        >
          <TransmissionReportColorBadge
            mb={'md'}
            color={colorEnum}
            size={'lg'}
            showCheck={count === 0}
          />

          {count === 0 ? (
            <Heading variant={'h5'} textTransform={'capitalize'}>
              You&apos;re all caught up
            </Heading>
          ) : (
            <Heading
              variant={'h5'}
              fontWeight={'900'}
              textTransform={'capitalize'}
            >{`${count} ${colorText}`}</Heading>
          )}

          {count !== 0 && (
            <Wrap align="center" spacing="xl">
              {/* {[
                TransmissionReportDtoColorEnum.Green,
                TransmissionReportDtoColorEnum.Gray,
              ].includes(colorEnum) && (
                <WrapItem>
                  <Button>Sign all</Button>
                </WrapItem>
              )} */}

              <WrapItem>
                {[TransmissionReportDtoColorEnum.Green].includes(colorEnum) &&
                  !hideSignAll &&
                  enableSignAll && (
                    <WrapItem>
                      <Button onClick={onClickSignAllRoutine}>Sign all</Button>
                    </WrapItem>
                  )}
                <RouterLink
                  to={{
                    pathname: routes.physician.transmissionReview,
                    state: { defaultExpanded: colorEnum },
                  }}
                >
                  <Link as={'div'} fontSize={'lg'}>
                    <HStack style={{ paddingLeft: 8 }}>
                      <Box>Review</Box>
                      <Icon boxSize={'md'} icon={'arrow-right'} />
                    </HStack>
                  </Link>
                </RouterLink>
              </WrapItem>
            </Wrap>
          )}
        </VStack>
      )}
    </Card>
  )
}

export default TransmissionReportsStatusCard
