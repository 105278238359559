import React, { useCallback, useMemo } from 'react'

import { NoteFieldValue, Notes } from '../../../../../components/Notes'
import {
  useTransmissionReportContext,
  useTransmissionReportNotes,
} from '../../../../../features/transmissionReports'
import {
  TransmissionReportLocations,
  TransmissionReportNote,
  TransmissionReportNoteLocationEnum,
} from '../../../../../lib/api'

export interface DeviceMonitoredValuesNotesProps {
  notes: TransmissionReportNote[]
}

const DeviceMonitoredValuesNotes = (): React.ReactElement => {
  const { transmissionReport, isLoading } = useTransmissionReportContext()

  const {
    createNote,
    updateNote,
    deleteNote,
    isLoading: isApiLoading,
  } = useTransmissionReportNotes()

  const { notes, id: transmissionReportId } = transmissionReport ?? {}
  const monitoredValuesNotes = useMemo(
    () =>
      notes?.filter(
        note =>
          note.location === TransmissionReportNoteLocationEnum.MonitoredValues,
      ),
    [notes],
  )

  const handleNoteMutation = useCallback(
    ({ id, note }: NoteFieldValue) => {
      if (transmissionReportId && !id) {
        createNote({
          transmissionReportId,
          flag: false,
          transmissionReportNoteCreateParams: {
            location: TransmissionReportLocations.MonitoredValues,
            note,
          },
        })
      } else if (transmissionReportId && id) {
        updateNote({
          transmissionReportId,
          noteId: id,
          transmissionReportNoteUpdateParams: { note },
        })
      }
    },
    [createNote, updateNote, transmissionReportId],
  )

  const handleNoteDelete = useCallback(
    (noteId: string) => {
      if (transmissionReportId && noteId) {
        deleteNote({
          transmissionReportId: transmissionReportId.toString(),
          noteId,
        })
      }
    },
    [transmissionReportId, deleteNote],
  )

  return (
    <Notes
      key={`monitored-values-${transmissionReportId}-notes`}
      notes={monitoredValuesNotes}
      groupName="MONITORED_VALUES"
      onSubmit={handleNoteMutation}
      onDelete={handleNoteDelete}
      isLoading={isLoading || isApiLoading}
    />
  )
}

export default DeviceMonitoredValuesNotes
