import { useMemo } from 'react'

import {
  Box,
  Editable,
  EditableProps,
  FormControl,
  Skeleton,
  Text,
} from '@rhythm/components'
import { Controller } from 'react-hook-form'

export const baseStyles: Record<string, string> = {
  display: 'flex',
  alignItems: 'center',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: 'neutral.200',
  paddingRight: 'lg',
}

export const baseStylesWithoutBorder: Record<string, string> = {
  display: 'flex',
  alignItems: 'center',
  paddingRight: 'lg',
}

export const ColumnHeader = ({ children }: { children: React.ReactNode }) => (
  <Text variant="smallCaps" color="text.secondary" {...baseStyles}>
    {children}
  </Text>
)

export const RowTitle = ({ children }: { children: React.ReactNode }) => (
  <Text fontWeight="bold" {...baseStyles}>
    {children}
  </Text>
)

export interface TSEditableCellProps extends EditableProps {
  name: string
  control?: any
}
export const EditableCell = ({
  control,
  name,
  ...props
}: TSEditableCellProps) => (
  <FormControl sx={baseStyles}>
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Editable
          w="full"
          name={name}
          value={value}
          defaultValue={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  </FormControl>
)

interface TransmissionReportValuesGridProps {
  gridTemplateColumns: string
  gridTemplateRows: string
  children: React.ReactNode[]
  loading?: {
    numOfColumns: number
    numOfRows: number
    isLoading: boolean
  }
}

export const TransmissionReportValuesGrid = ({
  gridTemplateColumns,
  gridTemplateRows,
  children,
  loading,
}: TransmissionReportValuesGridProps) => {
  const { numOfColumns = 0, numOfRows = 0, isLoading } = loading || {}

  const LoadingState = useMemo(() => {
    const InnerLoadingState = () => (
      <>
        {new Array(numOfColumns).fill(null).map((_c, _i) => children[_i])}
        {new Array(numOfColumns * numOfRows).fill(null).map((_r, _i) => (
          <Box key={`skeleton-${_i}`} {...baseStyles}>
            <Skeleton w="full" mb="none" />
          </Box>
        ))}
      </>
    )

    // Set a display name for debugging purposes
    InnerLoadingState.displayName = 'InnerLoadingState'

    return InnerLoadingState
  }, [children, numOfColumns, numOfRows])

  return (
    <Box
      w="full"
      display="grid"
      pl="xs"
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
    >
      {isLoading ? <LoadingState /> : children}
    </Box>
  )
}
