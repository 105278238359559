import React from 'react'

import { SimpleGrid } from '@rhythm/components'

import { AnalyticReport } from '../../../../../types'
import ReportChartCard from '../ReportChartCard'

export interface ReportsGridProps {
  reports: AnalyticReport[]
}

const ReportsGrid = ({ reports }: ReportsGridProps): React.ReactElement => {
  // TECH_SUITE_ONLY: reports currently all route to same page
  const routeToOperationsReportPage = () => {
    window.location.href = `//${process.env.REACT_APP_OPERATIONS_DOMAIN}/operations/report`
  }

  return (
    <SimpleGrid columns={4} spacing="xl">
      {reports.map(report => (
        <ReportChartCard
          key={report.title}
          title={report.title}
          lastUpdatedDate={report.lastUpdatedDate}
          onClickReport={routeToOperationsReportPage}
          isBookmarked={report.isBookmarked}
          chartImageSrc={report.chartImageSrc}
        />
      ))}
    </SimpleGrid>
  )
}

export default ReportsGrid
