import React, { useMemo } from 'react'

import { Grid, Skeleton, Text, VStack } from '@rhythm/components'
import moment from 'moment'

import useCurrentUser from '../../../../../hooks/useCurrentUser'
import {
  TransmissionReportDto,
  TransmissionReportSignature,
} from '../../../../../lib/api'
import formatUtcDate from '../../../../../utils/formatUtcDate'
import NarrativeCard from '../NarrativeCard'

import Signature from './Signature'

interface Signatures {
  practitioner?: TransmissionReportSignature
  nonPractitioner?: TransmissionReportSignature
}

export interface SignOffCardProps {
  transmissionReport?: TransmissionReportDto
}

const SignOffCard = ({
  transmissionReport,
}: SignOffCardProps): React.ReactElement => {
  const { currentUser } = useCurrentUser()
  const isRemote = transmissionReport?.transmissionType === 'remote'

  const signatures: Signatures = useMemo(() => {
    const signatures: Signatures = {}
    const sortedSignatures =
      transmissionReport?.signatures.sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
      ) ?? []
    sortedSignatures.forEach(
      (signature: TransmissionReportSignature, index) => {
        if (index === 1) {
          signatures.practitioner = signature
        } else {
          signatures.nonPractitioner = signature
        }
      },
    )
    return signatures
  }, [transmissionReport?.signatures])

  const transmissionDate = transmissionReport?.transmission?.transmissionDate
  const signLength = transmissionReport?.signatures.length ?? -1
  return (
    <NarrativeCard icon="signature" title="Sign Off">
      {signLength === 0 ? (
        <Text>There are currently no signatures.</Text>
      ) : (
        <Grid
          gridTemplateColumns="repeat(2, 1fr)"
          columnGap="4xl"
          rowGap="2xl"
          mb="xl"
        >
          {[signatures?.practitioner, signatures?.nonPractitioner].map(
            (signature?: TransmissionReportSignature, _i?: number) => {
              const signatureIsCurrentUser =
                signature?.user.email === currentUser.email

              const isPractitioner = signLength > 1 && _i === 0 ? true : false

              // const isNotPractitioner =
              //   signature?.user &&
              //   hasRole(signature.user, [
              //     Roles.InternalsuperAdmin,
              //     Roles.Internaladmin,
              //   ])

              return (
                <VStack
                  key={`signature-${_i}`}
                  alignContent="flex-start"
                  spacing="lg"
                  w="100%"
                >
                  {(transmissionReport === undefined ||
                    signature !== undefined) && (
                    <>
                      <Skeleton
                        isLoaded={transmissionReport !== undefined}
                        alignSelf="flex-start"
                      >
                        <Text
                          variant="smallCaps"
                          color="text.secondary"
                          w="full"
                        >
                          {signatureIsCurrentUser
                            ? 'Your Signature'
                            : isPractitioner
                              ? 'Practitioner'
                              : 'Non Practitioner'}
                        </Text>
                      </Skeleton>
                      <Signature
                        signature={signature}
                        signatureIsCurrentUser={signatureIsCurrentUser}
                      />
                    </>
                  )}
                </VStack>
              )
            },
          )}
        </Grid>
      )}

      {transmissionReport && isRemote ? (
        transmissionDate && (
          <Text textAlign="right">
            Transmission Date: {formatUtcDate(transmissionDate)}
          </Text>
        )
      ) : (
        <Skeleton
          isLoaded={transmissionDate !== undefined}
          w="fit-content"
          alignSelf="flex-end"
        />
      )}
    </NarrativeCard>
  )
}

export default SignOffCard
