import { Box } from '@rhythm/components'

const historySignSvg = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 108.67"
  >
    <path d="M14.52,55.12a54.53,54.53,0,0,1,8.12-29.54A52.65,52.65,0,0,1,46.75,4.79a60.05,60.05,0,0,1,11.1-3.55,52.77,52.77,0,0,1,60.35,31.2,59.34,59.34,0,0,1,3.5,11.07,52.58,52.58,0,0,1-1.31,27,53.18,53.18,0,0,1-14.66,22.87A57.62,57.62,0,0,1,83,106.64a48.71,48.71,0,0,1-25,.74,47.51,47.51,0,0,1-7.89-2.58,49.43,49.43,0,0,1-7.56-4,3.82,3.82,0,0,1,4.16-6.41,40.79,40.79,0,0,0,6.4,3.42,40,40,0,0,0,6.62,2.16,41.07,41.07,0,0,0,21.12-.63,49.92,49.92,0,0,0,19.73-11.48,45.64,45.64,0,0,0,12.56-19.61,44.86,44.86,0,0,0,1.1-23.11,52.48,52.48,0,0,0-3-9.67A45.1,45.1,0,0,0,59.43,8.73a52.47,52.47,0,0,0-9.68,3.09A45,45,0,0,0,29.14,29.59a47,47,0,0,0-7,26l8.75-9.72A3.83,3.83,0,1,1,36.61,51L21.89,67.37a3.83,3.83,0,0,1-5.4.28L1.17,52.86a3.82,3.82,0,0,1,5.3-5.51l8.05,7.77ZM62.71,32.89a3.83,3.83,0,1,1,7.66,0v21.9l17.27,9.59a3.82,3.82,0,0,1-3.71,6.67L65,60.52A3.83,3.83,0,0,1,62.71,57V32.89Z" />
  </svg>
)

const dollarSignSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1V23"
      stroke="#0E6DAD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6"
      stroke="#0E6DAD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const navigationSvg = () => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 4L10.5 1M10.5 1H7.5M10.5 1L6.5 5M5 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V7.6C1.5 8.44008 1.5 8.86012 1.66349 9.18099C1.8073 9.46323 2.03677 9.6927 2.31901 9.83651C2.63988 10 3.05992 10 3.9 10H7.1C7.94008 10 8.36012 10 8.68099 9.83651C8.96323 9.6927 9.1927 9.46323 9.33651 9.18099C9.5 8.86012 9.5 8.44008 9.5 7.6V6.5"
      stroke="#0E6DAD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const HistorySignIcon = () => {
  return (
    <Box
      as="svg"
      role="img"
      width="24px"
      marginTop={0}
      height="24px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#0E6DAD"
      _hover={{ fill: 'brand.accent' }}
    >
      {historySignSvg()}
    </Box>
  )
}

export const DollarSignIcon = () => {
  return (
    <Box
      as="svg"
      role="img"
      width="24px"
      marginTop={0}
      height="24px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#0E6DAD"
      _hover={{ fill: 'brand.accent' }}
    >
      {dollarSignSvg()}
    </Box>
  )
}

export const NavigationIcon = () => {
  return (
    <Box
      id="navigateIconBox"
      as="svg"
      role="img"
      width="12px"
      marginTop={0}
      height="11px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 11"
      fill="#0E6DAD"
      _hover={{ fill: 'brand.accent' }}
    >
      {navigationSvg()}
    </Box>
  )
}

export const DollarSignIconBlack = ({
  width = '14',
  height = '22',
}: {
  width?: string
  height?: string
}): React.ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15C1 17.2091 2.79086 19 5 19H9C11.2091 19 13 17.2091 13 15C13 12.7909 11.2091 11 9 11H5C2.79086 11 1 9.20914 1 7C1 4.79086 2.79086 3 5 3H9C11.2091 3 13 4.79086 13 7M7 1V21"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
