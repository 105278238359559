import { Redirect, Route, Switch } from 'react-router-dom'

import OpsDashboardPage from '../pages/operations/OpsDashboardPage'

import routes from './config'

const UserRoutes = () => {
  return (
    <Switch>
      <Route path={routes.user.settings}>
        <OpsDashboardPage />
      </Route>
      <Route path="*">
        <Redirect to={routes.user.settings} />
      </Route>
    </Switch>
  )
}

export default UserRoutes
