import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiPatientsControllerTotalMonitoredRequest } from '../../../lib/api'

import {
  addMissingMonthObjectInArr,
  addMonthAndYearLabelInArr,
} from './helpers'

export type useGetPatientsMonitoredMonthlyDataProps = {
  month: number
  year: number
  count: number
  monthString?: string
  xAxisLabel?: string
}
export const useGetPatientsMonitoredMonthlyData = (
  { queryParams }: DefaultApiPatientsControllerTotalMonitoredRequest,
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const Api = useApiContext()
  return useQuery(
    ['patients', [queryParams]],
    async () => {
      const res = await Api.patientsControllerTotalMonitored({
        queryParams,
      })
      const { data } = res
      data.patientsData = addMissingMonthObjectInArr(
        data.patientsData,
        data.startFrom,
      )
      data.patientsData = addMonthAndYearLabelInArr(data.patientsData)

      return data.patientsData
    },
    {
      ...options,
    },
  )
}
