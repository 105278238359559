import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerDismissRequest,
  queryClient,
  TransmissionReportDto,
  TransmissionReportListResponseDto,
} from '../../../lib/api'

import { useTransmissionReportsListContext } from '..'

export const useDismissTransmissionReport = () => {
  const { setIsLoading: setIsLoadingTransmissionReportsList } =
    useTransmissionReportsListContext()

  const Api = useApiContext()

  return useMutation(
    (params: DefaultApiTransmissionReportsControllerDismissRequest) => {
      return Api.transmissionReportsControllerDismiss(params)
    },
    {
      onSuccess: data => {
        setIsLoadingTransmissionReportsList(true)
        window.location.reload()
        const {
          data: { status },
        } = data
        const signatureResp = JSON.parse(JSON.stringify(status))
        queryClient.setQueryData<
          Partial<TransmissionReportListResponseDto | undefined>
        >(['transmissionReportsList'], cachedData => {
          if (!cachedData) return
          const updatedUnsignedTransmissionReports =
            cachedData.transmissionReports?.filter(
              (transmissionReport: TransmissionReportDto) =>
                transmissionReport.id !== signatureResp.id,
            ) || []
          cachedData.transmissionReports = updatedUnsignedTransmissionReports

          const updatedTotal = (cachedData?.total || 0) - 1
          cachedData.total = updatedTotal
          return cachedData
        })

        setIsLoadingTransmissionReportsList(false)
      },
    },
  )
}
