import { Roles } from '../lib/api'

export const InternalAdminRoles: Roles[] = [
  Roles.InternalsuperAdmin,
  Roles.Internaladmin,
]

export const AdminRoles: Roles[] = [
  Roles.InternalsuperAdmin,
  Roles.Internaladmin,
  Roles.Externaladmin,
  Roles.ExternalpractitionerAdmin,
  Roles.ExternalnonPractitionerAdmin,
]

export const PractitionerRoles: Roles[] = [
  Roles.ExternalpractitionerAdmin,
  Roles.Externalpractitioner,
  Roles.Externalprovider,
]

export const NonClinicalRoles: Roles[] = [Roles.InternalnonClinical]

export const USER_TYPE_INTERNAL = 'internal'
export const USER_TYPE_EXTERNAL = 'external'
