import { useCallback, useEffect, useMemo, useState } from 'react'

import { DataTable, TableHeader, TableRow } from '@rhythm/components'

import { NewGetAllPatientsProps } from '../../../../../features/patients/api'
import { FindAllPatientsResponse } from '../../../../../lib/api'
import { PatientMenuOptions } from '../../constants'
import {
  getSavedTableInfo,
  saveOrUpdateFilterTable,
  TablesMapName,
} from '../../TableDataStorageInfo'

import Columns from './Columns'

export interface PatientsTableProps {
  data?: FindAllPatientsResponse
  searchTerm?: string
  isLoading?: boolean
  queryData: NewGetAllPatientsProps
  selectReports?:
    | PatientMenuOptions.ALL_Reports
    | PatientMenuOptions.RECENT
    | PatientMenuOptions.NOT_DOWNLOAD
    | string
  selectPatients?: string
  selectStatus?: string
  setQueryData: (params: NewGetAllPatientsProps) => void
  setSelectedRowIds?: (selectedRowIds: string[]) => void
  selectedDeviceSchedule?: string
}

type SortBy = {
  id: string
  desc: boolean
}

const PatientsTable = ({
  data,
  searchTerm,
  isLoading,
  queryData,
  selectReports,
  selectPatients,
  selectStatus,
  selectedDeviceSchedule,
  setQueryData,
}: PatientsTableProps) => {
  const memoizedIsNew = useMemo(
    () => selectPatients === PatientMenuOptions.NEW,
    [selectPatients],
  )
  const pageTableInfo = getSavedTableInfo(TablesMapName.PATIENTS)
  const DEFAULT_PAGE_INDEX: number = pageTableInfo.pageIndex as number

  const DEFAULT_PAGE_SIZE: number = pageTableInfo.pageSize as number

  const memoizedIsRecentlySigned = useMemo(
    () =>
      queryData?.isRecentSigned || selectReports === PatientMenuOptions.RECENT,
    [queryData?.isRecentSigned, selectReports],
  )

  const memoizedIsNotDownloaded = useMemo(
    () => selectReports === PatientMenuOptions.NOT_DOWNLOAD,
    [selectReports],
  )

  const memoizedIsAllReports = useMemo(
    () => selectReports === PatientMenuOptions.ALL_Reports,
    [selectReports],
  )

  const memoizedStatus = useMemo(() => selectStatus, [selectStatus])
  const [skip, setSkip] = useState(0)
  const [pageLimit, setPageLimit] = useState(DEFAULT_PAGE_SIZE)
  const [sortBy, setSortBy] = useState<string | undefined>(
    pageTableInfo.sortBy !== undefined
      ? (pageTableInfo.sortBy as string)
      : undefined,
  )
  const [sortDirection, setSortDirection] = useState<string | undefined>(
    pageTableInfo.sortDirection !== undefined
      ? (pageTableInfo.sortDirection as boolean) === true
        ? 'ASC'
        : 'DESC'
      : undefined,
  )

  const totalRowCount = data?.total || 0

  if (data?.patients) {
    data?.patients.forEach((e: any) => {
      e['clinicName'] = e.clinic?.name
      e['matchDevicesValue'] = e?.matchDevices
        ?.map((t: any) => t?.type)
        .join('')
      e['deviceTypeValue'] = e?.matchDevices
        ?.map((t: any) => t?.vendor)
        .join('')
      e['transmissionDateValue'] = e?.matchDevices
        ?.map((t: any) => t?.transmissionDate)
        .join('')
    })
  }
  const tableData = data?.patients || []
  const totalPageCount = useMemo(() => {
    const count = Math.ceil(totalRowCount / pageLimit)
    return isNaN(count) ? 0 : count
  }, [totalRowCount, pageLimit])

  const fetchData = useCallback(
    ({
      search: searchParam,
      skip,
      pageLimit,
      sortBy,
      sortDirection,
    }: NewGetAllPatientsProps) => {
      const searchQuery = searchParam || searchTerm
      const updatedQueryData: NewGetAllPatientsProps = {
        isNew: memoizedIsNew ? memoizedIsNew : false,
        isRecentSigned: memoizedIsRecentlySigned,
        isDownloaded: memoizedIsNotDownloaded
          ? memoizedIsNotDownloaded
          : undefined,
        status: memoizedStatus,
        deviceSchedule: selectedDeviceSchedule,
      }

      if (memoizedIsAllReports || memoizedIsNotDownloaded) {
        updatedQueryData.isRecentSigned = undefined
      }
      if (searchQuery && searchQuery.length >= 2) {
        updatedQueryData.search = searchQuery
      }
      updatedQueryData.skip = skip
      updatedQueryData.pageLimit = pageLimit

      if (sortBy !== undefined && sortDirection !== undefined) {
        updatedQueryData.sortBy = sortBy
        updatedQueryData.sortDirection = sortDirection ? 'DESC' : 'ASC'
      }
      setQueryData(updatedQueryData)
    },
    [
      searchTerm,
      memoizedIsNew,
      memoizedIsRecentlySigned,
      memoizedIsAllReports,
      memoizedIsNotDownloaded,
      memoizedStatus,
      setQueryData,
    ],
  )
  const getData = (data: any): void => {
    if (skip !== data.pageIndex) {
      setSkip(data.pageIndex * data.pageSize)
      saveOrUpdateFilterTable(TablesMapName.PATIENTS, {
        pageIndex: data.pageIndex,
      })
    }

    if (pageLimit !== data.pageSize) {
      setPageLimit(data.pageSize)
      saveOrUpdateFilterTable(TablesMapName.PATIENTS, {
        pageSize: data.pageSize,
      })
    }
    const sort = data.sortBy && data.sortBy.length ? data.sortBy[0] : null
    if (sort) {
      const { desc, id } = sort
      if (sortBy !== id || sortDirection !== desc) {
        setSortBy(id)
        setSortDirection(desc)
        saveOrUpdateFilterTable(TablesMapName.PATIENTS, {
          sortDirection: desc,
          sortBy: id,
        })
      }
    } else {
      if (sortBy !== undefined || sortDirection !== undefined) {
        setSortBy(undefined)
        setSortDirection(undefined)
        saveOrUpdateFilterTable(TablesMapName.PATIENTS, {
          sortDirection: undefined,
          sortBy: undefined,
        })
      }
    }
  }

  useEffect(() => {
    fetchData({ search: searchTerm, skip, pageLimit, sortBy, sortDirection })
  }, [searchTerm, skip, pageLimit, sortBy, sortDirection, fetchData])

  const DEFAULT_SORT_ITEM: SortBy = {
    id: pageTableInfo.sortBy as string,
    desc: (pageTableInfo.sortDirection as boolean) ?? false,
  }
  const currPageIndex =
    isLoading || (searchTerm && searchTerm.length > 0) ? 0 : DEFAULT_PAGE_INDEX
  return (
    <DataTable
      hasPagination
      isSortable
      isSearchable
      isClickable
      columns={Columns}
      fetchData={(data: any) => getData(data)}
      data={tableData as Record<string, any>[]}
      totalRowCount={totalRowCount}
      totalPageCount={totalPageCount}
      isLoading={isLoading}
      searchTerm={searchTerm}
      initialPageSize={DEFAULT_PAGE_SIZE}
      initialSortBy={[DEFAULT_SORT_ITEM]}
      initialPageIndex={currPageIndex}
      goToPageIndex={currPageIndex}
      selectedRowBackgroundColor="primary.100"
      selectedRowBorderColor="primary.400"
      borderCollapse="separate"
      borderSpacing="0 2px"
      tableHeadCell={
        <TableHeader
          color="neutral.800"
          bg="neutral.white"
          borderBottomColor="neutral.200"
          borderBottomStyle="solid"
          height={35}
        />
      }
      tableBodyRow={() => (
        <TableRow
          borderBottomColor="neutral.200"
          borderBottomStyle="solid"
          borderBottomWidth="2px"
          bg="neutral.white"
          sx={{
            _hover: {
              '#patients-list__action-menu': {
                display: 'block',
              },
              cursor: 'default',
              background: 'var(--chakra-colors-primary-100)',
              boxShadow:
                'inset 0px 0px 1px #1a9ee0, inset 0px 0px 1px #1a9ee0, inset 0px 0px 1px #1a9ee0, inset 0px 0px 1px #1a9ee0',
            },
            '&:first-of-type': {
              borderTopWidth: 'none',
              '> td': {
                '&:first-of-type': {
                  borderBottomLeftRadius: 'md',
                },
                '&:last-of-type': {
                  borderBottomRightRadius: 'md',
                },
              },
            },
            '&:not(:first-of-type) > td': {
              '&:first-of-type': {
                borderTopLeftRadius: 'md',
                borderBottomLeftRadius: 'md',
              },
              '&:last-of-type': {
                borderTopRightRadius: 'md',
                borderBottomRightRadius: 'md',
              },
            },
            '&:last-of-type': {
              borderBottomWidth: 'none',
            },
          }}
        />
      )}
    />
  )
}

export default PatientsTable
