import { Link as RouterLink } from 'react-router-dom'

import { Box, Flex, HStack, Icon, Link, Text } from '@rhythm/components'

import { routes } from '../../../../routes'

import { AnalyticsNavBar } from './AnalyticsNavbar'

export const AnalyticsLayout = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => {
  return (
    <Flex>
      <AnalyticsNavBar />
      <Flex
        pb="20px"
        px={'35px'}
        mt={'65px'}
        flexDirection="row"
        transition="width 0.5s ease"
      >
        <Box w="100%">
          <Flex mb={'20px'}>
            <RouterLink
              to={{
                pathname: routes.physician.dashboard,
              }}
            >
              <Link as={'div'} fontSize={'md'}>
                <HStack>
                  <Icon boxSize={'sm'} icon={'arrow-left'} />
                  <Box>Back</Box>
                </HStack>
              </Link>
            </RouterLink>
          </Flex>
          <Text fontSize={'5xl'} fontWeight="light" mb="24px">
            {title}
          </Text>
          <Box>{children}</Box>
        </Box>
      </Flex>
    </Flex>
  )
}
