import { Toast, useToast } from '@rhythm/components'
import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiScheduledTransmissionControllerCreateRequest } from '../../../lib/api'

export const useCreateScheduledTransmission = () => {
  const Api = useApiContext()
  const toast = useToast()
  return useMutation(
    (
      scheduledTransmissionControllerCreateRequest: DefaultApiScheduledTransmissionControllerCreateRequest,
    ) =>
      Api.scheduledTransmissionControllerCreate(
        scheduledTransmissionControllerCreateRequest,
      ),
    {
      onSuccess: (_, variables) => {
        // const { transmissionReportId } = variables
        // queryClient.invalidateQueries([
        //   'transmissionReport',
        //   transmissionReportId,
        // ])
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
        toast({
          position: 'bottom-right',
          render: ({ onClose }) => (
            <Toast variant="error" onClose={onClose}>
              Error Creating Transmissions
            </Toast>
          ),
        })
      },
    },
  )
}
