import React, { useEffect, useState } from 'react'

import { Box, Button, Flex, Icon, Tooltip, VStack } from '@rhythm/components'
import ReactDatePicker, { CalendarContainer } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { useLocation } from 'react-router'

import { ReportType } from '../../lib/api'
import { fetchAnalyticsProps } from '../../pages/physician/AnalyticsPages/components/columns'

import { dateRangePickerStyling } from './datePickerStylings'
import {
  DateRangePickerHeader,
  DateRangePickerInput,
  getDateRange,
  quickNavigationOptions,
} from './DateRangePickerSubcomponents'

interface DateRangePickerProps {
  dateRange: Date[]
  onDateRangeSelected: (dateRange: Date[]) => void
}

const DateRangePicker = (props: DateRangePickerProps): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const { dateRange: newDateRange } = props
  const [dateRange, setDateRange] = useState<Date[]>([new Date(), new Date()])
  const [startDate, endDate] = dateRange
  const { key } = fetchAnalyticsProps(location.pathname)

  useEffect(() => {
    const [newStartDate, newEndDate] = newDateRange
    if (newStartDate && newEndDate) {
      setDateRange(newDateRange)
    }
  }, [newDateRange])

  const QuickNavigationContainer = ({
    className,
    children,
  }: {
    className: string
    children: React.ReactNode
  }) => {
    return (
      <Box>
        <CalendarContainer className={className}>
          <Flex>
            <Box width={200} borderRight={'1px solid #E5E5E5'}>
              {quickNavigationOptions.map(option => {
                if (option === 'Today' && key === ReportType.Billable) {
                  return (
                    <Box
                      key={option}
                      py={3}
                      pl={4}
                      bg={'gray.200'}
                      color={'gray.500'}
                      fontSize={'lg'}
                      cursor={'not-allowed'}
                      display={'flex'}
                      alignItems={'center'}
                      gap={2}
                    >
                      {option}
                      <Tooltip
                        label="It takes 24 hours for billable data to be available."
                        hasArrow
                        placement="top"
                      >
                        <span>
                          <Icon
                            icon="information"
                            boxSize={'sm'}
                            position={'relative'}
                            top={'3px'}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  )
                }
                return (
                  <Box
                    key={option}
                    py={3}
                    pl={4}
                    fontSize={'lg'}
                    _hover={{ bg: 'gray.100' }}
                    cursor={'pointer'}
                    onClick={() => {
                      const dateRange = getDateRange(option)
                      setDateRange(dateRange)
                    }}
                  >
                    {option}
                  </Box>
                )
              })}
            </Box>
            <VStack>
              <div>{children}</div>
              <Flex
                width={'100%'}
                height={'60px'}
                m={5}
                pr={5}
                pt={4}
                justifyContent={'flex-end'}
                borderTop={'1px solid #E5E5E5'}
              >
                <Button
                  size={'md'}
                  onClick={() => {
                    setOpen(false)
                    setDateRange(newDateRange)
                  }}
                  variant={'secondaryLight'}
                  mr={4}
                >
                  Cancel
                </Button>
                <Button
                  size={'md'}
                  onClick={() => {
                    // If the user selects a single date, we want to set the end date to the start date
                    dateRange[1] = dateRange[1] ?? dateRange[0]
                    props.onDateRangeSelected(dateRange)
                    setOpen(false)
                  }}
                >
                  Apply
                </Button>
              </Flex>
            </VStack>
          </Flex>
        </CalendarContainer>
      </Box>
    )
  }
  return (
    <Box __css={dateRangePickerStyling}>
      <ReactDatePicker
        key={startDate.toString()}
        open={open}
        customInput={
          <DateRangePickerInput bgColor={'white'} setCalenderOpen={setOpen} />
        }
        onInputClick={() => {
          setOpen(true)
        }}
        onClickOutside={() => {
          return
        }}
        renderCustomHeader={DateRangePickerHeader}
        showPopperArrow={false}
        placeholderText="Select Date Range"
        monthsShown={2}
        popperPlacement="bottom-end"
        calendarContainer={QuickNavigationContainer}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(dates: any) => setDateRange(dates)}
      />
    </Box>
  )
}

export default DateRangePicker
