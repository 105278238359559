import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerListRequest,
  queryClient,
} from '../../../lib/api'

export const useTransmissionReportList = () => {
  const Api = useApiContext()
  return useMutation(
    (params: DefaultApiTransmissionReportsControllerListRequest) => {
      return Api.transmissionReportsControllerList(params)
    },
    {
      onSuccess: res => {
        queryClient.setQueryData(['transmissionReportsList'], res.data)
      },
    },
  )
}
