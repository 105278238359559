import { useState } from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Card,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@rhythm/components'
import moment from 'moment'
import { useQuery } from 'react-query'

import { BatteryIcon, CircleAlertIcon } from '../../../../../assets'
import { useApiContext } from '../../../../../context/ApiContext'
import { useOrganization } from '../../../../../features/organizations/api/getOrganization'
import {
  DeviceTypes,
  GetScheduledTransmissionsResponse,
  MatchDevice,
} from '../../../../../lib/api'

import DeviceAttributes from './components/DeviceAttributes'
import DeviceSchedule from './components/DeviceSchedule'
import {
  DEVICE_ISSUE_TYPES,
  DeviceDetailsCardProps,
  DeviceIssueType,
  getTermDaysFromOrgBasedOnDeviceType,
  VENDOR_MAPPING,
} from './constants'

const DeviceDetail = ({
  device,
  organizationId,
  isInActivePatient,
}: {
  device: MatchDevice
  organizationId: string
  isInActivePatient: boolean
}) => {
  const API = useApiContext()

  const { type, scheduleRequiresCorrection, isEriTracked } = device

  const transmissionScheduleTermsAndFrequency = {
    heart_failure: {
      termDays: device.heartFailureScheduleTerm,
      startDate: device.heartFailureStartDate,
    },
    combo_billing: {
      termDays: device.comboBillingScheduleTerm,
      startDate: device.comboBillingStartDate,
    },
    routine: {
      termDays: device.routineScheduleTerm,
      startDate: device.routineStartDate,
    },
  }

  const [deviceIssue, setDeviceIssue] = useState<null | DeviceIssueType>(null)
  const { data: orgData } = useOrganization({
    organizationId: organizationId,
  })

  const { isLoading } = useQuery<GetScheduledTransmissionsResponse>(
    ['scheduledTransmission', { id: device.id }],
    async () => {
      const response = await API.scheduledTransmissionControllerGetAll({
        deviceId: device.id,
      })
      return response.data
    },
    {
      enabled: !!device.id && !!orgData?.organization?.id,
      retry: 0,
      onSuccess: resp => {
        const isReadOnly = resp.transmissionScheduleReadOnly
        if (isInActivePatient || isReadOnly) {
          setDeviceIssue(null)
          return
        }

        // Get the total count of transmissions
        const transmissionsCount = resp.total
        // Check if there are no transmissions. If so, set the device issue to deviceScheduleNotSet
        if (transmissionsCount < 1) {
          setDeviceIssue(DEVICE_ISSUE_TYPES.deviceScheduleNotSet)
        }
        // If the last scheduled transmission date is before the current date
        // If so, set the device issue to 'deviceScheduleNotSet'
        else if (
          moment
            .utc(resp?.data[transmissionsCount - 1]?.scheduledDate)
            .isBefore(moment(), 'days')
        ) {
          setDeviceIssue(DEVICE_ISSUE_TYPES.deviceScheduleNotSet)
        } else if (
          resp?.data.some(scheduledTransmission => {
            return scheduledTransmission.transmissionStatus === 'missed'
          })
        ) {
          setDeviceIssue(DEVICE_ISSUE_TYPES.deviceScheduleMissed)
        }
        // If the schedule requires correction, set the device issue to deviceScheduleRequiresCorrection
        else if (scheduleRequiresCorrection) {
          setDeviceIssue(DEVICE_ISSUE_TYPES.deviceRequiresCorrection)
        }
        // If there are transmissions, and the last scheduled transmission date
        // is not before the current date then clear the device issue
        else {
          setDeviceIssue(null)
        }
      },
    },
  )

  const termDays = getTermDaysFromOrgBasedOnDeviceType(
    type,
    orgData?.organization,
  )

  if (isLoading) {
    return <Skeleton height={'48px'} />
  }

  return (
    <AccordionItem borderTop="none" borderBottom="none">
      {({ isExpanded }) => (
        <Card
          mb={4}
          py={6}
          pl={4}
          px={8}
          transitionDuration={'0.3s'}
          borderLeft="4px"
          borderColor={deviceIssue && !isExpanded ? 'red.300' : 'transparent'}
        >
          <AccordionButton
            as="div"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            p={0}
            w={'full'}
            gap={4}
            _hover={{ cursor: 'pointer' }}
          >
            <HStack w={'full'} justifyContent={'space-between'}>
              <Flex alignItems={'center'} gap={2}>
                {deviceIssue ? (
                  <CircleAlertIcon width={24} height={24} />
                ) : (
                  <Icon icon="device" color="primary.600" />
                )}
                <Heading variant="h4">{device.type}</Heading>
                <Badge>{VENDOR_MAPPING[device.vendor] || device.vendor}</Badge>
                {isEriTracked && (
                  <Box
                    backgroundColor="#DFDAFB"
                    padding="1"
                    marginLeft="1"
                    marginRight="1"
                    borderRadius="sm"
                  >
                    <HStack spacing="1">
                      <BatteryIcon />
                      <Text fontSize="11px" color="#2612A5" fontWeight="bold">
                        ERI TRACKED
                      </Text>
                    </HStack>
                  </Box>
                )}
              </Flex>

              <AccordionIcon />
            </HStack>
            {deviceIssue && (
              <Flex
                gap={2}
                alignItems="center"
                transitionDuration={'0.3s'}
                hidden={isExpanded}
              >
                {deviceIssue.icon}
                <Text fontSize={'lg'} fontWeight={'medium'}>
                  {deviceIssue.text}
                </Text>
              </Flex>
            )}
          </AccordionButton>
          <AccordionPanel mt={4} p="1">
            <Stack spacing={8}>
              <DeviceAttributes device={device} />
              <Divider borderColor="gray.200" />
              <DeviceSchedule
                deviceId={device.id}
                deviceType={device.type}
                isScheduleEnabled={device.scheduleEnabled}
                orgId={orgData?.organization?.id}
                termDays={termDays}
                transmissionScheduleTermsAndFrequency={
                  transmissionScheduleTermsAndFrequency
                }
              />
            </Stack>
          </AccordionPanel>
        </Card>
      )}
    </AccordionItem>
  )
}

export const DeviceDetailsWithTransmissionSchedule = ({
  devicesArray,
  organizationId,
  isInActivePatient,
}: DeviceDetailsCardProps) => {
  if (!devicesArray.length) {
    return (
      <VStack spacing={2} alignItems={'start'}>
        <Heading variant={'h5'}>No Devices Available</Heading>
        <Text color="neutral.800" variant="smallCaps" mb="sm">
          There are no devices available for this patient.
        </Text>
      </VStack>
    )
  }
  return (
    <Accordion allowToggle>
      {devicesArray.map(deviceDetail => (
        <DeviceDetail
          isInActivePatient={isInActivePatient}
          key={deviceDetail.id}
          device={deviceDetail}
          organizationId={organizationId}
        />
      ))}
    </Accordion>
  )
}

export default DeviceDetailsWithTransmissionSchedule
