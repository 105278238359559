import React, { useCallback, useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Card,
  Checkbox,
  Error,
  Flex,
  Form,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from '@rhythm/components'
import moment from 'moment'
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import MultiSelect, { components, OptionProps } from 'react-select'
import * as yup from 'yup'

import { BillingIcon } from '../../../../../assets'
import DatePickerv2 from '../../../../../components/DatePickerv2'
import { useApiContext } from '../../../../../context/ApiContext'
import { useTransmissionReportContext } from '../../../../../features/transmissionReports'
import { Billing, queryClient } from '../../../../../lib/api'
import {
  DIAGNOSIS_OPTIONS,
  PROFESSIONAL_OPTIONS_IN_CLINIC_2,
  PROFESSIONAL_OPTIONS_MANUAL,
  PROFESSIONAL_OPTIONS_REMOTE,
  TECHNICAL_OPTIONS,
  TECHNICAL_OPTIONS_HEART_FAILURE,
  TECHNICAL_OPTIONS_ILR,
} from '../../../../../utils/constants/transmissionReports'
import formatDate from '../../../../../utils/formatDate'
import getZeroHourDbDate from '../../../../../utils/getZeroHourDbDate'
import { DefaultMultiSelectStyles } from '../../../../../utils/multiSelectOptions'
import { DATE_FORMAT_REGEX } from '../../../../../utils/regex'

import { ApiBillableDto, ApiBillingDto, BillingFormType } from './api/types'
import { NavigationIcon } from './CustomIcons'

interface LabelWithValue {
  label: string
  value: string
  description?: string
}

interface DiagnosisCodeLabelWithValue extends LabelWithValue {
  isPatientDiagnosisCode: boolean
}

interface FormType extends FieldValues {
  technicalCode: LabelWithValue
  professionalCodes: LabelWithValue[]
  diagnosisCodes: DiagnosisCodeLabelWithValue[]
  isBillable: boolean
  billingDate?: string | null
}

const LabelWithValueSchema = yup.object().shape({
  label: yup.string(),
  value: yup.string(),
  description: yup.string().optional(),
})

const DiagnosisCodeSchema = yup.object().shape({
  label: yup.string().required(),
  value: yup.string().required(),
  description: yup.string().optional(),
  isPatientDiagnosisCode: yup.boolean().optional(),
})

const formSchema = yup.object().shape({
  isBillable: yup.boolean().required(),
  diagnosisCodes: yup
    .array()
    .of(DiagnosisCodeSchema)
    .min(1, 'Diagnosis code is required'),
  // if the billing date is not provided, we will show an error message
  billingDate: yup.string().required('Billing date is required').nullable(),
  // if isBillable is false, we don't need to validate professionalCodes and technicalCode
  // else they are required
  professionalCodes: yup
    .array()
    .of(LabelWithValueSchema)
    .when('isBillable', {
      is: true,
      then: yup
        .array()
        .of(LabelWithValueSchema)
        .min(1, 'Professional code is required'),
    }),
  // when the isBillable is false, we don't need to validate the technicalCode
  technicalCode: yup
    .object()
    .shape({
      label: yup.string().optional(),
      value: yup.string().optional(),
    })
    .when('isBillable', {
      is: true,
      then: yup
        .object()
        .test(
          'technicalCode-required',
          'Technical code is required',
          value => !!value && !!value.label && !!value.value,
        )
        .required('Technical code is required'),
    }),
})

const OptionWithDescription = <isMulti extends boolean>(
  props: OptionProps<LabelWithValue, isMulti>,
) => {
  return (
    <components.Option {...props}>
      <VStack alignItems={'start'}>
        <span>{props.data.label}</span>
        <Text color={'inherit'} fontSize={'sm'} style={{ marginTop: 2 }}>
          {props.data.description}
        </Text>
      </VStack>
    </components.Option>
  )
}

const emptyOption = {
  label: '',
  value: '',
}

const BillingCard = (): React.ReactElement => {
  const { transmissionReport, setIsBillingValid, isBillingLoading } =
    useTransmissionReportContext()

  const Api = useApiContext()

  const {
    id: transmissionReportId,
    transmissionType,
    flagHeartFailure: isHeartFailure,
    device: { deviceType } = { deviceType: null },
  } = transmissionReport ?? {}

  const isInClinic = transmissionType === 'in-clinic'
  const isRemote = transmissionType === 'remote'
  const isManual = transmissionType === 'manual'
  const isILR = deviceType === 'ILR'

  // Get Billing: Get All the billing info by Transmission report id and billing id
  const {
    data: billingData,
    isFetching: getBillingApiLoading,
    isSuccess: getBillingApiSuccess,
    isError: getBillingApiError,
  } = useQuery<Billing>({
    queryKey: ['billing', transmissionReportId],
    queryFn: async () => {
      const response = await Api.transmissionReportsControllerGetBilling(
        String(transmissionReportId),
      )
      return response.data
    },
  })

  const billingId = billingData?.id ?? ''

  // Update Billing: Patch call for the billing information
  const updateBillingMutation = useMutation({
    mutationFn: async ({ billingId, data }: ApiBillingDto) => {
      const response = await Api.billingControllerUpdateBilling(billingId, data)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['billing', transmissionReportId])
    },
  })

  // Update Is Billable: All the logic for the isBillable handled in the backend
  const updateIsBillableMutation = useMutation({
    mutationFn: async ({ billingId, data }: ApiBillableDto) => {
      const response = await Api.billingControllerUpdateIsBillable(
        billingId,
        data,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['billing', transmissionReportId])
    },
  })

  const isApiLoading =
    getBillingApiLoading ||
    updateBillingMutation.isLoading ||
    updateIsBillableMutation.isLoading ||
    isBillingLoading

  const isApiError =
    getBillingApiError ||
    updateBillingMutation.isError ||
    updateIsBillableMutation.isError

  const methods = useForm<FormType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    criteriaMode: 'all',
  })

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { dirtyFields, errors },
    getValues,
    watch,
  } = methods

  // if there are errors in the form, set isBillingValid (true by default) to false else true
  useEffect(() => {
    console.log('errors:', errors)
    setIsBillingValid(Object.keys(errors).length === 0)
  }, [errors, setIsBillingValid])

  useEffect(() => {
    // Only reset when billingData is different or newly fetched
    if (getBillingApiSuccess && billingData) {
      reset({
        isBillable: billingData?.isBillable ?? false,
        diagnosisCodes:
          billingData?.diagnosisCodes?.map(code => ({
            label: code.value,
            value: code.value,
            isPatientDiagnosisCode: code.isPatientDiagnosisCode,
          })) ?? [],
        billingDate: billingData?.billingDate
          ? moment(billingData.billingDate).format('YYYY-MM-DD')
          : null,
        professionalCodes:
          billingData?.professionalCodes?.map(code => ({
            label: code.value,
            value: code.value,
          })) ?? [],
        technicalCode: {
          label: billingData?.technicalCode ?? '-',
          value: billingData?.technicalCode ?? '-',
        },
      })

      trigger()
    }
  }, [getBillingApiSuccess, billingData, reset])

  // Memoized options for select fields
  const professionalOptions = useMemo(() => {
    if (isRemote) return PROFESSIONAL_OPTIONS_REMOTE
    else if (isInClinic && deviceType)
      return PROFESSIONAL_OPTIONS_IN_CLINIC_2(deviceType)
    else return PROFESSIONAL_OPTIONS_MANUAL
  }, [isRemote, isInClinic, deviceType])

  const technicalOptions = useMemo(() => {
    if (isILR) return TECHNICAL_OPTIONS_ILR
    else if (isHeartFailure) return TECHNICAL_OPTIONS_HEART_FAILURE
    else if (isRemote || isManual) return TECHNICAL_OPTIONS
    else if (isInClinic) return [{ label: '-', value: '-', description: '' }]
    else return TECHNICAL_OPTIONS
  }, [isRemote, isManual, isILR, isHeartFailure, isInClinic])

  const onSubmit: SubmitHandler<FormType> = useCallback(
    async data => {
      try {
        const dirtyField = Object.keys(dirtyFields)[0] as keyof BillingFormType
        const dirtyValue = data[dirtyField]

        // as we are updating the isBillable field in the checkbox, we don't want to update it again
        if (dirtyField === 'isBillable') return

        await updateBillingMutation.mutateAsync({
          billingId: billingId,
          data: {
            transmissionReportId: transmissionReportId ?? 0,
            [dirtyField]: dirtyValue,
          },
        })
      } catch (error) {
        console.log('Error in handleBillingMutation:', error)
      }
    },
    [dirtyFields],
  )

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (Object.keys(dirtyFields).length > 0 && name) {
        onSubmit(value as FormType)
      }
    })

    return () => subscription.unsubscribe() // Clean up subscription on unmount
  }, [dirtyFields, handleSubmit, onSubmit, watch])

  return (
    <Card width="100%">
      <Flex direction="column">
        {/* Billing Header */}
        <Flex mb="2xl" justifyContent={'space-between'} alignItems={'center'}>
          <Flex alignItems={'center'} gap={4}>
            <Box
              p={2}
              borderRadius={4}
              style={{
                backgroundColor: 'var(--chakra-colors-neutral-200)',
                color: 'var(--chakra-colors-primary-600)',
                height: '56px',
                width: '50px',
                borderRadius: 'var(--chakra-radii-lg)',
                paddingTop: 'var(--chakra-space-sm)',
                paddingBottom: 'var(--chakra-space-sm)',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BillingIcon width={'24px'} height={'24px'} />
            </Box>
            <Text fontSize={'2xl'} fontWeight={600}>
              Billing
            </Text>
          </Flex>
          {billingData?.isAppDeterminedBillable && (
            <HStack>
              <Icon
                icon="information"
                boxSize={6}
                fontSize={12}
                id="infoIconStyle"
                color={'black'}
              />
              <Text fontSize={'md'} fontWeight={'bold'}>
                App has determined this report to be billable
              </Text>
            </HStack>
          )}
          <HStack
            spacing="sm"
            direction="row"
            alignContent={'flex-end'}
            align={'flex-end'}
          >
            <Icon
              icon="information"
              boxSize={4}
              fontSize={12}
              id="infoIconStyle"
              color={'#455468'}
            />
            <Text fontSize={'sm'}>Billing and Coding:</Text>

            <Link
              as={'div'}
              fontSize={'sm'}
              display={'flex'}
              alignItems={'center'}
              onClick={() =>
                window.open(
                  'https://www.cms.gov/medicare-coverage-database/view/article.aspx?articleId=56602',
                  '_blank',
                )
              }
            >
              <Text fontSize={'sm'} textColor={'primary'}>
                Cardiac Rhythm Device Evaluation
              </Text>
              <NavigationIcon />
            </Link>
          </HStack>
        </Flex>

        {/* Error Info */}
        <Flex
          hidden={!isApiError}
          height={'120px'}
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
        >
          <Text fontSize={'xl'}>Error loading billing information</Text>
        </Flex>

        {isApiLoading ? (
          <Flex justifyContent="center" alignItems="center" height="170px">
            <Spinner size="xl" color="primary.400" />
          </Flex>
        ) : (
          <Form
            onSubmit={e => {
              // handleSubmit(onSubmit)()
            }}
          >
            <Flex>
              <FormControl flexBasis={185}>
                <FormLabel htmlFor="isBillable">BILLING STATUS</FormLabel>
                <Controller
                  name="isBillable"
                  control={control}
                  disabled={isApiLoading || isApiError}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <Checkbox
                      ref={ref}
                      name={name}
                      isChecked={value}
                      onChange={async e => {
                        onChange(e.target.checked)
                        try {
                          await updateIsBillableMutation.mutateAsync({
                            billingId: billingId,
                            data: {
                              transmissionReportId: transmissionReportId ?? 0,
                              isBillable: e.target.checked,
                            },
                          })
                        } catch (error) {
                          console.error('Error in isBillable mutation:', error)
                        }
                      }}
                    >
                      <Text>Billable</Text>
                    </Checkbox>
                  )}
                />
              </FormControl>
              <SimpleGrid width="100%" spacing="2xl" columns={2} rowGap={'2xl'}>
                <FormControl>
                  <FormLabel htmlFor="diagnosisCodes">DIAGNOSIS CODE</FormLabel>
                  <Controller
                    name="diagnosisCodes"
                    control={control}
                    disabled={isApiLoading || isApiError}
                    render={({ field: { onChange, value, ref, name } }) => (
                      <MultiSelect
                        ref={ref}
                        name={name}
                        value={value}
                        options={DIAGNOSIS_OPTIONS}
                        onChange={onChange}
                        components={{
                          Option: OptionWithDescription,
                        }}
                        styles={DefaultMultiSelectStyles<
                          LabelWithValue,
                          true
                        >()}
                        isMulti
                        required
                        maxMenuHeight={220}
                      />
                    )}
                  />
                  {errors?.diagnosisCodes && (
                    <Error
                      message={
                        errors.diagnosisCodes.message ||
                        'Please select a diagnosis code'
                      }
                    />
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="billingDate">BILLING DATE</FormLabel>
                  <Controller
                    name="billingDate"
                    control={control}
                    disabled={isApiLoading || isApiError}
                    render={({ field: { onChange, value } }) => (
                      <Box id="billingBox">
                        <DatePickerv2
                          onlyOpenCalenderOnIconClick={true}
                          datePickerProps={{
                            id: 'billingDate',
                            selected: value ? getZeroHourDbDate(value) : null,
                            onChange: (date: Date | null) => {
                              onChange(
                                date ? formatDate(date.toISOString()) : null,
                              )
                            },
                            placeholderText: 'mm/dd/yyyy',
                            onlyOpenCalenderOnIconClick: true,
                            onFocus: e => e.target.blur(),
                            isClearable: true,
                            disabledKeyboardNavigation: true,
                            dayClassName: (date: Date) => {
                              const today = new Date()
                              return !value &&
                                formatDate(date.toISOString()) ===
                                  formatDate(today.toISOString())
                                ? 'currentDay'
                                : ''
                            },
                          }}
                        />
                      </Box>
                    )}
                    rules={{
                      pattern: {
                        value: DATE_FORMAT_REGEX,
                        message:
                          'Date is invalid or has wrong format (month/day/year)',
                      },
                      required: 'Billing date is required',
                    }}
                  />
                  {errors?.billingDate && (
                    <Error
                      message={
                        errors.billingDate?.message ?? 'Error in billing date'
                      }
                      color="red"
                    />
                  )}
                </FormControl>
                <FormControl hidden={!getValues('isBillable')}>
                  <FormLabel htmlFor="professionalCodes">
                    PROFESSIONAL CODE
                  </FormLabel>
                  <Controller
                    name="professionalCodes"
                    control={control}
                    disabled={isApiLoading || isApiError}
                    render={({ field: { onChange, value, ref } }) => (
                      <MultiSelect
                        ref={ref}
                        value={value}
                        options={professionalOptions}
                        components={{
                          Option: OptionWithDescription,
                        }}
                        styles={DefaultMultiSelectStyles<
                          LabelWithValue,
                          true
                        >()}
                        onChange={onChange}
                        maxMenuHeight={220}
                        isMulti
                      />
                    )}
                  />
                  {errors?.professionalCodes && (
                    <Error
                      message={
                        errors.professionalCodes.message ||
                        'Please select a professional code'
                      }
                    />
                  )}
                </FormControl>
                <FormControl hidden={!getValues('isBillable')}>
                  <FormLabel htmlFor="technicalCode">TECHNICAL CODE</FormLabel>
                  <Controller
                    name="technicalCode"
                    control={control}
                    disabled={isApiLoading || isApiError}
                    render={({ field: { onChange, value, ref } }) => (
                      <Box style={{ position: 'relative' }}>
                        <MultiSelect
                          ref={ref}
                          value={value}
                          options={technicalOptions}
                          components={{
                            Option: OptionWithDescription,
                          }}
                          styles={DefaultMultiSelectStyles<
                            LabelWithValue,
                            true
                          >()}
                          onChange={onChange}
                          maxMenuHeight={220}
                        />
                        {value?.value && (
                          <Icon
                            style={{
                              position: 'absolute',
                              right: '40px',
                              top: '12px',
                              height: '16px',
                              width: '16px',
                              cursor: 'pointer',
                            }}
                            icon="close-circle"
                            onClick={() => {
                              onChange(emptyOption)
                            }}
                          />
                        )}
                      </Box>
                    )}
                  />
                  {errors?.technicalCode && (
                    <Error
                      message={
                        errors.technicalCode?.message ||
                        'Please select a technical code'
                      }
                    />
                  )}
                </FormControl>
              </SimpleGrid>
            </Flex>
          </Form>
        )}
      </Flex>
    </Card>
  )
}

export default BillingCard
