import React from 'react'

import './FilterCount.css'

export interface FilterCountProps {
  count: number
}

const FilterCount = ({ count }: FilterCountProps): React.ReactElement => {
  return (
    <span className="filtercount">
      {count > 0 && (
        <span className="badge">
          {count < 10 ? 0 : ''}
          {count}
        </span>
      )}
    </span>
  )
}

export default FilterCount
