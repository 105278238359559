import React, { useEffect, useMemo, useState } from 'react'

import { Box, Column, Text } from '@rhythm/components'

import CustomPagination from '../../../components/CustomPagination/CustomPagination'
import { useGetRegisteredNpi } from '../../../features/users/api/getNpiUser'
import {
  DefaultApiRegisteredNpisControllerGetNpisDataRequest,
  RegisteredNpis,
} from '../../../lib/api'

import NpiList from './NpiDataTable'

interface FormValuesType {
  city?: string
  state: { label: string; value: string } | null
  taxonomy: { label: string; value: string } | null
  npiNumber: string
  providerLastName: string
  providerFirstName: string
}
interface ReferringPhysicianApiProps {
  data: FormValuesType
  onSubmit: (data: RegisteredNpis) => void
  setIsDataModal: (data: boolean) => void
  setIsDataLoading: (data: boolean) => void
  clearModalData: () => void
}
export type ColumnCellProps = {
  row: {
    original: RegisteredNpis
  }
}
export const npi = ({ row: { original } }: ColumnCellProps) => {
  const npi = original.NPI
  return <Text fontWeight="bold">{npi}</Text>
}
export const fullName = ({ row: { original } }: ColumnCellProps) => {
  const fullName = original.firstName + ' ' + original.lastName
  return <Text fontWeight="bold">{fullName}</Text>
}

export const address = ({ row: { original } }: ColumnCellProps) => {
  const section =
    original.city + ' ' + original.state + '-' + Number(original.zipCode)
  return <Text fontWeight="bold">{section}</Text>
}
export const phoneNumber = ({ row: { original } }: ColumnCellProps) => {
  const phoneNumber = Number(original.phoneNumber)
  return <Text fontWeight="bold">{phoneNumber}</Text>
}
export const taxonomyCell = ({ row: { original } }: ColumnCellProps) => {
  const taxonomyCode = original.taxonomyCode
  return <Text fontWeight="bold">{taxonomyCode?.display_name ?? ''}</Text>
}
const Cells = {
  npi,
  fullName,
  address,
  phoneNumber,
  taxonomyCell,
}
const columns: Column[] = [
  {
    Header: 'NPI',
    accessor: 'NPI',
    width: 10,
    Cell: Cells.npi,
  },
  {
    Header: 'Name',
    accessor: 'firstName',
    width: 20,
    Cell: Cells.fullName,
  },
  {
    Header: 'Address',
    width: 25,
    Cell: Cells.address,
  },
  {
    Header: 'Phone',
    width: 15,
    Cell: Cells.phoneNumber,
  },
  {
    Header: 'Primary Taxonomy',
    accessor: 'taxonomyCode',
    width: 30,
    Cell: Cells.taxonomyCell,
  },
]

const ReferringProviderData = ({
  data,
  onSubmit,
  setIsDataModal,
  setIsDataLoading,
  clearModalData,
}: ReferringPhysicianApiProps): React.ReactElement => {
  const [npiData, setNpiData] = useState<RegisteredNpis[]>([])
  const [pageIndex, setPageIndex] = useState(1)
  const [sortBy, setSortBy] = useState<string | undefined>(undefined)
  const [sortDirection, setSortDirection] = useState<
    'ASC' | 'DESC' | undefined
  >(undefined)
  const [currentPage, setCurrentPage] = useState(pageIndex ? pageIndex : 1)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const startIndex = (currentPage - 1) * rowsPerPage

  const {
    city,
    state,
    npiNumber,
    providerFirstName,
    providerLastName,
    taxonomy,
  } = data

  const params: DefaultApiRegisteredNpisControllerGetNpisDataRequest = {
    city: city || '',
    firstName: providerFirstName || '',
    lastName: providerLastName || '',
    taxonomy: taxonomy?.value || '',
    state: state?.value || '',
    npi: npiNumber || '',
    limit: rowsPerPage.toString(),
    offset: '0',
    sortBy: undefined,
    sortDirection: undefined,
  }

  const [queryData, setQueryData] =
    useState<DefaultApiRegisteredNpisControllerGetNpisDataRequest>(params)

  const { data: providerData, isLoading } = useGetRegisteredNpi(queryData)

  const totalPageCount = useMemo(() => {
    const totalValue = providerData?.total || 0
    const count = Math.ceil(totalValue / rowsPerPage)
    return isNaN(count) ? 0 : count
  }, [providerData, rowsPerPage])

  useEffect(() => {
    fetchDataForPage(1)
    setPageIndex(1)
  }, [data, sortBy, sortDirection, rowsPerPage])

  useEffect(() => {
    setIsDataLoading(isLoading)
    if (!providerData) return

    setNpiData(providerData.data)
  }, [providerData])
  useEffect(() => {
    fetchDataForPage(pageIndex)
  }, [pageIndex])

  const getData = (data: any): void => {
    setRowsPerPage(data.pageSize)
    const sort = data.sortBy && data.sortBy.length ? data.sortBy[0] : null
    if (sort) {
      const { desc, id } = sort
      if (sortBy !== id || sortDirection !== desc) {
        setSortBy(id)
        setSortDirection(desc === false ? 'ASC' : 'DESC')
      }
    } else {
      if (sortBy !== undefined || sortDirection !== undefined) {
        setSortBy(undefined)
        setSortDirection(undefined)
      }
    }
  }
  const fetchDataForPage = (page: number) => {
    const offset = (page - 1) * rowsPerPage

    const updatedParams: DefaultApiRegisteredNpisControllerGetNpisDataRequest =
      {
        city: data.city || '',
        firstName: data.providerFirstName || '',
        lastName: data.providerLastName || '',
        taxonomy: data.taxonomy?.value || '',
        state: data.state?.value || '',
        npi: data.npiNumber ?? '',
        limit: rowsPerPage.toString(),
        offset: String(offset),
        sortBy: sortBy,
        sortDirection: sortDirection,
      }
    setQueryData(updatedParams)
    setCurrentPage(page)
  }
  const handleRowClick = (row: any) => {
    if (row.original.NPI) {
      onSubmit(row.original)
      setIsDataModal(false)
      clearModalData()
    }
  }

  return (
    <>
      {!npiData.length && !isLoading ? (
        <Text
          align={'center'}
          fontSize={'16px'}
          fontWeight={'700'}
          mt={'35px'}
          mb={'5px'}
        >
          No Record Found
        </Text>
      ) : (
        <>
          <Text fontSize={'16px'} fontWeight={'700'} mt={'35px'} mb={'5px'}>
            Results
          </Text>
          <Box
            maxH={'500px'}
            border={'1px solid #cdcdcdde'}
            borderRadius={'10px'}
            overflowY={'scroll'}
            position={'relative'}
            css={{
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '96px',
                left: 'calc(50% - 6px/2)',
                top: '8px',
                background: '#6C7789',
              },
              '&::-webkit-scrollbar-track': {
                background: '#FFFFFF',
                opacity: 0.5,
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '100px',
                background: '#6C7789',
              },
            }}
          >
            <NpiList
              getData={getData}
              total={providerData?.total}
              totalPageCount={totalPageCount}
              data={npiData}
              columns={columns}
              onRowClick={handleRowClick}
              isLoading={isLoading}
              initialPageIndex={pageIndex}
              initialPageSize={rowsPerPage}
            />
            {npiData.length != 0 && !isLoading ? (
              <CustomPagination
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                startIndex={startIndex}
                totalPageCount={totalPageCount}
                setPageIndex={setPageIndex}
                total={providerData?.total || 0}
              />
            ) : null}
          </Box>
        </>
      )}
    </>
  )
}

export default ReferringProviderData
