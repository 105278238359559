import { generatePath } from 'react-router-dom'

import { Box, Text } from '@rhythm/components'

import routes from '../../../../routes/config'
import { NavigationIcon } from '../../TransmissionReportPage/components/BillingCard/CustomIcons'

export const PatientTableCell = ({
  value,
}: {
  value: { name: string; id: string }
}) => {
  const { name, id } = value
  const onCellClick = () => {
    if (id) {
      window.open(
        generatePath(routes.physician['patientProfile'], {
          id: id,
        }),
        '_blank',
      )
    }
  }
  return (
    <Box onClick={onCellClick} cursor={'pointer'}>
      <Text
        fontWeight={'bold'}
        color={'blue.600'}
        display={'flex'}
        alignItems={'center'}
      >
        {name}
      </Text>
    </Box>
  )
}
