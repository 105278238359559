import React, { ReactElement, useRef } from 'react'

import { TagProps } from '@chakra-ui/react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Tag,
  TagLabel,
  Text,
  theme,
} from '@rhythm/components'
import { Control, Controller } from 'react-hook-form'
import Select, {
  components,
  CSSObjectWithLabel,
  GroupBase,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  SelectInstance,
  ValueContainerProps,
} from 'react-select'
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components'

type OptionType = {
  label: string
  value: string
}

type MultiSelectWithFormControlProps = {
  name: string
  options: OptionType[]
  label?: string
  control: Control<any>
  placeholder?: string
  withSelectAll?: boolean
  maxOptionsBeforeClearAll?: number
  components?: SelectComponentsConfig<OptionType, true, GroupBase<OptionType>>
  onBlur?: () => void
  isDisabled?: boolean
  menuIsOpen?: boolean
  showLabel?: boolean
  highlightSelectedOptions?: boolean
}

interface CustomTagProps extends TagProps {
  label: string
}

export const CustomTag = (props: CustomTagProps) => {
  return (
    <Tag
      size={'md'}
      borderRadius={'lg'}
      py={1}
      pl={3}
      pr={1}
      gap={2}
      {...props}
    >
      <TagLabel color={'inherit'} textTransform={'capitalize'}>
        {props?.label}
      </TagLabel>
      {props?.children}
    </Tag>
  )
}

interface CustomValueContainerProps
  extends ValueContainerProps<OptionType, true> {
  max?: number
  label?: string
}

export function CustomCheckBoxOption(props: OptionProps<OptionType, true>) {
  return (
    <components.Option {...props}>
      <div
        style={{ zIndex: 2, cursor: 'pointer' }}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          props.selectOption(props.data)
        }}
      >
        <Checkbox
          isChecked={props.isSelected}
          style={{ textTransform: 'capitalize' }}
        >
          {props?.children ?? props.label}
        </Checkbox>
      </div>
    </components.Option>
  )
}

function CustomMultiValueContainer(
  props: MultiValueGenericProps<OptionType, true>,
) {
  return (
    <components.MultiValueContainer {...props}>
      <CustomTag label={props?.data?.label ?? ''}>
        {/*Here the children contains the MultiValueRemove component*/}
        {props.children}
      </CustomTag>
    </components.MultiValueContainer>
  )
}

export function CustomRemoveValueButton(
  props: MultiValueRemoveProps<OptionType, true>,
) {
  return (
    <components.MultiValueRemove {...props}>
      <Button
        borderRadius={'full'}
        size={'xs'}
        variant={'secondaryDark'}
        opacity={0.7}
        _hover={{
          opacity: 1,
        }}
        border={'none'}
      >
        X
      </Button>
    </components.MultiValueRemove>
  )
}

interface CustomValueContainerProps
  extends ValueContainerProps<OptionType, true> {
  max?: number
  label?: string
}
export function CustomValueContainer(props: CustomValueContainerProps) {
  const { children, max = 1, label = 'options', getValue } = props
  const [values, input] = children as ReactElement[]
  const selectedCount = getValue().length

  return (
    <components.ValueContainer {...props}>
      {selectedCount > max ? (
        <CustomTag label={`${selectedCount} ${label} Selected`} pr={3} />
      ) : selectedCount > 0 ? (
        <Box maxWidth="95%">{values}</Box>
      ) : (
        values
      )}
      {input}
    </components.ValueContainer>
  )
}

const MultiSelectWithFormControl = ({
  label,
  name,
  withSelectAll,
  control,
  placeholder,
  options,
  components,
  maxOptionsBeforeClearAll,
  onBlur,
  isDisabled,
  menuIsOpen,
  showLabel = true,
  highlightSelectedOptions = true,
}: MultiSelectWithFormControlProps): ReactElement => {
  const selectRef =
    useRef<SelectInstance<OptionType, true, GroupBase<OptionType>>>(null)

  const getOptionStyle = (
    base: CSSObjectWithLabel,
    state: OptionProps<OptionType, true>,
    theme: any, // Replace 'any' with the appropriate type for your theme
    highlightSelectedOptions: boolean,
  ) => {
    let backgroundColor
    if (state.isSelected) {
      backgroundColor = highlightSelectedOptions
        ? theme.colors.primary[400]
        : 'transparent'
      if (state.isFocused) {
        backgroundColor = highlightSelectedOptions
          ? theme.colors.primary[600]
          : theme.colors.neutral[200]
      }
    } else if (state.isFocused) {
      backgroundColor = theme.colors.neutral[200]
    } else {
      backgroundColor = 'transparent'
    }

    let color
    if (state.isDisabled) {
      color = theme.colors.neutral[600]
    } else if (state.isSelected) {
      color = highlightSelectedOptions
        ? theme.colors.neutral.white
        : theme.colors.neutral.black
    } else {
      color = theme.colors.neutral.black
    }

    return {
      ...base,
      backgroundColor,
      color,
      ':active': {},
    }
  }
  return (
    <FormControl>
      {showLabel && (
        <FormLabel htmlFor={name} size={'lg'}>
          <Text
            fontStyle={'md'}
            fontWeight={'bold'}
            color={'inherit'}
            textTransform={'uppercase'}
          >
            {label ?? name}
          </Text>
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box position={'relative'}>
            {withSelectAll && (
              <Button
                size={'xs'}
                variant={'plain'}
                position={'absolute'}
                bg={'transparent'}
                top={-8}
                right={0}
                fontStyle={'medium'}
                color={'text.secondary'}
                isDisabled={value?.length === options.length}
                border={'none'}
                onClick={() => {
                  onChange(options)
                  onBlur ? onBlur() : () => {}
                }}
              >
                Select All
              </Button>
            )}

            <Select
              ref={selectRef}
              onBlur={onBlur ? onBlur : () => {}}
              isDisabled={isDisabled}
              value={value}
              onChange={props => {
                onChange(props)
                if (props?.length === 0) {
                  onBlur ? onBlur() : () => {}
                }
              }}
              isMulti
              {...(maxOptionsBeforeClearAll && {
                onKeyDown: (event: any) => {
                  if (
                    // If the event key is 'Backspace'
                    event.key === 'Backspace' &&
                    // And the optional 'value' length is more than the constant 'maxOptionsBeforeClearAll'
                    value?.length > maxOptionsBeforeClearAll &&
                    // And 'selectionEnd' of the 'inputRef' from the current 'selectRef' is 0
                    selectRef.current?.inputRef?.selectionEnd === 0
                  ) {
                    event.preventDefault()
                    onChange([])
                  }
                },
              })}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              menuIsOpen={menuIsOpen}
              placeholder={placeholder ?? 'Select Items'}
              options={options}
              components={{
                Option: CustomCheckBoxOption,
                MultiValueContainer: CustomMultiValueContainer,
                MultiValueRemove: CustomRemoveValueButton,
                // We don't want to show the label again for the multi-value as the label is already shown in the multi-value container
                MultiValueLabel: () => null,
                ...components,
              }}
              styles={{
                option: (base, state) =>
                  getOptionStyle(base, state, theme, highlightSelectedOptions),
                control: (base: CSSObjectWithLabel, state) => ({
                  ...base,
                  cursor: 'pointer',
                  borderColor: state.isFocused
                    ? theme.colors.primary[400]
                    : theme.colors.neutral[400],
                  borderRadius: theme.radii.sm,
                  borderWidth: '0.5px',
                  boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.1)',
                  ':hover': {
                    borderColor: state.isFocused
                      ? theme.colors.primary[400]
                      : theme.colors.neutral[600],
                  },
                }),
                clearIndicator: base => ({
                  ...base,
                  color: theme.colors.neutral['600'],
                  '&:hover': {
                    color: theme.colors.neutral['800'],
                  },
                  cursor: 'pointer',
                }),
                placeholder: (base: CSSObjectWithLabel) => ({
                  ...base,
                  color: theme.colors.neutral[600],
                }),
                menu: (base: CSSObjectWithLabel) => ({
                  ...base,
                  zIndex: 2,
                }),
                multiValue: (base: CSSObjectWithLabel) => ({
                  ...base,
                  borderRadius: theme.radii.lg,
                }),
                // We are removing the default styles for the multi-value remove button as we are using a custom button.
                multiValueRemove: () => ({}),
              }}
            />
          </Box>
        )}
      />
    </FormControl>
  )
}

export default MultiSelectWithFormControl
