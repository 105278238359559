import dayjs from 'dayjs'

import { DATE_FORMAT } from './constants'

const formatDate = (
  date?: string | null,
  format: string = DATE_FORMAT,
): string => {
  return date ? dayjs(date).format(format) : ''
}

export default formatDate
