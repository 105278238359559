import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiPatientsControllerUpdateRequest,
  TransmissionReportDtoSignatureStatusEnum,
} from '../../../lib/api'
import { queryClient } from '../../../lib/api/react-query'

export const useUpdatePatient = () => {
  const Api = useApiContext()
  return useMutation(
    (updatePatientParams: DefaultApiPatientsControllerUpdateRequest) =>
      Api.patientsControllerUpdate(updatePatientParams),
    {
      onSuccess: data => {
        const {
          data: { patient },
        } = data

        queryClient.invalidateQueries('patients')
        queryClient.setQueryData(['patient', patient.id], patient)
        queryClient.setQueriesData(['transmissionReport'], (previous: any) => {
          if (
            previous?.patient?.id === patient.id &&
            previous?.signatureStatus !==
              TransmissionReportDtoSignatureStatusEnum.Signed
          ) {
            previous.patient = patient
          }
          return previous
        })
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
      },
    },
  )
}
