import dayjs from 'dayjs'

import { TimeOfDay } from '../types'

/**
 * 24-11:59 morning
 * 12-17:59 afternoon
 * 18-23:59 evening
 * @param hour Accepts numbers from 0 to 23. If the range is exceeded, it will bubble up to the day.
 * @returns TimeOfDay enum | undefined
 */
const getTimeOfday = (hour?: number): TimeOfDay => {
  const currentHour = hour ?? dayjs().get('hour')

  if (currentHour < 12) return TimeOfDay.MORNING
  else if (currentHour >= 12 && currentHour < 18) return TimeOfDay.AFTERNOON
  else return TimeOfDay.EVENING
}

export default getTimeOfday
