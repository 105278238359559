import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Modal,
  Text,
  VStack,
} from '@rhythm/components'

export interface ConfirmationModalProps {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  description: string
}

const ConfirmationModal = ({
  isOpen,
  isLoading,
  onClose,
  onConfirm,
  title,
  description,
}: ConfirmationModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm} isLoading={isLoading}>
            Confirm
          </Button>
        </HStack>
      }
    >
      <VStack>
        <VStack alignItems="flex-start" width={'100%'}>
          <Heading variant="h5">{title}</Heading>
          <br />
          <Text fontSize="lg" color="neutral.800">
            {description}
          </Text>
        </VStack>
      </VStack>
    </Modal>
  )
}

export default ConfirmationModal
