import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Checkbox,
  FormControl,
  FormLabel,
  Select,
  Text,
  Tooltip,
  VStack,
} from '@rhythm/components'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import DatePickerV2 from '../../../../../../components/DatePickerv2'
import { useGetScheduleTermDays } from '../../../../../../features/transmissionReports/api/getScheduleTermDays'
import { ScheduleTerm } from '../../../../../../lib/api'
import { ScheduleTypeWithDateAndTerm } from '../constants'

import { TransmissionType } from './DeviceSchedule'

export interface DeviceScheduleFormValues {
  startDate: string | null
  transmissionType: string | null
  isEriTracked: boolean
  transmissionFrequency: {
    label: string
    value: string
    freq: number
    transmissions: number
  } | null
  isChecked?: boolean
}

export const INVALID_HEART_FAILURE_FREQUENCIES = [31, 35, 365]

export type TxCardData = {
  [K in keyof ScheduleTypeWithDateAndTerm]?: ScheduleTypeWithDateAndTerm[K] & {
    isValidFormData: boolean
    isChecked: boolean
  }
}

const TransmissionValuesCard = ({
  transmissionType,
  onInputChange,
  isDirty,
  isReadOnly,
  txCardData,
  scheduleTermsAndFrequency,
  isFormResetted,
  setIsFormResetted,
}: {
  transmissionType: TransmissionType
  isDirty: boolean
  isReadOnly: boolean
  txCardData: TxCardData
  scheduleTermsAndFrequency: {
    termDays: ScheduleTerm | null
    startDate: string | null
  }
  isFormResetted: boolean
  setIsFormResetted: Dispatch<
    SetStateAction<{
      routine: boolean
      heart_failure: boolean
      combo_billing: boolean
    }>
  >
  onInputChange: (
    txType: TransmissionType,
    value: {
      isChecked: boolean
      txDate: string | null
      txFreq: {
        label: string
        value: string
        freq: number
        transmissions: number
      } | null
      validFormData?: boolean
    },
  ) => void
}) => {
  const [isCheckedState, setIsCheckedState] = useState(false) // Used to handle the checkbox state.
  const [disableCard, setDisableCard] = useState(false) // Used to disable the card if the combo billing is selected.
  const [disableText, setDisableText] = useState(
    'Combo Billing cannot be scheduled if Routine/ Heart Failure scheduling is selected',
  )
  const { data: scheduleTermDays } = useGetScheduleTermDays()
  const {
    formState: { errors, isValid },
    control,
    watch,
    reset,
  } = useForm<DeviceScheduleFormValues>({
    resolver: yupResolver(
      yup.object().shape({
        isChecked: yup.boolean(),

        transmissionType: yup.string().nullable(),
        // .typeError('Transmission Type is required'),
        // If the isDirty is true, then have the validations for the start date.
        // or else don't have the validations.
        startDate: isDirty
          ? yup
              .string()
              .typeError('Start Date is a required field')
              .test(
                'is-date-valid',
                'Start Date must be a valid date and in MM/DD/YYYY format.',
                value => moment(value, 'MM/DD/YYYY', true).isValid(),
              )
              .test(
                'is-date-future',
                'Start Date cannot be in the past.',
                value => !moment(value, 'MM/DD/YYYY').isBefore(moment(), 'day'),
              )
          : yup.string().typeError('Start Date is a required field'),
        _transmissionFrequency: yup
          .object()
          .shape({
            label: yup.string(),
            value: yup.string(),
            freq: yup.number(),
            transmissions: yup.number(),
          })
          .typeError('Transmission Frequency is required')
          .when(['transmissionType', 'isEriTracked'], {
            is: (transmissionType: string, isEriTracked: any) => {
              return transmissionType === 'heart_failure' || isEriTracked
            },
            then: schema =>
              schema.test(
                'is-valid-frequency',
                'Heart Failure Scheduling and Eri Tracking are not supported for 31, 35, and 365 days transmission frequencies. Please select the other transmission frequencies to enable those schedules',
                value => {
                  return true
                },
              ),
            otherwise: schema => schema, // Return original schema
          }),
        get transmissionFrequency() {
          return this._transmissionFrequency
        },
        set transmissionFrequency(value) {
          this._transmissionFrequency = value
        },
      }),
    ),
    mode: 'onChange',
    defaultValues: {
      startDate: scheduleTermsAndFrequency.startDate
        ? moment(scheduleTermsAndFrequency.startDate).format('MM/DD/YYYY')
        : null,
      transmissionFrequency: scheduleTermsAndFrequency.termDays?.termDays
        ? scheduleTermDays?.[scheduleTermsAndFrequency.termDays.termDays]
        : null,
      transmissionType: transmissionType,
      isChecked: Boolean(
        scheduleTermsAndFrequency.startDate &&
          scheduleTermsAndFrequency.termDays?.termDays,
      ),
    },
  })

  const isChecked = watch('isChecked') ?? false
  const txFreq = watch('transmissionFrequency')
  const txDate = watch('startDate')

  useEffect(() => {
    if (isFormResetted) {
      reset({
        startDate: null,
        transmissionType,
        transmissionFrequency: null,
        isChecked: false,
      })

      setIsFormResetted(prev => ({
        ...prev,
        [transmissionType]: false,
      }))
    }
  }, [isFormResetted])

  useEffect(() => {
    onInputChange(transmissionType, {
      txFreq: txFreq,
      txDate: txDate?.toString() ?? null,
      isChecked: isChecked,
      validFormData: isValid,
    })
  }, [txFreq, txDate, transmissionType, isChecked, isValid])

  // if we have transmission type with combination with combo_billing then we need to disable the card
  // we should take either routine with heart_failure or combo_billing or each individually
  useEffect(() => {
    const isHeartFailureChecked = txCardData['heart_failure']?.isChecked
    const isRoutineChecked = txCardData['routine']?.isChecked
    const isComboBillingChecked = txCardData['combo_billing']?.isChecked

    const case1 =
      transmissionType === 'combo_billing' &&
      (isRoutineChecked || isHeartFailureChecked)

    const case2 =
      isComboBillingChecked &&
      ['routine', 'heart_failure'].includes(transmissionType)

    if (isReadOnly) {
      setDisableText('Editing schedules is currently disabled.')
    }
    setDisableCard(case1 || case2 || isReadOnly)
  }, [txCardData, transmissionType, isReadOnly])

  return (
    <Tooltip isDisabled={!disableCard} label={disableText}>
      <VStack
        key={`${transmissionType}-select-card`}
        spacing={4}
        w="100%"
        p={4}
        bg={disableCard || !isDirty ? 'gray.100' : 'gray.50'}
        borderRadius={4}
        alignItems={'start'}
      >
        <FormControl>
          <Controller
            name="isChecked"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Checkbox
                  isChecked={value}
                  isDisabled={disableCard || !isDirty}
                  onChange={e => {
                    onChange(e.target.checked)
                    setIsCheckedState(!isCheckedState)
                    if (!e.target.checked) {
                      reset({
                        startDate: null,
                        transmissionType: transmissionType,
                        transmissionFrequency: null,
                        isChecked: false,
                      })
                    }
                  }}
                >
                  <Text
                    color={isChecked ? 'black' : 'gray.500'}
                    fontWeight={isChecked ? 'bold' : 'normal'}
                  >
                    {transmissionType === 'combo_billing'
                      ? 'Combo Billing'
                      : transmissionType === 'routine'
                        ? 'Routine'
                        : 'Heart Failure'}
                  </Text>
                </Checkbox>
              )
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="startDate">Start Date</FormLabel>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePickerV2
                onlyOpenCalenderOnIconClick={isChecked && isDirty}
                datePickerProps={{
                  minDate: new Date(),
                  id: 'startDate',
                  selected:
                    value && moment(value, 'MM/DD/YYYY', true).isValid()
                      ? moment(value).toDate()
                      : null,
                  popperPlacement: 'bottom',
                  placeholderText: 'MM/DD/YYYY',
                  disabled: disableCard || !isChecked || !isDirty,
                  strictParsing: true,
                  adjustDateOnChange: true,
                  onChangeRaw: (e: any) => {
                    if (e?.target?.value) onChange(e.target.value)
                  },
                  onChange: (date: any) => {
                    if (date instanceof Date)
                      onChange(moment(date).format('MM/DD/YYYY'))
                  },
                }}
              />
            )}
          />
          {errors?.startDate && (
            <Text color="red" mt={2}>
              {errors?.startDate.message}
            </Text>
          )}
        </FormControl>

        <FormControl>
          <FormLabel>Transmission Frequency</FormLabel>
          {scheduleTermDays && (
            <Controller
              name="transmissionFrequency"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <Select
                    ref={ref}
                    value={value}
                    isDisabled={disableCard || !isChecked || !isDirty}
                    options={Object.values(scheduleTermDays)}
                    onChange={onChange}
                    menuPlacement="auto"
                    menuPortalTarget={document.body} // Append the dropdown to body
                    required={true}
                    styles={
                      isChecked
                        ? {
                            control: provided => ({
                              ...provided,
                              backgroundColor: 'white',
                            }),
                          }
                        : {}
                    }
                  />
                )
              }}
            />
          )}

          {errors?.transmissionFrequency && (
            <Text color="red" mt={2}>
              {errors?.transmissionFrequency.message}
            </Text>
          )}
        </FormControl>
        {JSON.stringify(errors)}
      </VStack>
    </Tooltip>
  )
}

export default TransmissionValuesCard
