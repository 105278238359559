import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Box,
  Editable,
  Error,
  Flex,
  Form,
  FormControl,
  FormLabel,
  HStack,
  Select,
  VStack,
} from '@rhythm/components'
import { get } from 'lodash'
import { Controller, useForm } from 'react-hook-form'

import DatePickerv2 from '../../../../../components/DatePickerv2'
import {
  ColumnHeader,
  getDefaultTransmissionValuesForDeviceInfo,
  RowTitle,
  TransmissionReportValuesGrid,
  usePatchTransmissionReportDevice,
} from '../../../../../features/transmissionReports'
import {
  queryClient,
  TransmissionReportDto,
  VendorDataOverrides,
  VtLeadVendor,
  VtVendor,
} from '../../../../../lib/api'
import { VENDOR_DATA_OVERRIDE_KEYS } from '../../../../../utils/constants/transmissionReports'
import formatDate from '../../../../../utils/formatDate'
import formatUtcDate from '../../../../../utils/formatUtcDate'
import getZeroHourDbDate from '../../../../../utils/getZeroHourDbDate'
import { DATE_FORMAT_REGEX } from '../../../../../utils/regex'
import NarrativeCard from '../NarrativeCard'

import { DeviceLeadModel } from './DeviceLeadModel'
import { DeviceLeadVendor } from './DeviceLeadVendor'

export type VendorOptions = {
  label: string | null
  value: string | null
}

export interface DeviceDemographicsCardProps {
  transmissionReport?: TransmissionReportDto
  vendors?: VtVendor[]
  leadVendors?: VtLeadVendor[]
}

const DeviceDemographicsCard = ({
  transmissionReport,
  vendors,
  leadVendors,
}: DeviceDemographicsCardProps): React.ReactElement => {
  const [vendorsOptions, setVendorsOptions] = useState<VendorOptions[]>([])
  const [leadVendorsOptions, setLeadVendorsOptions] = useState<VendorOptions[]>(
    [],
  )
  const { mutate: patchTransmissionReportDevice } =
    usePatchTransmissionReportDevice()
  const datePickerWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (vendors && vendors.length > 0) {
      const vendorListOptions: VendorOptions[] = []
      vendors.forEach(v => {
        vendorListOptions.push({ label: v.vendorName, value: v.vendorId })
      })
      setVendorsOptions(vendorListOptions)
    }
  }, [vendors, setVendorsOptions])
  useEffect(() => {
    if (leadVendors && leadVendors.length > 0) {
      const leadVendorListOptions: VendorOptions[] = []
      leadVendors.forEach(v => {
        leadVendorListOptions.push({
          label: v.leadVendorName,
          value: v.leadVendorId,
        })
      })
      setLeadVendorsOptions(leadVendorListOptions)
    }
  }, [leadVendors, setLeadVendorsOptions])

  const defaultValues: Record<string, any> = useMemo(
    () =>
      getDefaultTransmissionValuesForDeviceInfo(transmissionReport, vendors),
    [transmissionReport, vendors],
  )

  const { deviceLeads, device } = transmissionReport ?? {}

  const methods = useForm({
    defaultValues,
  })

  const {
    control,
    formState: { dirtyFields, errors },
    watch,
    reset,
    handleSubmit,
    getValues,
  } = methods

  const submit = useCallback(
    async (data: Record<string, any>) => {
      const [updatedField] = Object.keys(dirtyFields)
      if (!updatedField) return

      if (transmissionReport?.id && updatedField === 'manufacturer') {
        patchTransmissionReportDevice({
          transmissionReportId: transmissionReport.id,
          vendorDataOverrides: {
            'device.vendorId': data.manufacturer.value,
          },
        })
        // getting query data from the query client and directly manipulating that
        queryClient.setQueriesData<Partial<any | undefined>>(
          ['transmissionReportsList'],
          response => {
            if (response && response.transmissionReports?.length > 0) {
              const ind = response.transmissionReports.findIndex(
                (t: any) => t.id === transmissionReport.id,
              )
              if (ind > -1) {
                response.transmissionReports[ind].deviceVendor.vendorName =
                  data.manufacturer.label
              }
            }
            return response
          },
        )
      }

      reset(data)
    },
    [reset, dirtyFields, transmissionReport, patchTransmissionReportDevice],
  )

  const watchManufacturer = watch('manufacturer')

  useEffect(() => {
    handleSubmit(submit)()
  }, [watchManufacturer, handleSubmit, submit])

  const handleOverrideSubmit = useCallback(
    (data: {
      name: keyof VendorDataOverrides
      value: string | Date | Record<string, unknown>
      leadId?: VendorDataOverrides['deviceLeadId']
    }) => {
      const { name, value, leadId } = data
      if (transmissionReport?.id && VENDOR_DATA_OVERRIDE_KEYS.includes(name)) {
        const vendorDataOverrides = {
          [name]: value,
          ...(!!leadId && { deviceLeadId: leadId }),
        }
        patchTransmissionReportDevice({
          transmissionReportId: transmissionReport.id,
          vendorDataOverrides,
        })
      }
    },
    [patchTransmissionReportDevice, transmissionReport],
  )

  useEffect(() => {
    const previousValue = getValues('device.deviceImplantDate')
    const dateInput = datePickerWrapperRef.current?.querySelector(
      "[id='device.deviceImplantDate']",
    )
    const submitCallback = (e: Event) => {
      const { value } = e?.target as EventTarget & HTMLInputElement
      if (value && value !== previousValue) {
        handleOverrideSubmit({
          name: 'device.deviceImplantDate',
          value,
        })
      }
    }
    if (dateInput) {
      dateInput.addEventListener('blur', submitCallback)
    }

    return () => {
      if (dateInput) {
        dateInput.removeEventListener('blur', submitCallback)
      }
    }
  }, [datePickerWrapperRef, getValues, handleOverrideSubmit])

  const deviceType = transmissionReport?.device?.deviceType

  let report = false

  const check = 'ILR'
  if (deviceType === check) {
    report = true
  }

  return (
    <NarrativeCard
      icon="device"
      title={device?.deviceModelNumber ?? ''}
      key={`device-demographics-${transmissionReport?.id}`}
    >
      <Form methods={methods} onSubmit={submit}>
        <VStack w="full" spacing="3xl">
          <HStack w="full" spacing="3xl">
            <FormControl>
              <FormLabel htmlFor="manufacturer">VENDOR</FormLabel>
              <Controller
                name="manufacturer"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={value}
                    options={vendorsOptions}
                    isDisabled={transmissionReport === undefined}
                    isInvalid={errors.manufacturer as unknown as boolean}
                    onChange={onChange}
                  />
                )}
              />
              {errors.manufacturer && (
                <Error
                  message={get(errors, `manufacturer.message`, '') as string}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="deviceSerialNumber" mb="lg">
                SERIAL NO.
              </FormLabel>
              <Controller
                name="deviceSerialNumber"
                control={control}
                render={({ field: { value } }) => (
                  <Editable
                    name="device.deviceSerialNumber"
                    value={value}
                    defaultValue={value}
                    isDisabled
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="device.deviceImplantDate" mb="lg">
                DOI
              </FormLabel>
              <Controller
                name="device.deviceImplantDate"
                control={control}
                defaultValue={formatUtcDate(
                  transmissionReport?.device?.deviceImplantDate,
                )}
                render={({ field: { onChange, value } }) => (
                  <Box ref={datePickerWrapperRef}>
                    <DatePickerv2
                      datePickerProps={{
                        id: 'device.deviceImplantDate',
                        selected: value ? getZeroHourDbDate(value) : null,
                        onChange: (date: any) =>
                          onChange(formatDate(date.toISOString())),
                        onSelect: (date: any) => {
                          if (date) {
                            handleOverrideSubmit({
                              name: 'device.deviceImplantDate',
                              value: formatDate(date.toString()),
                            })
                          }
                        },
                        placeholderText: 'mm/dd/yyyy',
                        disabled: !transmissionReport,
                      }}
                      onlyOpenCalenderOnIconClick={true}
                      inputProps={{
                        isInvalid: !!errors?.doi,
                      }}
                    />
                  </Box>
                )}
                rules={{
                  pattern: {
                    value: DATE_FORMAT_REGEX,
                    message:
                      'Date is invalid or has wrong format (month/day/year)',
                  },
                }}
              />
              {get(errors, 'device.deviceImplantDate') && (
                <Error
                  message={
                    get(
                      errors,
                      'device.deviceImplantDate.message',
                      '',
                    ) as string
                  }
                />
              )}
            </FormControl>
          </HStack>
          <Flex w="full" hidden={report}>
            <TransmissionReportValuesGrid
              gridTemplateColumns="0.5fr repeat(3, 1fr)"
              gridTemplateRows="repeat(3, minmax(40px, auto))"
              loading={{
                isLoading: !transmissionReport,
                numOfColumns: 4,
                numOfRows: 2,
              }}
            >
              <ColumnHeader>Lead</ColumnHeader>
              <ColumnHeader>RA</ColumnHeader>
              <ColumnHeader>RV</ColumnHeader>
              <ColumnHeader>LV</ColumnHeader>

              <RowTitle>Vendor</RowTitle>
              <DeviceLeadVendor
                deviceLeads={deviceLeads}
                isDisabled={transmissionReport === undefined}
                leadVendorsOptions={leadVendorsOptions}
                onChange={handleOverrideSubmit}
              />
              <RowTitle>Model</RowTitle>
              <DeviceLeadModel
                deviceLeads={deviceLeads}
                onSubmit={handleOverrideSubmit}
              />
            </TransmissionReportValuesGrid>
          </Flex>
        </VStack>
      </Form>
    </NarrativeCard>
  )
}

export default DeviceDemographicsCard
