import { Toast, useToast } from '@rhythm/components'
import { useMutation, useQueryClient } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest } from '../../../lib/api'

export const useScheduledTransmissionTypeControllerUpdate = () => {
  const Api = useApiContext()
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    (
      req: DefaultApiScheduledTransmissionControllerUpdateScheduleTransmissionRequest,
    ) => Api.scheduledTransmissionControllerUpdateScheduleTransmission(req),

    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('patient')
        queryClient.invalidateQueries('scheduledTransmission')
        queryClient.invalidateQueries('find-upcoming-Scheduled-transmission')
        toast({
          position: 'bottom-right',
          render: ({ onClose }) => (
            <Toast variant="success" onClose={onClose}>
              Transmission Type succesfully updated.
            </Toast>
          ),
        })
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
        toast({
          position: 'bottom-right',
          render: ({ onClose }) => (
            <Toast variant="error" onClose={onClose}>
              Error Updating Transmissions
            </Toast>
          ),
        })
      },
    },
  )
}
