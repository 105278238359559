import React from 'react'

import { Circle, Icon, IconProps } from '@rhythm/components'

export interface FabProps {
  icon: IconProps['icon']
}

const Fab = ({ icon }: FabProps): React.ReactElement => {
  return (
    <Circle
      size="64px"
      bg="neutral.white"
      boxShadow="0px 2px 12px rgba(120, 134, 156, 0.55)"
    >
      <Icon icon={icon} boxSize="md" color="neutral.800" />
    </Circle>
  )
}

export default Fab
