import '@fontsource/bad-script'

import React, { useMemo } from 'react'

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import moment from 'moment'

import { getDefaultTransmissionValuesForPatient } from '../../../../../features/transmissionReports'
import { Billing, Patient, TransmissionReportDto } from '../../../../../lib/api'
import { formatCodesWithLineBreaks } from '../../../../../utils/basic'
import displayMonitoredValue from '../../../../../utils/displayMonitoredValue'
import formatUtcDate from '../../../../../utils/formatUtcDate'
import { capitalizeFirstCharacter } from '../PatientDemographicsCard/PatientDemographicsCard'

import { referringProvider } from './CreatingPDFGrey'
import device from './device.png'
import patient from './patient.png'
import Rhythm_logo from './Rhythm_logo.png'

export interface CreatingPDFProps {
  patient?: Patient
  transmissionReport?: TransmissionReportDto
  billing: Billing
  urlImg1: any
  urlImg2: any
  urlImg3: any
  urlImg4: any
  urlImg5: any
  urlImg6: any
  urlImgEpisode1: any
  urlImgEpisode2: any
  urlImgEpisode3: any
  urlegm: any
  practitionGet: any
  deviceType: any
  clinicAddress: any
  showEditedByInfoOnPdf: any
  editedUser: any
  editedDate: any
}

const CreatingPDF = ({
  transmissionReport,
  billing,
  urlImg1,
  urlImg2,
  urlImg3,
  urlImg4,
  urlImg5,
  urlImg6,
  urlImgEpisode1,
  urlImgEpisode2,
  urlImgEpisode3,
  urlegm,
  practitionGet,
  deviceType = '',
  clinicAddress,
  showEditedByInfoOnPdf,
  editedUser,
  editedDate,
}: CreatingPDFProps): React.ReactElement => {
  const { givenName, familyName, diagnosisOption, clinic } =
    transmissionReport?.patient || {}

  Font.registerHyphenationCallback(familyName => [familyName])

  const editedDateUtc = formatUtcDate(editedDate)
  const editedTimeWithTimezone = formatUtcDate(editedDate, 'hh:mm A UTC')

  const {
    deviceSerialNumber,
    deviceModelNumber,
    deviceImplantDate,
    batteryMeasurementTime,
    batteryStatus,
    batteryVoltage,
    batteryRemainingLongevity,
    batteryPercentRemaining,
    capacitorChargeTime,
  } = transmissionReport?.device || {}

  const {
    faxNumber: clinicFaxNumber,
    phoneNumber: clinicPhoneNumber,
    name: clinicName,
  } = clinic || {}

  const isSecondSign = transmissionReport?.account?.sentToEhr === 'secondSign'
  const reportComment = transmissionReport?.comment
  const faxPrefix = clinicFaxNumber ? 'Fax: ' : ''
  const telephonePrefix = clinicPhoneNumber ? 'T.: ' : ''

  const { line1, line2 } = clinicAddress?.organization?.address || {}
  const addressLines = [line1, line2].filter(Boolean).join(', ')

  const { city, postalCode, state } = clinicAddress?.organization?.address || {}
  const addressLines2 = [city, state, postalCode].filter(Boolean).join(', ')

  const transmissionReportAlerts = transmissionReport?.alerts

  let devvv = ''
  if (deviceType) {
    devvv = deviceType
  }
  const monitoredValues = transmissionReport?.deviceLeads

  const { vendorName } = transmissionReport?.deviceVendor || {}

  const alertNotes = transmissionReport?.notes
  const billingdate = billing?.billingDate
    ? moment(billing?.billingDate).format('MM/DD/yyyy')
    : '-'

  const diagnosisCodesValue = formatCodesWithLineBreaks(billing?.diagnosisCodes)
  const professionalCodesValue = formatCodesWithLineBreaks(
    billing?.professionalCodes,
  )
  const technicalcodevalue = billing?.technicalCode

  let signatureSorting: any = ''
  if (
    transmissionReport?.signatures &&
    transmissionReport?.signatures.length > 1
  ) {
    signatureSorting = transmissionReport?.signatures.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
    )
  }
  const signatures =
    signatureSorting === '' ? transmissionReport?.signatures : signatureSorting
  const signs = signatures
  let practionerForPdf: string = ''
  const practionerDetails = transmissionReport?.patient?.practitioner
  // const { currentUser } = useCurrentUser()
  const {
    hvImpedance,
    afBurden,
    apAPacePercent,
    rvpRvPacePercent,
    lvpPercent,
  } = transmissionReport?.deviceMeasurement || {}

  let alertName1 = ''
  let alertName2 = ''
  let alertName3 = ''
  let sign1Date
  let sign2Date
  let sign1Family = ''
  let sign1Given = ''
  let sign2Family = ''
  let sign2Given = ''
  let sign1Credentials = ''
  let sign2Credentials = ''
  let sign1Text
  let sign2Text

  let practitionerValue
  if (practitionGet) {
    for (let tcv = 0; tcv < practitionGet.length; tcv++) {
      practitionerValue = practitionGet[tcv].label
    }
  }
  // console.log(practitionerValue)

  if (practionerDetails) {
    practionerForPdf = `${practionerDetails.given} ${practionerDetails.family}`
  } else {
    practionerForPdf = practitionerValue
  }
  if (signs) {
    for (let i = 0; i < signs.length; i++) {
      if (i === 0) {
        sign1Family = signs[i].user.family
        sign1Given = signs[i].user.given
        const d1 = formatUtcDate(signs[i].createdAt)
          ? formatUtcDate(signs[i].createdAt)
          : ''
        sign1Date = signs[i].signDate
          ? signs[i].signDate.replaceAll('-', '/')
          : d1
        sign1Credentials = signs[i].user?.credentials?.join(', ')
        sign1Text = signs[1]?.signDate
          ? 'Electronically signed by:'
          : 'Reviewed by Technician:'
        sign2Text = signs[1]?.signDate
          ? 'Reviewed by Technician:'
          : 'Electronically signed by:'
      } else {
        sign2Family = signs[i].user.family
        sign2Given = signs[i].user.given
        const d2 = formatUtcDate(signs[i].createdAt)
          ? formatUtcDate(signs[i].createdAt)
          : ''
        sign2Date = signs[i].signDate
          ? signs[i].signDate.replaceAll('-', '/')
          : d2
        sign2Credentials = signs[i].user?.credentials?.join(', ')
        sign2Text = signs[1]?.signDate
          ? 'Reviewed by Technician:'
          : 'Electronically signed by:'
      }
    }
  }

  let transmissionCount = 0
  let transmissionReportAlertsnote = 0
  let transmissionReportAlertsnoteTwo = 0
  let alertNoteCount = 0
  let batteryNoteCount = 0
  let moniteryValuesNoteCount = 0
  let episodeAlertsnote = 0

  let epiCard1 = ''
  let epiCard2 = ''
  let epiCard3 = ''
  let epiCard4 = ''
  let epiCard5 = ''

  let monitNote1
  let monitNote2
  let monitNote3
  let monitNote4
  let monitNote5

  let batNote1
  let batNote2
  let batNote3
  let batNote4
  let batNote5

  let SensingLv
  let leadImpedanceLv
  let pacingThresholdLV
  let pacingThresholdLvPulseWidth

  let SensingRv
  let leadImpedanceRv
  let pacingThresholdRv
  let pacingThresholdRvPulseWidth

  let SensingRa
  let leadImpedanceRa
  let pacingThresholdRa
  let pacingThresholdRaPulseWidth

  let leadRa
  let leadRv
  let leadLv

  let altNote1
  let altNote2
  let altNote3
  let altNote4
  let altNote5
  let altNote6 = ''
  let altNote7 = ''
  let altNote8 = ''
  let altNote9 = ''
  let altNote10 = ''

  let note1 = ''
  let note2 = ''
  let note3 = ''
  let note4 = ''
  let note5 = ''

  let lvLeadName
  let rvLeadName
  let raLeadName

  Font.register({
    family: 'Bad Script',
    src: 'https://fonts.gstatic.com/s/badscript/v5/rL_b2ND61EQmMOJ8CRr1fi3USBnSvpkopQaUR-2r7iU.ttf',
    fontWeight: 700,
  })

  Font.register({
    family: 'Satisfy',
    src: 'https://fonts.gstatic.com/s/satisfy/v6/PRlyepkd-JCGHiN8e9WV2w.ttf',
    fontWeight: 700,
  })

  Font.register({
    family: 'Lato',
    src: 'https://fonts.gstatic.com/s/lato/v11/h7rISIcQapZBpei-sXwIwg.ttf',
    fontWeight: 400,
  })

  Font.register({
    family: 'Lato1',
    src: 'https://fonts.gstatic.com/s/lato/v11/iX_QxBBZLhNj5JHlTzHQzg.ttf',
    fontWeight: 700,
  })

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
  })

  if (alertNotes) {
    for (let inn = 0; inn < alertNotes.length; inn++) {
      const x = alertNotes[inn]
      if (x.location === 'TRANSMISSION') {
        transmissionCount++

        if (transmissionCount === 1) {
          note1 = x.note
        }
        if (transmissionCount === 2) {
          note2 = x.note
        }
        if (transmissionCount === 3) {
          note3 = x.note
        }
        if (transmissionCount === 4) {
          note4 = x.note
        }
        if (transmissionCount === 5) {
          note5 = x.note
        }
      } else if (x.location === 'BATTERY_STATUS') {
        batteryNoteCount++

        if (batteryNoteCount === 1) {
          batNote1 = x.note
        }
        if (batteryNoteCount === 2) {
          batNote2 = x.note
        }
        if (batteryNoteCount === 3) {
          batNote3 = x.note
        }
        if (batteryNoteCount === 4) {
          batNote4 = x.note
        }
        if (batteryNoteCount === 5) {
          batNote5 = x.note
        }
      } else if (x.location === 'MONITORED_VALUES') {
        moniteryValuesNoteCount++

        if (moniteryValuesNoteCount === 1) {
          monitNote1 = x.note
        }
        if (moniteryValuesNoteCount === 2) {
          monitNote2 = x.note
        }
        if (moniteryValuesNoteCount === 3) {
          monitNote3 = x.note
        }
        if (moniteryValuesNoteCount === 4) {
          monitNote4 = x.note
        }
        if (moniteryValuesNoteCount === 5) {
          monitNote5 = x.note
        }
      }
    }
  }

  if (transmissionReportAlerts) {
    for (let ind = 0; ind < transmissionReportAlerts.length; ind++) {
      const y = transmissionReportAlerts[ind]

      const z = y.notes
      alertNoteCount++
      if (alertNoteCount === 1) {
        alertName1 = y.title
        if (y.notes) {
          for (let v = 0; v < z.length; v++) {
            transmissionReportAlertsnote++

            if (transmissionReportAlertsnote === 1) {
              altNote1 = z[v].note
            }
            if (transmissionReportAlertsnote === 2) {
              altNote2 = z[v].note
            }
            if (transmissionReportAlertsnote === 3) {
              altNote3 = z[v].note
            }
            if (transmissionReportAlertsnote === 4) {
              altNote4 = z[v].note
            }
            if (transmissionReportAlertsnote === 5) {
              altNote5 = z[v].note
            }
          }
        }
      } else if (alertNoteCount === 2) {
        alertName2 = y.title
        if (y.notes) {
          for (let w = 0; w < z.length; w++) {
            transmissionReportAlertsnoteTwo++

            if (transmissionReportAlertsnoteTwo === 1) {
              altNote6 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 2) {
              altNote7 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 3) {
              altNote8 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 4) {
              altNote9 = z[w].note
            }
            if (transmissionReportAlertsnoteTwo === 5) {
              altNote10 = z[w].note
            }
          }
        }
      } else if (alertNoteCount === 3) {
        alertName3 = y.title
        if (y.notes) {
          for (let n = 0; n < z.length; n++) {
            episodeAlertsnote++

            if (episodeAlertsnote === 1) {
              epiCard1 = z[n].note
            }
            if (episodeAlertsnote === 2) {
              epiCard2 = z[n].note
            }
            if (episodeAlertsnote === 3) {
              epiCard3 = z[n].note
            }
            if (episodeAlertsnote === 4) {
              epiCard4 = z[n].note
            }
            if (episodeAlertsnote === 5) {
              epiCard5 = z[n].note
            }
          }
        }
      }
    }
  }

  const note1bullet = ''
  const note2bullet = ''
  const note3bullet = ''
  const note4bullet = ''
  const note5bullet = ''

  //Monitored values starts here
  if (monitoredValues) {
    for (let o = 0; o < monitoredValues.length; o++) {
      if (monitoredValues[o].leadLocation === 'RA') {
        SensingRa = monitoredValues[o].sensing

        leadImpedanceRa = monitoredValues[o].impedance
        pacingThresholdRa = monitoredValues[o].threshold
        pacingThresholdRaPulseWidth = monitoredValues[o].pulsewidth
        leadRa = monitoredValues[o].vendor
        raLeadName = leadRa?.vendorName
      } else if (monitoredValues[o].leadLocation === 'RV') {
        SensingRv = monitoredValues[o].sensing
        leadImpedanceRv = monitoredValues[o].impedance
        pacingThresholdRv = monitoredValues[o].threshold
        pacingThresholdRvPulseWidth = monitoredValues[o].pulsewidth
        leadRv = monitoredValues[o].vendor
        rvLeadName = leadRv?.vendorName
      } else {
        SensingLv = monitoredValues[o].sensing

        leadImpedanceLv = monitoredValues[o].impedance

        pacingThresholdLV = monitoredValues[o].threshold
        pacingThresholdLvPulseWidth = monitoredValues[o].pulsewidth
        leadLv = monitoredValues[o].vendor
        lvLeadName = leadLv?.vendorName
      }
    }
  }
  let notesname1 = ''
  let notesname2 = ''
  let notesname3 = ''
  let notesname4 = ''
  let notesname5 = ''

  if (epiCard1) {
    notesname3 = 'Notes:'
  }
  if (altNote6) {
    notesname2 = 'Notes:'
  }
  if (altNote1) {
    notesname1 = 'Notes:'
  }
  if (monitNote1) {
    notesname4 = 'Notes:'
  }
  if (batNote1) {
    notesname5 = 'Notes:'
  }

  const reportName = transmissionReport?.color

  const reportNamew = reportName as keyof typeof reportTypeMap
  const reportTypeMap = {
    YELLOW: transmissionReport?.flagHeartFailure
      ? 'Requires Review: Heart Failure report'
      : 'Requires Review',
    GREEN: transmissionReport?.flagHeartFailure
      ? 'Routine: Heart Failure report'
      : 'Routine Report',
    RED: transmissionReport?.flagHeartFailure
      ? 'Urgent: Heart Failure report'
      : 'Urgent Report',
  }

  const reportType = reportTypeMap[reportNamew]

  function ReportShow() {
    if (reportType !== 'no_device_type' && devvv !== '') {
      return <Text style={styles.roundbutton}>{reportType}</Text>
    } else {
      return <span></span>
    }
  }

  const defaultValues: Record<string, any> = useMemo(
    () => getDefaultTransmissionValuesForPatient(transmissionReport?.patient),
    [transmissionReport?.patient],
  )
  let oacValue
  if (defaultValues.oac?.label!) {
    oacValue = defaultValues.oac.label!
  }

  const cardoneNotes = [epiCard1, epiCard2, epiCard3, epiCard4, epiCard5]
  const cardTwoNotes = [altNote1, altNote2, altNote3, altNote4, altNote5]
  const cardThreeNotes = [altNote6, altNote7, altNote8, altNote9, altNote10]
  const monitNotes = [
    monitNote1,
    monitNote2,
    monitNote3,
    monitNote4,
    monitNote5,
  ]
  const battNotes = [batNote1, batNote2, batNote3, batNote4, batNote5]

  function faxNumber() {
    if (clinicFaxNumber) {
      return (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '5px',
          }}
        >
          <Text style={{ fontSize: '9pt', color: '#213147' }}>{faxPrefix}</Text>
          <Text style={{ fontSize: '9pt', color: '#455468' }}>
            {clinicFaxNumber}
          </Text>
        </View>
      )
    }
  }

  function commFunction() {
    if (isSecondSign) {
      return (
        <View
          style={{
            margin: '12px 20px 0px 20px',
            padding: '12px',
            backgroundColor: '#FFFFFF',
            borderRadius: '5px',
          }}
        >
          <Text style={styles.headingInter}>
            Provider/Clinician Interpretation
          </Text>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '9pt', color: '#213147' }}>
              {reportComment}
            </Text>
          </View>
        </View>
      )
    }
  }

  function fetchSubheading(value: string) {
    if (value !== '') {
      return <Text style={styles.subheading}>{value}</Text>
    }
  }

  function fetchHeading(value: string) {
    if (value !== '') {
      return <Text style={styles.heading}>{value}</Text>
    }
  }

  function DeviceShow() {
    if (devvv !== '') {
      return <Text style={styles.squarebutton}>{devvv}</Text>
    } else {
      return <span></span>
    }
  }

  const myFunction = cardoneNotes.map((nott, index) => {
    if (nott !== '') {
      return (
        <div key={nott + '_' + index}>
          <Text
            style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
          >
            {nott}
          </Text>
        </div>
      )
    } else {
      // eslint-disable-next-line react/jsx-key
      return <span></span>
    }
  })

  const myFunctionTwo = cardTwoNotes.map((nottr, index) => {
    if (nottr !== '') {
      return (
        <div key={nottr + '_' + index}>
          <Text
            style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
          >
            {nottr}
          </Text>
        </div>
      )
    } else {
      // eslint-disable-next-line react/jsx-key
      return <span></span>
    }
  })

  const myFunctionThree = cardThreeNotes.map((notty, index) => {
    if (notty !== '') {
      return (
        <div key={notty + '_' + index}>
          <Text
            style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
          >
            {notty}
          </Text>
        </div>
      )
    } else {
      // eslint-disable-next-line react/jsx-key
      return <span></span>
    }
  })

  const myFunctionMonitored = monitNotes.map((noott, index) => {
    return (
      <div key={noott + '_' + index}>
        <Text
          style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
        >
          {noott}
        </Text>
      </div>
    )
  })

  const myFunctionBattery = battNotes.map((noottt, index) => {
    return (
      <div key={noottt + '_' + index}>
        <Text
          style={{ fontSize: '9pt', color: '#213147', marginBottom: '2px' }}
        >
          {noottt}
        </Text>
      </div>
    )
  })

  const useEpisodeUrls = []
  useEpisodeUrls.push(urlImgEpisode1, urlImgEpisode2, urlImgEpisode3)

  if (alertName3 === 'Presenting' || alertName3 === 'Episodes') {
    useEpisodeUrls.push(urlegm)
  }

  const getEpisodeUrls = []

  const useUrls = []
  useUrls.push(urlImg3, urlImg2, urlImg1)
  if (alertName1 === 'Presenting' || alertName1 === 'Episodes') {
    useUrls.push(urlegm)
  }

  const useUrls2 = []
  useUrls2.push(urlImg6, urlImg5, urlImg4)
  if (alertName2 === 'Presenting' || alertName2 === 'Episodes') {
    useUrls2.push(urlegm)
  }

  for (let tc = 0; tc < useEpisodeUrls.length; tc++) {
    if (
      typeof useEpisodeUrls[tc] !== 'undefined' &&
      useEpisodeUrls[tc] !== ''
    ) {
      getEpisodeUrls.push(useEpisodeUrls[tc])
    }
  }

  const getUrls = []

  if (useUrls) {
    for (let ttt = 0; ttt < useUrls.length; ttt++) {
      if (typeof useUrls[ttt] !== 'undefined') {
        getUrls.push(useUrls[ttt])
      }
    }
  }

  const getUrls2 = []

  if (useUrls2) {
    for (let tt = 0; tt < useUrls2.length; tt++) {
      if (typeof useUrls2[tt] !== 'undefined') {
        getUrls2.push(useUrls2[tt])
      }
    }
  }

  const cardoneimages = getUrls.map(imagg => {
    return (
      <div key={imagg}>
        <View>
          <Image
            style={{
              marginTop: '4px',
            }}
            source={imagg}
          />
        </View>
      </div>
    )
  })

  const cardtwoimages = getUrls2.map(imgg => {
    return (
      <div key={imgg}>
        <View>
          <Image
            style={{
              marginTop: '4px',
            }}
            source={imgg}
          />
        </View>
      </div>
    )
  })

  const epiImages = getEpisodeUrls.map(immg => {
    return (
      <div key={immg}>
        <View>
          <Image
            style={{
              marginTop: '4px',
            }}
            source={immg}
          />
        </View>
      </div>
    )
  })

  console.log('alertName3 ', alertName3)
  console.log('alertName2 ', alertName2)
  console.log('alertName1 ', alertName1)

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    special: {
      fontFamily: 'Roboto',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    sign: {
      fontSize: '17pt',
      color: 'black',
      fontWeight: 700,
      fontFamily: 'Satisfy',
    },

    flex: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 0,
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    desc: {
      fontSize: '9pt',
      color: '#6C7789',
      marginBottom: '6px',
    },
    details: {
      fontSize: '9pt',
      color: '#213147',
      marginBottom: '2px',
    },
    detailsHeads: {
      fontSize: '11pt',
      fontWeight: 'bold',
      color: '#213147',
      marginBottom: '2px',
    },
    notee: {
      fontSize: '10pt',
      color: 'black',
      paddingBottom: 5,
    },
    hline: {
      border: '1px solid gray',
      margin: '10px 0 20px 0',
    },
    roundbutton: {
      borderRadius: '50px',
      padding: '4px 7px 2px 8px',
      border: '1px solid #6C7789',
      marginRight: '12px',
      backgroundColor: '#EFF2F6',
      fontSize: '9pt',
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
      color: 'black',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    squarebutton: {
      padding: '4px 7px 2px 8px',
      border: '1px solid #6C7789',
      marginRight: '12px',
      backgroundColor: '#EFF2F6',
      fontSize: '9pt',
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
      color: 'black',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    heading: {
      fontSize: '13pt',
      color: '#213147',
      marginBottom: '5px',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    headingInter: {
      fontSize: '13pt',
      marginTop: '5px',
      marginBottom: '5px',
      fontWeight: 700,
      fontFamily: 'Lato1',
    },
    subheading: {
      fontSize: '11pt',
      color: '#213147',
      marginBottom: '3px',
    },
    tableheader: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '40%',
      padding: '4px 0',
      color: '#6C7789',
      borderBottom: '1px solid #E0E5EB',
    },
    tableheader1: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '20%',
      padding: '4px 0',
      color: '#6C7789',
      borderBottom: '1px solid #E0E5EB',
    },
    tablebody: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '40%',
      padding: '4px 0',
      borderBottom: '1px solid #E0E5EB',
    },
    tablebody1: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '20%',
      padding: '4px 0',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytableheader: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '16.6%',
      padding: '4px 0',
      color: '#6C7789',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytableheader1: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '25%',
      padding: '4px 0',
      paddingLeft: 10,
      color: '#6C7789',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytablebody: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '16.6%',
      padding: '4px 0',
      borderBottom: '1px solid #E0E5EB',
    },
    batterytablebody1: {
      flex: '0 0 50%',
      fontSize: '9pt',
      maxWidth: '25%',
      paddingLeft: 20,
      padding: '4px 0',
      borderBottom: '1px solid #E0E5EB',
    },
    pageNumbers: {
      position: 'absolute',
      bottom: 10,
      left: 20,
      right: 0,
      textAlign: 'left',
      fontSize: '9pt',
      color: '#213147',
    },
    pageNumbers1: {
      position: 'absolute',
      bottom: 0,
      left: 20,
      right: 20,
      textAlign: 'right',
      fontSize: '9pt',
      color: '#213147',
    },
  })

  return (
    <Document>
      <Page
        style={{
          fontFamily: 'Lato',
          paddingBottom: '20',
        }}
        size="A4"
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingRight: 20,
            paddingLeft: 20,
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {/* <Text style={styles.roundbutton}>{reportType}</Text>
            <Text style={styles.squarebutton}>{devvv}</Text> */}
            {ReportShow()}
            {DeviceShow()}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10pt',
              }}
            >
              <Text style={{ color: '#213147' }}>
                Transmission report date:{' '}
              </Text>
              <Text style={{ color: '#455468' }}>
                {formatUtcDate(transmissionReport?.transmissionDate)}
              </Text>
            </View>
          </View>
          <View>
            <Image
              style={{ width: '72px', height: '16px', marginRight: '2px' }}
              src={Rhythm_logo}
            />
          </View>
        </View>

        <View
          style={{ border: '1px solid #E0E5EB', margin: '7px 20px 8px 20px' }}
        ></View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 20px',
          }}
        >
          <View
            style={{
              flex: '0 0 35%',
              maxWidth: '35%',
              paddingRight: '10px',
            }}
          >
            {/* <Text style={styles.details}>Left Side</Text> */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ paddingRight: '10px' }}>
                <Image style={{ width: '25px' }} src={patient} />
              </View>
              <View>
                <Text
                  style={{
                    fontSize: '9pt',
                    color: '#6C7789',
                    marginBottom: '2px',
                  }}
                >
                  Patient
                </Text>
                <Text style={styles.detailsHeads}>
                  {`${givenName} ${familyName}`}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flex: '0 0 65%',
              maxWidth: '65%',
              paddingRight: '10px',
              marginLeft: '15px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>MRN:</Text>
                <Text style={styles.desc}>
                  {defaultValues.mrn2 ? defaultValues.mrn2 : '-'}
                </Text>
              </View>

              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>Diagnosis:</Text>
                <Text style={styles.desc}>
                  {' '}
                  {diagnosisOption ? diagnosisOption : '-'}
                </Text>
              </View>

              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>Referring Provider:</Text>
                <Text style={styles.desc}>
                  {capitalizeFirstCharacter(referringProvider(defaultValues)) ??
                    '-'}{' '}
                </Text>
              </View>

              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>DOB:</Text>
                <Text style={styles.desc}>
                  {' '}
                  {defaultValues.birthDate ? defaultValues.birthDate : '-'}
                </Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>OAC:</Text>
                <Text style={styles.desc}>{oacValue ? oacValue : '-'}</Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>Following Practitioner:</Text>
                <Text style={styles.desc}>
                  {' '}
                  {practionerForPdf ? practionerForPdf : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{ border: '1px solid #E0E5EB', margin: '2px 20px 8px 20px' }}
        ></View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 20px',
          }}
        >
          <View
            style={{
              flex: '0 0 35%',
              maxWidth: '35%',
              paddingRight: '10px',
            }}
          >
            {/* <Text style={styles.details}>Left Side</Text> */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ paddingRight: '10px' }}>
                <Image style={{ width: '25px' }} src={device} />
              </View>
              <View>
                <Text
                  style={{
                    fontSize: '9pt',
                    color: '#6C7789',
                    marginBottom: '2px',
                  }}
                >
                  Device Info
                </Text>
                <Text style={styles.detailsHeads}>{deviceModelNumber}</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flex: '0 0 65%',
              maxWidth: '65%',
              paddingRight: '10px',
              marginLeft: '15px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>Manufacturer</Text>
                <Text style={styles.desc}>{vendorName ? vendorName : '-'}</Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>DOI</Text>
                <Text style={styles.desc}>
                  {formatUtcDate(deviceImplantDate)
                    ? formatUtcDate(deviceImplantDate)
                    : '-'}{' '}
                </Text>
              </View>

              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>LV Lead</Text>
                <Text style={styles.desc}>{lvLeadName ? lvLeadName : '-'}</Text>
              </View>

              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingRight: '10px',
                }}
              >
                <Text style={styles.details}>Serial No.</Text>
                <Text style={styles.desc}>
                  {' '}
                  {deviceSerialNumber ? deviceSerialNumber : '-'}
                </Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>RV Lead</Text>
                <Text style={styles.desc}>{rvLeadName ? rvLeadName : '-'}</Text>
              </View>
              <View
                style={{
                  flex: '0 0 33%',
                  maxWidth: '33%',
                  paddingLeft: '10px',
                }}
              >
                <Text style={styles.details}>RA Lead</Text>
                <Text style={styles.desc}>{raLeadName ? raLeadName : '-'}</Text>
              </View>
            </View>
          </View>
        </View>
        {/* {myTransmissionFunction} */}
        <View
          style={{
            margin: '12px 20px 0px 20px',
            padding: '12px',
            backgroundColor: '#FFFFFF',
            borderRadius: '5px',
            border: '1px solid #6C7789',
          }}
        >
          <Text
            style={{
              fontSize: '13pt',
              color: '#213147',
              marginBottom: '0px',
              fontWeight: 700,
              fontFamily: 'Lato1',
            }}
          >
            Technician Interpretation
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '5px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note1bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note1}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note2bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note2}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note3bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note3}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note4bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note4}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '0px 0 0 3px',
            }}
          >
            <Text style={{ fontSize: '10pt', marginRight: '5px' }}>
              {note5bullet}
            </Text>
            <Text style={{ fontSize: '9pt', color: '#213147' }}>{note5}</Text>
          </View>
        </View>
        {commFunction()}
        <View style={{ margin: '5px 20px 0px 20px' }}>
          {fetchHeading(alertName3)}
          {fetchSubheading(notesname3)}
          {myFunction}
        </View>
        <View style={{ paddingLeft: 20, paddingRight: 20, maxWidth: '100%' }}>
          {epiImages.reverse()}
        </View>

        <View style={{ margin: '5px 20px 0px 20px' }}>
          {fetchHeading(alertName2)}
          {fetchSubheading(notesname2)}
          {myFunctionThree}
        </View>
        <View style={{ paddingLeft: 20, paddingRight: 20, maxWidth: '100%' }}>
          {cardtwoimages.reverse()}
        </View>

        <View style={{ margin: '5px 20px 0px 20px' }}>
          {fetchHeading(alertName1)}
          {fetchSubheading(notesname1)}
          {myFunctionTwo}
        </View>
        <View style={{ paddingLeft: 20, paddingRight: 20, maxWidth: '100%' }}>
          {cardoneimages.reverse()}
        </View>

        <View style={{ margin: '15px 20px 0px' }}>
          <Text style={styles.heading}>Monitored Values</Text>
          <Text style={styles.subheading}>{notesname4}</Text>
          {myFunctionMonitored}
        </View>

        <View
          style={{
            margin: '5px 20px 0px',
          }}
        >
          <View wrap={false}>
            <View style={styles.flex}>
              <Text style={styles.tableheader}>Device</Text>
              <Text style={styles.tableheader1}>RA</Text>
              <Text style={styles.tableheader1}>RV</Text>
              <Text style={styles.tableheader1}>LV</Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.tablebody}>Sensing (mV)</Text>
              <Text style={styles.tablebody1}>
                {SensingRa ? displayMonitoredValue(SensingRa) : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {SensingRv ? displayMonitoredValue(SensingRv) : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {SensingLv ? displayMonitoredValue(SensingLv) : '-'}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.tablebody}>
                Lead Impedance <View style={styles.special}>(Ω)</View>{' '}
              </Text>
              <Text style={styles.tablebody1}>
                {leadImpedanceRa ? displayMonitoredValue(leadImpedanceRa) : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {leadImpedanceRv ? displayMonitoredValue(leadImpedanceRv) : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {leadImpedanceLv ? displayMonitoredValue(leadImpedanceLv) : '-'}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.tablebody}>Pacing Threshold (V@ms)</Text>
              <Text style={styles.tablebody1}>
                {pacingThresholdRa
                  ? displayMonitoredValue(pacingThresholdRa) +
                    ' @ ' +
                    displayMonitoredValue(pacingThresholdRaPulseWidth)
                  : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {pacingThresholdRv
                  ? displayMonitoredValue(pacingThresholdRv) +
                    ' @ ' +
                    displayMonitoredValue(pacingThresholdRvPulseWidth)
                  : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {pacingThresholdLV
                  ? displayMonitoredValue(pacingThresholdLV) +
                    ' @ ' +
                    displayMonitoredValue(pacingThresholdLvPulseWidth)
                  : '-'}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.tablebody}>
                High Voltage Impedance <View style={styles.special}>(Ω)</View>
              </Text>
              <Text style={styles.tablebody1}>-</Text>
              <Text style={styles.tablebody1}>
                {hvImpedance ? displayMonitoredValue(hvImpedance) : '-'}
              </Text>
              <Text style={styles.tablebody1}>-</Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.tablebody}>AF Burden (%)</Text>
              <Text style={styles.tablebody1}>
                {afBurden ? displayMonitoredValue(afBurden) : '-'}
              </Text>
              <Text style={styles.tablebody1}>-</Text>
              <Text style={styles.tablebody1}>-</Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.tablebody}>Percent Paced (%)</Text>
              <Text style={styles.tablebody1}>
                {apAPacePercent ? displayMonitoredValue(apAPacePercent) : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {rvpRvPacePercent
                  ? displayMonitoredValue(rvpRvPacePercent)
                  : '-'}
              </Text>
              <Text style={styles.tablebody1}>
                {lvpPercent ? displayMonitoredValue(lvpPercent) : '-'}
              </Text>
            </View>
          </View>

          <View style={{ margin: '15px 0px 0px 0px' }}>
            <Text style={styles.heading}>Battery Status</Text>
            <Text style={styles.subheading}>{notesname5}</Text>
            {myFunctionBattery}
          </View>

          <View wrap={false} style={{ marginTop: '5px' }}>
            <View style={styles.flex}>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '14%',
                  padding: '4px 0',
                  color: '#6C7789',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                Date/Time
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '14%',
                  padding: '4px 0',
                  color: '#6C7789',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                Battery Status
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '14%',
                  padding: '4px 0',
                  color: '#6C7789',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                Battery Voltage
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '16.6%',
                  padding: '4px 0',
                  color: '#6C7789',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                Remaining Longevity
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '24%',
                  padding: '4px 0',
                  color: '#6C7789',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                Battery Percent Remaining(%)
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '15%',
                  padding: '4px 0',
                  color: '#6C7789',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                Charge time
              </Text>
            </View>

            <View style={styles.flex}>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '14%',
                  padding: '4px 0',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                {batteryMeasurementTime
                  ? formatUtcDate(batteryMeasurementTime)
                  : '-'}
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '14%',
                  padding: '4px 0',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                {batteryStatus ? batteryStatus : '-'}
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '14%',
                  padding: '4px 0',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                {batteryVoltage ? batteryVoltage : '-'}
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '16.6%',
                  padding: '4px 0',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                {batteryRemainingLongevity ? batteryRemainingLongevity : '-'}
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '24%',
                  padding: '4px 0',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                {batteryPercentRemaining ? batteryPercentRemaining : '-'}
              </Text>
              <Text
                style={{
                  flex: '0 0 50%',
                  fontSize: '9pt',
                  maxWidth: '15%',
                  padding: '4px 0',
                  borderBottom: '1px solid #E0E5EB',
                }}
              >
                {capacitorChargeTime ? capacitorChargeTime : '-'}
              </Text>
            </View>
          </View>
        </View>
        <View
          wrap={false}
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#FFFFFF',
            padding: '15px 20px',
            marginTop: '10px',
            border: '1px solid #6C7789',
          }}
        >
          <View
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingRight: '10px',
              }}
            >
              <Text style={styles.subheading}>{clinicName}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                  marginTop: '5px',
                }}
              >
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  {telephonePrefix}
                </Text>
                <Text style={{ fontSize: '9pt', color: '#455468' }}>
                  {clinicPhoneNumber}
                </Text>
              </View>
              {faxNumber()}
              <Text style={{ fontSize: '9pt', color: '#455468' }}>
                {addressLines}
              </Text>
              <Text style={{ fontSize: '9pt', color: '#455468' }}>
                {addressLines2}
              </Text>
            </View>
            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingLeft: '10px',
              }}
            >
              <View>
                <Text
                  wrap={false}
                  style={styles.sign}
                >{`${sign1Given} ${sign1Family}${
                  sign1Credentials ? ', ' + sign1Credentials : ''
                }`}</Text>
              </View>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#213147',
                  marginBottom: '3px',
                }}
              >
                {sign1Text}
              </Text>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#455468',
                  marginBottom: '5px',
                }}
              >
                {`${sign1Given} ${sign1Family}`}
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  Date:{' '}
                </Text>
                <Text style={{ fontSize: '9pt', color: '#455468' }}>
                  {sign1Date}
                </Text>
              </View>
            </View>

            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingLeft: '10px',
              }}
            >
              <View>
                <Text
                  wrap={false}
                  style={styles.sign}
                >{`${sign2Given} ${sign2Family}${
                  sign2Credentials ? ', ' + sign2Credentials : ''
                }`}</Text>
              </View>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#213147',
                  marginBottom: '3px',
                }}
              >
                {sign2Text}
              </Text>
              <Text
                style={{
                  fontSize: '9pt',
                  color: '#455468',
                  marginBottom: '5px',
                }}
              >
                {`${sign2Given} ${sign2Family}`}
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  Date:{' '}
                </Text>
                <Text style={{ fontSize: '9pt', color: '#455468' }}>
                  {sign2Date}
                </Text>
              </View>
            </View>
            <View
              style={{ flex: '0 0 100%', maxWidth: '100%', marginTop: '35px' }}
            ></View>

            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingRight: '10px',
              }}
            >
              <Text style={styles.subheading}>Billing Information</Text>
            </View>
            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingLeft: '10px',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  Professional:
                </Text>
                <Text
                  style={{
                    fontSize: '9pt',
                    color: '#455468',
                    paddingLeft: 4,
                  }}
                >
                  {professionalCodesValue}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  Technical:
                </Text>
                <Text
                  style={{ fontSize: '9pt', color: '#455468', paddingLeft: 4 }}
                >
                  {technicalcodevalue ? technicalcodevalue : '-'}
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: '0 0 33%',
                maxWidth: '33%',
                paddingLeft: '10px',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  Diagnosis code:
                </Text>
                <Text
                  style={{ fontSize: '9pt', color: '#455468', paddingLeft: 4 }}
                >
                  {diagnosisCodesValue}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontSize: '9pt', color: '#213147' }}>
                  Billing date:
                </Text>
                <Text
                  style={{ fontSize: '9pt', color: '#455468', paddingLeft: 4 }}
                >
                  {billingdate ? billingdate : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {showEditedByInfoOnPdf ? (
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#EFF2F6',
              padding: '15px 20px',
              fontSize: '11pt',
            }}
          >
            <Text>
              Edited by {editedUser} on {editedDateUtc} at{' '}
              {editedTimeWithTimezone}
            </Text>
          </View>
        ) : (
          <View></View>
        )}
        <View style={styles.pageNumbers} fixed>
          <Text>
            Patient:{' '}
            {`${givenName} ${familyName}   DOB: ${
              defaultValues.birthDate ? defaultValues.birthDate : '-'
            }`}
          </Text>
          <Text
            style={styles.pageNumbers1}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages} `
            }
          />
        </View>
      </Page>
    </Document>
  )
}

export default CreatingPDF
