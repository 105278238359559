import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../context/ApiContext'
import { FindOneGlobalFilterResponse } from '../lib/api'

export function useGlobalFilter(
  options?:
    | Omit<
        UseQueryOptions<
          FindOneGlobalFilterResponse,
          unknown,
          FindOneGlobalFilterResponse,
          QueryKey
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) {
  const Api = useApiContext()
  return useQuery<FindOneGlobalFilterResponse>(
    'globalFilter',
    async () => {
      const response = await Api.globalFilterControllerFindOne()
      return response.data
    },
    options ?? {},
  )
}
