import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import { ProgrammedParametersDto } from '../../../../lib/api'
import { queryClient } from '../../../../lib/api/react-query'

export const usePatchProgrammedParameters = () => {
  const Api = useApiContext()

  const mutation = useMutation(
    ({
      transmissionReportId,
      programmedParametersDto,
    }: {
      transmissionReportId: number
      programmedParametersDto: ProgrammedParametersDto
    }) =>
      Api.transmissionReportsControllerUpdateProgrammedParameters(
        transmissionReportId,
        programmedParametersDto,
      ),
    {
      onSuccess: (data, variables) => {
        const { transmissionReportId } = variables

        queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReportId,
        ])
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
      },
    },
  )

  return mutation
}
