import { ComponentProps, FC } from 'react'

export const CombineContexts = (...contexts: FC<unknown>[]): FC => {
  return contexts.reduce(
    (AccumulatedContexts, CurrentContext) => {
      const CombinedContext = ({ children }: ComponentProps<FC>) => (
        <AccumulatedContexts>
          <CurrentContext>{children}</CurrentContext>
        </AccumulatedContexts>
      )

      // Fix a display name for easier debugging
      CombinedContext.displayName = `CombinedContext(${
        AccumulatedContexts.displayName ?? 'Unknown'
      }, ${CurrentContext.displayName ?? 'Unknown'})`

      return CombinedContext
    },
    ({ children }) => <>{children}</>,
  )
}
