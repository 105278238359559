import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import useCurrentUser from '../../../hooks/useCurrentUser'
import {
  TransmissionReportColorEnum,
  TransmissionReportSignatureStatuses,
} from '../../../lib/api'
import { PRACTITIONER_SIGNATURE_ROLES } from '../../../utils/constants/transmissionReports'

export const useGetTransmissionReportsCountByColor = (
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const { currentUser } = useCurrentUser()
  const Api = useApiContext()
  return useQuery(
    ['TransmissionReportsCountByColor'],
    async () => {
      if (!currentUser?.role) return

      const { data } = await Api.transmissionReportsControllerCount({
        queryParams: {
          signatureStatus: PRACTITIONER_SIGNATURE_ROLES.includes(
            currentUser.role,
          )
            ? TransmissionReportSignatureStatuses.NonPractitionerSigned
            : TransmissionReportSignatureStatuses.NotSigned,
        },
      })
      // just make sure we have all the key value pairs for all colors
      const colors = Object.values(TransmissionReportColorEnum)
      colors.forEach(value => {
        if ((data.counts as any)[value] === undefined) {
          ;(data.counts as any)[value] = 0
        }
      })
      return data
    },
    {
      ...options,
    },
  )
}
