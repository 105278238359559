import React, { useCallback, useEffect } from 'react'

import { Editable, EditableProps, Flex } from '@rhythm/components'

import {
  baseStyles,
  ColumnHeader,
  RowTitle,
  TransmissionReportValuesGrid,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import { useTransmissionReportCounters } from '../../../../../features/transmissionReports/hooks/useTransmissionReportCounters'
import { CountersObject } from '../../../../../lib/api'
import displayCounterValue from '../../../../../utils/displayCounterValue'

interface CellProps extends EditableProps {
  name: string
}
const Cell = (props: CellProps) => (
  <Flex {...baseStyles}>
    <Editable w="full" fontSize="sm" {...props} />
  </Flex>
)

const CountersValuesTable = (): React.ReactElement => {
  const { transmissionReport, isLoading: isLoadingTransmissionReport } =
    useTransmissionReportContext()

  const { counters, id: transmissionReportId } = transmissionReport ?? {}
  // const [countersData, setcountersData] = useState(counters)
  // useEffect(() => {
  //   if (counters) {
  //     setcountersData(counters)
  //   }
  // }, [counters])

  const { createCounters, updateCounters } = useTransmissionReportCounters()

  const { Id: countersId } = counters ?? {}

  useEffect(() => {
    if (transmissionReportId && !countersId) {
      const countersobject: CountersObject = { tachyCounters: '' }
      createCounters({
        transmissionReportId,
        transmissionReportCountersCreateParams: {
          countersobject,
        },
      })
    }
  }, [transmissionReportId, countersId, createCounters])

  const handleCountersMutation = useCallback(
    async (data: Record<string, any>) => {
      let objjValue: any = { tachyCounters: data['value'] }
      if (data['name'] === 'symptomCounters') {
        objjValue = { symptomCounters: data['value'] }
      } else if (data['name'] === 'symptomTable') {
        objjValue = { symptomTable: data['value'] }
      } else if (data['name'] === 'tachyCounters') {
        objjValue = { tachyCounters: data['value'] }
      } else if (data['name'] === 'tachyTime') {
        objjValue = { tachyTime: data['value'] }
      } else if (data['name'] === 'pauseCounters') {
        objjValue = { pauseCounters: data['value'] }
      } else if (data['name'] === 'pauseTime') {
        objjValue = { pauseTime: data['value'] }
      } else if (data['name'] === 'bradyCounters') {
        objjValue = { bradyCounters: data['value'] }
      } else if (data['name'] === 'bradyTime') {
        objjValue = { bradyTime: data['value'] }
      } else if (data['name'] === 'ATCounters') {
        objjValue = { ATCounters: data['value'] }
      } else if (data['name'] === 'ATTime') {
        objjValue = { ATTime: data['value'] }
      } else if (data['name'] === 'AFCounters') {
        objjValue = { AFCounters: data['value'] }
      } else if (data['name'] === 'AFTime') {
        objjValue = { AFTime: data['value'] }
      } else if (data['name'] === 'timeInATAFCounters') {
        objjValue = { TimeInATAFCounters: data['value'] }
      } else if (data['name'] === 'timeInATAFTime') {
        objjValue = { TimeInATAFTime: data['value'] }
      }

      //  let x = {'tachyCounters':data['value']}
      //  let countersobject = {'transmissionId':data['value'] }     // data as CountersObject
      const countersobject = objjValue
      // if (transmissionReportId && !countersId) {
      //   createCounters({
      //     transmissionReportId,
      //     transmissionReportCountersCreateParams: {
      //       countersobject,
      //     },
      //   })
      // } else

      if (transmissionReportId && countersId) {
        updateCounters({
          transmissionReportId,
          countersId,
          transmissionReportCountersUpdateParams: { countersobject },
        })
      }
    },
    [updateCounters, transmissionReportId, countersId],
  )

  return (
    <TransmissionReportValuesGrid
      gridTemplateColumns="1fr repeat(2, 20%)"
      gridTemplateRows="repeat(8, minmax(40px, auto))"
      loading={{
        isLoading: isLoadingTransmissionReport,
        numOfColumns: 3,
        numOfRows: 7,
      }}
    >
      <ColumnHeader>Type</ColumnHeader>
      <ColumnHeader>Counters</ColumnHeader>
      <ColumnHeader>LifeTime</ColumnHeader>

      <RowTitle>Symptom</RowTitle>
      {[
        { symptomCounters: counters?.symptomCounters },
        { symptomTable: counters?.symptomTable },
      ].map((data: Record<string, string | undefined | null>, _i) => {
        const [[nam, defaultValue]] = Object.entries(data)

        return (
          <Cell
            key={`Symptom-${counters?.Id}-${_i}`}
            name="Symptom"
            fontSize="12px"
            defaultValue={displayCounterValue(defaultValue)}
            onSubmit={value =>
              handleCountersMutation({
                name: nam as keyof CountersObject,
                value,
              })
            }
          />
        )
      })}

      <RowTitle>Tachy</RowTitle>
      {[
        { tachyCounters: counters?.tachyCounters },
        { tachyTime: counters?.tachyTime },
      ].map((data: Record<string, string | undefined | null>, _i) => {
        const [[nam, defaultValue]] = Object.entries(data)

        return (
          <Cell
            key={`Tachy-${counters?.Id}-${_i}`}
            name="Tachy"
            fontSize="12px"
            defaultValue={displayCounterValue(defaultValue)}
            onSubmit={value =>
              handleCountersMutation({
                name: nam as keyof CountersObject,
                value,
              })
            }
          />
        )
      })}

      <RowTitle>Pause</RowTitle>
      {[
        { pauseCounters: counters?.pauseCounters },
        { pauseTime: counters?.pauseTime },
      ].map((data: Record<string, string | undefined | null>, _i) => {
        const [[nam, defaultValue]] = Object.entries(data)

        return (
          <Cell
            key={`Pause-${counters?.Id}-${_i}`}
            name="Pause"
            fontSize="12px"
            defaultValue={displayCounterValue(defaultValue)}
            onSubmit={value =>
              handleCountersMutation({
                name: nam as keyof CountersObject,
                value,
              })
            }
          />
        )
      })}
      <RowTitle>Brady</RowTitle>
      {[
        { bradyCounters: counters?.bradyCounters },
        { bradyTime: counters?.bradyTime },
      ].map((data: Record<string, string | undefined | null>, _i) => {
        const [[nam, defaultValue]] = Object.entries(data)

        return (
          <Cell
            key={`Brady-${counters?.Id}-${_i}`}
            name="Brady"
            fontSize="12px"
            defaultValue={displayCounterValue(defaultValue)}
            onSubmit={value =>
              handleCountersMutation({
                name: nam as keyof CountersObject,
                value,
              })
            }
          />
        )
      })}
      <RowTitle>AT</RowTitle>
      {[{ ATCounters: counters?.ATCounters }, { ATTime: counters?.ATTime }].map(
        (data: Record<string, string | undefined | null>, _i) => {
          const [[nam, defaultValue]] = Object.entries(data)

          return (
            <Cell
              key={`AT-${counters?.Id}-${_i}`}
              name="AT"
              fontSize="12px"
              defaultValue={displayCounterValue(defaultValue)}
              onSubmit={value =>
                handleCountersMutation({
                  name: nam as keyof CountersObject,
                  value,
                })
              }
            />
          )
        },
      )}
      <RowTitle>AF</RowTitle>
      {[{ AFCounters: counters?.AFCounters }, { AFTime: counters?.AFTime }].map(
        (data: Record<string, string | undefined | null>, _i) => {
          const [[nam, defaultValue]] = Object.entries(data)

          return (
            <Cell
              key={`AF-${counters?.Id}-${_i}`}
              name="AF"
              fontSize="12px"
              defaultValue={displayCounterValue(defaultValue)}
              onSubmit={value =>
                handleCountersMutation({
                  name: nam as keyof CountersObject,
                  value,
                })
              }
            />
          )
        },
      )}
      <RowTitle>% of Time in AT/AF</RowTitle>
      {[
        { timeInATAFCounters: counters?.TimeInATAFCounters },
        { timeInATAFTime: counters?.TimeInATAFTime },
      ].map((data: Record<string, string | undefined | null>, _i) => {
        const [[nam, defaultValue]] = Object.entries(data)

        return (
          <Cell
            key={`timeInATAF-${counters?.Id}-${_i}`}
            name="timeInATAF"
            fontSize="12px"
            defaultValue={displayCounterValue(defaultValue)}
            onSubmit={value =>
              handleCountersMutation({
                name: nam as keyof CountersObject,
                value,
              })
            }
          />
        )
      })}
    </TransmissionReportValuesGrid>
  )
}

export default CountersValuesTable
