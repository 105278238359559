import { useQuery, UseQueryOptions } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApi,
  DefaultApiTransmissionReportsControllerFindOneRequest,
  queryClient,
} from '../../../lib/api'

export const useGetTransmissionReportById = (
  params: DefaultApiTransmissionReportsControllerFindOneRequest,
  options?: Pick<UseQueryOptions, 'enabled'>,
) => {
  const Api = useApiContext()
  return useQuery(
    ['transmissionReport', params.transmissionReportId],
    async () => {
      const { data } = await Api.transmissionReportsControllerFindOne(params)
      return data.transmissionReport
    },
    {
      ...options,
    },
  )
}

export async function getTReportById(Api: DefaultApi, id: number) {
  return Api.transmissionReportsControllerFindOne({
    transmissionReportId: id,
  })
    .then(({ data }) => {
      queryClient.invalidateQueries(['transmissionReport', id])
      return data.transmissionReport
    })
    .catch(() => {
      console.log('Error fetching transmission report by id: ', id)
      return null
    })
}
