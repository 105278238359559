import { useQuery } from 'react-query'

import { useApiContext } from '../../context/ApiContext'
import {
  AccountOverviewQuery,
  DefaultApiAnalyticsControllerAccountOverviewReportRequest,
} from '../../lib/api'

export const useGetAnalyticsAccountOverviewData = (
  accountOverviewQuery: AccountOverviewQuery,
) => {
  const Api = useApiContext()
  return useQuery(
    'accountOverviewAnalyticsData',
    async () => {
      const res = await Api.analyticsControllerAccountOverviewReport({
        accountOverviewQuery,
      } as DefaultApiAnalyticsControllerAccountOverviewReportRequest)
      return res.data
    },
    {
      enabled:
        !!accountOverviewQuery &&
        !!accountOverviewQuery.clinicIds &&
        accountOverviewQuery.clinicIds.length > 0 &&
        accountOverviewQuery.dateRange &&
        accountOverviewQuery.dateRange.length > 0 &&
        accountOverviewQuery.columns &&
        accountOverviewQuery.columns.length > 0,
    },
  )
}
