import React, { useState } from 'react'

import {
  Box,
  Button,
  Divider,
  Error,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  Select,
  Text,
} from '@rhythm/components'
import { Controller, useForm } from 'react-hook-form'

import { usStateOptions } from '../../../constants'
import { useGetTaxonomyCodes } from '../../../features/users/api/getTaxonomyCodes'
import { RegisteredNpis } from '../../../lib/api'

import ReferringProviderData from './RefferingProviderData'

export interface ReferringPhysicianModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: RegisteredNpis) => void
  isProfilePage?: boolean
}

export interface ProviderFormValuesType {
  city?: string
  state: { label: string; value: string } | null
  taxonomy: { label: string; value: string } | null
  npiNumber: string
  providerLastName: string
  providerFirstName: string
}

const ReferringPhysicianModal = ({
  isOpen,
  onClose,
  onSubmit,
  isProfilePage = false,
}: ReferringPhysicianModalProps): React.ReactElement => {
  const { data: taxonomyCodes, isLoading: isTaxonomyCodesLoading } =
    useGetTaxonomyCodes()
  const [formData, setFormData] = useState<ProviderFormValuesType | null>(null)
  const [isDataModal, setIsDataModal] = useState<boolean>(false)
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const defaultValues: ProviderFormValuesType = {
    city: '',
    taxonomy: null,
    state: null,
    npiNumber: '',
    providerFirstName: '',
    providerLastName: '',
  }

  const methods = useForm<ProviderFormValuesType>({
    defaultValues,
  })

  const handleUpdatePatient = (data: ProviderFormValuesType) => {
    setFormData(data)
    setIsDataModal(true)
  }
  const clearModalData = () => {
    methods.reset(defaultValues)
  }
  const { handleSubmit, control, watch } = methods
  const watchNpiNumber = watch('npiNumber')
  const watchproviderFirstName = watch('providerFirstName')
  const watchproviderLastName = watch('providerLastName')
  const watchCity = watch('city')
  const watchState = watch('state')
  const watchTaxonomy = watch('taxonomy')

  const providerFirstNameErrMsg =
    watchproviderFirstName.length && watchproviderFirstName.length < 2
      ? 'Minimum 2 character required'
      : undefined
  const providerLastNameErrMsg =
    watchproviderLastName.length && watchproviderLastName.length < 2
      ? 'Minimum 2 character required'
      : undefined
  const cityErrMsg =
    watchCity?.length && watchCity.length < 2
      ? 'Minimum 2 character required'
      : undefined

  const npiNumberErrMsgg = (() => {
    if (!watchNpiNumber) return undefined
    const npiString = watchNpiNumber.toString()
    const containsOnlyDigits = /^\d+$/.test(npiString)
    const isValidLength = npiString.length === 10
    return containsOnlyDigits && isValidLength
      ? undefined
      : 'Enter a valid 10 digit NPI'
  })()
  // Check if all fields are empty or there are validation errors
  const isFormEmptyOrInvalid =
    (!watchproviderFirstName &&
      !watchproviderLastName &&
      !watchCity &&
      !watchNpiNumber &&
      !watchState &&
      !watchTaxonomy) ||
    providerFirstNameErrMsg
      ? true
      : false || providerLastNameErrMsg
        ? true
        : false || cityErrMsg
          ? true
          : false || npiNumberErrMsgg
            ? true
            : false

  return (
    <Modal
      width="70%"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      hideCloseButton={true}
      footer={
        <>
          {!isDataModal && (
            <HStack spacing="xl" float={'right'}>
              <Button
                variant="secondaryDark"
                onClick={() => {
                  methods.reset(defaultValues) // Reset the form when the modal is closed
                  onClose() // Call the onClose callback
                  setIsDataModal(false)
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(handleUpdatePatient)}
                disabled={isFormEmptyOrInvalid}
                isLoading={isDataLoading}
              >
                Search
              </Button>
            </HStack>
          )}
        </>
      }
    >
      {isProfilePage && (
        <Box ml={'-25px'}>
          <Button
            onClick={() => {
              methods.reset(defaultValues) // Reset the form when the modal is closed
              onClose() // Call the onClose callback
              setIsDataModal(false)
            }}
            leftIcon="arrow-left"
            color={'black'}
            bg="none"
            _hover={{
              bg: 'none',
            }}
            _focus={{
              bg: 'none',
            }}
          >
            Back
          </Button>
        </Box>
      )}
      <Text fontSize={'20px'} fontWeight={'900'} mb={'15px'}>
        Referring Provider
      </Text>
      <form>
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem>
            <FormControl>
              <FormLabel htmlFor="providerFirstName">
                Provider First Name
              </FormLabel>
              <Controller
                name="providerFirstName"
                control={control}
                render={() => (
                  <Input
                    {...methods.register('providerFirstName')}
                    name="providerFirstName"
                    placeholder="First Name"
                  />
                )}
              />
              {providerFirstNameErrMsg && (
                <Error message={providerFirstNameErrMsg} />
              )}
            </FormControl>
          </GridItem>
          <FormControl>
            <FormLabel htmlFor="providerLastName">Provider Last Name</FormLabel>
            <Controller
              name="providerLastName"
              control={control}
              render={() => (
                <Input
                  {...methods.register('providerLastName')}
                  name="providerLastName"
                  placeholder="Last Name"
                />
              )}
            />
            {providerLastNameErrMsg && (
              <Error message={providerLastNameErrMsg} />
            )}
          </FormControl>
          <GridItem>
            <FormControl>
              <FormLabel htmlFor="npiNumber">NPI Number</FormLabel>
              <Controller
                name="npiNumber"
                control={control}
                render={() => (
                  <Input
                    {...methods.register('npiNumber')}
                    name="npiNumber"
                    placeholder="NPI Number"
                  />
                )}
              />
              {npiNumberErrMsgg && <Error message={npiNumberErrMsgg} />}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Controller
                name="city"
                control={control}
                render={() => (
                  <Input
                    {...methods.register('city')}
                    name="city"
                    placeholder="City"
                  />
                )}
              />
              {cityErrMsg && <Error message={cityErrMsg} />}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>State</FormLabel>
              <Controller
                name="state"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    placeholder={'Select State'}
                    value={value}
                    options={usStateOptions}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Taxonomy</FormLabel>
              <Controller
                name="taxonomy"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    placeholder={'Taxonomy'}
                    value={value}
                    isLoading={isTaxonomyCodesLoading}
                    options={taxonomyCodes}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
          </GridItem>
        </Grid>
        {isDataModal && formData && (
          <>
            <Divider mt={5} mb={5} />
            <GridItem alignContent={'revert'}>
              <HStack spacing="xl" justifyContent="flex-end">
                <Button
                  variant="secondaryDark"
                  onClick={() => {
                    methods.reset(defaultValues) // Reset the form when the modal is closed
                    onClose() // Call the onClose callback
                    setIsDataModal(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit(handleUpdatePatient)}
                  disabled={isFormEmptyOrInvalid}
                  isLoading={isDataLoading}
                >
                  Search
                </Button>
              </HStack>
            </GridItem>
            <ReferringProviderData
              data={formData}
              onSubmit={onSubmit}
              setIsDataModal={setIsDataModal}
              setIsDataLoading={setIsDataLoading}
              clearModalData={clearModalData}
            />
          </>
        )}
      </form>
    </Modal>
  )
}

export default ReferringPhysicianModal
