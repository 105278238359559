import { Box } from '@rhythm/components'
import moment from 'moment'

import { Organization } from '../../../../lib/api'

export type Account = {
  name: string
  id: string
  clinics?: Account[]
}

export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export const ActiveDot = ({ sx }: { sx?: any }) => (
  <Box
    as="span"
    cursor={'pointer'}
    sx={{
      fontSize: '20px',
      color: 'primary.400',
      textShadow:
        '-2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff',
      ...sx,
    }}
  >
    •
  </Box>
)

export const fetchApplicableDateRange = (
  savedDateRange: string[],
  activeDateRange: string[],
) => {
  const isValidSavedRange =
    savedDateRange.length === 2 &&
    savedDateRange.every(date => moment(date).isValid())

  const isDifferentRange =
    activeDateRange[0] !== savedDateRange[0] ||
    activeDateRange[1] !== savedDateRange[1]

  if (isValidSavedRange && isDifferentRange) {
    return savedDateRange
  }

  return activeDateRange
}

export const fetchAccountClinicMap = (clinics: Organization[]) => {
  const map = new Map<string, Account>()

  clinics?.forEach(clinic => {
    const parentId = clinic?.parent.id
    if (!parentId) return

    const account = map.get(parentId) || {
      name: clinic?.parent.name,
      id: parentId,
      clinics: [],
    }

    account.clinics?.push({
      name: clinic?.name,
      id: clinic?.id,
    })

    map.set(parentId, account)
  })
  return map
}

export const fetchFormattedDateRange = (dateRange: Date[]) => {
  const formattedDR = dateRange.map(dated => {
    const date = new Date(dated.toISOString())
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    // return in yyyy-mm-dd format and account for single digit months and days
    const formattedMonth = month < 10 ? `0${month}` : month
    const formattedDay = day < 10 ? `0${day}` : day
    return `${year}-${formattedMonth}-${formattedDay}`
  })
  return formattedDR
}
