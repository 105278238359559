import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import {
  DefaultApiTransmissionReportsControllerSignRequest,
  queryClient,
  TransmissionReportCountResponseDto,
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportListResponseDto,
} from '../../../lib/api'

import { useTransmissionReportsListContext } from '..'

export const useSignTransmissionReport = () => {
  const { setIsLoading: setIsLoadingTransmissionReportsList } =
    useTransmissionReportsListContext()
  const Api = useApiContext()
  return useMutation(
    (params: DefaultApiTransmissionReportsControllerSignRequest) => {
      return Api.transmissionReportsControllerSign(params)
    },
    {
      onSuccess: data => {
        setIsLoadingTransmissionReportsList(true)

        const {
          data: { signature },
        } = data
        let reportColor = TransmissionReportDtoColorEnum.Gray

        queryClient.setQueryData<Partial<TransmissionReportDto | undefined>>(
          ['transmissionReport', signature.transmissionReport.id],
          cachedData => {
            if (!cachedData) return
            reportColor = cachedData.color!
            const updatedSignatures = [
              ...(cachedData.signatures || []),
              signature,
            ]
            cachedData.signatures = updatedSignatures

            return cachedData
          },
        )

        queryClient.setQueryData<
          Partial<TransmissionReportListResponseDto | undefined>
        >(['transmissionReportsList'], cachedData => {
          if (!cachedData) return
          const updatedUnsignedTransmissionReports =
            cachedData.transmissionReports?.filter(
              (transmissionReport: TransmissionReportDto) =>
                transmissionReport.id !== signature.transmissionReport.id,
            ) || []
          cachedData.transmissionReports = updatedUnsignedTransmissionReports

          const updatedTotal = (cachedData?.total || 0) - 1
          cachedData.total = updatedTotal
          return cachedData
        })

        queryClient.setQueryData<
          Partial<TransmissionReportCountResponseDto | undefined>
        >(['TransmissionReportsCountByColor'], previous => {
          if (!previous || !previous.counts) return

          const previousCounts: any = { ...previous.counts }
          previousCounts[reportColor] -= 1

          return { ...previous, counts: previousCounts }
        })
        queryClient.invalidateQueries('transmissionReport')
        setIsLoadingTransmissionReportsList(false)
      },
    },
  )
}
