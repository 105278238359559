import React, { createContext, useState } from 'react'

import { CheckedPatients } from '../../../types'

export const patientSelectionContext = createContext<
  [CheckedPatients[], React.Dispatch<React.SetStateAction<CheckedPatients[]>>]
>([[], () => {}])

const PatientSelectionProvider: React.FC = props => {
  const [checkedPatients, setCheckedPatients] = useState<CheckedPatients[]>([])

  return (
    <patientSelectionContext.Provider
      value={[checkedPatients, setCheckedPatients]}
    >
      {props.children}
    </patientSelectionContext.Provider>
  )
}

export default PatientSelectionProvider
