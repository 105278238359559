import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import { DefaultApiTransmissionReportsControllerUpdateCountersRequest } from '../../../../lib/api'
import { queryClient } from '../../../../lib/api/react-query'

export const usePatchTransmissionReportCounters = () => {
  const Api = useApiContext()
  return useMutation(
    (
      TransmissionReportCountersUpdateParams: DefaultApiTransmissionReportsControllerUpdateCountersRequest,
    ) =>
      Api.transmissionReportsControllerUpdateCounters(
        TransmissionReportCountersUpdateParams,
      ),
    {
      onSuccess: (data, variables) => {
        const { transmissionReportId } = variables

        queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReportId,
        ])
      },
      onError: (error, variables, context) => {
        /**
         * Nothing to do here for now, but leaving here in case we want to
         * leverage an errors context store
         */
        // console.log('onError', error, variables, context)
      },
    },
  )
}
