import { useMutation } from 'react-query'

import { useApiContext } from '../../../../context/ApiContext'
import {
  DefaultApi,
  DefaultApiTransmissionReportsControllerDeleteNoteRequest,
  queryClient,
  TransmissionReport,
} from '../../../../lib/api'
import { useTransmissionReportContext } from '../../context/TransmissionReportContext'

export interface Note {
  transmissionReportId: string
  noteId: string
  note?: any
}

const deleteTransmissionReportAlertNote = async (
  Api: DefaultApi,
  noteInfo: Note,
  onCardUpdate?: any,
) => {
  return new Promise(async (resolve: any, reject) => {
    try {
      const { data } = (await Api.transmissionReportsControllerDeleteNote(
        noteInfo as unknown as DefaultApiTransmissionReportsControllerDeleteNoteRequest,
      )) as unknown as any
      const { deleted } = data
      const { transmissionReportId, noteId, note } = noteInfo
      if (note && onCardUpdate) {
        onCardUpdate(note, true)
        return
      }

      if (!deleted) return

      queryClient.setQueryData<Partial<TransmissionReport | undefined>>(
        ['transmissionReport', transmissionReportId],
        transmissionReport => {
          if (!transmissionReport || !noteId) {
            return
          }
          if (transmissionReport?.alerts) {
            const currentAlert = transmissionReport.alerts.find(alert =>
              alert.notes.find(note => note.id === noteId),
            )

            if (!currentAlert) return
            currentAlert.notes = currentAlert.notes.filter(
              note => note.id !== noteId,
            )

            transmissionReport.alerts = transmissionReport.alerts.map(alert =>
              alert.id === currentAlert.id ? currentAlert : alert,
            )
          } else if (transmissionReport?.notes) {
            transmissionReport.notes = transmissionReport.notes.filter(
              note => note.id !== noteId,
            )
          }

          return transmissionReport
        },
      )
      queryClient
        .invalidateQueries(['transmissionReport', transmissionReportId])
        .then(() => setTimeout(resolve, 100))
        .catch(() => setTimeout(resolve, 100))
    } catch (error) {
      console.error('deleteTransmissionReportAlertNote', error)
      reject(error)
    }
  })
}

export const useDeleteTransmissionReportAlertNote = (onCardUpdate?: any) => {
  const Api = useApiContext()

  const { transmissionReport } = useTransmissionReportContext()
  return useMutation(
    (noteInfo: Note) =>
      deleteTransmissionReportAlertNote(Api, noteInfo, onCardUpdate),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          'transmissionReport',
          transmissionReport?.id,
        ])
      },
      onError: (error, variables, context) => {},
    },
  )
}
