import '@fontsource/bad-script'

import { useEffect, useState } from 'react'

import { getFullPDF } from '../../../../../utils/transmissionReportUtil'

export interface ViewVendorReportsProps {
  reportList: any[]
  setPdfGenerated: React.Dispatch<React.SetStateAction<boolean>>
}

const ViewVendorReports = ({
  reportList,
  setPdfGenerated,
}: ViewVendorReportsProps) => {
  const [fullPdf, setFullPdf] = useState<any>(null)
  const downloadMerge = async () => {
    setFullPdf(await getFullPDF(reportList))
    setPdfGenerated(true)
  }

  useEffect(() => {
    if (reportList && reportList.length && !fullPdf) {
      downloadMerge()
    }
    // eslint-disable-next-line
  }, [reportList])

  return (
    <div>
      {fullPdf && (
        <iframe
          title={'VendorReport'}
          src={fullPdf}
          style={{ height: '800px', margin: 'auto', width: '100%' }}
        />
      )}
    </div>
  )
}

export default ViewVendorReports
