import React from 'react'

import {
  Button,
  Heading,
  HStack,
  ListItem,
  Modal,
  Text,
  UnorderedList,
} from '@rhythm/components'

import { Patient } from '../../../../../types'

export interface SignAllRoutineModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  patients: Patient[]
}

const SignAllRoutineModal = ({
  isOpen,
  onClose,
  onConfirm,
  patients,
}: SignAllRoutineModalProps): React.ReactElement => {
  const filteredPatients = patients.filter((patient, index, all) => {
    return all.findIndex(p => p.id === patient.id) === index
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      footer={
        <HStack spacing="xl">
          <Button variant="secondaryDark" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Sign All</Button>
        </HStack>
      }
    >
      <Heading variant="h4" mb="2xl">
        Sign All Routine Reports
      </Heading>
      <Text>Sign routine reports for the following patients:</Text>
      <UnorderedList mt="lg" ml="4xl">
        {filteredPatients.map(patient => (
          <ListItem key={patient.id}>
            {`${patient.givenName} ${patient.familyName}`}
          </ListItem>
        ))}
      </UnorderedList>
    </Modal>
  )
}

export default SignAllRoutineModal
