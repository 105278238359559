import { useMutation } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'
import { DefaultApiTransmissionReportsControllerSignAllToLambdaRequest } from '../../../lib/api'

export const useSignAllGreenReports = () => {
  const Api = useApiContext()
  return useMutation(
    ({
      transmissionReportSignAllParams: { signAll },
    }: DefaultApiTransmissionReportsControllerSignAllToLambdaRequest) =>
      Api.transmissionReportsControllerSignAllToLambda({
        transmissionReportSignAllParams: { signAll },
      }),
  )
}
