import React from 'react'

import { Badge, Box } from '@rhythm/components'

import { PatientActiveStatusEnum } from '../../../../../types'

export interface PatientStatusBadgeProps {
  patientStatus: string
}

const PatientStatusBadge = ({
  patientStatus,
}: PatientStatusBadgeProps): React.ReactElement => {
  return (
    <Box>
      {
        // WORKAROUND for status mysteriously changing to lowercase by itself in DB
      }
      {patientStatus?.toLowerCase() !==
        PatientActiveStatusEnum.Inactive.toLowerCase() && (
        <Badge
          variant="success"
          border={'1px'}
          borderColor={'#068923'}
          color={'black'}
          fontWeight={'bold'}
        >
          {patientStatus}
        </Badge>
      )}
      {
        // WORKAROUND for status mysteriously changing to lowercase by itself in DB
      }
      {patientStatus?.toLowerCase() ===
        PatientActiveStatusEnum.Inactive.toLowerCase() && (
        <Badge
          variant="error"
          border={'1px'}
          borderColor={'#ED3B3B'}
          color={'black'}
          fontWeight={'bold'}
        >
          Inactive
        </Badge>
      )}
    </Box>
  )
}

export default PatientStatusBadge
