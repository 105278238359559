import React, { useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'
import {
  Avatar,
  Box,
  Flex,
  Heading,
  SideNav,
  SideNavItem,
  SideNavItemProps,
  Spacer,
} from '@rhythm/components'

import CustomBadge from '../components/CustomBadge/CustomBadge'
import FilterCount from '../components/FilterCount/FilterCount'
import GlobalFiltersModal from '../components/GlobalFiltersModal/GlobalFiltersModal'
import { globalConfig } from '../config'
import { useFeatureFlagsContext } from '../context/FeatureFlagsContext'
import useCurrentUser from '../hooks/useCurrentUser'
import { useGlobalFilter } from '../hooks/useGlobalFilter'
import { Roles } from '../lib/api'
import routes from '../routes/config'

interface PageLocationState {
  backButton?: {
    label: string
    path?: string
  }
}

const HeaderBackground = (): React.ReactElement => (
  <Box
    position="absolute"
    top={0}
    left={0}
    right={0}
    bottom={0}
    bg="linear-gradient(90deg, #213147 0%, #00365E 100%)"
    height="300px"
    width="100%"
  />
)

interface MenuItem {
  key: string
  label: string
  to?: string
  href?: string
  icon: SideNavItemProps['icon']
  iconActive: SideNavItemProps['icon']
}

interface SideNavLinkProps {
  menuItem: MenuItem
  active?: boolean
}

const SideNavLink = ({
  menuItem,
  active,
}: SideNavLinkProps): React.ReactElement => {
  const to =
    menuItem.key === 'analytics'
      ? sessionStorage.getItem('selectedAnalyticsTab') ||
        routes.physician.analytics.accountOverview
      : menuItem.to
  return (
    <SideNavItem
      icon={active ? menuItem.iconActive : menuItem.icon}
      active={active}
    >
      <Heading variant="h6" fontWeight="regular">
        {menuItem.label}
      </Heading>
      {to && <RouterLink to={to} />}
      {menuItem.href && <a href={menuItem.href} />}
    </SideNavItem>
  )
}

interface AppLayoutProps {
  children: React.ReactNode
  hasHeaderBackground?: boolean
  hideSideNav?: boolean
}

const AppLayout = ({
  children,
  hasHeaderBackground,
  hideSideNav,
}: AppLayoutProps): React.ReactElement => {
  const location = useLocation<PageLocationState>()
  const { currentUser } = useCurrentUser()
  const { oktaAuth } = useOktaAuth()
  const [showGlobalFilter, setShowGlobalFilter] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [filterCounter, setFilterCounter] = useState(0)
  const runtimeConfig = globalConfig.get()
  const { hasFeatureFlag } = useFeatureFlagsContext()
  const isAnalyticsEnabled = hasFeatureFlag('analytics')

  useGlobalFilter({
    onSuccess: data => {
      setFilterCounter(
        data?.filteredClinics?.length +
          data?.filteredPractitioners?.length +
          data?.vendors?.length +
          data?.deviceTypes?.length,
      )
    },
  })

  const primaryMenu: MenuItem[] = [
    {
      key: 'dashboard',
      label: 'My Dashboard',
      to: routes.physician.dashboard,
      icon: 'dashboard',
      iconActive: 'dashboard-filled',
    },
    {
      key: 'patients',
      label: 'Patients',
      to: routes.physician.patients,
      icon: 'patients',
      iconActive: 'patients-filled',
    },
  ]

  if (isAnalyticsEnabled) {
    primaryMenu.push({
      key: 'analytics',
      label: 'Analytics',
      to: routes.physician.analytics.root,
      icon: 'analytics',
      iconActive: 'analytics',
    })
  }

  const secondaryMenu: MenuItem[] = []

  if (currentUser.role === Roles.InternalsuperAdmin) {
    secondaryMenu.push({
      key: 'settings',
      label: 'Settings',
      to: routes.user.settings,
      icon: 'settings',
      iconActive: 'settings-filled',
    })
  }

  if (!currentUser.isExternal) {
    secondaryMenu.push({
      key: 'operations-portal',
      label: 'Switch Portal',
      href: `//${runtimeConfig.OPERATIONS_DOMAIN}`,
      icon: 'connection',
      iconActive: 'connection',
    })
  }

  const getProfileDetails = () => {
    return (
      <Heading variant="h6" fontWeight="regular">
        {currentUser.firstName} {currentUser.lastName}
        {currentUser?.credentials && (
          <>
            , <br />
            {currentUser?.credentials}
          </>
        )}
      </Heading>
    )
  }
  const isAnalyticsPage = location.pathname.startsWith(
    routes.physician.analytics.root,
  )
  return (
    <Flex minHeight="100%" bg="neutral.200">
      {hasHeaderBackground && <HeaderBackground />}

      {!hideSideNav && (
        <SideNav
          width={260}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        >
          <Flex flexDirection="column" flex={1}>
            <SideNavItem
              icon={
                <Avatar
                  name={`${currentUser.firstName} ${currentUser.lastName}`}
                  size="sm"
                />
              }
            >
              {getProfileDetails()}
            </SideNavItem>
            <SideNavItem
              onClick={() => {
                setShowGlobalFilter(true)
              }}
            >
              <CustomBadge count={filterCounter} isOpen={!isCollapsed} />
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                  marginBottom: '10px',
                }}
              >
                Global Filter
                <FilterCount count={filterCounter} />
              </div>
            </SideNavItem>

            {primaryMenu.map(menuItem => {
              const active = menuItem.to
                ? location.pathname.startsWith(menuItem.to)
                : false
              return (
                <SideNavLink
                  key={menuItem.key}
                  menuItem={menuItem}
                  active={active}
                />
              )
            })}

            <Spacer flexGrow={1} />

            {secondaryMenu.map(menuItem => {
              const active = location.pathname === menuItem.to
              return (
                <SideNavLink
                  key={menuItem.key}
                  menuItem={menuItem}
                  active={active}
                />
              )
            })}
            <SideNavItem icon="sign-out" onClick={() => oktaAuth.signOut()}>
              <Heading variant="h6" fontWeight="regular">
                Log Out
              </Heading>
            </SideNavItem>
          </Flex>
        </SideNav>
      )}
      {/* {isAnalyticsPage && <AnalyticsNavBar />} */}

      <Box
        bg="neutral.200"
        px={!isAnalyticsPage ? '35px' : ''}
        mt={!isAnalyticsPage ? '65px' : ''}
        flex={1}
      >
        {children}
      </Box>
      <GlobalFiltersModal
        isModalOpen={showGlobalFilter}
        setShow={setShowGlobalFilter}
      />
    </Flex>
  )
}

export default AppLayout
