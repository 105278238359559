import { Redirect, Route, Switch } from 'react-router-dom'

import { useFeatureFlagsContext } from '../context/FeatureFlagsContext'
import {
  TransmissionReportContextProvider,
  TransmissionReportsListContextProvider,
} from '../features/transmissionReports'
import { AccountOverview } from '../pages/physician/AnalyticsPages/AccountOverview'
import { AlertsAnalytics } from '../pages/physician/AnalyticsPages/AlertsAnalytics'
import { BillingAnalytics } from '../pages/physician/AnalyticsPages/BillingAnalytics'
import { SchedulingAnalytics } from '../pages/physician/AnalyticsPages/SchedulingAnalytics'
import { SignatureStatusAnalytics } from '../pages/physician/AnalyticsPages/SignatureStatusAnalytics'
import DashboardPage from '../pages/physician/DashboardPage'
import PatientProfilePage from '../pages/physician/PatientProfilePage'
import PatientsPage from '../pages/physician/PatientsPage'
import PatientSelectionProvider from '../pages/physician/PatientsPage/PatientSelectionProvider'
import ReportsPage from '../pages/physician/ReportsPage'
import TransmissionReportPage from '../pages/physician/TransmissionReportPage'

import routes from './config'

const PhysicianRoutes = () => {
  const { hasFeatureFlag } = useFeatureFlagsContext()
  const isAnalyticsEnabled = hasFeatureFlag('analytics')
  const isSchedulingAnalyticsEnabled = hasFeatureFlag('schedulingAnalytics')
  const ShowAnalyticsComponent = ({
    Component,
    isSchedulingComponent = false,
  }: any) => {
    if (
      isAnalyticsEnabled &&
      (!isSchedulingComponent || isSchedulingAnalyticsEnabled)
    ) {
      return <Component />
    } else {
      return <Redirect to={routes.physician.dashboard} />
    }
  }
  return (
    <Switch>
      <Route path={routes.physician.dashboard}>
        <TransmissionReportsListContextProvider>
          <DashboardPage />
        </TransmissionReportsListContextProvider>
      </Route>
      <Route path={routes.physician.patientProfile}>
        <PatientProfilePage />
      </Route>
      <Route exact path={routes.physician.patients}>
        <PatientSelectionProvider>
          <PatientsPage />
        </PatientSelectionProvider>
      </Route>
      <Route exact path={routes.physician.analytics.root}>
        <ShowAnalyticsComponent Component={AccountOverview} />
      </Route>
      <Route exact path={routes.physician.analytics.accountOverview}>
        <ShowAnalyticsComponent Component={AccountOverview} />
      </Route>
      <Route exact path={routes.physician.analytics.billing}>
        <ShowAnalyticsComponent Component={BillingAnalytics} />
      </Route>
      <Route exact path={routes.physician.analytics.alerts}>
        <ShowAnalyticsComponent Component={AlertsAnalytics} />
      </Route>
      <Route path={routes.physician.analytics.scheduling}>
        <ShowAnalyticsComponent
          Component={SchedulingAnalytics}
          isSchedulingComponent={true}
        />
      </Route>
      <Route path={routes.physician.analytics.signatureStatus}>
        <ShowAnalyticsComponent Component={SignatureStatusAnalytics} />
      </Route>
      <Route path={routes.physician.reports}>
        <ReportsPage />
      </Route>
      <Route path={routes.physician.transmissionReport}>
        <TransmissionReportsListContextProvider>
          <TransmissionReportContextProvider>
            <TransmissionReportPage />
          </TransmissionReportContextProvider>
        </TransmissionReportsListContextProvider>
      </Route>
      <Route path={routes.physician.transmissionReview}>
        <TransmissionReportsListContextProvider>
          <TransmissionReportContextProvider>
            <TransmissionReportPage />
          </TransmissionReportContextProvider>
        </TransmissionReportsListContextProvider>
      </Route>
      <Route path="*">
        <Redirect to={routes.physician.dashboard} />
      </Route>
    </Switch>
  )
}

export default PhysicianRoutes
