import React, { useMemo } from 'react'

import { Card, Heading } from '@rhythm/components'

import './UpcomingScheduleCard.css'

import moment from 'moment'

type UpcomingScheduleCardProps = {
  date: string
  days: number
  device: string
  transmissionType: string
}

const UpcomingScheduleCard = ({
  date,
  days,
  transmissionType,
  device,
}: UpcomingScheduleCardProps): React.ReactElement => {
  // Memoize the date formatting to avoid unnecessary recomputation
  const formattedDate = useMemo(() => {
    const utcDate = moment.utc(date)
    return {
      month: utcDate.format('MMM'),
      day: utcDate.date(),
      year: utcDate.year(),
    }
  }, [date])

  // Memoize the transmission label computation
  const transmissionLabel = useMemo(() => {
    switch (transmissionType) {
      case 'heart_failure':
        return 'Heart Failure'
      case 'routine':
        return 'Routine'
      default:
        return 'Combo'
    }
  }, [transmissionType])

  const daysLabel = days ? `${days} Day` : ''

  return (
    <Card className="upcoming-schedule-card">
      <div className="date-box">
        <Heading variant="h6" fontSize="sm">
          {formattedDate.month}
        </Heading>
        <Heading variant="h4" fontSize="2xl">
          {formattedDate.day}
        </Heading>
        <Heading variant="h6" fontSize="sm">
          {formattedDate.year}
        </Heading>
      </div>
      <div>
        <Heading variant="h4" fontSize="1xl" marginBottom="1">
          {`${transmissionLabel} ${daysLabel} Transmission`}
        </Heading>
        <Heading variant="h6" fontSize="sm" className="event-subtitle">
          {`${device} Remote Transmission`}
        </Heading>
      </div>
    </Card>
  )
}

export default UpcomingScheduleCard
