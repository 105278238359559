import { Flex } from '@rhythm/components'

import displayValue from '../../../../../utils/displayValue'

export const DeviceLeadEmptyState = () => {
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor="neutral.200"
    >
      {displayValue('')}
    </Flex>
  )
}
