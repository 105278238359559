import { Button, Heading, HStack, Modal, Text } from '@rhythm/components'
import { useQueryClient } from 'react-query'

import { useResetScheduledTransmission } from '../../../../../../features/scheduledtransmissions/api'

const ResetModal = ({
  isOpen,
  onClose,
  deviceId,
  onReset,
}: {
  isOpen: boolean
  onClose: () => void
  deviceId: string
  onReset: () => void
}) => {
  const queryClient = useQueryClient()
  const resetScheduledTransmissionMutation = useResetScheduledTransmission()

  const handleReset = async () => {
    try {
      await resetScheduledTransmissionMutation.mutateAsync(
        {
          deviceId,
        },
        {
          onError: err => {
            console.log('err', err)
            onClose()
          },
          onSuccess: data => {
            queryClient.invalidateQueries('patient')
            queryClient.invalidateQueries('scheduledTransmission')
            queryClient.invalidateQueries(
              'find-upcoming-Scheduled-transmission',
            )
            onReset()
            onClose()
          },
        },
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        header={<Heading variant={'h5'}>Confirm Reset</Heading>}
      >
        <Text variant="h6" fontWeight="bold">
          Are you sure you want to reset an existing schedule?
        </Text>
        <HStack mt={8}>
          <Button variant="secondaryLight" size={'md'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size={'md'}
            onClick={handleReset}
            isLoading={resetScheduledTransmissionMutation.isLoading}
          >
            Save
          </Button>
        </HStack>
      </Modal>
    </div>
  )
}

export default ResetModal
