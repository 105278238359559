import moment from 'moment'
import PDFMerger from 'pdf-merger-js/browser'

import {
  TransmissionReportDto,
  TransmissionReportDtoColorEnum,
  TransmissionReportDtoSignatureStatusEnum,
} from '../lib/api'
import { FlagType, ReportType, TramssionReportColor } from '../types'

import formatUtcDate from './formatUtcDate'

export const getEGMImage = (vendorTransmissionReportsList: any) => {
  if (
    vendorTransmissionReportsList &&
    vendorTransmissionReportsList.length !== 0
  ) {
    const requiredImageInfo = vendorTransmissionReportsList.find(
      (report: any) => {
        const prefixName = JSON.parse(JSON.stringify(report))['name'].split('.')
        if (
          prefixName[prefixName.length - 1] === 'png' &&
          prefixName.some((name: any) => name.includes('Process_EGM')) &&
          prefixName.some((name: any) => name.includes('ZZZ')) &&
          report.baseEgmUrl
        ) {
          return report
        }
        return false
      },
    )
    return requiredImageInfo
  }
  return undefined
}

export const getFullPDF = async (reportList: any[]) => {
  const isFiltered = (item: any) =>
    item.key.includes('transmission-report-attachments') && item.pdfUrl

  const transmissionReportAttachments: any[] = []
  const restArray: any[] = []

  reportList.forEach(item => {
    if (isFiltered(item)) {
      transmissionReportAttachments.push(item)
    } else {
      restArray.push(item)
    }
  })

  const getTimestamp = (name: string) => parseInt(name.split('_')[0], 10)

  if (transmissionReportAttachments.length > 1) {
    transmissionReportAttachments.sort(
      (a, b) => getTimestamp(a.name) - getTimestamp(b.name),
    )
  }

  reportList = [...restArray, ...transmissionReportAttachments]

  try {
    if (reportList && reportList.length !== 0) {
      const merger = new PDFMerger()
      for (const report of reportList) {
        await merger.add(report.pdfUrl, null)
      }

      const mergedPdf = await merger.saveAsBlob()
      return URL.createObjectURL(mergedPdf)
    }
    return ''
  } catch (e) {
    console.log('Error getting full PDF', e)
    return ''
  }
}

export const hideReportDownloadStatus = (
  reportDate: string,
  createdat: string,
) => {
  const reportDownloadDate = reportDate
    ? moment(reportDate).format('MM/DD/YYYY')
    : ''
  const reportCreatedDate = createdat
    ? moment(createdat).format('MM/DD/YYYY')
    : ''
  const d1 = Date.parse('01-01-2023')
  const d2 = Date.parse(reportDownloadDate)
  const d3 = Date.parse(reportCreatedDate)
  if (reportDownloadDate === undefined || reportDownloadDate === null) {
    return d3 > d1
  }
  return d2 > d1
}

export const getDateTimeWithTimezone = () => {
  const local_date = new Date()
  const date_local = local_date.toISOString()
  const short = local_date.toLocaleDateString(undefined)
  const timeZone = local_date.toLocaleDateString(undefined, {
    timeZoneName: 'long',
  })
  const shortIndex = timeZone.indexOf(short)
  const trimmed =
    timeZone.substring(0, shortIndex) +
    timeZone.substring(shortIndex + short.length)
  const timeZonee = trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '')
  const matches = timeZonee.match(/\b(\w)/g)
  const shortTimezone = matches?.join('')
  const dd = moment(date_local).format('MM-DD-YYYY hh:mm A')
  const date = String(dd + ' ' + shortTimezone)

  return date
}

export const getLocalTimeAndTimezoneOfISODate = (isoDateString: string) => {
  const date = new Date(isoDateString)

  // Get the user's browser locale
  const userLocale = navigator.language

  // Convert to the user's local time
  const localDateString = date.toLocaleString(userLocale)
  const formattedTime = moment(localDateString).format('hh:mm:ss A')
  console.log('formattedTime', formattedTime)
  const timeZone = date
    .toLocaleTimeString(undefined, {
      timeZoneName: 'short',
    })
    .split(' ')
    .pop()
  console.log('userTimeZone', timeZone)
  return formattedTime + ' ' + (timeZone ?? '')
}

export const getCurrentDate = (format: string) => {
  const local_date = new Date()
  //let date_local = local_date.toISOString()
  const dd = moment(local_date).format(format)

  return String(dd)
}

export const getReportDownloadName = (
  reportType: string,
  givenName: string,
  familyName: string,
  transmissionDate: string,
  isFlagHeartFailure: boolean,
) => {
  try {
    const patientName = `${givenName}_${familyName}`
    if (reportType === ReportType.InClinic) {
      return `${patientName}_inclinic.pdf`
    } else if (reportType === ReportType.Manual) {
      return `${patientName}_manual.pdf`
    } else {
      return `${patientName}_${formatUtcDate(transmissionDate, 'MM.DD.YYYY')}${
        isFlagHeartFailure ? '_heartfailure' : ''
      }.pdf`
    }
  } catch (e) {
    return '`RhythmReport+.pdf`'
  }
}

export const getReportDisplayName = (
  recentReportType: string,
  device_type: string,
  recentReportColor: string,
  flagHeartFailure: boolean,
) => {
  device_type = device_type! || ''
  device_type = device_type === 'no_device_type' ? '' : device_type
  if (recentReportType === ReportType.InClinic) {
    if (flagHeartFailure) {
      return 'In-clinic ' + device_type + ' Heart Failure uploaded'
    } else {
      return 'In-clinic ' + device_type + ' uploaded'
    }
  } else if (recentReportType === ReportType.Manual) {
    if (flagHeartFailure) {
      return 'Manual ' + device_type + ' Heart Failure uploaded'
    } else {
      return 'Manual ' + device_type + ' uploaded'
    }
  } else if (recentReportType === ReportType.Remote) {
    if (recentReportColor.toString() === TramssionReportColor.YELLOW) {
      if (flagHeartFailure) {
        return 'Yellow Alert ' + device_type + ' Heart Failure transmitted'
      } else {
        return 'Yellow Alert ' + device_type + ' transmitted'
      }
    } else if (recentReportColor.toString() === TramssionReportColor.RED) {
      if (flagHeartFailure) {
        return 'Red Alert ' + device_type + ' Heart Failure transmitted'
      } else {
        return 'Red Alert ' + device_type + ' transmitted'
      }
    } else {
      if (flagHeartFailure) {
        return 'Routine ' + device_type + ' Heart Failure transmitted'
      } else {
        return 'Routine ' + device_type + ' transmitted'
      }
    }
  }
}

export const getSignedPDF = async (
  vendorTransmissionReportsList: object[],
  rhythmId: string,
) => {
  if (vendorTransmissionReportsList.length < 1) {
    return null
  }
  vendorTransmissionReportsList.sort((a: any, b: any) =>
    moment(b.lastModified).valueOf() > moment(a.lastModified).valueOf()
      ? 1
      : -1,
  )

  let hasLatestTransmissionReportItem = false
  const requiredPdfInfo = vendorTransmissionReportsList.filter(
    (report: any) => {
      const isPdf = report.name.endsWith('.pdf')
      const includesRhythmId = report.name.includes(rhythmId)
      if (isPdf) {
        if (includesRhythmId && !hasLatestTransmissionReportItem) {
          hasLatestTransmissionReportItem = true
          return true
        }
        return !includesRhythmId
      }
      return false
    },
  )

  return requiredPdfInfo?.find(
    (report: any) =>
      report.name.includes(rhythmId) ||
      report.name.includes('Signed_Rhythm_Report'),
  )
}

export const getTemplateLabel = (flagType: FlagType) => {
  switch (flagType) {
    case FlagType.NOT_SELECTED:
      return 'Not Selected'
    case FlagType.NO_ALERT:
      return 'No Alert, No Episodes'
    case FlagType.HEART_FAILURE:
      return 'Heart Failure'
  }
}

export const getSelectedTemplate = (
  flagNoAlerts: boolean,
  flagHeartFailure: boolean,
) => {
  if (!flagNoAlerts && !flagHeartFailure) {
    return getTemplateLabel(FlagType.NOT_SELECTED)
  } else if (flagNoAlerts && !flagHeartFailure) {
    return getTemplateLabel(FlagType.NO_ALERT)
  } else if (!flagNoAlerts && flagHeartFailure) {
    return getTemplateLabel(FlagType.HEART_FAILURE)
  } else if (flagNoAlerts && flagHeartFailure) {
    return (
      getTemplateLabel(FlagType.NO_ALERT) +
      ', ' +
      getTemplateLabel(FlagType.HEART_FAILURE)
    )
  }
}

export const checkIfEditedAfterSigned = (
  transmissionReport: TransmissionReportDto,
) => {
  if (
    !transmissionReport ||
    !transmissionReport.signatureStatus ||
    transmissionReport.signatureStatus !==
      TransmissionReportDtoSignatureStatusEnum.Signed ||
    !transmissionReport.editedDate ||
    !transmissionReport.editedUser
  ) {
    return false
  }
  const latestSignature = transmissionReport.signatures.reduce(
    (latest, obj) => {
      const timestampDate = new Date(obj.updatedAt)
      return timestampDate > new Date(latest.updatedAt) ? obj : latest
    },
  )
  const latestSignatureDate = new Date(latestSignature.updatedAt)
  const editedDate = new Date(transmissionReport.editedDate)
  return editedDate > latestSignatureDate
}

export const moveToNextColor = (color: string | undefined) => {
  switch (color) {
    case TransmissionReportDtoColorEnum.Red:
      return TransmissionReportDtoColorEnum.Yellow
    case TransmissionReportDtoColorEnum.Yellow:
      return TransmissionReportDtoColorEnum.Green
    case TransmissionReportDtoColorEnum.Green:
    case TransmissionReportDtoColorEnum.Gray:
    default:
      return TransmissionReportDtoColorEnum.Gray
  }
}

export const getExpandedIndex = (status?: TransmissionReportDtoColorEnum) => {
  return [
    TransmissionReportDtoColorEnum.Red,
    TransmissionReportDtoColorEnum.Yellow,
    TransmissionReportDtoColorEnum.Green,
    TransmissionReportDtoColorEnum.Gray,
  ].findIndex(s => s === status)
}
