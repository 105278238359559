import dayjs from 'dayjs'

import { DATE_FORMAT } from './constants'

const formatUtcDate = (
  date?: string | null,
  format: string = DATE_FORMAT,
): string => {
  return date ? dayjs.utc(date).format(format) : ''
}

export default formatUtcDate
