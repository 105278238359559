import { useQuery } from 'react-query'

import { useApiContext } from '../../../context/ApiContext'

export const useGetAllFlagsAdmin = () => {
  const Api = useApiContext()
  return useQuery('featureFlagsAdmin', async () => {
    const res = await Api.featureFlagControllerGetAllFlagsAdmin()
    return res.data
  })
}
