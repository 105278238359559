import { useCallback, useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  IconBadge,
  Link,
  Search,
  Skeleton,
  Text,
  Tooltip,
} from '@rhythm/components'

import {
  GetAllPatientsProps,
  useGetAllPatients,
} from '../../../features/patients'
import { routes } from '../../../routes'
import displayPlural from '../../../utils/displayPlural'

import PatientsTable from './components/PatientsTable'
import {
  getSavedTableInfo,
  saveOrUpdateFilterTable,
  TablesMapName,
} from './TableDataStorageInfo'

import './PatientsPage.css'

import { useApiContext } from '../../../context/ApiContext'
import {
  BatchJobStatus,
  TransmissionData,
  TransmissionIdsRequest,
} from '../../../lib/api'
import { CheckedPatients, PatientActiveStatusEnum } from '../../../types'

import CustomFilterButton from './components/CustomFilterButton'
import {
  DeviceScheduleFilterOptions,
  LabelAndValue,
  PatientFilterOptions,
  PatientMenuOptions,
  ReportFilterOptions,
  StatusFilterOptions,
} from './constants'
import ExportReportsModal from './ExportReportsModal'
import { patientSelectionContext } from './PatientSelectionProvider'

const queryData: GetAllPatientsProps = {
  // sortBy: 'familyName',
  // sortDirection: 'ASC',
}

const PatientsPage = () => {
  const [totalPatients, setTotalPatients] = useState<number | undefined>()

  const [canShowTable, setCanShowTable] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)
  const [isSelectAllForExport, setIsSelectAllForExport] = useState(false)
  const [isScheduleReadOnly, setIsScheduleReadOnly] = useState(true)
  const [batchJobId, setBatchJobId] = useState(0)
  const [batchReportIds, setBatchReportIds] = useState<Array<number>>([])
  const [defaultBatchStatus, setDefaultBatchStatus] = useState<string>(
    BatchJobStatus.Initialized,
  )
  const [batchStatusError, setBatchStatusError] = useState<any>(null)

  const Api = useApiContext()

  const [checkedPatients, setCheckedPatients] = useContext(
    patientSelectionContext,
  )

  const savePatientTableInfo = getSavedTableInfo(TablesMapName.PATIENTS)
  const [searchTerm, setSearchTerm] = useState<string>(
    savePatientTableInfo.searchTerm ? savePatientTableInfo.searchTerm : '',
  )

  // TODO: The filter options of the patients table need to be optimized.

  const [selectedReports, setSelectedReports] = useState<LabelAndValue>(
    savePatientTableInfo.reportOptions
      ? savePatientTableInfo.reportOptions
      : ReportFilterOptions[0],
  )
  const [selectedPatients, setSelectedPatients] = useState<LabelAndValue>(
    savePatientTableInfo.patientOptions
      ? savePatientTableInfo.patientOptions
      : PatientFilterOptions[0],
  )

  const [selectedStatus, setSelectedStatus] = useState<LabelAndValue>(
    savePatientTableInfo.statusOptions
      ? savePatientTableInfo.statusOptions
      : StatusFilterOptions[0],
  )

  const [selectedDeviceSchedulerFilter, setSelectedDeviceSchedulerFilter] =
    useState<LabelAndValue>(
      savePatientTableInfo?.deviceScheduleOptions ??
        DeviceScheduleFilterOptions.ALL,
    )

  const [allPatientsQueryData, setAllPatientsQueryData] =
    useState<GetAllPatientsProps>({
      ...queryData,
    })

  const { data: allPatientsData, isLoading: isLoadingAllPatients } =
    useGetAllPatients(allPatientsQueryData)

  useEffect(() => {
    if (!allPatientsData) return
    setIsScheduleReadOnly(
      allPatientsData?.transmissionScheduleReadOnly === true,
    )
  }, [allPatientsData])

  const isLoading = isLoadingAllPatients

  const getDownloadReportIds = useCallback(() => {
    let transmissiondata: Array<TransmissionData> = []
    if (checkedPatients && checkedPatients.length > 0) {
      checkedPatients.forEach((checkedItem: CheckedPatients) => {
        transmissiondata = transmissiondata.concat(checkedItem.transmissionData)
      })
    }

    let reportIds: Array<number> = []
    if (transmissiondata.length > 0) {
      reportIds = transmissiondata.map(
        (x: TransmissionData) => x.transmissionId,
      ) as Array<number>
    }
    return reportIds
  }, [checkedPatients])

  const getCurrentPagePatients = useCallback(() => {
    const currentPagePatients: CheckedPatients[] = []
    if (allPatientsData?.patients) {
      allPatientsData.patients.forEach((patient: any) => {
        const transmissionData: Array<TransmissionData> = []

        if (patient?.reportData) {
          patient.reportData.forEach((reportItem: any) => {
            if (reportItem.downloaded === !1) {
              transmissionData.push({
                transmissionId: reportItem.id,
              })
            }
          })
        }
        currentPagePatients.push({
          patientId: patient.id,
          scheduleReadOnly: isScheduleReadOnly,
          transmissionData,
        } as any)
      })
    }
    return currentPagePatients
  }, [allPatientsData])

  useEffect(() => {
    const currPagePatients = getCurrentPagePatients()
    localStorage.setItem(
      'currentPagePatients',
      JSON.stringify(currPagePatients ?? []),
    )
    if (totalPatients !== undefined || allPatientsData === undefined) return
    setTotalPatients(allPatientsData.total)
  }, [allPatientsData, totalPatients, getCurrentPagePatients])

  const onSearchTextChange = (newTrimmedValue: string): void => {
    setSearchTerm(newTrimmedValue)
    saveOrUpdateFilterTable(TablesMapName.PATIENTS, {
      searchTerm: newTrimmedValue,
    })
  }

  /**
   * This function is triggered when a filter option is changed.
   * It clears the preserved checkboxes as records are changing in the table.
   * It also saves the updated filter options in the table data storage.
   *
   * @param {Record<string, Record<string, string>>} optionChange - The changed filter options.
   */
  const onFilterChange = (
    optionChange: Record<string, Record<string, string>>,
  ) => {
    // Clear preserved checkboxes as records are changing in table
    setCheckedPatients([])
    // Reset the select all for export flag
    setIsSelectAllForExport(false)
    // Save the updated filter options in the table data storage
    saveOrUpdateFilterTable(TablesMapName.PATIENTS, {
      ...optionChange,
      pageIndex: 0,
      pageSize: 10,
    })
  }

  const downloadReportIds: number[] = getDownloadReportIds()

  const prepareDownload = async () => {
    // handles select all sceanrio for batch download
    if (isCheckedAllSelected() && isSelectAllForExport) {
      const batchRequest: TransmissionIdsRequest = {
        isDownloaded: true,
        isNew: (selectedPatients?.value ?? '') === PatientMenuOptions.NEW,
        isRecentSigned:
          (selectedReports?.value ?? '') === PatientMenuOptions.RECENT
            ? true
            : undefined,
        isSelectedAll: true,
        status: selectedStatus?.value ?? PatientActiveStatusEnum.All,
      }

      try {
        const result =
          await Api.batchDownloadControllerGetPatientTransmissionData({
            transmissionIdsRequest: batchRequest,
          })
        const jobId = (result?.data?.jobId ?? 0) as number
        setBatchJobId(jobId)
        if (result.data.isSuccess === !1 || jobId === 0) {
          const errorObj: any = result.data.error
          if (errorObj && errorObj.error) {
            //err
            const errorStr =
              typeof errorObj.error === 'string'
                ? errorObj.error
                : JSON.stringify(errorObj.error)
            if (
              errorStr.indexOf('Message must be shorter than 262144 bytes') > -1
            ) {
              setBatchStatusError(
                'To ensure a smooth export process, please reduce the number of selected patients.',
              )
            }
          }
          setDefaultBatchStatus(BatchJobStatus.Failed)
        } else {
          setDefaultBatchStatus(BatchJobStatus.Initialized)
          setBatchReportIds(result?.data?.transmissionIds ?? [])
        }
      } catch (err) {
        setDefaultBatchStatus(BatchJobStatus.Failed)
      }
    } else {
      // handles selected patients sceanrio for batch download
      let transmissiondata: Array<TransmissionData> = []
      if (checkedPatients && checkedPatients.length > 0) {
        checkedPatients.forEach((checkedItem: CheckedPatients) => {
          transmissiondata = transmissiondata.concat(
            checkedItem.transmissionData,
          )
        })
      }

      if (transmissiondata.length > 0) {
        try {
          const preparing = await Api.batchDownloadControllerCreate({
            createInitializeBatchJobRequest: { transmissiondata },
          })
          const jobId = (preparing?.data?.jobId ?? 0) as number
          setBatchJobId(jobId)
          setDefaultBatchStatus(BatchJobStatus.Initialized)
          setBatchReportIds(downloadReportIds)
        } catch (err) {
          setDefaultBatchStatus(BatchJobStatus.Failed)
        }
      }
    }
  }

  const handleExportClick = () => {
    setShowExportModal(true)
    prepareDownload()
  }

  const isExportDisabled = useCallback(() => {
    const totalNotDownloadedCount =
      allPatientsData?.totalNotDownloadedCount ?? 0
    return (
      (checkedPatients?.length ?? 0) === 0 ||
      totalNotDownloadedCount === 0 ||
      (!isSelectAllForExport && downloadReportIds.length === 0)
    )
  }, [
    checkedPatients,
    downloadReportIds,
    allPatientsData,
    isSelectAllForExport,
  ])

  const isCheckedAllSelected = useCallback(() => {
    const allPatientsCount = allPatientsData?.patients?.length ?? 0
    return (
      allPatientsCount > 0 &&
      (checkedPatients?.length ?? 0) === allPatientsCount
    )
  }, [checkedPatients, allPatientsData])

  const onCloseUncheckAll = () => {
    setShowExportModal(false)
    setBatchJobId(0)
    setIsSelectAllForExport(false)
    setCheckedPatients([])
  }

  const onCloseExportModal = () => {
    setShowExportModal(false)
    setBatchJobId(0)
    setBatchStatusError(null)
    if (!isCheckedAllSelected()) {
      setIsSelectAllForExport(false)
    }
  }

  const handleSelectOrClearAllSelection = () => {
    if (isSelectAllForExport) {
      setIsSelectAllForExport(false)
      setCheckedPatients([])
    } else {
      setIsSelectAllForExport(true)
    }
  }

  return (
    <Flex pb="80px" flexDirection="row" transition="width 0.5s ease">
      <ExportReportsModal
        jobId={batchJobId}
        isOpen={showExportModal}
        defaultBatchStatus={defaultBatchStatus}
        tranmissionReportIds={batchReportIds}
        onClose={() => onCloseExportModal()}
        batchStatusError={batchStatusError}
        onCloseUncheckAll={() => onCloseUncheckAll()}
      />

      <Box w="100%">
        <Flex>
          <br></br> <br></br>
          <RouterLink
            to={{
              pathname: routes.physician.dashboard,
            }}
          >
            <Link as={'div'} fontSize={'md'}>
              <HStack>
                <Icon boxSize={'sm'} icon={'arrow-left'} />
                <Box>Back</Box>
              </HStack>
            </Link>
          </RouterLink>
        </Flex>
        <Skeleton
          h="lg"
          mb="2xl"
          width="xl"
          isLoaded={totalPatients !== undefined}
        >
          <Heading variant="h3" fontWeight="normal" mb="24px">
            {displayPlural({
              displayCount: false,
              count: totalPatients || 0,
              word: 'Patient Management',
            })}
          </Heading>
        </Skeleton>
        <Flex direction="column" display={'inline-block'} width={'100%'}>
          <Box width="700px">
            {canShowTable && (
              <Search
                placeholderColor="neutral.800"
                onDebouncedSearch={onSearchTextChange}
                size="md"
                value={searchTerm}
                float={'left'}
                width={700}
                id={'txtPatientSearch'}
              />
            )}
          </Box>

          <Tooltip
            hasArrow
            label={'No new reports available to download'}
            placement="top"
            arrowSize={25}
            marginBottom={5}
            visibility={isExportDisabled() ? 'visible' : 'hidden'}
          >
            <Button
              variant={'primary'}
              type="submit"
              float={'right'}
              isLoading={isLoading}
              borderRadius={8}
              size="md"
              onClick={
                !isExportDisabled() ? () => handleExportClick() : () => {}
              }
              className={isExportDisabled() ? 'disabledExport' : ''}
            >
              <IconBadge
                icon={'download'}
                size="sm"
                background={'transparent'}
                color={'white'}
              />
              {'Export Reports'} {isSelectAllForExport}
            </Button>
            {/* </div> */}
          </Tooltip>
        </Flex>
        <Flex
          gap={2}
          justifyContent={'space-between'}
          alignItems={'center'}
          bg={'white'}
          mt={2}
          px={4}
          py={3}
          borderRadius={'8px 9px 0px 0px'}
        >
          <HStack flex={1} gap={2}>
            <CustomFilterButton
              placeholder={'Patients'}
              currentOption={selectedPatients}
              options={PatientFilterOptions}
              setValue={option => {
                setSelectedPatients(option)
                onFilterChange({
                  patientOptions: option,
                })
              }}
            />
            <CustomFilterButton
              placeholder={'Reports'}
              currentOption={selectedReports}
              options={ReportFilterOptions}
              setValue={option => {
                setSelectedReports(option)
                onFilterChange({
                  reportOptions: option,
                })
              }}
            />
            <CustomFilterButton
              placeholder={'Status'}
              currentOption={selectedStatus}
              options={StatusFilterOptions}
              setValue={option => {
                setSelectedStatus(option)
                onFilterChange({
                  statusOptions: option,
                })
              }}
            />
            {!isScheduleReadOnly && (
              <CustomFilterButton
                placeholder={'Device Schedule'}
                currentOption={selectedDeviceSchedulerFilter}
                options={Object.values(DeviceScheduleFilterOptions)}
                setValue={option => {
                  setSelectedDeviceSchedulerFilter(option)
                  onFilterChange({
                    deviceScheduleOptions: option,
                  })
                  setAllPatientsQueryData({
                    ...allPatientsQueryData,
                    deviceSchedule: option.value,
                  })
                }}
              />
            )}
          </HStack>
          <Skeleton isLoaded={allPatientsData?.total !== undefined}>
            <Text>{`${allPatientsData?.total} Patients`}</Text>
          </Skeleton>
        </Flex>

        {allPatientsData &&
          checkedPatients &&
          checkedPatients.length > 0 &&
          checkedPatients.length !== (allPatientsData?.total ?? 0) &&
          isCheckedAllSelected() && (
            <Box
              style={{
                display: 'flex',
                textAlign: 'center',
                height: '38px',
                width: '100%',
                background: '#fff',
                borderRadius: '0px 0px 8px 8px',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: '1px solid #eeeeee',
              }}
              onClick={() => {}}
            >
              All &nbsp;
              <Text fontWeight={700}>
                {' '}
                {isSelectAllForExport
                  ? allPatientsData?.total ?? 0
                  : checkedPatients?.length ?? 0}
                &nbsp; patients &nbsp;
              </Text>
              {isSelectAllForExport ? '' : 'on this page '}
              are selected.
              <Text
                mb="xs"
                className="patientText"
                color={'#1083CB'}
                fontWeight={700}
                paddingLeft={2}
                onClick={() => handleSelectOrClearAllSelection()}
              >
                {!isSelectAllForExport
                  ? `Select all ${allPatientsData?.total} patients`
                  : 'Clear selection'}
              </Text>
            </Box>
          )}

        {canShowTable && (
          <PatientsTable
            data={allPatientsData}
            isLoading={isLoadingAllPatients}
            queryData={allPatientsQueryData}
            selectReports={selectedReports.value}
            selectPatients={selectedPatients.value}
            selectStatus={selectedStatus.value}
            selectedDeviceSchedule={selectedDeviceSchedulerFilter.value}
            searchTerm={searchTerm}
            setQueryData={setAllPatientsQueryData}
          />
        )}
        {!isLoadingAllPatients &&
          allPatientsData &&
          allPatientsData.patients &&
          allPatientsData.patients.length === 0 && (
            <Center mt="4xl">
              {searchTerm.length >= 3
                ? `There are currently no patients with names consisting of ${searchTerm.trim()}`
                : 'There are currently no patients'}
            </Center>
          )}
      </Box>
    </Flex>
  )
}

export default PatientsPage
